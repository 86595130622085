import axios from "axios";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./MedicalReport.scss";
import { UserContext } from "../../../../Context/UserContextAPI";
import { format } from "date-fns";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode.react";

const MedicalReportCopy = () => {
  const staticURL = `${process.env.REACT_APP_API_BASE_URL}/static-contents`;
  const { accessPerm } = useContext(UserContext);
  const navigate = useNavigate();
  const { userId } = useParams();
  const [statusState, setStatusState] = useState(0);
  const [checked, setChecked] = useState(false);
  const [physicalNewRiskFactor, setPhysicalNewRiskFactor] = useState("");
  const urlWithBase64ID = `https://${process.env.REACT_APP_API_BASE_URL}/medical-verification/result/report/print/${userId}`;

  function divideArray(array) {
    const length = array.length;
    const chunkSize = Math.ceil(length / 4);

    const result = [[], [], [], []];

    for (let i = 0; i < length; i++) {
      const chunkIndex = Math.floor(i / chunkSize);
      result[chunkIndex].push(array[i]);
    }
    return result;
  }

  const [userResultsData, setUserResultsData] = useState({
    user_id: userId,
    final_comments: "",
    advice: "",
    risk_factor: "",
    is_auto: 0,
    status: 0,
  });

  const removeHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const checkboxHandler = (e) => {
    setChecked(e.target.checked);

    if (e.target.checked) {
      setUserResultsData((prev) => {
        return {
          ...prev,
          is_auto: 1,
        };
      });
    } else {
      setUserResultsData((prev) => {
        return {
          ...prev,
          is_auto: 0,
        };
      });
    }
  };

  let pathologyStatus = "";
  let physicalStatus = "";
  let pathologyRiskFactor = "";
  let physicalRiskFactor = "";

  // const pathologyStatusCheckHandler = (reports) => {
  //   reports?.forEach((report) => {
  //     // check hemoglobin status
  //     if (report.investigation_id === 15) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 16) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       } else if (report.result < 11.8) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       }
  //     }

  //     // check R.B.S status
  //     if (report.investigation_id === 16) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 7.5) {
  //         pathologyStatus += "R.B.S.: " + report?.result + ", ";
  //       } else if (report.result > 7.5 && report.result <= 9) {
  //         pathologyRiskFactor += "30% Risk for Diabetes, ";
  //       } else if (report.result > 9 && report.result <= 12) {
  //         pathologyRiskFactor += "20% Risk for Diabetes, ";
  //       } else if (report.result > 12 && report.result <= 15) {
  //         pathologyRiskFactor += "40% Risk for Diabetes, ";
  //       } else if (report.result > 15 && report.result <= 18) {
  //         pathologyRiskFactor += "50% Risk for Diabetes, ";
  //       } else if (report.result > 18 && report.result <= 25) {
  //         pathologyRiskFactor += "75% Risk for Diabetes, ";
  //       } else if (report?.result > 25) {
  //         pathologyRiskFactor += "90~100% Risk for Diabetes, ";
  //       }
  //     }

  //     // check S. Creatinine status
  //     if (report.investigation_id === 17) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 1.3) {
  //         pathologyStatus += "S. Creatinine: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 2) {
  //         pathologyRiskFactor += "35% Risk for S. Creatinine, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "60% Risk for S. Creatinine, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for S. Creatinine, ";
  //       }
  //     }

  //     // check S.Bilirubin status
  //     if (report.investigation_id === 20) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 0.99) {
  //         pathologyStatus += "S.Bilirubin: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 1.6) {
  //         pathologyRiskFactor += "35% Risk for Jaundice, ";
  //       } else if (report.result > 1.6 && report.result <= 2) {
  //         pathologyRiskFactor += "50% Risk for Jaundice, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "75% Risk for Jaundice, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for Jaundice, ";
  //       }
  //     }

  //     // check Malarial Parasite status
  //     if (report.investigation_id === 25) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Found") {
  //         pathologyStatus += "Malarial Parasite: Found, ";
  //       }
  //     }

  //     // check SGPT status
  //     if (report.investigation_id === 23) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 41) {
  //         pathologyStatus += "SGPT: " + report?.result + ", ";
  //       } else if (report.result > 60 && report.result <= 80) {
  //         pathologyRiskFactor += "20% Risk for SGPT, ";
  //       } else if (report.result > 80 && report.result <= 100) {
  //         pathologyRiskFactor += "25% Risk for SGPT, ";
  //       } else if (report.result > 100 && report.result <= 140) {
  //         pathologyRiskFactor += "40% Risk for SGPT, ";
  //       } else if (report.result > 140 && report.result <= 200) {
  //         pathologyRiskFactor += "75% Risk for SGPT, ";
  //       } else if (report.result > 200) {
  //         pathologyRiskFactor += "90~100% Risk for SGPT, ";
  //       }
  //     }

  //     // check SGOT status
  //     if (report.investigation_id === 24) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 38) {
  //         pathologyStatus += "SGOT: " + report?.result + ", ";
  //       }
  //     }

  //     // check C.U. Sugar status
  //     if (report.investigation_id === 21) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > "NIL") {
  //         pathologyStatus += "C.U. Sugar: Nill, ";
  //       }
  //     }

  //     // check HBsAg status
  //     if (report.investigation_id === 26) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HBsAg: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyRiskFactor += "100% Risk for Blood(HBsAg), ";
  //       }
  //     }

  //     // check VDRL status
  //     if (report.investigation_id === 27) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //         pathologyRiskFactor += "75% Risk for Blood(VDRL), ";
  //       } else if (report.result === "W. Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //       }
  //     }

  //     // check TPHA status
  //     if (report.investigation_id === 28) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(TPHA), ";
  //       }
  //     }

  //     // check Anti-HCV status
  //     if (report.investigation_id === 29) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(Anti-HCV), ";
  //       }
  //     }

  //     // check HIV 1 - 2 status
  //     if (report.investigation_id === 30) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(HIV), ";
  //       }
  //     }

  //     // check pregnancy status
  //     if (report.investigation_id === 22) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (
  //         report.result === "W. Positive" ||
  //         report.result === "Positive"
  //       ) {
  //         pathologyStatus += "Pregnancy: " + report?.result + ", ";
  //       }
  //     }
  //   });
  // };

  const handleResultChange = ({ currentTarget: input }) => {
    setUserResultsData({ ...userResultsData, [input.name]: input.value });
  };

  const saveResults = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/save-user-results`,
        {
          ...userResultsData,
          status: statusState,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        console.log("response", response);
      })
      .catch((err) => console.log(err));
    toast.success("Saved Successfully!");
  };

  const [pathologyResults, setPathologyResults] = useState([]);
  const [radiologyResults, setRadiologyResults] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);

  const getRadiologyData = async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${2}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        const dividedIntoFour = divideArray(data);
        setRadiologyResults(dividedIntoFour);
      })
      .catch((err) => console.log(err));
  };

  const getPathologyData = async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        setPathologyResults(data);
      })
      .catch((err) => console.log(err));
  };

  async function getUserResults() {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-user-results/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        setUserResultsData((prev) => {
          return {
            ...prev,
            final_comments: data?.final_comments,
            advice: data?.advice,
            risk_factor: data?.risk_factor,
          };
        });
      })
      .catch((err) => console.log(err));
  }

  const fetchStaticContentData = async () => {
    await axios
      .get(staticURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        setStaticData(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const getComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { comments } = response?.data?.data;
      setPhysicalNewRiskFactor(comments);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getUserStatus = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (res?.data.some((item) => item.status === 3)) {
        setStatusState(3);
      } else if (
        res?.data?.some((item) => item.status === 4 && item.status !== 3)
      ) {
        setStatusState(4);
      } else {
        setStatusState(2);
      }
    };
    getUserStatus();
    getRadiologyData();
    getPathologyData();
    getUserResults();
    fetchStaticContentData();
  }, []);

  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [physicalTests, setPhysicalTests] = useState([]);
  const [pathologyTests, setPathologyTests] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [xrayImage, setXrayImage] = useState("");
  const [riskFactor, setRiskFactor] = useState("");
  const [pathologyCommentRisk, setPathologyCommentRisk] = useState();
  const [physicalCommentRisk, setPhysicalCommentRisk] = useState();
  const [staticData, setStaticData] = useState([]);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const physicalNewRiskFactorWithoutTags = removeHtmlTags(
    physicalNewRiskFactor
  );
  const pathologyCommentRiskWithoutTags = removeHtmlTags(pathologyCommentRisk);
  const riskFactorWithoutTags = removeHtmlTags(riskFactor);

  const formattedBirthDate = user?.user?.date_of_birth
    ? (() => {
        const date = new Date(user?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }

    const days = Math.floor(hours / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      width: 1,
      height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };

  const receivingDate = user?.user_payment?.createdAt;
  const preparationDuration = user?.package?.preparation_duration;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(user?.user?.registration_no), {
    format: "CODE128", // Use CODE128 format,
    width: 1,
    height: 20,
  });
  const patientIdDataUrl = patientIdBarcode.toDataURL();
  const patientRegNameDataUrl = generateBarcode(
    String(`${user?.user?.f_name}`)
  );

  const getUserDetail = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user-details/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      setUserDetail(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPhysicalTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setRadiologyTests(response?.data?.data);
      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/pathology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPathologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  async function getRiskFactor() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-risk-factor/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const risk = response?.data?.data || [];
      setRiskFactor(risk[0]?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  const getPathologyComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-pathology-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;
      setPathologyCommentRisk(commentsData?.comments);
      console.log(commentsData);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;
      console.log(commentsData);
      const physicalCommentRisk = commentsData.filter(
        (comment) => comment.investigation_group_id === 101
      );
      setPhysicalCommentRisk(physicalCommentRisk);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    userId && getPathologyComment();
    userId && getPhysicalComment();
    userId && getPathologyTests();
    userId && getRadiologyTests();
    userId && getUserDetail();
    userId && getRiskFactor();
    userId && getComment();
  }, [userId, userDetail?.nextPage, userDetail?.previousPage]);

  useEffect(() => {
    getPhysicalTests();
    getRadiologyTests();
  }, [userId, userDetail?.nextPage, userDetail?.previousPage]);

  // Get user, Introducer, and Representative---------------------
  const [representative, setRepresentative] = useState();
  useEffect(() => {
    userDetail && setUser(userDetail.userDetail);
    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => {
          // console.log(res)
          const representative_id = res?.data?.user_id;
          // console.log(representative_id)

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            }
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userDetail]);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item?.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item?.user_file);
        }
      });
  }, [radiologyData]);

  const radiologyFindings = radiologyTests.find(
    (item) => item?.investigation_test?.name === "Findings"
  )?.result;
  // pathologyStatusCheckHandler(pathologyTests);
  // Assuming userDetail is an object with the structure you mentioned
  const userDetailAdviceIds =
    userDetail?.userDetail?.user?.user_pescriptions[0]?.advice_ids;

  // Assuming staticData is an array of objects with 'id' and 'name' properties
  const advice = staticData
    .filter((advice) => userDetailAdviceIds?.includes(advice?.id))
    .map((advice) => advice?.name);
  return (
    <>
      <div className="card p-3 medical-report">
        <div className="row mb-2 bg-primary text-white px-2 py-2">
          <h6 className="mb-0">
            Medical Report for {user?.user?.f_name} [
            {user?.user?.registration_no}]
          </h6>
        </div>
        <div className="mt-2">
          <div className="row">
            <div className="col-sm-5 h-100">
              <div
                className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center p-1"
                style={{ height: "90px" }}
              >
                <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                  CLIENT ID: {user?.user?.registration_no}
                </p>
                <img
                  src={patientIdDataUrl}
                  alt=""
                  className="patient-barcode2"
                  style={{
                    height: "60px",
                  }}
                />
              </div>
              <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                DATE: {formatDeliveryTime(newDate, "dd-MM-yyyy hh:mm a")}
              </p>
            </div>
            <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-dark"
                style={{ height: "90px" }}
              >
                {userDetail?.user?.image ? (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${userDetail?.user?.image}`}
                    className="img-fluid"
                    alt="patient"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <img
                    src="./dummy.jpg"
                    alt="patient"
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                )}
              </div>
              <p className="p-0 m-0 w-100 border border-end-0 border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                PHOTO
              </p>
            </div>
            <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-dark"
                style={{ height: "90px" }}
              >
                {userDetail?.user?.finger_print ? (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${userDetail?.user?.finger_print}`}
                    className="img-fluid"
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <div
                    className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    No Fingerprint Found
                  </div>
                )}
              </div>
              <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                THUMB PRINT
              </p>
            </div>
            {/* <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-bottom-0 border-start-0 border-dark"
                style={{ height: "90px", width: "100%", overflow: "hidden" }}
              >
                <table
                  className="table mt-sm-0 mb-0 border border-top-0 border-end-0 border-dark"
                  id="xrayImage"
                  style={{
                    height: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: 0, height: "100%", width: "100%" }}>
                        {!!xrayImage ? (
                          <img
                            src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                            alt="X-Ray film"
                            className="img-fluid"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          <div
                            className="text-center d-flex align-items-center justify-content-center fw-bold"
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            No X-Ray film found
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                X-RAY IMAGE
              </p>
            </div> */}
            <div className="col-sm-3 h-100">
              <div
                className="d-flex justify-content-center align-items-center w-100 border border-dark"
                style={{ height: "90px" }}
              >
                <Link to={urlWithBase64ID}>
                  <QRCode value={urlWithBase64ID} size={85} />
                </Link>
              </div>
              <p className="p-0 m-0 w-100 border border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                QR CODE
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <h6 className="mb-2 bg-primary text-white p-1">Physical Report</h6>
        </div>
        <div className="row rounded-1">
          {physicalTests && physicalTests.length > 0 ? (
            <div
              style={{
                color: "black",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                border: "3px solid black",
                padding: "8px",
              }}
            >
              {physicalTests.map((physicalTest, key) => (
                <div
                  className="row align-items-center border-end border-dark p-2"
                  key={key}
                >
                  <div className="col-md-6 px-1 m-0 d-flex align-items-center justify-content-between">
                    {physicalTest?.investigation?.name}
                  </div>
                  <div className="col-md-6 px-2">
                    {physicalTest?.result === null ||
                    physicalTest?.result === "" ? (
                      <p className="fs-5 d-flex justify-content-start p-0 m-0">
                        - - -
                      </p>
                    ) : (
                      <p
                        className={`w-50 m-0 p-0 fw-bold ${
                          physicalTest?.test_id === 15
                            ? physicalTest?.result === "Normal"
                              ? "text-success"
                              : physicalNewRiskFactor > 70
                              ? "text-danger"
                              : "text-primary"
                            : "text-dark"
                        }`}
                      >
                        {physicalTest?.result}{" "}
                        {
                          physicalTest?.investigation?.investigation_tests[0]
                            ?.uom?.symbol
                        }
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="text-center"
              style={{
                color: "black",
                border: "3px solid black",
                padding: "8px",
              }}
            >
              No Data Available
            </div>
          )}
        </div>
        <div className="row mt-2">
          <h6 className="mb-2 bg-primary text-white p-1">Pathology Report</h6>
        </div>
        <div className="row rounded-1">
          {pathologyResults && pathologyResults.length > 0 ? (
            <div
              style={{
                color: "black",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                border: "3px solid black",
                padding: "8px",
              }}
            >
              {pathologyResults?.map((pathologyTest, key) => (
                <div
                  className="row align-items-center border-end border-dark p-2"
                  key={key}
                >
                  <div className="col-md-6 px-1 m-0 d-flex align-items-center justify-content-between">
                    {pathologyTest?.investigation_test?.name}
                  </div>
                  <div className="col-md-6 px-2">
                    {pathologyTest?.result === null ||
                    pathologyTest?.result === "" ? (
                      <p className="fs-5 d-flex justify-content-start p-0 m-0">
                        - - -
                      </p>
                    ) : (
                      <p className={`w-50 m-0 p-0 fw-bold`}>
                        {pathologyTest?.result}{" "}
                        {pathologyTest?.investigation_test?.uom?.symbol}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="text-center"
              style={{
                color: "black",
                display: "grid",
                border: "3px solid black",
                padding: "8px",
              }}
            >
              No Data Available
            </div>
          )}
        </div>
        <div className="row mt-2">
          <h6 className="mb-2 bg-primary text-white p-1">Radiology Report</h6>
        </div>
        <div
          className="row text-white rounded-1"
          style={{ border: "3px solid #222" }}
        >
          <div
            className="row"
            style={{
              display: "flex",
              color: "black",
              gridTemplateColumns: "repeat(4, 1fr)",
              padding: "8px",
            }}
          >
            {/* <div className="col"> */}
            {radiologyTests && radiologyTests.length > 0 ? (
              radiologyTests.map((radiology, key) => (
                <div
                  className="row align-items-center border-end border-dark p-2"
                  key={key}
                >
                  <p className="w-25 px-2 m-0 d-flex align-items-center justify-content-between">
                    {radiology?.investigation_test.name === "Findings"
                      ? "Comment"
                      : radiology?.investigation_test.name}
                  </p>
                  {radiology?.result === null || radiology?.result === "" ? (
                    <p className="w-75 px-2 fs-5">- - -</p>
                  ) : (
                    <p
                      className={`w-75 px-2 text-left p-1 fw-bold ${
                        radiology?.test_id === 35 &&
                        radiology?.investigation_test.name === "Findings"
                          ? radiology?.result === "Normal CXR"
                            ? "text-success"
                            : riskFactor > 70
                            ? "text-danger"
                            : "text-primary"
                          : "text-dark"
                      }`}
                    >
                      {radiology?.result}
                    </p>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center">No Data Available</div>
            )}
          </div>
        </div>
        <div className="row mt-3 inputs">
          <div className="col-4 px-1">
            <h6 className="bg-primary text-white p-1">Comments</h6>
            <textarea
              readOnly={true}
              name="final_comments"
              onChange={handleResultChange}
              id="final_comments"
              rows="5"
              value={`${
                physicalStatus
                  ? `Physical: ${
                      physicalStatus !== "Normal" ? physicalStatus : "N/A"
                    }`
                  : ""
              }${
                pathologyStatus
                  ? `\nPathology: ${pathologyStatus}`
                  : "\nPathology: N/A"
              }\nX-Ray: ${radiologyFindings ? radiologyFindings : "N/A"}`}
              className="w-100 border-none ps-1 fs-6"
            />
          </div>
          <div className="col-4 px-1">
            <h6 className="bg-primary text-white p-1">Advice</h6>
            <textarea
              readOnly={true}
              name="advice"
              onChange={handleResultChange}
              id="advice"
              rows="5"
              defaultValue={advice[2] || ""}
              className="w-100 border-none ps-1 fs-6"
            />
          </div>
          <div className="col-4 ps-1">
            <h6 className="bg-primary text-white p-1">Risk Factor</h6>
            <textarea
              readOnly={true}
              name="risk_factor"
              onChange={handleResultChange}
              id="risk_factor"
              rows="5"
              defaultValue={`${
                physicalNewRiskFactor
                  ? `Physical: ${physicalNewRiskFactorWithoutTags}% Risk,\n`
                  : ""
              }${
                pathologyCommentRisk
                  ? `Pathology: ${pathologyCommentRiskWithoutTags}\n`
                  : ""
              }${riskFactor ? `X-Ray: ${riskFactorWithoutTags}% Risk\n` : ""}`}
              className="w-100 border-none ps-1 fs-6"
            />
          </div>
        </div>
        <div className="row mt-2 d-flex align-items-center gap-4">
          <div className="drop-down-select col-sm-4">
            <h6>Status</h6>
            <select
              name="status"
              onChange={(event) => setStatusState(parseInt(event.target.value))}
              className="single-select form-select"
              disabled={checked === true ? false : true}
            >
              {!checked && statusState === 2 ? (
                <option value={2}>FIT</option>
              ) : !checked && statusState === 3 ? (
                <option value={3}>UNFIT</option>
              ) : !checked && statusState === 4 ? (
                <option value={4}>HELD UP</option>
              ) : (
                <>
                  <option value={""}>Choose One</option>
                  <option value={2}>FIT</option>
                  <option value={3}>UNFIT</option>
                  <option value={4}>HELD UP</option>
                </>
              )}
            </select>
          </div>
          {accessPerm(33, 11) && (
            <div className="autoResult col-sm-4 mt-4">
              <input
                type="checkbox"
                id="ignoreAutoResult"
                checked={checked}
                onChange={checkboxHandler}
              />
              <label className="p-2 text-dark" htmlFor="ignoreAutoResult">
                Ignore Auto Result
              </label>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <div className="d-flex gap-2">
              {userDetail?.nextPage && (
                <Link
                  to={`/dashboard/medical-report/${userDetail?.nextPage}`}
                  className="btn btn-primary"
                >
                  Previous
                </Link>
              )}
              {userDetail?.previousPage && (
                <Link
                  to={`/dashboard/medical-report/${userDetail?.previousPage}`}
                  className="btn btn-primary"
                >
                  Next
                </Link>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end gap-2">
              {accessPerm(33, 11) && (
                <button className="btn btn-success px-2" onClick={saveResults}>
                  Save Results
                </button>
              )}
              <button
                className="btn btn-outline-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/dashboard/medical-reports`);
                }}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedicalReportCopy;
