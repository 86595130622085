import { useEffect, useState } from "react";
import "./PrescriptionModal.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import JsBarcode from "jsbarcode";
import { add, format, isValid } from "date-fns";
import QRCode from "qrcode.react";

function PDFPrescriptionModal({ data }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  const staticURL = `${process.env.REACT_APP_API_BASE_URL}/static-contents`;
  const investigationURL = `${process.env.REACT_APP_API_BASE_URL}/investigation`;
  const prescriptionURL = `${process.env.REACT_APP_API_BASE_URL}/prescription`;
  const medicineURL = `${process.env.REACT_APP_API_BASE_URL}/medicine`;
  const websiteUrlWithBase64ID = `https://edmcbd.com`;

  const [addressData, setAddressData] = useState({});
  const [staticData, setStaticData] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [drugGroups, setDrugGroups] = useState([]);
  const [investigationData, setInvestigationData] = useState([]);
  const [medicines, setMedicines] = useState([1]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [physicalTests, setPhysicalTests] = useState([]);
  const [pathologyTests, setPathologyTests] = useState([]);
  const [pathologyReportData, setPathologyReportData] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [defaultDate, setDefaultDate] = useState();

  const { state } = useLocation();

  const updatedState = state || data;
  console.log(updatedState);
  const [medicineForm, setMedicineForm] = useState([
    {
      user_id: updatedState?.user?.id.toString(),
      prescription_id: "",
      drug_id: "",
      rules_id: "",
      duration_id: "",
    },
  ]);

  const [prescriptionForm, setPrescriptionForm] = useState({
    user_id: updatedState?.user?.id.toString(),
    diagnosis_ids: [],
    complaint_ids: [],
    investigation_ids: [],
    advice_ids: [],
    next_visit_date: "",
  });

  let unfit = 0;
  let fit = 0;
  let heldUp = 0;
  let status = "";
  let riskFactor = "";

  let pathologyStatus = "";
  let physicalStatus = "";
  let pathologyRiskFactor = "";
  let physicalRiskFactor = "";

  // const pathologyStatusCheckHandler = (reports) => {
  //   reports?.forEach((report) => {
  //     // check hemoglobin status
  //     if (report.investigation_id === 15) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 16) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       } else if (report.result < 11.8) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       }
  //     }

  //     // check R.B.S status
  //     if (report.investigation_id === 16) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 7.5) {
  //         pathologyStatus += "R.B.S.: " + report?.result + ", ";
  //       } else if (report.result > 7.5 && report.result <= 9) {
  //         pathologyRiskFactor += "30% Risk for Diabetes, ";
  //       } else if (report.result > 9 && report.result <= 12) {
  //         pathologyRiskFactor += "20% Risk for Diabetes, ";
  //       } else if (report.result > 12 && report.result <= 15) {
  //         pathologyRiskFactor += "40% Risk for Diabetes, ";
  //       } else if (report.result > 15 && report.result <= 18) {
  //         pathologyRiskFactor += "50% Risk for Diabetes, ";
  //       } else if (report.result > 18 && report.result <= 25) {
  //         pathologyRiskFactor += "75% Risk for Diabetes, ";
  //       } else if (report?.result > 25) {
  //         pathologyRiskFactor += "90~100% Risk for Diabetes, ";
  //       }
  //     }

  //     // check S. Creatinine status
  //     if (report.investigation_id === 17) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 1.3) {
  //         pathologyStatus += "S. Creatinine: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 2) {
  //         pathologyRiskFactor += "35% Risk for S. Creatinine, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "60% Risk for S. Creatinine, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for S. Creatinine, ";
  //       }
  //     }

  //     // check S.Bilirubin status
  //     if (report.investigation_id === 20) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 0.99) {
  //         pathologyStatus += "S.Bilirubin: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 1.6) {
  //         pathologyRiskFactor += "35% Risk for Jaundice, ";
  //       } else if (report.result > 1.6 && report.result <= 2) {
  //         pathologyRiskFactor += "50% Risk for Jaundice, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "75% Risk for Jaundice, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for Jaundice, ";
  //       }
  //     }

  //     // check Malarial Parasite status
  //     if (report.investigation_id === 25) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Found") {
  //         pathologyStatus += "Malarial Parasite: Found, ";
  //       }
  //     }

  //     // check SGPT status
  //     if (report.investigation_id === 23) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 41) {
  //         pathologyStatus += "SGPT: " + report?.result + ", ";
  //       } else if (report.result > 60 && report.result <= 80) {
  //         pathologyRiskFactor += "20% Risk for SGPT, ";
  //       } else if (report.result > 80 && report.result <= 100) {
  //         pathologyRiskFactor += "25% Risk for SGPT, ";
  //       } else if (report.result > 100 && report.result <= 140) {
  //         pathologyRiskFactor += "40% Risk for SGPT, ";
  //       } else if (report.result > 140 && report.result <= 200) {
  //         pathologyRiskFactor += "75% Risk for SGPT, ";
  //       } else if (report.result > 200) {
  //         pathologyRiskFactor += "90~100% Risk for SGPT, ";
  //       }
  //     }

  //     // check SGOT status
  //     if (report.investigation_id === 24) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 38) {
  //         pathologyStatus += "SGOT: " + report?.result + ", ";
  //       }
  //     }

  //     // check C.U. Sugar status
  //     if (report.investigation_id === 21) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > "NIL") {
  //         pathologyStatus += "C.U. Sugar: Nill, ";
  //       }
  //     }

  //     // check HBsAg status
  //     if (report.investigation_id === 26) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HBsAg: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyRiskFactor += "100% Risk for Blood(HBsAg), ";
  //       }
  //     }

  //     // check VDRL status
  //     if (report.investigation_id === 27) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //         pathologyRiskFactor += "75% Risk for Blood(VDRL), ";
  //       } else if (report.result === "W. Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //       }
  //     }

  //     // check TPHA status
  //     if (report.investigation_id === 28) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(TPHA), ";
  //       }
  //     }

  //     // check Anti-HCV status
  //     if (report.investigation_id === 29) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(Anti-HCV), ";
  //       }
  //     }

  //     // check HIV 1 - 2 status
  //     if (report.investigation_id === 30) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(HIV), ";
  //       }
  //     }

  //     // check pregnancy status
  //     if (report.investigation_id === 22) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (
  //         report.result === "W. Positive" ||
  //         report.result === "Positive"
  //       ) {
  //         pathologyStatus += "Pregnancy: " + report?.result + ", ";
  //       }
  //     }
  //   });
  // };

  const physicalStatusCheckHandler = (reports) => {
    reports.forEach((report) => {
      // check left eye status
      if (report.investigation_id === 7) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "LEFT EYE: Squint, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "LEFT EYE: Blind";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "LEFT EYE: Dimness of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "LEFT EYE: Color of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        }
      }

      // check right eye status
      if (report.investigation_id === 8) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "RIGHT EYE: Squint";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "RIGHT EYE: Blind";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "RIGHT EYE: Dimness of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "RIGHT EYE: Color of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        }
      }

      // check left ear status
      if (report.investigation_id === 9) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "LEFT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "LEFT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        }
      }

      // check right ear status
      if (report.investigation_id === 10) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "RIGHT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "RIGHT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        }
      }

      // check hernia status
      if (report.investigation_id === 11) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hernia: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check hydrocele status
      if (report.investigation_id === 12) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hydrocele: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check BP status
      if (report.investigation_id === 3) {
        const bpAValue = parseInt(report.result?.split("/")[0]);
        const bpBValue = parseInt(report.result?.split("/")[0]);

        if (
          (bpAValue > 130 || bpAValue < 100) &&
          (bpBValue > 85 || bpBValue < 60)
        ) {
          physicalStatus += "Blood Pressure: " + report.result + ", ";
        }
      }

      // check pulse status
      if (report.investigation_id === 4) {
        const pulseValue = parseInt(report.result);

        if (pulseValue > 100 || pulseValue < 60) {
          physicalStatus += "Pulse: " + report.result + ", ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          } else {
            // riskFactor = riskFactor + "Pulse: 100%, ";
          }
        }
      }
    });
  };

  const patientRegisterBarcode = document.createElement("canvas");
  JsBarcode(
    patientRegisterBarcode,
    String(updatedState?.user?.registration_no)
  );
  const patientIdDataUrl = patientRegisterBarcode.toDataURL();

  const formattedMedicalDate = data?.createdAt
    ? (() => {
        const date = new Date(data?.createdAt);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const formattedBirthDate = updatedState?.user?.date_of_birth
    ? new Date(updatedState?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  const fetchStaticContentData = async () => {
    await axios
      .get(staticURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        setStaticData(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchAddressData = async () => {
    await axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response.data.data[0];
        setAddressData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPatientInfo = async () => {
    try {
      const prescriptionResponse = await axios.get(
        `${prescriptionURL}/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const prescriptionData = prescriptionResponse?.data?.data;

      const medicineResponse = await axios.get(
        `${medicineURL}/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const medicineData = medicineResponse?.data?.data;

      const diagnosisIdsArray = prescriptionData?.diagnosis_id
        ? prescriptionData.diagnosis_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedDiagnosis = diagnosisIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));
      const complaintIdsArray = prescriptionData?.complaint_id
        ? prescriptionData.complaint_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedComplaint = complaintIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const investigationIdsArray = prescriptionData?.investigation_ids
        ? prescriptionData.investigation_ids
            .split(",")
            .map((id) => parseInt(id, 10))
        : [];

      const selectedInvestigation = investigationIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const adviceIdsArray = prescriptionData?.advice_ids
        ? prescriptionData.advice_ids.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedAdvice = adviceIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      setPrescriptionForm((prevForm) => ({
        ...prevForm,
        diagnosis_ids: selectedDiagnosis.map((item) => item.value),
        complaint_ids: selectedComplaint.map((item) => item.value),
        investigation_ids: selectedInvestigation.map((item) => item.value),
        advice_ids: selectedAdvice.map((item) => item.value),
        next_visit_date: prescriptionData?.next_visit_date || "", // Update the next_visit_date
      }));
      setSelectedDiagnosis(selectedDiagnosis);

      setPrescriptionData(prescriptionData);
      setMedicineForm(medicineData);
    } catch (error) {
      console.log(error);
    }
  };

  const options = drugs.map((drug) => ({
    value: drug?.id,
    label: drug?.name,
  }));

  const fetchDrugsAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drugs/all-drugs`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );
    const data = response.data.data;
    setDrugs(data);
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPhysicalTests(response?.data?.data);
      physicalStatusCheckHandler(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${updatedState?.user?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setRadiologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDrugsGroupAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drug-groups/all-groups`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );
    const data = response.data.data;
    setDrugGroups(data);
  };

  const fetchInvestigationData = async () => {
    await axios
      .get(investigationURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => setInvestigationData(response.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPhysicalTests();
    getRadiologyTests();
    setMedicines(medicines);
    fetchDrugsAPI();
    fetchDrugsGroupAPI();
    fetchAddressData();
    fetchStaticContentData();
    fetchInvestigationData();
    fetchPatientInfo();
  }, []);

  useEffect(() => {
    if (
      prescriptionForm?.next_visit_date &&
      isValid(new Date(prescriptionForm.next_visit_date))
    ) {
      setDefaultDate(prescriptionForm.next_visit_date);
    } else {
      const today = new Date();
      const sevenDaysLater = add(today, { days: 7 });
      const formattedDate = format(sevenDaysLater, "yyyy-MM-dd");
      setDefaultDate(formattedDate);
    }
  }, [prescriptionForm?.next_visit_date]);

  const radiologyFindings = radiologyTests.find(
    (item) => item?.investigation_test?.name === "Findings"
  )?.result;

  // pathologyStatusCheckHandler(pathologyReportData);
  physicalStatusCheckHandler(pathologyTests);

  return (
    <div style={{ position: "relative", minHeight: "100vh" }} className="px-3">
      <div className="">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${addressData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <section
        style={{
          // width: data ? "1710px" : "1710px",
          // overflowX: "scroll",
          fontFamily: "Verdana",
          fontWeight: "bolder",
          overflow: "hidden",
        }}
        className="verified-prescription"
      >
        <div className="row justify-content-center">
          <div className="col-sm-2 p-1 h-100">
            <div className="invoice-img d-flex gap-1 flex-column border border-dark w-100">
              {updatedState?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${updatedState?.user?.image}`}
                  style={{ objectFit: "fill" }}
                  alt=""
                />
              ) : (
                <img src="./dummy.jpg" alt="patient" className="img-fluid" />
              )}
            </div>
            <div className=" mt-1">
              <img
                src={patientIdDataUrl}
                alt=""
                style={{ height: "80px", width: "100%" }}
              />
            </div>
          </div>

          <div className="col-sm-8 p-1">
            <table
              className="w-100 table-bordered border-dark mb-0 text-dark"
              style={{ height: "100%" }}
            >
              <tbody>
                <tr>
                  <td className="text-uppercase">
                    <span className="ps-1">Id no: </span>
                    <span className="ps-1 fw-bold">
                      {updatedState?.user?.registration_no || "---"}
                    </span>
                  </td>
                  <td className="text-uppercase">
                    <span className="ps-1">Reg no: </span>
                    <span className="ps-1">
                      {updatedState?.user?.registration_no || "---"}
                    </span>
                  </td>
                  {updatedState?.user?.role_id !== 5 ? (
                    <td colSpan={2} className="text-uppercase">
                      <span className="text-uppercase ps-1">Passport no: </span>
                      <span className="text-uppercase ps-1">
                        {updatedState?.user?.passport_no || "---"}
                      </span>
                    </td>
                  ) : (
                    <td colSpan={2} className="text-uppercase">
                      <span className="text-uppercase ps-1"></span>
                      <span className="text-uppercase ps-1"></span>
                    </td>
                  )}
                </tr>
                <tr>
                  {updatedState?.user?.role_id !== 5 && (
                    <>
                      <td className="text-uppercase ps-1">Country</td>
                      <td className="text-uppercase ps-1">
                        {updatedState?.userDetail?.country?.name || "---"}
                      </td>
                    </>
                  )}
                  <td className="text-uppercase ps-1">Medical date</td>
                  <td
                    className="text-uppercase ps-1"
                    colSpan={updatedState?.user?.role_id === 5 ? 3 : undefined}
                  >
                    {formattedMedicalDate || "---"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1">Name</td>
                  <td className="text-uppercase ps-1" colSpan={3}>
                    {updatedState?.user?.f_name || "---"}
                  </td>
                </tr>
                {updatedState?.user?.role_id !== 5 && (
                  <tr>
                    <td className="text-uppercase ps-1">Father name</td>
                    <td className="text-uppercase ps-1" colSpan={3}>
                      {updatedState?.userDetail?.father_name || "---"}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="text-uppercase ps-1">
                    {updatedState?.user?.role_id !== 5
                      ? `Date of birth`
                      : `Age`}
                  </td>
                  <td className="text-uppercase ps-1">
                    {updatedState?.user?.role_id !== 5
                      ? formattedBirthDate || "---"
                      : updatedState?.user?.age || "---"}
                  </td>
                  <td className="text-uppercase ps-1">Gender</td>
                  <td className="text-uppercase ps-1">
                    {updatedState?.userDetail?.gender?.name || "---"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase ps-1">
                    {updatedState?.user?.role_id !== 5 ? `Agency` : `Reference`}
                  </td>
                  <td className="text-uppercase ps-1" colSpan={3}>
                    {updatedState?.user?.role_id !== 5
                      ? updatedState?.userDetail?.introducer_user?.f_name ||
                        "---"
                      : updatedState?.user?.reference || "---"}
                  </td>
                </tr>
                {updatedState?.userDetail?.company_user && (
                  <tr>
                    <td className="text-uppercase ps-1">Company</td>
                    <td className="text-uppercase ps-1" colSpan={3}>
                      {updatedState?.userDetail?.company_user?.f_name || "---"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-sm-2 p-1 h-100">
            <div className="finger-img  d-flex flex-column h-100 border border-dark">
              {updatedState?.user?.finger_print ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${updatedState?.user?.finger_print}`}
                  alt=""
                  className="finger-img"
                />
              ) : (
                <div className="p-2 border text-center text-dark finger-img d-flex align-items-center justify-content-center">
                  No Fingerprint Found
                </div>
              )}
              <p className="p-0 m-0 border border-1 border-dark text-center fw-bold w-100 d-flex align-items-center justify-content-center text-dark">
                Finger Print
              </p>
            </div>
          </div>
        </div>
        <div className={`row mt-4 text-dark ${data ? "p-4" : "p-4"}`}>
          <div className="col-6 pe-3">
            <div>
              <div className="fs-5 fw-bold border-bottom fw-bold">
                Diagnosis:
              </div>
              <div className="p-1 fs-5 fw-bold">
                {physicalStatus && (
                  <div className="text-dark">
                    Physical: {physicalStatus}
                    {physicalStatus && <br />}
                  </div>
                )}
                <div className="text-dark">
                  {pathologyStatus && (
                    <span>
                      Pathology: {pathologyStatus}
                      {radiologyFindings && <br />}
                    </span>
                  )}
                </div>
                {radiologyFindings && <div>Radiology: {radiologyFindings}</div>}
              </div>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Complaints:</div>
              <ul className="pt-1 fs-5 fw-bold">
                {prescriptionForm.complaint_ids.map((id) => (
                  <li key={id}>
                    {staticData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Investigations:</div>
              <ul className="pt-1 fs-5 fw-bold">
                {prescriptionForm.investigation_ids.map((id) => (
                  <li key={id}>
                    {investigationData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Advices:</div>
              <ul className="pt-1 fs-5 fw-bold">
                {prescriptionForm.advice_ids.map((id) => (
                  <li key={id}>
                    {staticData.find((data) => data?.id === id)?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="fs-5 fw-bold border-bottom">Next Visits:</div>
              <ul className="pt-1 fw-bold">
                <li>
                  <div className="next-visit-date fs-5 fw-bold">
                    Next Visit Date:{" "}
                    {defaultDate && format(new Date(defaultDate), "dd-MM-yyyy")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 ps-3">
            <div className="fs-5 fw-bold border-bottom">
              R <sub>x</sub>
            </div>
            <ul className="medicine-table medicine-no-print fs-5">
              {medicineForm.map((medicine, index) => {
                const selectedOption = options.find(
                  (option) => option.value === medicine.drug_id
                );

                return (
                  <li key={index}>
                    <div className="my-bangla-content">
                      {selectedOption?.label}
                    </div>
                    <div className="my-bangla-content">
                      {
                        staticData.find(
                          (data) => data?.id === medicine.rules_id
                        )?.name
                      }
                      <br />
                      {
                        staticData.find(
                          (data) => data?.id === medicine.duration_id
                        )?.name
                      }
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
      {data && (
        <div
          className="w-100 d-flex justify-content-center positive-absolute bottom-0"
          style={{ position: "absolute", bottom: 0, left: 0 }}
        >
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${addressData?.footer_image}`}
            alt="Report Footer"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
}

export default PDFPrescriptionModal;
