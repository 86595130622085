import React, { useContext } from "react";
import { UserContext } from "../../Context/UserContextAPI";
import { useLocation, useNavigate } from "react-router-dom";
import JsBarcode from "jsbarcode";
import { format } from "date-fns";
import "./StaticXray.scss";

const StaticXray = () => {
  const { settingsData } = useContext(UserContext);
  const { state } = useLocation();
  const patientData = state?.record;
  const navigate = useNavigate();
  const formattedBirthDate = patientData?.user?.date_of_birth
    ? (() => {
        const date = new Date(patientData?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  const handlePrint = () => {
    window.print();
  };

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      width: 1,
      height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };
  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(patientData?.user?.registration_no), {
    format: "CODE128", // Use CODE128 format,
    width: 1,
    height: 20,
  });
  const patientIdDataUrl = patientIdBarcode.toDataURL();
  const patientRegNameDataUrl = generateBarcode(
    String(`${patientData?.user?.f_name}`)
  );
  console.log(patientData);
  return (
    <div>
      <div className="static-xray mx-4">
        <div className="">
          <div className="row align-items-center">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="text-center text-uppercase text-dark w-100 fs-6">
          <span
            style={{ borderBottom: "2px solid black", paddingBottom: "3px" }}
          >
            X-RAY REPORT
          </span>
        </div>

        <div className="mt-2">
          <div className="row">
            <div className="col-sm-4 h-100">
              <div
                className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center p-1"
                style={{ height: "90px" }}
              >
                <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                  DATE:{" "}
                  {formatDate(
                    state?.record?.user?.createdAt,
                    "dd-MM-yyyy hh:mm a"
                  )}{" "}
                </p>
                <img
                  src={patientIdDataUrl}
                  alt=""
                  className="patient-barcode2"
                  style={{
                    height: "70px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-dark"
                style={{ height: "90px" }}
              >
                {patientData?.user?.image ? (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.image}`}
                    className="img-fluid"
                    alt="patient"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <img
                    src="./dummy.jpg"
                    alt="patient"
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-dark"
                style={{ height: "90px" }}
              >
                {patientData?.user?.finger_print ? (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.finger_print}`}
                    className="img-fluid"
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <div
                    className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    No Fingerprint Found
                  </div>
                )}
              </div>
            </div>
            {/* <div className="col-sm-2 h-100">
            <div
              className="report-avatar border border-end-0 border-start-0 border-bottom-0 border-dark"
              style={{ height: "90px", width: "100%", overflow: "hidden" }}
            >
              <table
                className="table mt-sm-0 mb-0 border border-end-0 border-top-0 border-bottom-0 border-dark"
                id="xrayImage"
                style={{
                  height: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: 0, height: "100%", width: "100%" }}>
                      {!!xrayImage ? (
                        <img
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                          alt="X-Ray film"
                          className="img-fluid"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                          }}
                        />
                      ) : (
                        <div
                          className="text-center d-flex align-items-center justify-content-center fw-bold"
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          No X-Ray film found
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
              X-RAY IMAGE
            </p>
          </div> */}
            <div className="col-sm-4 h-100">
              <div
                className="w-100 border border-dark d-flex flex-column justify-content-between"
                style={{ height: "90px" }}
              >
                <img
                  src={patientRegNameDataUrl}
                  alt=""
                  className="patient-barcode2"
                  style={{
                    height: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-2 text-dark">
            <div className="row">
              <table
                className="responsive-width1 table-bordered"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  textAlign: "left",
                }}
              >
                <tbody>
                  {/* Row 1 - Name and Client ID */}
                  <tr>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      Name
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      {state?.record?.user?.f_name || "---"}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      Client ID
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      {state?.record?.user?.registration_no || "---"}
                    </td>
                  </tr>

                  {/* Row 2 - Gender and Passport No */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Gender
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.gender?.name || "---"}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Passport No
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.passport_no || "---"}
                    </td>
                  </tr>

                  {/* Row 3 - Date of Birth and Travelling To */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.role_id !== 5
                        ? `Date of Birth`
                        : `Age`}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.role_id !== 5
                        ? formattedBirthDate || "---"
                        : state?.record?.user?.age || "---"}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Travelling To
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.country?.name || "---"}
                    </td>
                  </tr>

                  {/* Row 4 - Father Name and Contact Number */}
                  {state?.record?.user?.role_id !== 5 && (
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Father Name
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.record?.father_name || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Contact Number
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.record?.user?.mobile || "---"}
                      </td>
                    </tr>
                  )}

                  {/* Row 5 - Nationality and Agency */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Nationality
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.national_country?.nationality || "---"}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.role_id !== 5
                        ? `${settingsData?.introducer_label}`
                        : `Reference`}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      colSpan={3}
                    >
                      {state?.record?.user?.role_id !== 5
                        ? state?.record?.introducer_user?.f_name || "---"
                        : state?.record?.user?.reference || "---"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mt-2">
          <div className="row w-100">
            <div class="col-12 d-flex justify-content-center">
              <table className="w-100 table table-bordered border-dark">
                <thead>
                  <tr>
                    <th
                      className="fs-3 text-dark text-center"
                      style={{ width: "40%" }}
                    >
                      FINDINGS TYPE
                    </th>
                    <th
                      className="fs-3 text-dark text-center w-75"
                      style={{ width: "60%" }}
                    >
                      RESULTS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fs-5 text-dark text-center">
                      X-RAY Chest P/A View
                    </td>
                    <td className="fs-5 text-dark align-middle text-center">
                      NAD
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Heart</td>
                    <td className="fs-5 text-dark align-middle text-center">
                      NAD
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Diaphragm</td>
                    <td className="fs-5 text-dark align-middle text-center">
                      NAD
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Lungs</td>
                    <td className="fs-5 text-dark align-middle text-center">
                      CLEAR
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-5 text-dark text-center">Others</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      className="fs-5 text-dark text-center d-flex justify-content-center border-end-0 align-items-center"
                      style={{ height: "100px" }}
                    >
                      Comments
                    </td>

                    <td className="fs-5 text-dark align-middle text-center">
                      NAD / NSPI / ......................................
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="fit-status-section">
          <div className="d-flex justify-content-between">
            <table
              className="table border border-dark"
              style={{ height: "70px", width: "60%" }}
            >
              <tbody>
                <tr className="d-flex align-items-center justify-content-center h-100 gap-4">
                  <td
                    className="d-flex gap-2 align-items-center justify-content-center h-100 border border-dark text-center align-middle border-0"
                    style={{ verticalAlign: "middle" }}
                  >
                    <input
                      type="checkbox"
                      style={{ transform: "scale(2.4)", marginRight: "10px" }} // Add margin-right for space
                    />{" "}
                    <p className="p-0 m-0 fs-4"> *FIT</p>
                  </td>
                  <td
                    className="d-flex gap-2 align-items-center justify-content-center h-100 border border-dark text-center align-middle border-0"
                    style={{ verticalAlign: "middle" }}
                  >
                    <input
                      type="checkbox"
                      style={{ transform: "scale(2.4)", marginRight: "10px" }} // Add margin-right for space
                    />{" "}
                    <p className="p-0 m-0 fs-4"> *UNFIT</p>
                  </td>
                  <td
                    className="d-flex gap-2 align-items-center justify-content-center h-100 border border-dark text-center align-middle border-0"
                    style={{ verticalAlign: "middle" }}
                  >
                    <input
                      type="checkbox"
                      style={{ transform: "scale(2.4)", marginRight: "10px" }} // Add margin-right for space
                    />{" "}
                    <p className="p-0 m-0 fs-4"> *HELD-UP</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="signature-section d-flex justify-content-between mt-2 col-6 text-dark">
            <div className="w-100">
              <label className="text-uppercase">Instructions (If any):</label>
              <div
                className="w-100 border border-dark"
                style={{ height: "90px" }}
              ></div>
            </div>
          </div>
          <div className="col-12 text-dark">
            <div className="d-flex justify-content-end">
              <span className="fs-6">Signature: </span>
              <div
                className="ms-2"
                style={{ width: "150px", borderBottom: "1px solid black" }}
              >
                {/* Empty space for signature */}
              </div>
            </div>
          </div>
          <div className="static-xray-footer mt-5 text-dark">
            <div className="d-flex justify-content-between col-12">
              <p
                className="small-text static-xray-footer-1"
                style={{ fontSize: "10px" }}
              >
                User: {state?.record?.user?.created_by}
                <br />
              </p>
              <p
                className="small-text static-xray-footer-2"
                style={{ fontSize: "10px" }}
              >
                Licensed to: {settingsData?.website_name} <br />
                Vendor: Clincx Medical Solutions
              </p>
              <div className="col-2">
                <p
                  className="small-text static-xray-footer-3 pe-5"
                  style={{ fontSize: "10px" }}
                >
                  Date: <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="static-xray-btn d-flex mt-5 align-items-center justify-content-end">
        <div className="d-flex gap-2 mx-3">
          <button className="btn btn-primary" onClick={() => handlePrint()}>
            Print
          </button>
          {patientData?.user?.role_id === 5 ? (
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/medical-tests")}
            >
              Back
            </button>
          ) : (
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/patient-info")}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaticXray;
