import React, { useState, useEffect } from "react";
import ActivityRow from "./ActivityRow";
import axios from "axios";
import HorizontalScrollContainer from "../../../components/HorizontalScrollContainer/HorizontalScrollContainer";

const RoleSetupCard = ({ roleId, roles }) => {
  const [activities, SetActivities] = useState([]);
  const [modules, setModules] = useState([]);

  const getAllActivities = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/activity`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        const allData = response.data.data;

        SetActivities(allData);
        // console.log(allData)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllModules = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/module`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        const allMData = response.data.data;

        setModules(allMData);
        // console.log(allMData)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllActivities();
    getAllModules();
  }, []);
  return (
    <div>
      {roleId == 0 ? (
        ""
      ) : (
        <div className="card">
          <div className="card-body">
            <h3 className="text-justify text-center text-dark">
              {roles[roleId - 1]?.name} Permissions Setup{" "}
            </h3>
            <br />
            <HorizontalScrollContainer>
              <div
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  cursor: "grab",
                  userSelect: "none",
                }}
              >
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th className="text-center" scope="col">
                        Module Name
                      </th>
                      {activities.map((activity) => (
                        <th className="text-center" scope="col">
                          {activity.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <ActivityRow
                      modulesData={modules}
                      activityData={activities}
                      roleId={roleId}
                    />
                  </tbody>
                </table>
              </div>
            </HorizontalScrollContainer>

            <br />
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleSetupCard;
