import axios from "axios";
import "./BirthdayReport.scss";
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-hot-toast";
import { format } from "date-fns";
import { UserContext } from "../../Context/UserContextAPI";

const BirthdayReport = () => {
  const { accessPerm } = useContext(UserContext);
  const [roles, setRoles] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [usersInfo, setUsersInfo] = useState([]);

  const handlerOnSearch = (e) => {
    e.preventDefault();
    const filterData = {
      fromDate,
      toDate,
      selectedRole,
    };
    if (fromDate === null && toDate === null) {
      toast.error("Please select the required fields!");
    } else {
      methodFilterPatientsDetails(filterData);
    }
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const methodFilterPatientsDetails = async (filterData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/birthday-report`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      if (response?.data.length > 0) {
        setUsersInfo(response?.data);
      } else {
        setUsersInfo([]);
        toast.error("No birthday record found!");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchRoles = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/roles`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );
    const data = response.data.data;
    setRoles(data);
  };

  const handleSelectedRole = (e) => {
    setSelectedRole(e.target.value);
  };

  const mapRoleToUserType = (roleId) => {
    switch (roleId) {
      case 1:
        return "Software Engineers";
      case 2:
        return "Administrations";
      case 3:
        return "Doctors";
      case 4:
        return "Diagnostic Patients";
      case 5:
        return "Hospital Patients";
      case 6:
        return "Company";
      case 7:
        return "Delegets";
      case 8:
        return "Introducer";
      case 9:
        return "Representatives";
      default:
        return "Unknown";
    }
  };

  function getStatusText(status) {
    switch (status) {
      case 2:
        return "FIT";
      case 3:
        return "UNFIT";
      case 4:
        return "HELD-UP";
      default:
        return "PROCESSING";
    }
  }

  function getStatusColor(status) {
    switch (status) {
      case 2:
        return "#5cb85c";
      case 3:
        return "red";
      case 4:
        return "#0000FF";
      default:
        return "black";
    }
  }

  useEffect(() => {
    fetchRoles();
    setFromDate(new Date());
    setToDate(new Date());
  }, [setFromDate, setToDate]);

  const columns = [
    {
      name: "Date",
      selector: (row) => format(new Date(row.createdAt), "dd-MM-yyyy"),
      sortable: true,
    },
    {
      name: "Reg.No",
      selector: (row) => row?.registration_no,
      sortable: true,
    },
    {
      name: "Name / Company",
      selector: (row) => `${row?.f_name} ${row?.l_name}`,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.mobile,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Date of Birth",
      selector: (row) => format(new Date(row.date_of_birth), "dd-MM-yyyy"),
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => mapRoleToUserType(row?.role_id),
      sortable: true,
    },
    {
      name: "Status",
      selector: "status", // Change this to the actual property name
      sortable: true,
      left: true,
      cell: (row) => {
        const statusText = getStatusText(row.status); // Implement a function to map status to text
        const cellStyles = {
          color: getStatusColor(row.status), // Implement a function to map status to color
        };

        return <div style={cellStyles}>{statusText}</div>;
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      excel: false,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [];

  const conditionalRowStyles = [
    {
      when: (row) => row?.status === 1,
      style: {
        color: "black",
        fontWeight: "bold",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    // You can also pass a callback to style for additional customization
    {
      when: (row) => row?.status === 2,
      style: {
        color: "#5cb85c",
        fontWeight: "bold",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row?.status === 3,
      style: {
        color: "red",
        fontWeight: "bold",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row?.status === 4,
      style: {
        color: "#0000ff",
        fontWeight: "bold",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row?.status === undefined,
      style: {
        color: "black",
        fontWeight: "bold",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontSize: "15px", // Increase the font size of header titles
        border: "1px solid #e0e0e0", // Add a border to the cells
        fontWeight: "bold",
        backgroundColor: "#095d7e",
        color: "white",
      },
    },
    headCells: {
      style: {
        border: "1px solid #e0e0e0", // Add a border to every cell of the head row
        overflowWrap: "break-word", // Wrap and break text to the next line
      },
    },
    cells: {
      style: {
        fontSize: "14px", // Adjust the font size of cell content as needed
        border: "1px solid #e0e0e0", // Add a border to the cells
        padding: "4px",
        whiteSpace: "pre-wrap", // Set white space to pre-wrap for all cells
      },
    },
  };

  let statusText;
  let statusClassName;
  const status = usersInfo?.status;

  switch (status) {
    case 1:
      statusClassName = "black";
      break;
    case 2:
      statusClassName = "#5cb85c";
      break;
    case 3:
      statusClassName = "red";
      break;
    case 4:
      statusClassName = "#0000FF";
      break;
    default:
      statusClassName = "black";
      break;
  }

  return (
    <>
      <div className="card patient-information">
        <div className="card-body">
          <div className="border p-2 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Birthday Report</h6>
            </div>

            <hr />

            <div className="card shadow-none">
              <div className="border p-1 rounded">
                <div className="">
                  <form onSubmit={(e) => handlerOnSearch(e)}>
                    <div className="d-flex flex-wrap pb-2 gap-1">
                      <div className="w-25">
                        <label className="col-form-label" htmlFor="from_date">
                          From
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              name="from_date"
                              views={["month", "day"]}
                              openTo="month"
                              slotProps={{
                                textField: {
                                  placeholder: "Select Day and Month",
                                },
                              }}
                              onChange={handleFromDateChange}
                              defaultValue={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "mm-dd",
                                    style: { height: "32px" }, // Adjust the height here
                                  }}
                                />
                              )}
                              inputFormat="MM/dd"
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="col-form-label" htmlFor="to_date">
                          To
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              name="to_date"
                              views={["month", "day"]}
                              openTo="month"
                              slotProps={{
                                textField: {
                                  placeholder: "Select Day and Month",
                                },
                              }}
                              onChange={handleToDateChange}
                              defaultValue={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "mm-dd",
                                  }}
                                />
                              )}
                              inputFormat="MM/dd"
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="col-form-label">User Type</label>
                        <div className="">
                          <select
                            value={selectedRole}
                            onChange={(e) => handleSelectedRole(e)}
                            className="single-select form-select pb-2"
                          >
                            <option value={0}>Select a Role</option>
                            {roles.map((role) => (
                              <option key={role?.id} value={role?.id}>
                                {role?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-end patient-info-search">
                        <button
                          type="submit"
                          className="btn btn-success border border-3 pb-2"
                          style={{ width: "6rem" }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <h5 className="mt-2">Birthday List</h5>
            </div>
            <DataTable
              columns={columns}
              data={usersInfo}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              highlightOnHover
              striped
              dense
              fixedHeader
              noDataComponent="Select Dates to Check Birthday Reports" //or your component
              // Add any additional configurations as needed
              {...config}
              // Add any extra buttons if needed
              extraButtons={extraButtons}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BirthdayReport;
