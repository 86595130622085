import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();

  const handlerOnSubmit = async (e) => {
    try {
      e.preventDefault();

      const form = e.target;
      const mobile = form.mobile.value;

      const mobileNumber = {
        mobile: mobile,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/verify-phonenumber`,
        mobileNumber,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res.data;

      if (res.status === 200) {
        toast.success(`Otp Verification Code Sent to ${mobile}`);
        navigate("/otp-verification", { state: mobileNumber });
      }
    } catch (error) {
      console.error(error);
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const myStyle = {
    container: {
      position: "relative",
      height: "100vh",
      width: "100vw",
    },
    backgroundImage: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/website-background.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      opacity: 0.8,
      zIndex: -1,
    },
  };

  return (
    <>
      {/* ResetPassword Container */}
      <div className="wrapper" style={myStyle.backgroundImage}>
        {/* <!--start content--> */}
        <main
          className="h-100 d-flex align-items-center justify-content-center mx-auto"
          style={myStyle.container}
        >
          <div style={myStyle.backgroundImage}></div>
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card index-card shadow rounded-0 overflow-hidden">
                <div className="row">
                  <div className="card-body p-4 p-sm-5">
                    <h5 className="card-title fs-6 text-center text-dark">
                      Forgot Password?
                    </h5>
                    <p className="card-text text-dark">
                      Enter your registered Phone Number to reset the password
                    </p>
                    <form
                      className="form-body"
                      onSubmit={(e) => handlerOnSubmit(e)}
                    >
                      <div className="login-separater text-center mb-4">
                        <span>RESET PASSWORD WITH PHONE NUMBER</span>
                        <hr />
                      </div>
                      <div className="row gap-2 mx-auto w-100 d-flex justify-content-center">
                        <div className="col-12 w-75">
                          <label htmlFor="inputEmailid" className="form-label text-dark">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            name="mobile"
                            className="form-control form-control-lg radius-30"
                            id="inputEmailid"
                            placeholder="Phone Number"
                            required
                          />
                        </div>
                        <div className="col-12 w-75">
                          <div className="d-grid gap-3">
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary radius-30"
                              style={{
                                backgroundColor: "#14977e",
                                border: "none",
                                color: "white",
                              }}
                            >
                              Submit
                            </button>
                            <Link
                              to="/signin"
                              className="btn btn-lg btn-secondary radius-30"
                            >
                              Back to Login
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ForgetPasswordPage;
