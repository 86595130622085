import { createBrowserRouter } from "react-router-dom";
import AccountHead from "../Pages/Accounts/AccountHead/AccountHead";
import HeadClassification from "../Pages/Accounts/HeadClassification/HeadClassification";
import HeadGroup from "../Pages/Accounts/HeadGroup/HeadGroup";
import HeadIdentifier from "../Pages/Accounts/HeadIdentifier/HeadIdentifier";
import HeadType from "../Pages/Accounts/HeadType/HeadType";
import ProfitLossStatement from "../Pages/Accounts/ProfitLossStatement/ProfitLossStatement";
import TrailBalance from "../Pages/Accounts/TrailBalance/TrailBalance";
import TransectionType from "../Pages/Accounts/TransectionType/TransectionType";
import VoucherEntry from "../Pages/Accounts/VoucherEntry/VoucherEntry";
import VoucherList from "../Pages/Accounts/VoucherList/VoucherList";
import BirthdayReport from "../Pages/BirthdayReport/BirthdayReport";
import CollectionLabel from "../Pages/CollectionLabel/CollectionLabel";
import FitPatients from "../Pages/FitPatients/FitPatients";
import ForgetPasswordPage from "../Pages/ForgetPasswordPage/ForgetPasswordPage";
import HeldUpPatients from "../Pages/HeldUpPatients/HeldUpPatients";
import Homepage from "../Pages/Homepage/Homepage";
import InvestigationCategory from "../Pages/Investigation/InvestigationCategory/InvestigationCategory";
import InvestigationGroup from "../Pages/Investigation/InvestigationGroup/InvestigationGoup";
import AddNewInv from "../Pages/Investigation/InvestigationManagement/AddNewInv";
import EditInvestigation from "../Pages/Investigation/InvestigationManagement/EditInvestigation";
import InvestigationManagement from "../Pages/Investigation/InvestigationManagement/InvestigationManagement";
import UserInvoiceCopy from "../Pages/Investigation/Invoice/UserInvoiceCopy";
import Package from "../Pages/Investigation/Package/Package";
import HeldUpCopy from "../Pages/Investigation/Report/Certificates/HeldUpCopy";
import MedicalFormCopy from "../Pages/Investigation/Report/MedicalForm/MedicalFormCopy";
import TestManagement from "../Pages/Investigation/Test/TestManagement";
import MedicalVerification from "../Pages/MedicalVerification/MedicalVerification";
import PrescriptionModal from "../Pages/MedicalVerification/PrescriptionModal/PrescriptionModal";
import Verification from "../Pages/MedicalVerification/Verification";
import VerificationReport from "../Pages/MedicalVerification/Verification/VerificationReport";
import PrintVerificationReport from "../Pages/MedicalVerification/Verification/PrintVerificationReport";
import OtpVerification from "../Pages/OtpVerification/OtpVerification";
import Prescription from "../Pages/Prescription/Prescription";
import HealthCard from "../Pages/PrintExamples/HealthCard";
import CreatePatientInformation from "../Pages/ProcessAndProcedure/PatientInformation/CreatePatientInformation";
import EditPatientInformation from "../Pages/ProcessAndProcedure/PatientInformation/EditPatientInformation";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import ReportsPage from "../Pages/ReportsPage/ReportsPage";
import ResetPasswordPage from "../Pages/ResetPasswordPage/ResetPasswordPage";
import BloodGroup from "../Pages/Settings/BloodGroup/BloodGroup";
import City from "../Pages/Settings/City/City";
import Country from "../Pages/Settings/Country/Country";
import Department from "../Pages/Settings/Department/Department";
import DrugGroupList from "../Pages/Settings/DrugGroupList/DrugGroupList";
import DrugList from "../Pages/Settings/DrugList/DrugList";
import EmployeeCategory from "../Pages/Settings/EmployeeCategory/EmployeeCategory";
import EmployeeType from "../Pages/Settings/EmployeeType/EmployeeType";
import GenderPage from "../Pages/Settings/GenderPage/GenderPage";
import InstrumentCategoryList from "../Pages/Settings/InstrumentCategoryList/InstrumentCategoryList";
import InstrumentsPage from "../Pages/Settings/InstrumentsPage/InstrumentsPage";
import MaritialStatusSettings from "../Pages/Settings/MaritalStatus/MaritalStatusSettings";
import ProfessionSettingsPage from "../Pages/Settings/ProfessionSettingsPage/ProfessionSettingsPage";
import QualificationPage from "../Pages/Settings/Qualification/QualificationPage";
import Room from "../Pages/Settings/RoomManagement/Room";
import SettingsPage from "../Pages/Settings/SettingsPage/SettingsPage";
import State from "../Pages/Settings/State/State";
import StaticContentGroupPage from "../Pages/Settings/StaticContentGroupPage/StaticContentGroupPage";
import StaticContentPage from "../Pages/Settings/StaticContentPage/StaticContentPage";
import UOMPage from "../Pages/Settings/UOMPage/UOMPage";
import IndexPage from "../Pages/Signin/IndexPage/IndexPage";
import Signin from "../Pages/Signin/Signin";
import Signup from "../Pages/Signup/Signup";
import UnFitPatients from "../Pages/UnFitPatients/UnFitPatients";
import ActivityListPage from "../Pages/UserManagement/ActivityListPage/ActivityListPage";
import ModuleListPage from "../Pages/UserManagement/ModuleListPage/ModuleListPage";
import RoleListPage from "../Pages/UserManagement/RoleListPage/RoleListPage";
import RolePermissionPage from "../Pages/UserManagement/RolePermissionPage/RolePermissionPage";
import UserListPage from "../Pages/UserManagement/UserListPage/UserListPage";
import DashboardLayout from "../components/Layout/DashboardLayout";
import PrivateRouter from "./Private/PrivateRouter";
import MedicalReportsCopy from "../Pages/ProcessAndProcedure/MedicalReports/MedicalReportsCopy";
import SpecimenCollection from "../Pages/ProcessAndProcedure/SpecimenCollection/SpecimenCollection";
import MedicalPhysicalCopy from "../Pages/Investigation/Report/MedicalPhysical/MedicalPhysicalCopy";
import MedicalPathologyCopy from "../Pages/Investigation/Report/MedicalPathology/MedicalPathologyCopy";
import MedicalXrayCopy from "../Pages/Investigation/Report/MedicalXray/MedicalXrayCopy";
import PatientInformationCopy from "../Pages/ProcessAndProcedure/PatientInformation/PatientInformationCopy";
import MedicalReportCopy from "../Pages/Investigation/Report/MedicalReport/MedicalReportCopy";
import Fit from "../Pages/Investigation/Report/Certificates/Fit";
import Unfit from "../Pages/Investigation/Report/Certificates/Unfit";
import ResultPage from "../Pages/ResultPage/ResultPage";
import PrintMedicalPhysical from "../Pages/Investigation/Report/MedicalPhysical/PrintMedicalPhysical";
import PrintMedicalPathology from "../Pages/Investigation/Report/MedicalPathology/PrintMedicalPathology";
import PrintMedicalXray from "../Pages/Investigation/Report/MedicalXray/PrintMedicalXray";
import PrintMedicalReport from "../Pages/Investigation/Report/MedicalReport/MedicalReportPrint";
import PrintHealthCard from "../Pages/PrintExamples/PrintHealthCard";
import PrintPrescription from "../Pages/Prescription/PrintPrescription";
import PrintFitCertificate from "../Pages/Investigation/Report/Certificates/PrintFitCertificate";
import PrintUnfitCertificate from "../Pages/Investigation/Report/Certificates/PrintUnfitCertificate";
import PrintHeldUpCertificate from "../Pages/Investigation/Report/Certificates/PrintHeldUpCertificate";
import PrintMedicalExamination from "../Pages/PrintExamples/PrintMedicalExamination";
import MedicalRegistrationPage from "../Pages/MedicalRegistrationPage/MedicalRegistrationPage";
import PrintReadOnlyRegistration from "../Pages/MedicalRegistrationPage/PrintReadOnlyRegistration";
import PrintPhotoRegistration from "../Pages/MedicalRegistrationPage/PrintPhotoRegistration";
import PrintAccountRegistration from "../Pages/MedicalRegistrationPage/PrintAccountRegistration";
import Ledger from "../Pages/Accounts/Ledger/Ledger";
import HeadBalance from "../Pages/Accounts/HeadBalance/HeadBalance";
import StatementFinancialPosition from "../Pages/Accounts/StatementFinancialPosition/StatementFinancialPosition";
import Reinvestigation from "../Pages/ProcessAndProcedure/Reinvestigation/Reinvestigation";
import RoleWiseListPage from "../Pages/UserManagement/UserListPage/RoleWiseListPage";
import LeaveCategory from "../Pages/Settings/LeaveCategory/LeaveCategory";
import HolyDays from "../Pages/Settings/HolyDays/HolyDays";
import LeaveApplication from "../Pages/HRManagement/LeaveApplication/LeaveApplication";
import MedicalSummary from "../Pages/MedicalSummary/MedicalSummary";
import PreRegistration from "../Pages/ProcessAndProcedure/PreRegistration/PreRegistration";
import LoggedInUsers from "../Pages/UserManagement/LoggedInUsers/LoggedInUsers";
import Balance from "../Pages/Balance/Balance";
import MedicalTests from "../Pages/ProcessAndProcedure/MedicalTests/MedicalTests";
import CreateMedicalTests from "../Pages/ProcessAndProcedure/MedicalTests/CreateMedicalTests";
import EditPatient from "../Pages/ProcessAndProcedure/MedicalTests/EditPatient";
import PackagePrint from "../Pages/PackagePrint/PackagePrint";
import ProfilePreviewPage from "../Pages/ProfilePreview/ProfilePreviewPage";
import PrintHeaderReport from "../Pages/Investigation/Report/PrintHeaderReport/PrintHeaderReport";
import CollectionReport from "../Pages/CollectionReport/CollectionReport";
import CollectionReportPrint from "../Pages/CollectionReport/CollectionReportPrint";
import StaticXray from "../Pages/StaticXray/StaticXray";
import PrintInvoiceForm from "../Pages/PrintInvoiceForm/PrintInvoiceForm.jsx";
import PrintInvoiceFormXray from "../Pages/PrintInvoiceFormXray/PrintInvoiceFormXray";

const router = createBrowserRouter([
  {
    index: true,
    path: "/",
    element: <IndexPage />,
  },
  {
    path: "signin",
    element: <Signin />,
  },
  // {
  //   path: "signup",
  //   element: <Signup />,
  // },
  {
    path: "forget-password",
    element: <ForgetPasswordPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "otp-verification",
    element: <OtpVerification />,
  },
  {
    path: "medical-verification",
    element: <MedicalVerification />,
  },
  {
    path: "medical-verification/result/report",
    element: (
      <Verification>
        <VerificationReport />
      </Verification>
    ),
  },
  // {
  //   path: "medical-verification/result/report/:id",
  //   element: (
  //     <VerificationById>
  //       <VerificationReportById />
  //     </VerificationById>
  //   ),
  // },
  {
    path: "medical-verification/result/report/print/:id",
    element: <PrintVerificationReport />,
  },
  {
    path: "medical-prescription-print/:userId",
    element: <PrintPrescription />,
  },
  {
    path: "medical-verification/result/prescription",
    element: (
      <Verification>
        <PrescriptionModal />
      </Verification>
    ),
  },
  // {
  //   path: "medical-verification/result/prescription/:id",
  //   element: (
  //     <VerificationById>
  //       <PrescriptionModalById />
  //     </VerificationById>
  //   ),
  // },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: "profile/preview",
        element: <ProfilePreviewPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "balance",
        element: <Balance />,
      },
      {
        path: "reports",
        element: <ReportsPage />,
      },
      {
        path: "investigation/investigation-groups",
        element: (
          <PrivateRouter props={21}>
            <InvestigationGroup />
          </PrivateRouter>
        ),
      },

      {
        path: "investigation/investigation-categories",
        element: (
          <PrivateRouter props={22}>
            <InvestigationCategory />
          </PrivateRouter>
        ),
      },
      {
        path: "investigation/investigation-management",
        element: (
          <PrivateRouter props={23}>
            <InvestigationManagement />
          </PrivateRouter>
        ),
      },
      {
        path: "investigation/test-management",
        element: (
          <PrivateRouter props={24}>
            <TestManagement />
          </PrivateRouter>
        ),
      },
      {
        path: "investigation/add-new",
        element: <AddNewInv />,
      },
      {
        path: "investigation/update",
        element: <EditInvestigation />,
      },
      {
        path: "package",
        element: (
          <PrivateRouter props={25}>
            <Package />
          </PrivateRouter>
        ),
      },
      {
        path: "transection-type",
        element: (
          <PrivateRouter props={26}>
            <TransectionType />
          </PrivateRouter>
        ),
      },
      {
        path: "head-type",
        element: (
          <PrivateRouter props={30}>
            <HeadType />
          </PrivateRouter>
        ),
      },
      {
        path: "head-identifier",
        element: (
          <PrivateRouter props={27}>
            <HeadIdentifier />
          </PrivateRouter>
        ),
      },
      {
        path: "head-classification",
        element: (
          <PrivateRouter props={28}>
            <HeadClassification />
          </PrivateRouter>
        ),
      },
      {
        path: "head-group",
        element: (
          <PrivateRouter props={29}>
            <HeadGroup />
          </PrivateRouter>
        ),
      },
      {
        path: "account-head",
        element: (
          <PrivateRouter props={31}>
            <AccountHead />
          </PrivateRouter>
        ),
      },
      {
        path: "users",
        element: (
          <PrivateRouter props={5}>
            <UserListPage />
          </PrivateRouter>
        ),
      },

      {
        path: "users/role/:id",
        element: (
          <PrivateRouter props={5}>
            <RoleWiseListPage />
          </PrivateRouter>
        ),
      },

      {
        path: "roles",
        element: (
          <PrivateRouter props={4}>
            <RoleListPage />
          </PrivateRouter>
        ),
      },
      {
        path: "hr-management/leave-application",
        element: (
          <PrivateRouter props={6}>
            <LeaveApplication />
          </PrivateRouter>
        ),
      },
      {
        path: "user-management/role-permissions",
        element: (
          <PrivateRouter props={6}>
            <RolePermissionPage />
          </PrivateRouter>
        ),
      },
      {
        path: "user-management/logged-in",
        element: (
          <PrivateRouter props={6}>
            <LoggedInUsers />
          </PrivateRouter>
        ),
      },
      {
        path: "module",
        element: (
          <PrivateRouter props={3}>
            <ModuleListPage />
          </PrivateRouter>
        ),
      },
      {
        path: "activity",
        element: (
          <PrivateRouter props={2}>
            <ActivityListPage />
          </PrivateRouter>
        ),
      },
      {
        path: "static-content-groups",
        element: (
          <PrivateRouter props={7}>
            <StaticContentGroupPage />
          </PrivateRouter>
        ),
      },
      {
        path: "static-content",
        element: (
          <PrivateRouter props={8}>
            <StaticContentPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/instruments-category",
        element: (
          <PrivateRouter props={9}>
            <InstrumentCategoryList />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/instruments",
        element: (
          <PrivateRouter props={10}>
            <InstrumentsPage />
          </PrivateRouter>
        ),
      },
      {
        path: "setting/drug-groups",
        element: (
          <PrivateRouter props={11}>
            <DrugGroupList />
          </PrivateRouter>
        ),
      },
      {
        path: "setting/drugs",
        element: (
          <PrivateRouter props={12}>
            <DrugList />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/room-management",
        element: (
          <PrivateRouter props={13}>
            <Room />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/uom",
        element: (
          <PrivateRouter props={15}>
            <UOMPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/gender",
        element: (
          <PrivateRouter props={14}>
            <GenderPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/professions",
        element: (
          <PrivateRouter props={16}>
            <ProfessionSettingsPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/merital-status",
        element: (
          <PrivateRouter props={17}>
            <MaritialStatusSettings />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/country",
        element: (
          <PrivateRouter props={18}>
            <Country />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/state",
        element: (
          <PrivateRouter props={19}>
            <State />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/city",
        element: (
          <PrivateRouter props={20}>
            <City />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/qualification",
        element: (
          <PrivateRouter props={20}>
            <QualificationPage />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/employee-category",
        element: (
          <PrivateRouter props={20}>
            <EmployeeCategory />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/leave-category",
        element: (
          <PrivateRouter props={20}>
            <LeaveCategory />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/holy-days",
        element: (
          <PrivateRouter props={20}>
            <HolyDays />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/employee-type",
        element: (
          <PrivateRouter props={20}>
            <EmployeeType />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/department",
        element: (
          <PrivateRouter props={20}>
            <Department />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/blood-group",
        element: (
          <PrivateRouter props={20}>
            <BloodGroup />
          </PrivateRouter>
        ),
      },
      {
        path: "settings/system-settings",
        element: (
          <PrivateRouter props={1}>
            <SettingsPage />
          </PrivateRouter>
        ),
      },
      {
        path: "collection-label/:uId",
        element: <CollectionLabel />,
      },
      {
        path: "collection-label",
        element: <CollectionLabel />,
      },
      {
        path: "invoice",
        element: <UserInvoiceCopy />,
      },
      {
        path: "print-medical-form",
        element: <MedicalFormCopy />,
      },
      {
        path: "healthcard/:userId",
        element: <HealthCard />,
      },
      {
        path: "medical-report/:userId",
        element: <MedicalReportCopy />,
      },
      {
        path: "held-up-certificate/:userId",
        element: <HeldUpCopy />,
      },
      {
        path: "fit-certificate/:userId",
        element: <Fit />,
      },
      {
        path: "unfit-certificate/:userId",
        element: <Unfit />,
      },
      {
        path: "medical-reports/physical/:userId",
        element: <MedicalPhysicalCopy />,
      },
      {
        path: "medical-reports/pathology/:userId",
        element: <MedicalPathologyCopy />,
      },
      {
        path: "medical-reports/radiology/:userId",
        element: <MedicalXrayCopy />,
      },
      {
        path: "patient-info/edit",
        element: <EditPatientInformation />,
      },
      {
        path: "patient-info/create",
        element: <CreatePatientInformation />,
      },
      {
        path: "prescription/:userId",
        element: <Prescription />,
      },
      {
        path: "medical-reports",
        element: <MedicalReportsCopy />,
      },
      {
        path: "medical-tests/create",
        element: <CreateMedicalTests />,
      },
      {
        path: "medical-tests/edit",
        element: <EditPatient />,
      },
      {
        path: "medical-tests",
        element: <MedicalTests />,
      },
      {
        path: "account/voucher-entry",
        element: <VoucherEntry />,
      },
      {
        path: "account/voucher-list",
        element: <VoucherList />,
      },
      {
        path: "account/trail-balance",
        element: <TrailBalance />,
      },
      {
        path: "account/ledger",
        element: <Ledger />,
      },
      {
        path: "account/head-balance",
        element: <HeadBalance />,
      },
      {
        path: "account/profit-loss-statement",
        element: <ProfitLossStatement />,
      },
      {
        path: "account/statement-financial-position",
        element: <StatementFinancialPosition />,
      },
      {
        path: "birthday-report",
        element: <BirthdayReport />,
      },
      {
        path: "medical-summary",
        element: <MedicalSummary />,
      },
      {
        path: "medical-collection",
        element: <CollectionReport />,
      },
      {
        path: "medical-collection/print",
        element: <CollectionReportPrint />,
      },
      {
        path: "package-print",
        element: <PackagePrint />,
      },
      {
        path: "static-xray",
        element: <StaticXray />,
      },
      {
        path: "fit-patients",
        element: <FitPatients />,
      },
      {
        path: "unfit-patients",
        element: <UnFitPatients />,
      },
      {
        path: "held-up-patients",
        element: <HeldUpPatients />,
      },
      {
        path: "result",
        element: <ResultPage />,
      },
      {
        path: "medical-registration",
        element: <MedicalRegistrationPage />,
      },
      {
        path: "medical-registration/read-only",
        element: <PrintReadOnlyRegistration />,
      },
      {
        path: "medical-registration/with-photo",
        element: <PrintPhotoRegistration />,
      },
      {
        path: "medical-registration/with-account",
        element: <PrintAccountRegistration />,
      },
      {
        path: "registration",
        element: <PatientInformationCopy />,
      },
      {
        path: "specimen-collection",
        element: <SpecimenCollection />,
      },
      {
        path: "patient-info",
        element: <PatientInformationCopy />,
      },
      {
        path: "patient-pre-registration",
        element: <PreRegistration />,
      },
      {
        path: "re-investigation",
        element: <Reinvestigation />,
      },
    ],
  },
  {
    path: "/medical-physical-print/:userId",
    element: <PrintMedicalPhysical />,
  },
  {
    path: "/medical-pathology-print/:userId",
    element: <PrintMedicalPathology />,
  },
  {
    path: "/medical-radiology-print/:userId",
    element: <PrintMedicalXray />,
  },
  {
    path: "/medical-report-print/:userId",
    element: <PrintMedicalReport />,
  },
  {
    path: "/header-report-print/:userId",
    element: <PrintHeaderReport />,
  },
  {
    path: "/medical-health-card-print/:userId",
    element: <PrintHealthCard />,
  },
  {
    path: "/print-held-up-certificate/:userId",
    element: <PrintHeldUpCertificate />,
  },
  {
    path: "/print-fit-certificate/:userId",
    element: <PrintFitCertificate />,
  },
  {
    path: "/print-unfit-certificate/:userId",
    element: <PrintUnfitCertificate />,
  },
  {
    path: "/print-medical-examination/:userId",
    element: <PrintMedicalExamination />,
  },
  {
    path: "/print-static-xray",
    element: <StaticXray />,
  },
  {
    path: "/print-invoice-form",
    element: <PrintInvoiceForm />,
  },
  {
    path: "/print-invoice-form-xray",
    element: <PrintInvoiceFormXray />,
  },
]);

export default router;
