import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Reinvestigation.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { UserContext } from "../../../Context/UserContextAPI";

const Reinvestigation = () => {
  const { accessPerm, settingsData } = useContext(UserContext);
  const [patientData, setPatientData] = useState();
  const [selectedSpecimens, setSelectedSpecimens] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [specimenCats, setSpecimenCats] = useState([]);
  const [introducerUser, setIntroducerUser] = useState({});
  const [representativeId, setRepresentativeId] = useState(null);
  const [representative, setRepresentative] = useState();
  const [uId, setUid] = useState();

  const navigate = useNavigate();
  const formattedBirthDate = patientData?.user?.date_of_birth
    ? new Date(patientData?.user?.date_of_birth).toLocaleDateString("en-GB")
    : "---";

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const regNo = form.invoice_reg_mobile.value;

    const filterData = {
      regNo: regNo.trim(),
    };

    methodFilterPatientsDetails(filterData, form);
  };

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filterData, form) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data;
      setUid(data?.userDetails[0]?.user_id);
      if (data?.userDetails[0] !== undefined) {
        setPatientData(data?.userDetails[0]);
      } else {
        toast.error("No Patient Found");
        setPatientData(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveData = async (selectedData, paymentData) => {
    const currentDate = new Date().toLocaleDateString();
    try {
      const collectionResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/collection/reinvestigation/${uId}`,
        selectedData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const paymentResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/create-repayment/${uId}`,
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const detailsResponse = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/createdAt/${uId}`,
        { createdAt: currentDate },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (
        collectionResponse?.status === 201 &&
        paymentResponse?.status === 200 &&
        detailsResponse?.status === 200
      ) {
        toast.success("Reinvestigation saved successfully");
      }
    } catch (error) {
      toast.error("Some error occured while saving!");
    }
  };

  const getSpecimenData = async (invArray) => {
    try {
      const dataToSend = {
        invIds: invArray,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/collection/${uId}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (res) {
        return res?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserDetailsById = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/test/${uId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = res?.data?.data?.userDetail;
      setIntroducerUser(data?.introducer_user);
      setRepresentativeId(data?.representative_id);
      if (data) {
        let invArray = [];

        if (data?.user?.role_id === 4) {
          invArray = Array.isArray(data?.package?.investigation_id)
            ? data.package.investigation_id
            : data.package.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        } else if (data?.user?.role_id === 5) {
          invArray = Array.isArray(data?.investigation_id)
            ? data.investigation_id
            : data.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        }

        const response = await getSpecimenData(invArray);
        if (response) {
          setPatientData(data);
          setSpecimenCats(response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSpecimenChange = useCallback((event) => {
    const value = JSON.parse(event.target.value);
    const isChecked = event.target.checked;

    setSelectedSpecimens((prevSelectedSpecimens) => {
      if (isChecked) {
        // Add the selected specimen
        return [...prevSelectedSpecimens, value];
      } else {
        // Remove the deselected specimen
        return prevSelectedSpecimens.filter((specimen) => {
          return specimen.id !== value.id || specimen.name !== value.name;
        });
      }
    });
  }, []);

  const fetchPaymentMethodsAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/account-head`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const filterPaymentMethods = res?.data?.data?.filter(
        (filterPaymentMethod) => {
          return (
            filterPaymentMethod?.head_type_id === 3 ||
            filterPaymentMethod?.head_type_id === 4
          );
        }
      );

      setPaymentMethods(filterPaymentMethods);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Collect payment data
    const totalAmount = parseFloat(e.target.elements.package_price.value);
    const paidAmount = parseFloat(e.target.elements.paid_amount.value);
    const paymentMethodId = parseInt(e.target.elements.payment_method_id.value);

    if (
      isNaN(totalAmount) ||
      isNaN(paidAmount) ||
      isNaN(paymentMethodId) ||
      paymentMethodId === 0
    ) {
      // Handle validation error (you can show an error message to the user)
      toast.error("Fill in the required amounts");
      return;
    }

    const paymentData = {
      totalAmount,
      paidAmount,
      paymentMethodId,
    };
    const currentDateTime = new Date().toLocaleString();

    const selectedData = selectedSpecimens.map((specimen) => ({
      name: specimen.name,
      id: specimen.id,
      createdAt: currentDateTime,
    }));

    const update = {
      specimenCats: selectedData,
    };

    await saveData(update, paymentData);

    setSelectedSpecimens([]);

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    e.target.reset();
  };

  // const fetchUserDetailsById = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}/user-details/${uId}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
  //         },
  //       }
  //     );

  //     const data = res?.data?.data?.userDetail;
  //     setIntroducerUser(data?.introducer_user);
  //     setRepresentativeId(data?.representative_id);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleTotalAmountChange = (e) => {
    const totalAmount = parseFloat(e.target.value);

    // Update the paid amount to match the total amount
    const paidAmountInput = e.target.form.elements.paid_amount;
    if (!isNaN(totalAmount)) {
      paidAmountInput.value = totalAmount;
    }
  };

  useEffect(() => {
    const introducer_id = patientData?.introducer_id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            }
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [patientData]);

  useEffect(() => {
    fetchUserDetailsById();
    fetchPaymentMethodsAPI();
  }, [uId]);

  return (
    <>
      <div className="card p-0 m-0">
        <div className="card-body">
          <div className="border p-2 rounded">
            <div className="card-box">
              <h6
                className="mb-2 btn btn-danger text-white text-capitalize fw-bold m-0 p-0"
                style={{ cursor: "default" }}
              >
                Reinvestigation
              </h6>
            </div>
            <div className="d-flex flex-wrap">
              <form onSubmit={(e) => handlerOnSearch(e)} className="col-sm-12">
                <div className="d-flex flex-wrap">
                  <div className="p-1">
                    <label className="col-form-label tdt">
                      Registration No.
                    </label>
                    <div className="">
                      <input
                            style={{ width: "180px", height: "37px" }}
                            type="text"
                        name="invoice_reg_mobile"
                        className="form-control medical-form-select py-2"
                      />
                    </div>
                  </div>
                  <div className="p-1 d-flex align-items-end justify-content-end gap-1">
                    <button
                      type="submit"
                      className="btn text-white bg-primary border border-3"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {patientData !== undefined && (
        <>
          <div className="row mb-0 bg-primary text-white px-2 py-2">
            <h6 className="mb-0">
              Reinvestigation for {patientData?.user?.f_name}[
              {patientData?.user?.registration_no}]
            </h6>
          </div>
          <div className="row justify-content-center h-100">
            <>
              <div className="col-sm-2">
                <div
                  className="pres-photo border border-end-0 border-dark"
                  style={{ width: "100%", height: "114px" }}
                >
                  {patientData?.user?.image ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.image}`}
                      className="img-fluid"
                      alt="patient"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <img
                      src="./dummy.jpg"
                      alt="patient"
                      className="img-fluid"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
                <p
                  className="pres-title p-0 m-0 w-100 border border-end-0 border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                  style={{ height: "29px" }}
                >
                  PHOTO
                </p>
              </div>
              <div className="col-sm-8 h-100">
                <div className="row">
                  <table
                    className="responsive-width1 table-bordered"
                    style={{
                      width: "100%",
                      height: "143px",
                      borderCollapse: "collapse",
                      textAlign: "left",
                    }}
                  >
                    <tbody>
                      {/* Row 1 - Name and Client ID */}
                      <tr>
                        <td
                          className="text-uppercase border border-dark ps-1 align-middle"
                          style={{ width: "25%" }}
                        >
                          Name
                        </td>
                        <td
                          className="text-uppercase border border-dark ps-1 align-middle"
                          style={{ width: "25%" }}
                        >
                          {patientData?.user?.f_name || "---"}
                        </td>
                        <td
                          className="text-uppercase border border-dark ps-1 align-middle"
                          style={{ width: "25%" }}
                        >
                          Client ID
                        </td>
                        <td
                          className="text-uppercase border border-dark ps-1 align-middle"
                          style={{ width: "25%" }}
                        >
                          {patientData?.user?.registration_no || "---"}
                        </td>
                      </tr>

                      {/* Row 2 - Gender and Passport No */}
                      <tr>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Gender
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {patientData?.gender?.name || "---"}
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Passport No
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {patientData?.user?.passport_no || "---"}
                        </td>
                      </tr>

                      {/* Row 3 - Date of Birth and Travelling To */}
                      <tr>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {patientData?.user?.role_id !== 5
                            ? `Date of Birth`
                            : `Age`}
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {patientData?.user?.role_id !== 5
                            ? formattedBirthDate || "---"
                            : patientData?.user?.age || "---"}
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Travelling To
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {patientData?.country?.name || "---"}
                        </td>
                      </tr>

                      {/* Row 4 - Father Name and Contact Number */}
                      {patientData?.user?.role_id !== 5 && (
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Father Name
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.father_name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Contact Number
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {patientData?.user?.mobile || "---"}
                          </td>
                        </tr>
                      )}

                      {/* Row 5 - Nationality and Agency */}
                      <tr>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Nationality
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {patientData?.national_country?.nationality || "---"}
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {patientData?.user?.role_id !== 5
                            ? `${settingsData?.introducer_label}`
                            : `Reference`}
                        </td>
                        <td
                          className="text-uppercase border border-dark ps-1 align-middle"
                          colSpan={3}
                        >
                          {patientData?.user?.role_id !== 5
                            ? patientData?.introducer_user?.f_name || "---"
                            : patientData?.user?.reference || "---"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-2 h-100">
                <div
                  className="pres-photo border border-start-0 border-dark"
                  style={{ width: "100%", height: "114px" }}
                >
                  {patientData?.user?.finger_print ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.finger_print}`}
                      className="img-fluid"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <div
                      className="p-0 border border-start-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    >
                      No Fingerprint Found
                    </div>
                  )}
                </div>
                <p
                  className="pres-title p-0 m-0 w-100 border border-top-0 border-start-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                  style={{ height: "29px" }}
                >
                  THUMB PRINT
                </p>
              </div>
            </>
          </div>
          <div className="mt-4">
            <form onSubmit={(e) => handleSave(e)}>
              <table className="table table-striped">
                <thead className="bg-primary text-white">
                  <tr>
                    <th scope="col">Specimen</th>
                  </tr>
                </thead>
                <tbody>
                  {specimenCats?.map((specimen, i) => (
                    <>
                      <tr key={i}>
                        <>
                          <th scope="row">
                            <div className="form-check d-flex align-items-center mx-4">
                              <input
                                className="form-check-input mt-0 me-2 fs-4"
                                type="checkbox"
                                id={i}
                                value={JSON.stringify({
                                  id: specimen?.id,
                                  name: specimen?.name,
                                })}
                                // defaultChecked={specimen?.createdAt !== "---"}
                                // checked={isChecked}
                                onChange={handleSpecimenChange}
                                // disabled={specimen?.createdAt !== "---"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="physicalExam"
                              >
                                {specimen?.name}
                              </label>
                            </div>
                          </th>
                        </>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
              <div className="mb-3 mx-4 row">
                <label className="col-sm-3 col-form-label d-flex justify-content-start">
                  Total Amount <span className="text-danger">*</span>
                </label>
                <div className="col-sm-3">
                  <input
                    type="number"
                    name="package_price"
                    className="form-control"
                    onChange={handleTotalAmountChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-3 mx-4 row">
                <label className="col-sm-3 col-form-label d-flex justify-content-start">
                  Paid Amount
                </label>
                <div className="col-sm-3">
                  <input
                    type="number"
                    name="paid_amount"
                    className="form-control"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="mb-3 mx-4 row">
                <label className="col-sm-3 col-form-label d-flex justify-content-start">
                  Payment Method <span className="text-danger">*</span>
                </label>
                <div className="col-sm-3">
                  <select
                    className="form-select"
                    name="payment_method_id"
                    aria-label="Default select example"
                    required
                  >
                    <option value="">Choose One</option>
                    {paymentMethods?.map((payment) => (
                      <option
                        key={payment.id}
                        value={parseInt(payment?.id)}
                        selected={payment.head_type_id === 3}
                      >
                        {payment?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 mx-3">
                <div className="btn-group btn-group-justified">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 m-2 rounded"
                    disabled={selectedSpecimens.length === 0}
                  >
                    Save
                  </button>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/dashboard/specimen-collection");
                    }}
                    className="btn btn-outline-secondary px-5 m-2 rounded"
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Reinvestigation;
