import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AgencySummaryModal from "../../components/Modal/RegistrationSummaryModal/AgencySummaryModal";
import CountrySummaryModal from "../../components/Modal/RegistrationSummaryModal/CountrySummaryModal";
import PackageSummaryModal from "../../components/Modal/RegistrationSummaryModal/PackageSummaryModal";
import RepresentativeSummeryModal from "../../components/Modal/RegistrationSummaryModal/RepresentativeSummaryModal";
import "../ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import { UserContext } from "../../Context/UserContextAPI";
import Select from "react-select";
import VirtualizedMenuList from "../../components/VirtualMenuList/VirtualMenuList";
import VirtualizedMenuListPackage from "../../components/VirtualMenuList/VirtualMenuListPackage";
import Loading from "../../utils/Loader/Loading";

const MedicalRegistrationPage = () => {
  const { accessPerm } = useContext(UserContext);

  const {
    introducerUsers,
    countryUsers,
    companyUsers,
    delegateUsers,
    packages,
    representativeUsers,
  } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState([]);
  const [regInputField, setRegInputField] = useState("");
  const [initialRowSelection, setInitialRowSelection] = useState([]);
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });
  const [totalStatus, setTotalStatus] = useState(0);
  const [otherStatus, setOtherStatus] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [searchedData, setSearchedData] = useState();
  const [introducerWiseUsers, setIntroducerWiseUsers] = useState([]);
  const [packageWiseUsers, setPackageWiseUsers] = useState([]);
  const [representativeWiseUsers, setRepresentativeWiseUsers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
  });
  const [searchInputs, setSearchInputs] = useState({
    from_date: "",
    to_date: "",
    status: "",
    packageId: "",
    introducer: "",
    country: "",
    company: "",
    delegate: "",
    regNo: "",
  });

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      const representativeMobile =
        user?.user_users_maps[0]?.representative_user_map?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const countryOptions = [
    { value: "", label: "Select Country" },
    ...(countryUsers?.map((country) => ({
      value: parseInt(country?.id),
      label: `${country?.shortname} - ${country?.name}`,
    })) || []),
  ];

  const companyOptions = [
    { value: "", label: "Select Company" },
    ...(companyUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const delegateOptions = [
    { value: "", label: "Select Delegate" },
    ...(delegateUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const representativeOptions = [
    { value: "", label: "Select Representative" },
    ...representativeUsers.map((user) => {
      const representativeMobile = user?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "";

      return {
        value: parseInt(user?.id),
        label: `${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };
    let notDoneCount = 0;

    data.forEach((userInfo) => {
      const collection = userInfo?.user?.user_collections?.map(
        (collection) =>
          collection.investigation_category?.investigation_group_id
      );

      const uniqueCollection = [...new Set(collection)];
      const done =
        (uniqueCollection?.length < 2 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) <
            2) ||
        (uniqueCollection?.length >= 2 &&
          uniqueCollection?.length < 3 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) >=
            2) ||
        (uniqueCollection?.length >= 3 &&
          userInfo?.user?.is_pathology_done &&
          userInfo?.user?.is_physical_done &&
          userInfo?.user?.is_radiology_done);

      // Counting entries where the task is not done
      if (!done || userInfo?.user?.status === 1) {
        notDoneCount++;
      }

      switch (userInfo?.user?.status) {
        case 1:
          counts.status1 = counts.status1 + 1;
          break;
        case done && 2:
          counts.status2 = counts.status2 + 1;
          break;
        case done && 3:
          counts.status3 = counts.status3 + 1;
          break;
        case done && 4:
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });
    setStatusCounts(counts);
    setTotalStatus(userDetails?.length);
    setOtherStatus(notDoneCount);
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const status = form.status.value;
    const packageId = form.package.value;
    const introducer = form.introducer.value;
    const country = form.country.value;
    const company = form.company.value;
    const delegate = form.delegate.value;
    const representative = form.representative.value;
    const regNo = form.invoice_reg_mobile.value;

    const filterData = {
      fromDate,
      toDate,
      status,
      packageId,
      introducer,
      country,
      company,
      delegate,
      representative,
      regNo: regNo.trim(),
    };
    setSearchedData(searchInputs);
    methodFilterPatientsDetails(filterData, "form");
  };

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filterData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search-registration`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data;
      if (data) {
        calculateStatus(data?.userDetails);
        setUserDetails(data?.userDetails);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setTotalStatus(data?.userDetails?.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const optionLink = [
    {
      name: "Record Only",
      link: "1",
    },
    {
      name: "With Photo",
      link: "2",
    },
    {
      name: "With Account",
      link: "3",
    },
  ];

  const handleOptionSelect = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue === "1") {
      return navigate("/dashboard/medical-registration/read-only", {
        state: { checkedUsers, searchedData },
      });
    } else if (selectedValue === "2") {
      return navigate("/dashboard/medical-registration/with-photo", {
        state: { checkedUsers, searchedData },
      });
    } else if (selectedValue === "3") {
      return navigate("/dashboard/medical-registration/with-account", {
        state: { checkedUsers, searchedData },
      });
    }
  };

  function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
    const ref = useRef(null);

    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + " cursor-pointer"}
        {...rest}
      />
    );
  }

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => {
          return (
            <div>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (row, index) => {
          const reversedSerialNumber = row?.row?.index + 1;

          return (
            <div className="text-start d-flex justify-content-start fw-bold">
              <span className="text-center">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: () => (
          <span className="d-flex justify-content-center">Date</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          const dateValue = new Date(info.renderValue(reversedIndex));
          const formattedDate = format(dateValue, "dd-MM-yyyy");

          return (
            <div className="d-flex justify-content-start">{formattedDate}</div>
          );
        },
      }),

      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Patient Name</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.passport_no", {
        header: () => (
          <span className="d-flex justify-content-center">Passport No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;

          info.getValue();
          const value1 = info.renderValue(reversedIndex);
          const representative_Mob =
            info?.row?.original?.introducer_user?.user_users_maps[0]?.representative_user_map?.mobile?.slice(
              -2
            );
          return (
            <div>
              <span className="d-flex justify-content-start">
                {representative_Mob ? (
                  <span className="text-center d-flex justify-content-start">
                    {value1} - ({representative_Mob})
                  </span>
                ) : (
                  <span className="text-center d-flex justify-content-start">
                    {value1}
                  </span>
                )}
              </span>
            </div>
          );
        },
      }),

      columnHelper.accessor("country.shortname", {
        header: () => (
          <span className="d-flex justify-content-center">Country</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center">Package</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span className="d-flex justify-content-center">Status</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          const {
            is_radiology_done,
            is_pathology_done,
            is_physical_done,
            status,
          } = info.renderValue(reversedIndex);

          const collection = info?.row?.original?.user?.user_collections?.map(
            (collection) =>
              collection.investigation_category?.investigation_group_id
          );

          const uniqueCollection = [...new Set(collection)];

          const isDone =
            (uniqueCollection?.length < 2 &&
              (is_pathology_done || is_physical_done || is_radiology_done)) ||
            (uniqueCollection?.length >= 2 &&
              uniqueCollection?.length < 3 &&
              (is_pathology_done ? 1 : 0) +
                (is_physical_done ? 1 : 0) +
                (is_radiology_done ? 1 : 0) >=
                2) ||
            (uniqueCollection?.length >= 3 &&
              is_pathology_done &&
              is_physical_done &&
              is_radiology_done);

          let content;

          if (isDone && status === 2) {
            content = "FIT";
          } else if (isDone && status === 3) {
            content = "UNFIT";
          } else if (isDone && status === 4) {
            content = "HELD-UP";
          } else {
            content = "PROCESSING";
          }

          return <div className="d-flex justify-content-start">{content}</div>;
        },
      }),
      columnHelper.accessor("user.role.name", {
        header: () => (
          <span className="d-flex justify-content-center">Type</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
    ],
    [accessPerm]
  );

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/reg/all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        calculateStatus(data?.userDetails);
        setTotalStatus(data?.userDetails?.length);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        status: "",
        packageId: "",
        introducer: "",
        country: "",
        company: "",
        delegate: "",
        regNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
        country: null,
        company: null,
        delegate: null,
      });

      setRegInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (name, value) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  useEffect(() => {
    const initialSelection = userDetails.map((user, index) => index + 1);
    setInitialRowSelection(initialSelection);
    setRowSelection(initialSelection);
  }, [userDetails]);

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: (selectedRows) => {
      setRowSelection(selectedRows);
    },
    enableRowSelection: true,
  });
  useEffect(() => {
    const selectedRows = table
      .getSelectedRowModel()
      ?.flatRows?.map((row) => row.original);
    setCheckedUsers(selectedRows);
  }, [table.getSelectedRowModel()]);

  useEffect(() => {
    getUserDetails();
    table.toggleAllRowsSelected(true);
  }, [table]);

  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 text-uppercase fw-bold"
                  style={{ color: "red" }}
                >
                  Medical Registration
                </h6>
                <div className="d-flex flex-wrap align-items-center">
                  <form
                    onSubmit={(e) => handlerOnSearch(e)}
                    className="col-sm-12"
                  >
                    <div className="d-flex flex-wrap w-100">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("from_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("to_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Status</label>
                        <div className="fw-light">
                          <select
                            className="form-select medical-form-select py-2"
                            name="status"
                            aria-label="Default select example"
                            style={{ maxWidth: "140px" }}
                            onChange={(e) =>
                              handleInputChange("status", e.target.value)
                            }
                          >
                            <option value={""}>Select Status</option>
                            <option value={1}>PROCESSING</option>
                            <option value={2}>FIT</option>
                            <option value={3}>UNFIT</option>
                            <option value={4}>HELD-UP</option>
                          </select>
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Package</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("package", selectedOptions);
                              handleInputChange("package", selectedOptions);
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Representative</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={representativeOptions}
                            placeholder="Select Representative"
                            onChange={(selectedOptions) => {
                              handleSelect("representative", selectedOptions);
                              handleInputChange(
                                "representative",
                                selectedOptions
                              );
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.representative}
                            name="representative"
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={options}
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) => {
                              handleSelect("introducer", selectedOptions);
                              handleInputChange("introducer", selectedOptions);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">Country</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="country"
                            className="fw-light"
                            options={countryOptions}
                            value={selectedValues.country}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("country", selectedOptions);
                              handleInputChange("country", selectedOptions);
                            }}
                          />
                        </div>
                      </div>
                      <div className=" p-1">
                        <label className="col-form-label">Company</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="company"
                            className="fw-light"
                            options={companyOptions}
                            value={selectedValues.company}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("company", selectedOptions);
                              handleInputChange("company", selectedOptions);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Delegate</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="delegate"
                            className="fw-light"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            options={delegateOptions}
                            value={selectedValues.delegate}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("delegate", selectedOptions);
                              handleInputChange("delegate", selectedOptions);
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label tdt">
                          Registration No.
                        </label>
                        <div className="">
                          <input
                            type="text"
                            onChange={(e) => {
                              handleInputChange("fromDate", e.target.value);
                              setRegInputField(e.target.value);
                            }}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select py-2"
                            style={{ width: "180px", height: "37px" }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1 d-flex align-items-end justify-content-between gap-2">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <button
                            type="submit"
                            className="btn btn-primary text-white border border-3 fw-bold"
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning border border-3"
                            onClick={(e) => reset(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {accessPerm(67, 24) && (
                    <div className="d-flex align-items-center gap-3 justify-content-end w-100 mt-0">
                      <label htmlFor="">Report Type</label>
                      <select
                        className="px-5 py-2"
                        onChange={handleOptionSelect}
                        value={selectedOption}
                      >
                        <option value="">Action</option>
                        {optionLink.map((option, index) => (
                          <option key={index} value={option.link}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="patient-info-table-search">
              <div className="fit-unfit-status">
                <span className="fw-bolder">Record Summary &gt; </span>
                <span className="text-success fw-bolder">{`FIT: ${statusCounts?.status2}`}</span>
                <span> | </span>
                <span className="text-danger fw-bolder">{`UNFIT: ${statusCounts?.status3}`}</span>
                <span> | </span>
                <span className="fw-bolder" style={{ color: "blue" }}>
                  {`HELD-UP: ${statusCounts?.status4}`}
                </span>
                <span> | </span>
                <span className="fw-bolder">{`Others: ${otherStatus}`}</span>
                <span> | </span>
                <span className="fw-bolder">{`Total: ${totalStatus}`}</span>
              </div>
              <div className="reg-summary d-flex justify-content-end">
                <span className="me-1">Summary Of:</span>{" "}
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#country-summary-modal"
                >
                  Country
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#agency-summary-modal"
                >
                  Agency
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#repres-summary-modal"
                >
                  Representative
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#package-summary-modal"
                >
                  Package
                </span>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <table className="table align-middle table-hover table-bordered mb-0 rounded">
                <thead className="bg-primary">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, i) => (
                        <th
                          key={i}
                          id={`th-${i}`}
                          className="text-light p-1"
                          style={{ backgroundColor: "#095d7e" }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, i) => {
                    const {
                      original: {
                        user: {
                          status,
                          is_radiology_done,
                          is_pathology_done,
                          is_physical_done,
                        },
                      },
                    } = row;
                    const collection =
                      row?.original?.user?.user_collections?.map(
                        (collection) =>
                          collection.investigation_category
                            ?.investigation_group_id
                      );

                    const uniqueCollection = [...new Set(collection)];
                    const done =
                      (uniqueCollection?.length < 2 &&
                        (is_pathology_done ||
                          is_physical_done ||
                          is_radiology_done)) ||
                      (uniqueCollection?.length >= 2 &&
                        uniqueCollection?.length < 3 &&
                        (is_pathology_done ? 1 : 0) +
                          (is_physical_done ? 1 : 0) +
                          (is_radiology_done ? 1 : 0) >=
                          2) ||
                      (uniqueCollection?.length >= 3 &&
                        is_pathology_done &&
                        is_physical_done &&
                        is_radiology_done);

                    return (
                      <tr
                        key={i}
                        className={`${done && status === 4 && "text-primary"} ${
                          done && status === 2 && "text-success"
                        } ${done && status === 3 && "text-danger"} ${
                          status === 1 && "text-dark"
                        } 
           `}
                      >
                        {row.getVisibleCells().map((cell, i) => (
                          <td key={i} className="p-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <div>
        <CountrySummaryModal countryWiseUsers={countryWiseUsers} />
        <AgencySummaryModal introducerWiseUsers={introducerWiseUsers} />
        <PackageSummaryModal packageWiseUsers={packageWiseUsers} />
        <RepresentativeSummeryModal
          representativeWiseUsers={representativeWiseUsers}
        />
      </div>
    </>
  );
};

export default MedicalRegistrationPage;
