import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import AgencySummaryModal from "../../components/Modal/RegistrationSummaryModal/AgencySummaryModal";
import CountrySummaryModal from "../../components/Modal/RegistrationSummaryModal/CountrySummaryModal";
import PackageSummaryModal from "../../components/Modal/RegistrationSummaryModal/PackageSummaryModal";
import RepresentativeSummeryModal from "../../components/Modal/RegistrationSummaryModal/RepresentativeSummaryModal";
import "../ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import { UserContext } from "../../Context/UserContextAPI";
import Select from "react-select";
import VirtualizedMenuList from "../../components/VirtualMenuList/VirtualMenuList";
import VirtualizedMenuListPackage from "../../components/VirtualMenuList/VirtualMenuListPackage";
import Loading from "../../utils/Loader/Loading";
import toast from "react-hot-toast";
import CollectionReportPrint from "./CollectionReportPrint";

const CollectionReport = () => {
  const { accessPerm } = useContext(UserContext);
  const { introducerUsers, packages } = useContext(UserContext);
  const [collectionData, setCollectionData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [activeRowId, setActiveRowId] = useState(null);
  const [regInputField, setRegInputField] = useState("");
  const [refInputField, setRefInputField] = useState("");
  const [receivedAmounts, setReceivedAmounts] = useState({});
  const [initialRowSelection, setInitialRowSelection] = useState([]);
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState(true);
  const [toDateSearch, setToDateSearch] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [fromDateSearch, setFromDateSearch] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [packageSearch, setPackageSearch] = useState();
  const [introducerSearch, setIntroducerSearch] = useState();
  const [regSearch, setRegSearch] = useState();
  const navigate = useNavigate();
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });
  const [totalStatus, setTotalStatus] = useState(0);
  const [otherStatus, setOtherStatus] = useState(0);
  const inputRef = useRef(null);
  const tbodyRef = useRef(null);

  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [searchedData, setSearchedData] = useState();
  const [introducerWiseUsers, setIntroducerWiseUsers] = useState([]);
  const [packageWiseUsers, setPackageWiseUsers] = useState([]);
  const [representativeWiseUsers, setRepresentativeWiseUsers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
  });
  const [searchInputs, setSearchInputs] = useState({
    from_date: "",
    to_date: "",
    status: "",
    packageId: "",
    introducer: "",
    country: "",
    company: "",
    delegate: "",
    regNo: "",
  });

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      const representativeMobile =
        user?.user_users_maps[0]?.representative_user_map?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  // Function to calculate commission and dues
  const calculateCommissionAndDues = (row) => {
    let receivedAmount =
      receivedAmounts[row.id] || row.user_payment?.paid_amount || 0;
    let netAmount = row?.package?.discounted_price || 0;
    if (row?.user?.role_id === 5) {
      netAmount = row?.user?.tests
        ?.map((test) => test?.investigation?.discounted_price || 0)
        .reduce((sum, price) => sum + price, 0);
    }
    const commission = netAmount - receivedAmount;
    const dues = commission >= 0 ? commission : 0;

    return { commission: commission < 0 ? Math.abs(commission) : 0, dues };
  };

  // Function to handle key press on a row
  const handleKeyDown = (event, row) => {
    const currentRow = tbodyRef.current?.children.namedItem(row.id);

    // Only prevent default behavior for arrow keys
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevent default only for navigation keys
    }

    switch (event.key) {
      case "ArrowUp":
        if (currentRow?.previousElementSibling) {
          const prevInput =
            currentRow.previousElementSibling.querySelector(
              'input[type="text"]'
            );
          if (prevInput) {
            prevInput.focus();
            prevInput.setSelectionRange(0, prevInput.value.length); // Highlight input text
          }
        }
        break;
      case "ArrowDown":
        if (currentRow?.nextElementSibling) {
          const nextInput =
            currentRow.nextElementSibling.querySelector('input[type="text"]');
          if (nextInput) {
            nextInput.focus();
            nextInput.setSelectionRange(0, nextInput.value.length); // Highlight input text
          }
        }
        break;
      default:
        // Allow default behavior for non-navigation keys (typing, etc.)
        break;
    }
  };

  const handleReceivedAmountChange = useCallback((id, value, info) => {
    setReceivedAmounts((prevAmounts) => ({
      ...prevAmounts,
      [id]: parseFloat(value) || 0,
    }));
    setActiveRowId(id); // Set the active row ID

    const userId = info?.row?.original?.user?.id;
    if (userId) {
      setCollectionData((prevCollectionData) => {
        const existingEntry = prevCollectionData.find(
          (item) => item.id === userId
        );

        if (existingEntry) {
          // Update the existing entry
          return prevCollectionData.map((item) =>
            item.id === userId ? { ...item, receivedAmount: value } : item
          );
        } else {
          // Add a new entry to collectionData
          return [
            ...prevCollectionData,
            {
              id: userId,
              receivedAmount: value,
            },
          ];
        }
      });
    }
  }, []);

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };
    let notDoneCount = 0;

    data.forEach((userInfo) => {
      const collection = userInfo?.user?.user_collections?.map(
        (collection) =>
          collection.investigation_category?.investigation_group_id
      );

      const uniqueCollection = [...new Set(collection)];
      const done =
        (uniqueCollection?.length < 2 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) <
            2) ||
        (uniqueCollection?.length >= 2 &&
          uniqueCollection?.length < 3 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) >=
            2) ||
        (uniqueCollection?.length >= 3 &&
          userInfo?.user?.is_pathology_done &&
          userInfo?.user?.is_physical_done &&
          userInfo?.user?.is_radiology_done);

      // Counting entries where the task is not done
      if (!done || userInfo?.user?.status === 1) {
        notDoneCount++;
      }

      switch (userInfo?.user?.status) {
        case 1:
          counts.status1 = counts.status1 + 1;
          break;
        case done && 2:
          counts.status2 = counts.status2 + 1;
          break;
        case done && 3:
          counts.status3 = counts.status3 + 1;
          break;
        case done && 4:
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });
    setStatusCounts(counts);
    setTotalStatus(userDetails?.length);
    setOtherStatus(notDoneCount);
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    // const status = form.status.value;
    const packageId = form.package.value;
    const introducer = form.introducer.value;
    // const country = form.country.value;
    // const company = form.company.value;
    // const delegate = form.delegate.value;
    // const representative = form.representative.value;
    const regNo = form.invoice_reg_mobile.value;
    // const refNo = form.ref.value;
    setToDateSearch(toDate);
    setFromDateSearch(fromDateSearch);
    setPackageSearch(packageId);
    setIntroducerSearch(introducer);
    setRegSearch(regNo.trim());
    // setRefSearch(refNo.trim());
    setFilterData({
      fromDate,
      toDate,
      //   status,
      packageId,
      introducer,
      //   country,
      //   company,
      //   delegate,
      //   representative,
      regNo: regNo.trim(),
      // refNo: refNo.trim(),
    });
    const filteredData = {
      fromDate,
      toDate,
      //   status,
      packageId,
      introducer,
      //   country,
      //   company,
      //   delegate,
      //   representative,
      regNo: regNo.trim(),
      // refNo: refNo.trim(),
    };
    // setFilterData({
    //   fromDateSearch,
    //   toDateSearch,
    //   //   status,
    //   packageId,
    //   introducer,
    //   //   country,
    //   //   company,
    //   //   delegate,
    //   //   representative,
    //   regNo: regNo.trim(),
    //   refNo: refNo.trim(),
    // });
    setSearchedData(searchInputs);
    methodFilterPatientsDetails(filteredData, "form");
  };

  const saveCollection = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/collection/payment`,
        collectionData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filteredData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search-collection-report`,
        filteredData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data;
      if (data) {
        calculateStatus(data?.userDetails);
        setUserDetails(data?.userDetails);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setTotalStatus(data?.userDetails?.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (row, index) => {
          const reversedSerialNumber = row?.row?.index + 1;

          return (
            <div className="text-start d-flex justify-content-start fw-bold">
              <span className="text-center">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Passenger</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;

          info.getValue();
          const value1 = info.renderValue(reversedIndex);
          const representative_Mob =
            info?.row?.original?.introducer_user?.user_users_maps[0]?.representative_user_map?.mobile?.slice(
              -2
            );
          return (
            <div>
              <span className="d-flex justify-content-start">
                {representative_Mob ? (
                  <span className="text-center d-flex justify-content-start">
                    {value1} - ({representative_Mob})
                  </span>
                ) : (
                  <span className="text-center d-flex justify-content-start">
                    {value1}
                  </span>
                )}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center">Test/Ref By</span>
        ),
        cell: (info, index) => {
          const user = info?.row?.original?.user;

          // Check if role_id is 5
          if (user?.role_id === 5) {
            // Get the tests array from the user
            const tests = user.tests || [];

            let investigation31Added = false;

            const investigationNames = tests
              .filter((test) => {
                if (test?.investigation?.id === 31) {
                  if (investigation31Added) {
                    return false;
                  }
                  investigation31Added = true;
                  return true;
                }
                return true;
              })
              .map((test) => test.investigation?.name)
              .filter(Boolean)
              .join(", ");

            return (
              <div className="d-flex justify-content-start">
                {investigationNames || "No Investigations"}
              </div>
            );
          } else {
            // If role_id is not 5, render the default value
            const reversedIndex = userDetails.length - index - 1;
            return (
              <div className="d-flex justify-content-start">
                {info.renderValue(reversedIndex)}
              </div>
            );
          }
        },
      }),
      columnHelper.accessor("user", {
        header: () => <span>Received</span>,
        cell: (info) => {
          const row = info.row.original;
          return (
            <input
              className="form-control border border-dark"
              ref={row.id === activeRowId ? inputRef : null}
              type="text"
              value={
                receivedAmounts[row.id] || row?.user_payment?.paid_amount || 0
              }
              onChange={(e) =>
                handleReceivedAmountChange(row.id, e.target.value, info)
              }
              onKeyDown={(e) => handleKeyDown(e, row)} // Attach keydown for navigation
            />
          );
        },
      }),

      columnHelper.accessor("user", {
        header: () => <span>Commission</span>,
        cell: (info) => {
          const row = info.row.original;
          const { commission } = calculateCommissionAndDues(row);
          return <span>{commission}</span>;
        },
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span className="d-flex justify-content-center">Net Amount</span>
        ),
        cell: (info, index) => {
          const user = info?.row?.original;
          // Check if role_id is 5
          if (user?.user?.role_id === 5) {
            const totalDiscountedPrice = user?.user?.tests
              .map((test) => test.investigation?.discounted_price || 0)
              .reduce((sum, price) => sum + price, 0);
            return (
              <div className="d-flex justify-content-start">
                {totalDiscountedPrice}
              </div>
            );
          } else {
            // If role_id is not 5, render the default value
            return (
              <div className="d-flex justify-content-start">
                {user?.package?.discounted_price}
              </div>
            );
          }
        },
      }),
      columnHelper.accessor("user", {
        header: () => <span>Dues</span>,
        cell: (info) => {
          const row = info.row.original;
          const { dues } = calculateCommissionAndDues(row);
          return <span>{dues}</span>;
        },
      }),
    ],
    [receivedAmounts]
  );

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/collection-report-all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        calculateStatus(data?.userDetails);
        setTotalStatus(data?.userDetails?.length);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        // status: "",
        packageId: "",
        introducer: "",
        // country: "",
        // company: "",
        // delegate: "",
        regNo: "",
        refNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
        // country: null,
        // company: null,
        // delegate: null,
      });

      setRegInputField("");
      setRefInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (name, value) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: (selectedRows) => {
      setRowSelection(selectedRows);
    },
  });
  useEffect(() => {
    getUserDetails();
  }, [table]);

  useEffect(() => {
    if (activeRowId !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [receivedAmounts, activeRowId]);

  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 text-uppercase fw-bold"
                  style={{ color: "red" }}
                >
                  Collection Report
                </h6>
                <div className="d-flex align-items-end justify-content-between">
                  <form onSubmit={(e) => handlerOnSearch(e)} className="">
                    <div className="d-flex flex-wrap">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={
                              regInputField || refInputField ? false : true
                            }
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("from_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={
                              regInputField || refInputField ? false : true
                            }
                            style={{ maxWidth: "200px" }}
                            onChange={(e) =>
                              handleInputChange("to_date", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Package</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) => {
                              handleSelect("package", selectedOptions);
                              handleInputChange("package", selectedOptions);
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={options}
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) => {
                              handleSelect("introducer", selectedOptions);
                              handleInputChange("introducer", selectedOptions);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label tdt">
                          Registration No.
                        </label>
                        <div className="">
                          <input
                            type="text"
                            onChange={(e) => {
                              handleInputChange("fromDate", e.target.value);
                              setRegInputField(e.target.value);
                            }}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select py-2"
                            style={{ width: "180px", height: "37px" }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-1 p-1 d-flex align-items-end justify-content-between">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                          <button
                            type="submit"
                            className="btn btn-primary px-4 text-white border border-3 fw-bold"
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning px-4 border border-3"
                            onClick={(e) => reset(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="d-flex align-items-end ps-3 mb-1">
                    <div className="d-flex align-items-center gap-3 justify-content-end mt-0">
                      {accessPerm(67, 24) && (
                        <button
                          className="btn bg-success px-5 border border-3 text-white"
                          onClick={saveCollection}
                        >
                          Save
                        </button>
                      )}
                      <button
                        className="btn bg-danger px-5 border border-3 text-white"
                        onClick={() =>
                          navigate("/dashboard/medical-collection/print", {
                            state: filterData,
                          })
                        }
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <table className="table w-100 align-middle table-hover table-bordered mb-0 rounded position-relative overflow-x-scroll">
                <thead
                  className="bg-primary w-100"
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: "#095d7e",
                  }}
                >
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="w-100">
                      {headerGroup.headers.map((header, i) => (
                        <th
                          key={i}
                          id={`th-${i}`}
                          className="text-light p-1"
                          style={{ backgroundColor: "#095d7e" }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody ref={tbodyRef}>
                  {table.getRowModel().rows.map((row, i) => {
                    const {
                      original: {
                        user: {
                          status,
                          is_radiology_done,
                          is_pathology_done,
                          is_physical_done,
                        },
                      },
                    } = row;
                    const collection =
                      row?.original?.user?.user_collections?.map(
                        (collection) =>
                          collection.investigation_category
                            ?.investigation_group_id
                      );

                    const uniqueCollection = [...new Set(collection)];
                    const done =
                      (uniqueCollection?.length < 2 &&
                        (is_pathology_done ||
                          is_physical_done ||
                          is_radiology_done)) ||
                      (uniqueCollection?.length >= 2 &&
                        uniqueCollection?.length < 3 &&
                        (is_pathology_done ? 1 : 0) +
                          (is_physical_done ? 1 : 0) +
                          (is_radiology_done ? 1 : 0) >=
                          2) ||
                      (uniqueCollection?.length >= 3 &&
                        is_pathology_done &&
                        is_physical_done &&
                        is_radiology_done);

                    return (
                      <tr
                        key={i}
                        id={row.id}
                        tabIndex={0}
                        className={`${done && status === 4 && "text-primary"} ${
                          done && status === 2 && "text-success"
                        } ${done && status === 3 && "text-danger"} ${
                          status === 1 && "text-dark"
                        }`}
                        onKeyDown={(e) => handleKeyDown(e, row)}
                      >
                        {row.getVisibleCells().map((cell, i) => (
                          <td key={i} className="p-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div>
        <CountrySummaryModal countryWiseUsers={countryWiseUsers} />
        <AgencySummaryModal introducerWiseUsers={introducerWiseUsers} />
        <PackageSummaryModal packageWiseUsers={packageWiseUsers} />
        <RepresentativeSummeryModal
          representativeWiseUsers={representativeWiseUsers}
        />
      </div>
    </>
  );
};

export default CollectionReport;
