import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

const AgencySummaryModal = ({ introducerWiseUsers }) => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("serialNumber", {
      header: () => <span>SL No</span>,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("introducer_user", {
      header: () => <span>Agency</span>,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("count", {
      header: () => <span> Medical</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("total", {
      header: () => <span>Total</span>,
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: introducerWiseUsers,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div
        className="modal fade"
        id="agency-summary-modal"
        tabIndex="-1"
        aria-labelledby="agency-summary-modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="agency-summary-modalLabel">
                Agency Summary | Total: {introducerWiseUsers?.length}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="card patient-information">
                <div className="border p-2 rounded">
                  <table className="table align-middle table-hover table-bordered mb-0 rounded">
                    <thead className="bg-primary text-white">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup?.id}>
                          {headerGroup.headers.map((header) => (
                            <th key={header?.id} className={` p-1`}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="p-1">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencySummaryModal;
