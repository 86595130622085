import React, { useContext, useEffect, useState } from "react";
import "./Invoice.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../Context/UserContextAPI";
import JsBarcode from "jsbarcode";
import axios from "axios";
import { format, isValid } from "date-fns";

const UserInvoiceCopy = ({ stateData }) => {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  const instrumentsURL = `${process.env.REACT_APP_API_BASE_URL}/instruments`;

  const { accessPerm, settingsData } = useContext(UserContext);
  const {
    state: {
      record: {
        user: { role_id },
      },
    },
  } = useLocation();
  const { state } = useLocation();
  const userId = state?.record?.user?.id;
  const createdBy = state?.record?.user?.created_by;

  const formattedBirthDate = state?.record?.user?.date_of_birth
    ? (() => {
        const date = new Date(state?.record?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const birthYear = state?.record?.user?.date_of_birth
    ? new Date(state?.record?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear}Y` : "";

  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [data, setData] = useState({});
  const [userTests, setUserTests] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [testInstruments, setTestInstruments] = useState([]);
  const [discount, setDiscount] = useState("");
  const [due, setDue] = useState("");
  const [investigations, setInvestigations] = useState([]);
  const [itemsData, setItemsData] = useState([
    {
      item_id: "",
      item_price: "",
      type: "",
    },
  ]);
  const [checked, setChecked] = useState(false);
  const [specimenGrp, setSpecimenGrp] = useState([]);
  const paidAmount = state?.record?.user_payment?.paid_amount;
  const packageAmount = state?.record?.user_payment?.amount;
  const packageName = state?.record?.package?.name;
  const [totalPrice, setTotalPrice] = useState("");
  const [netTotalPrice, setNetTotalPrice] = useState(
    state?.record?.user?.package_price || ""
  );
  // const [discountedPrice, setDiscountedPrice] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState("");

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      width: 1,
      height: 20,
      // displayValue: false,
    });
    return canvas.toDataURL();
  };

  const patientRegNameDataUrl = generateBarcode(
    String(`${state?.record?.user?.f_name}${age ? `-${age}` : ``}`)
  );

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(state?.record?.user?.registration_no), {
    format: "CODE128", // Use CODE128 format,
    width: 1,
    height: 20,
  });
  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);

    if (!isValid(date)) {
      return ""; // Return an empty string if the date is invalid
    }

    return format(date, "dd-MM-yyyy");
  };

  const getData = () => {
    axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response?.data?.data[0];
        setData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrint = () => {
    window.print();
  };

  const calculateTotalAmount = () => {
    const newTotalPrice = itemsData?.reduce((sum, investigation) => {
      const price = parseFloat(investigation.item_price) || 0;
      return sum + price;
    }, 0);
    setDiscountedAmount(state?.record?.user?.discount);
    setTotalPrice(newTotalPrice);
    const discountAmount = (newTotalPrice * discountedAmount) / 100;
    setDiscount(discountAmount);
    const newNetTotalPrice = newTotalPrice - discountAmount;
    setNetTotalPrice(newNetTotalPrice);
    const dueAmount = newNetTotalPrice - paidAmount;
    if (state?.record?.user?.role_id === 5) {
      setDue(dueAmount);
    } else {
      const due = packageAmount - paidAmount;
      setDue(due);
    }
  };

  const getSpecimenData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/investigation-groups`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      if (res?.status === 200) {
        setSpecimenGrp(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserDetailsById = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = res?.data?.data?.userDetail;
      if (data) {
        let invArray = [];

        if (data?.user?.role_id === 4) {
          invArray = Array.isArray(data?.package?.investigation_id)
            ? data.package.investigation_id
            : data.package.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        } else if (data?.user?.role_id === 5) {
          invArray = Array.isArray(data?.investigation_id)
            ? data.investigation_id
            : data.investigation_id?.split(",").map(Number); // If it's a string, split and convert to numbers
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInvestigationsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/test/investigations`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setInvestigations(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchtestInstrumentsData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-test-instruments`,
        {
          userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setTestInstruments(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserTestsData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-test-instruments/user-tests`,
        {
          userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUserTests(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchInstrumentsData = async () => {
    try {
      const response = await axios.get(instrumentsURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      setInstruments(response?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      return "Invalid date";
    }
  }

  const initializeItemsData = () => {
    let initialItemsData = [];

    // Check if role_id is not 5
    if (state?.record?.user?.role_id !== 5) {
      // Convert the investigation_id string into an array of integers
      const investigationIds = state?.record?.package?.investigation_id
        ?.split(",")
        ?.map((id) => parseInt(id.trim()));
      // Filter investigations based on package investigation_id
      initialItemsData = investigations
        .filter((investigation) => investigationIds?.includes(investigation.id))
        .map((filteredInvestigation) => ({
          item_id: filteredInvestigation.id,
          item_price: filteredInvestigation.price,
          type: 1,
        }));
    } else {
      // Regular initialization for role_id 5
      initialItemsData = [...userTests, ...testInstruments]?.map((test) => {
        const item = combinedItems?.find(
          (option) => option.value === test.item_id && option.type === test.type
        );
        return {
          item_id: item ? item?.value : test?.item_id,
          item_price: item
            ? parseFloat(test?.item_price)
            : parseFloat(item?.price), // Use custom price from test
          type: item ? item?.type : test?.type,
        };
      });
    }
    setItemsData(initialItemsData);
  };

  const instrumentsWithType = instruments?.map((instrument) => ({
    ...instrument,
    type: 2,
    label: instrument.name,
    value: instrument.id,
  }));

  const investigationsWithType = investigations?.map((investigation) => ({
    ...investigation,
    type: 1,
    label: investigation.name,
    value: investigation.id,
  }));

  const combinedItems = [...investigationsWithType, ...instrumentsWithType];

  useEffect(() => {
    calculateTotalAmount();
  }, [itemsData]);

  useEffect(() => {
    if (userTests?.length > 0 || testInstruments?.length > 0) {
      initializeItemsData();
    } else {
      initializeItemsData();
    }
  }, [userTests, testInstruments, investigations, instruments]);

  useEffect(() => {
    getData();
    fetchUserDetailsById();
    fetchUserTestsData();
    fetchtestInstrumentsData();
    fetchInstrumentsData();
    fetchInvestigationsData();
    getSpecimenData();
  }, []);
  return (
    <div className="patient-invoice p-3">
      {/* changing */}
      <div className="col-sm-12 text-dark">
        <div className="text-center patient-invoice-header-middle">
          <div className="">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="row px-0 mx-3 justify-content-center h-100">
        <div className="d-flex align-items-center">
          <div style={{ width: "35%" }}>
            <p className="p-0 m-0 text-dark fs-6">
              REG DATE:{" "}
              {formatDate(state?.record?.user?.createdAt, "dd-MM-yyyy hh:mm a")}
            </p>
          </div>
          <div style={{ width: "30%" }} className="p-0 m-0">
            <h6 className="text-uppercase text-bold fs-5 mx-2 px-3 text-center text-dark p-0 m-0">
              Money Receipt
            </h6>
          </div>
          <div style={{ width: "35%" }}>
            <p className="p-0 m-0 text-end text-dark fs-6">Customer Copy</p>
          </div>
        </div>
      </div>
      <div className="row mx-3 justify-content-center h-100 text-dark">
        <div className="d-flex w-100">
          <div className="h-100" style={{ width: "10%" }}>
            <div
              className="border border-end-0 border-dark h-100"
              style={{ width: "100%", height: "114px" }}
            >
              {state?.record?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.record?.user?.image}`}
                  // className="img-fluid"
                  alt="patient"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
          </div>
          <div className="h-100" style={{ width: "15%" }}>
            <div
              className="d-flex flex-column border border-end-0 border-dark m-0"
              style={{ width: "100%", height: "100%" }}
            >
              {/* <p
                  style={{ height: "34px" }}
                className="invoice-date p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                >
                  DATE:{" "}
                  {formatDate(
                    state?.record?.user?.createdAt,
                    "dd-MM-yyyy hh:mm a"
                  )}
                </p> */}
              <img
                src={patientIdDataUrl}
                alt=""
                className="patient-barcode2 h-100"
              />
            </div>
            {/* <p
              className="pres-title p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
              style={{ height: "34px" }}
            >
              CLIENT ID: {state?.record?.user?.registration_no}
            </p> */}
          </div>
          <div className="h-100" style={{ width: "55%" }}>
            <div className="row h-100 text-dark">
              <table
                className="table-bordered invoice-table h-100 text-dark"
                style={{
                  width: "100%",
                  height: "100%",
                  borderCollapse: "collapse",
                  textAlign: "left",
                }}
              >
                <tbody>
                  {/* Row 1 - Name and Client ID */}
                  <tr>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      Name
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      {state?.record?.user?.f_name || "---"}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      Client ID
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      {state?.record?.user?.registration_no || "---"}
                    </td>
                  </tr>

                  {/* Row 2 - Gender and Passport No */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Gender
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.gender?.name || "---"}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      // style={{ fontSize: "30px" }}
                    >
                      Passport No
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.passport_no || "---"}
                    </td>
                  </tr>

                  {/* Row 3 - Date of Birth and Travelling To */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Date of Birth
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {formattedBirthDate}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Travelling To
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.country?.name || "---"}
                    </td>
                  </tr>

                  {/* Row 4 - Father Name and Contact Number */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Father Name
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.father_name || "---"}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Contact Number
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.mobile || "---"}
                    </td>
                  </tr>

                  {/* Row 5 - Nationality and Agency */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Nationality
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.national_country?.nationality || "---"}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {settingsData?.introducer_label}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      colSpan={3}
                    >
                      {state?.record?.introducer_user?.f_name || "---"}
                      {/* {state?.record?.user?.gcc_expairy_date
                        ? formatDateToDDMMYYYY(
                            state?.record?.user?.gcc_expairy_date
                          )
                        : "N/A"} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="h-100" style={{ width: "20%" }}>
            <div
              className="w-100 border border-dark border-start-0 d-flex flex-column justify-content-between"
              style={{ height: "100%" }}
            >
              <img
                src={patientRegNameDataUrl}
                alt=""
                className="patient-barcode2 h-100"
              />
            </div>
            {/* <p
              className="p-0 m-0 w-100 border border-top-0 border-start-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
              style={{ height: "34px" }}
            >
              Name: {state?.record?.user?.f_name}
            </p> */}
          </div>
        </div>

        <div className="d-flex flex-column align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-between w-100 invoice-delivery">
            <div
              className="d-flex flex-row justify-content-between text-dark"
              // style={{ width: "30%" }}
            >
              <p className="m-0 text-dark">
                Report Delivery Date:{" "}
                {state?.record?.user?.report_delivery_date
                  ? formatDateToDDMMYYYY(
                      state?.record?.user?.report_delivery_date
                    )
                  : "N/A"}
              </p>
            </div>

            {/* <div className="d-flex justify-content-end text-dark">
              <p className="m-0 text-dark">
                Slip Expiary Date :{" "}
                {state?.record?.user?.gcc_expairy_date
                  ? formatDateToDDMMYYYY(state?.record?.user?.gcc_expairy_date)
                  : "N/A"}
              </p>
            </div> */}
          </div>
        </div>
        {/* <div className="d-flex flex-row justify-content-between text-dark"> */}
        {/*   <span className="fs-5"> */}
        {/*     Amount:{" "} */}
        {/*     {state?.record?.user?.role_id !== 5 ? packageAmount : netTotalPrice} */}
        {/*   </span> */}
        {/*   <p className="p-0 m-0 fst-italic"> */}
        {/*     N.B: In case of technical/electrical failure delivery of reports may */}
        {/*     delay. */}
        {/*   </p> */}
        {/* </div> */}
        {/* <div className="d-flex flex-row justify-content-between text-dark">
              <span className="fs-6">Narration: WITH TPHA/MIZ</span>
            </div> */}
      </div>

      <div
        className="d-flex flex-wrap justify-content-between mx-5 py-1"
        // style={{ width: "40%" }}
      >
        {specimenGrp?.map((specimen, i) => (
          <div className="d-flex text-dark" key={i}>
            <div className="d-flex">
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input mt-0 me-2 p-2 fs-4 border-3 border-dark "
                  // style={{ borderWidth: "10px" }}
                  type="radio"
                  id={i}
                  value={JSON.stringify({
                    id: specimen?.id,
                    name: specimen?.name,
                  })}
                  // checked={isChecked}
                />
                <label className="form-check-label fs-5" htmlFor="physicalExam">
                  {specimen?.name}
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>

      <section
        className="patient-invoice mx-3"
        // style={{ pageBreakAfter: "always" }}
      >
        {/* {state?.record?.user?.role_id === 5 ? (
          <div className="d-flex flex-column">
            <div
              className="d-flex justify-content-between medical-form-patient-data text-dark"
              style={{ gap: "250px" }}
            >
              <div className="w-50 d-flex">
                <div>
                  <div className="fw-bold">ID Number</div>
                  <div className="fw-bold">Registration Number</div>
                  <div className="fw-bold">Name</div>
                </div>
                <div className="ms-auto">
                  <div className="fw-bold">
                    : {registration_no?.split(" ")[1]}
                  </div>
                  <div className="fw-bold">: {registration_no}</div>
                  <div className="">: {f_name}</div>
                </div>
              </div>
              <div className="w-50 d-flex">
                <div>
                  <div className="fw-bold">Gender</div>
                  <div className="fw-bold">Age</div>
                  <div className="fw-bold">Contact</div>
                </div>
                <div className="ms-auto">
                  <div className="">: {genderName}</div>
                  <div className="">
                    : {state?.record?.user?.age ? state?.record?.user?.age : ""}
                  </div>
                  <div className="">: {mobile}</div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center w-100">
              <div className="w-25">
                <div className="fw-bold text-dark">Referred By</div>
              </div>
              <div className="w-25">
                {state?.record?.user?.role_id === 5 ? (
                  <div className="text-dark">
                    :{" "}
                    {state?.record?.user?.reference &&
                      `${state?.record?.user?.reference}`}
                  </div>
                ) : (
                  <div className="text-dark">
                    : {introducer_user && `${introducer_user.f_name}`}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-between medical-form-patient-data text-dark"
            style={{ gap: "250px" }}
          >
            <div className="w-50 d-flex">
              <div>
                <div className="fw-bold">ID Number</div>
                <div className="fw-bold">Registration Number</div>
                <div className="fw-bold">Name</div>
                <div className="fw-bold">Father Name</div>
                <div className="fw-bold">Gender</div>
                <div className="fw-bold">Passport Number</div>
              </div>
              <div className="ms-auto">
                <div className="fw-bold">
                  : {registration_no?.split(" ")[1]}
                </div>
                <div className="fw-bold">: {registration_no}</div>
                <div className="">: {f_name}</div>
                <div className="">: {father_name}</div>
                <div className="">: {genderName}</div>
                <div className="">: {passport_no}</div>
              </div>
            </div>
            <div className="w-50 d-flex">
              <div>
                <div className="fw-bold">Date of Birth</div>
                <div className="fw-bold">Contact</div>
                <div className="fw-bold">Referred By</div>
                <div className="fw-bold">Country</div>
                <div className="fw-bold">Company</div>
              </div>
              <div className="ms-auto">
                <div className="">
                  :{" "}
                  {date_of_birth
                    ? format(new Date(date_of_birth), "dd-MM-yyyy")
                    : ""}
                </div>
                <div className="">: {mobile}</div>
                <div className="">
                  : {introducer_user && `${introducer_user.f_name}`}
                </div>
                <div className="">: {countryName}</div>
                <div className="">
                  : {company_user && `${company_user.f_name}`}
                </div>
              </div>
            </div>
          </div>
        )} */}

        <div className="row">
          <table className="table table-bordered mb-0 custom-table">
            <thead>
              <tr className="table-active">
                <th
                  colSpan="3"
                  className="text-center colspan-heading border border-dark m-0 p-0"
                >
                  Accounts Transaction Details
                </th>
              </tr>
              {!checked ? (
                <>
                  <tr>
                    <th className="col-sm-8 border border-dark">Description</th>
                    <th className="text-center border border-dark">
                      Price (BDT)
                    </th>
                  </tr>
                </>
              ) : (
                ""
              )}
            </thead>
            {state?.record?.user?.role_id === 5 && (
              <tbody>
                {!checked ? (
                  <>
                    {itemsData?.map((item, index) => {
                      const matchingItem = combinedItems?.find(
                        (option) =>
                          option.value === item?.item_id &&
                          option.type === item.type
                      );
                      return (
                        <tr key={index} className="border-dark">
                          <td className="w-50">
                            {matchingItem ? matchingItem?.name : "---"}
                          </td>
                          <td className="w-50 text-end">
                            {/* Display the name of the matching item */}
                            {item ? item?.item_price : "---"}
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="no-border">
                      <td className="text-end no-border">Total:</td>
                      <td className="text-end no-border">{totalPrice || 0}</td>
                    </tr>
                    {discountedAmount > 0 && (
                      <tr className="no-border">
                        <td className="text-end no-border">
                          Discount (
                          {discountedAmount ? `${discountedAmount}%` : "0"}):
                        </td>
                        <td className="text-end no-border">{discount || 0}</td>
                      </tr>
                    )}
                    <tr className="no-border">
                      <td className="text-end no-border">Grand Total:</td>
                      <td className="text-end no-border">
                        {netTotalPrice || 0}
                      </td>
                    </tr>
                    {/* <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Commission:</td>
                      <td className="text-end no-border">{due || 0}</td>
                    </tr> */}
                  </>
                ) : (
                  <>
                    <tr
                      className="text-center d-flex align-items-center justify-content-center border border-dark border-top-0 fw-bold"
                      style={{ height: "70px", fontSize: "22px" }}
                    >
                      Paid
                    </tr>
                  </>
                )}
              </tbody>
            )}
            {state?.record?.user?.role_id !== 5 && (
              <tbody>
                {!checked ? (
                  <>
                    <tr>
                      <td className="w-50 border border-dark">{packageName}</td>
                      <td className="w-50 text-end border border-dark">
                        {/* Display the name of the matching item */}
                        {packageAmount ? packageAmount : 0}
                      </td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Grand Total:</td>
                      <td className="text-end no-border">
                        {packageAmount || 0}
                      </td>
                    </tr>
                    {/* <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    {due > 0 && (
                      <tr className="no-border">
                        <td className="text-end no-border">Due:</td>
                        <td className="text-end no-border">{due || 0}</td>
                      </tr>
                    )} */}
                  </>
                ) : (
                  <>
                    <tr
                      className="text-center d-flex align-items-center justify-content-center border border-dark border-top-0 fw-bold"
                      style={{ height: "70px", fontSize: "22px" }}
                    >
                      Paid
                    </tr>
                  </>
                )}
              </tbody>
            )}
          </table>
        </div>
        <div className="row">
          {/* <div className="col-7 pe-2">
            <p className="fw-bold text-dark">
              Receiving Date:{" "}
              {formatReceiveTime(state?.record?.user_payment?.createdAt)}
            </p>
            <div>
              <textarea
                name="invoiceComments"
                id="invoiceComments"
                rows="5"
                cols="83"
                placeholder="Comments&#13;...&#13;"
                className="d-block bg-transparent border border-dark w-100"
                readOnly
                style={{ resize: "none" }}
              />
            </div>
          </div>
          <div className="col-5 ps-2">
            <div className="d-flex h-100 flex-column">
              <p className="fw-bold flex-grow-1 text-dark">
                Report Delivery Date: {formatDeliveryTime(newDate)}
              </p>
              <div className="border-bottom border-dark"></div>
            </div>
          </div> */}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-column invoice-verification w-100">
            <p className="p-0 m-0 text-dark">
              Online Verification:{" "}
              <Link to={`${settingsData?.website_url}`} target="_blank">
                {settingsData?.website_url}
              </Link>{" "}
            </p>
          </div>

          {/* <div className="text-center text-dark border border-start-0 border-end-0 border-bottom-0 border-dark">
            Official Seal & Signature
          </div> */}
        </div>
        <div className="w-100">
          <p className="static-user-invoice text-dark w-100 p-0 m-0">
            সতর্কীকরণ, এখানে ক্যাশ কাউন্টার ছাড়া বিকাশ বা অন্য কোন মাধ্যমে টাকা
            পয়সা লেনদেন করা হয় না দালাল বা প্রতারক চক্রের হতে সাবধান থাকুন,
            ফোন বা এসএমএস করে কেউ টাকা পয়সা চাইলে মেডিকেল সেন্টারের কাউন্টারে
            অবহিত করার জন্য অনুরোধ করা হলো
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start text-dark">
            User: {createdBy}
          </div>
          <p className="p-0 m-0 fst-italic text-dark text-center">
            This is an auto generated report, does not require any signature.
          </p>
          <div className="text-end text-dark fst-italic p-0 m-0">
            Powered by Clincx Medical Solutions
          </div>
        </div>
        <div className="invoice-footer d-flex mt-0 align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-1 text-dark">
            <input
              type="checkbox"
              name="checkbox-invoice"
              id="checkbox-invoice"
              defaultChecked={checked}
              onClick={() => setChecked(!checked)}
            />
            <label htmlFor="checkbox-invoice">Print Without Amount</label>
          </div>
          {/* <div className="d-flex gap-2">
            {accessPerm(32, 23) && (
              <button className="btn btn-primary" onClick={() => handlePrint()}>
                Print
              </button>
            )}
            {role_id === 5 ? (
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/dashboard/medical-tests")}
              >
                Back
              </button>
            ) : (
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/dashboard/patient-info")}
              >
                Back
              </button>
            )}
          </div> */}
        </div>
      </section>
      <hr style={{ borderTop: "3px dotted black", height: "0" }} />
      <div className="col-sm-12 text-dark">
        <div className="text-center patient-invoice-header-middle">
          <div className="">
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
              alt="Report Header"
              className="mx-auto"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="row px-2 mx-2 justify-content-center h-100">
        <div className="d-flex align-items-center">
          <div style={{ width: "35%" }}>
            <p className="p-0 m-0 text-dark fs-6">
              REG DATE:{" "}
              {formatDate(state?.record?.user?.createdAt, "dd-MM-yyyy hh:mm a")}
            </p>
          </div>
          <div style={{ width: "30%" }} className="p-0 m-0">
            <h6 className="text-uppercase text-bold fs-5 mx-2 px-3 text-center text-dark p-0 m-0">
              Money Receipt
            </h6>
          </div>
          <div style={{ width: "35%" }}>
            <p className="p-0 m-0 text-end text-dark fs-6">Office Copy</p>
          </div>
        </div>
      </div>

      <div className="row px-2 mx-2 justify-content-center h-100">
        <>
          <div className="col-sm-2">
            <div
              className="border border-end-0 border-dark"
              style={{ width: "100%", height: "100%" }}
            >
              {state?.record?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.record?.user?.image}`}
                  className="img-fluid h-100"
                  alt="patient"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid h-100"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-sm-8">
            <div className="row text-dark h-100">
              <table
                className="table-bordered invoice-table h-100 text-dark"
                style={{
                  width: "100%",
                  height: "100%",
                  borderCollapse: "collapse",
                  textAlign: "left",
                }}
              >
                <tbody>
                  {/* Row 1 - Name and Client ID */}
                  <tr>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      Name
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      {state?.record?.user?.f_name || "---"}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      Client ID
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      style={{ width: "25%" }}
                    >
                      {state?.record?.user?.registration_no || "---"}
                    </td>
                  </tr>

                  {/* Row 2 - Gender and Passport No */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Gender
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.gender?.name || "---"}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      // style={{ fontSize: "30px" }}
                    >
                      Passport No
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.passport_no || "---"}
                    </td>
                  </tr>

                  {/* Row 3 - Date of Birth and Travelling To */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Date of Birth
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {formattedBirthDate}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Travelling To
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.country?.name || "---"}
                    </td>
                  </tr>

                  {/* Row 4 - Father Name and Contact Number */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Father Name
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.father_name || "---"}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Contact Number
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.user?.mobile || "---"}
                    </td>
                  </tr>

                  {/* Row 5 - Nationality and Agency */}
                  <tr>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      Nationality
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {state?.record?.national_country?.nationality || "---"}
                    </td>
                    <td className="text-uppercase border border-dark ps-1 align-middle">
                      {settingsData?.introducer_label}
                    </td>
                    <td
                      className="text-uppercase border border-dark ps-1 align-middle"
                      colSpan={3}
                    >
                      {state?.record?.introducer_user?.f_name || "---"}
                      {/* {state?.record?.user?.gcc_expairy_date
                        ? formatDateToDDMMYYYY(
                            state?.record?.user?.gcc_expairy_date
                          )
                        : "N/A"} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-2">
            <div
              className="d-flex flex-column border border-start-0 border-dark"
              style={{ width: "100%", height: "100%" }}
            >
              <img
                src={patientIdDataUrl}
                alt=""
                className="patient-barcode2 h-100"
              />
            </div>
            {/* <p
              className="pres-title p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
              style={{ height: "34px" }}
            >
              CLIENT ID: {state?.record?.user?.registration_no}
            </p> */}
          </div>
          {/* <div className="d-flex flex-row justify-content-between text-dark">
            {state?.record?.user?.role_id !== 5 ? (
              <span className="fs-6">Service: {packageName}</span>
            ) : (
              <span className="fs-6">Service: General Test</span>
            )}
          </div> */}
          <div className="d-flex align-items-center justify-content-start invoice-delivery">
            <div className="d-flex flex-row justify-content-between text-dark col-sm-6">
              <p className="p-0 m-0 text-dark">
                Reports Delivery Date:{" "}
                {state?.record?.user?.report_delivery_date
                  ? formatDateToDDMMYYYY(
                      state?.record?.user?.report_delivery_date
                    )
                  : "N/A"}
              </p>
            </div>
            {/* <div className="d-flex flex-row justify-content-end text-dark col-sm-6">
              <p className="p-0 m-0 text-dark">
                GCC Slip Expiary Date :{" "}
                {state?.record?.user?.gcc_expairy_date
                  ? formatDateToDDMMYYYY(state?.record?.user?.gcc_expairy_date)
                  : "N/A"}
              </p>
            </div> */}
          </div>
          {/* <div className="d-flex justify-content-center gap-5 px-3">
              {specimenGrp?.map((specimen, i) => (
                <div className="d-flex" key={i}>
                  {accessPerm(35, 15) && (
                    <div className="d-flex">
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input mt-0 me-2 fs-3"
                          type="radio"
                          id={i}
                          value={JSON.stringify({
                            id: specimen?.id,
                            name: specimen?.name,
                          })}
                          // checked={isChecked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="physicalExam"
                        >
                          {specimen?.name}
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div> */}
        </>
      </div>

      <section className="patient-invoice mx-3">
        {/* {state?.record?.user?.role_id === 5 ? (
          <div className="d-flex flex-column">
            <div
              className="d-flex justify-content-between medical-form-patient-data text-dark"
              style={{ gap: "250px" }}
            >
              <div className="w-50 d-flex">
                <div>
                  <div className="fw-bold">ID Number</div>
                  <div className="fw-bold">Registration Number</div>
                  <div className="fw-bold">Name</div>
                </div>
                <div className="ms-auto">
                  <div className="fw-bold">
                    : {registration_no?.split(" ")[1]}
                  </div>
                  <div className="fw-bold">: {registration_no}</div>
                  <div className="">: {f_name}</div>
                </div>
              </div>
              <div className="w-50 d-flex">
                <div>
                  <div className="fw-bold">Gender</div>
                  <div className="fw-bold">Age</div>
                  <div className="fw-bold">Contact</div>
                </div>
                <div className="ms-auto">
                  <div className="">: {genderName}</div>
                  <div className="">
                    : {state?.record?.user?.age ? state?.record?.user?.age : ""}
                  </div>
                  <div className="">: {mobile}</div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center w-100">
              <div className="w-25">
                <div className="fw-bold text-dark">Referred By</div>
              </div>
              <div className="w-25">
                {state?.record?.user?.role_id === 5 ? (
                  <div className="text-dark">
                    :{" "}
                    {state?.record?.user?.reference &&
                      `${state?.record?.user?.reference}`}
                  </div>
                ) : (
                  <div className="text-dark">
                    : {introducer_user && `${introducer_user.f_name}`}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-between medical-form-patient-data text-dark"
            style={{ gap: "250px" }}
          >
            <div className="w-50 d-flex">
              <div>
                <div className="fw-bold">ID Number</div>
                <div className="fw-bold">Registration Number</div>
                <div className="fw-bold">Name</div>
                <div className="fw-bold">Father Name</div>
                <div className="fw-bold">Gender</div>
                <div className="fw-bold">Passport Number</div>
              </div>
              <div className="ms-auto">
                <div className="fw-bold">
                  : {registration_no?.split(" ")[1]}
                </div>
                <div className="fw-bold">: {registration_no}</div>
                <div className="">: {f_name}</div>
                <div className="">: {father_name}</div>
                <div className="">: {genderName}</div>
                <div className="">: {passport_no}</div>
              </div>
            </div>
            <div className="w-50 d-flex">
              <div>
                <div className="fw-bold">Date of Birth</div>
                <div className="fw-bold">Contact</div>
                <div className="fw-bold">Referred By</div>
                <div className="fw-bold">Country</div>
                <div className="fw-bold">Company</div>
              </div>
              <div className="ms-auto">
                <div className="">
                  :{" "}
                  {date_of_birth
                    ? format(new Date(date_of_birth), "dd-MM-yyyy")
                    : ""}
                </div>
                <div className="">: {mobile}</div>
                <div className="">
                  : {introducer_user && `${introducer_user.f_name}`}
                </div>
                <div className="">: {countryName}</div>
                <div className="">
                  : {company_user && `${company_user.f_name}`}
                </div>
              </div>
            </div>
          </div>
        )} */}

        <div className="row">
          <table className="table table-bordered mb-0 custom-table">
            <thead>
              <tr className="table-active">
                <th
                  colSpan="3"
                  className="text-center colspan-heading m-0 p-0 border border-dark"
                >
                  Accounts Transaction Details
                </th>
              </tr>
              {!checked ? (
                <>
                  <tr>
                    <th className="col-sm-8 border border-dark">Description</th>
                    <th className="text-center border border-dark">
                      Price (BDT)
                    </th>
                  </tr>
                </>
              ) : (
                ""
              )}
            </thead>
            {state?.record?.user?.role_id === 5 && (
              <tbody>
                {!checked ? (
                  <>
                    {itemsData?.map((item, index) => {
                      const matchingItem = combinedItems?.find(
                        (option) =>
                          option.value === item?.item_id &&
                          option.type === item.type
                      );
                      return (
                        <tr key={index}>
                          <td className="w-50 border border-dark">
                            {matchingItem ? matchingItem?.name : "---"}
                          </td>
                          <td className="w-50 text-end border border-dark">
                            {/* Display the name of the matching item */}
                            {item ? item?.item_price : "---"}
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="no-border">
                      <td className="text-end no-border">Total:</td>
                      <td className="text-end no-border">{totalPrice || 0}</td>
                    </tr>
                    {discountedAmount > 0 && (
                      <tr className="no-border">
                        <td className="text-end no-border">
                          Discount (
                          {discountedAmount ? `${discountedAmount}%` : "0"}):
                        </td>
                        <td className="text-end no-border">{discount || 0}</td>
                      </tr>
                    )}
                    <tr className="no-border">
                      <td className="text-end no-border">Grand Total:</td>
                      <td className="text-end no-border">
                        {netTotalPrice || 0}
                      </td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Commission:</td>
                      <td className="text-end no-border">{due || 0}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr
                      className="text-center d-flex align-items-center justify-content-center fw-bold"
                      style={{ height: "114px", fontSize: "22px" }}
                    >
                      Paid
                    </tr>
                  </>
                )}
              </tbody>
            )}
            {state?.record?.user?.role_id !== 5 && (
              <tbody>
                {!checked ? (
                  <>
                    <tr>
                      <td className="w-50 border border-dark">{packageName}</td>
                      <td className="w-50 text-end border border-dark">
                        {/* Display the name of the matching item */}
                        {packageAmount ? packageAmount : 0}
                      </td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Grand Total:</td>
                      <td className="text-end no-border">
                        {packageAmount || 0}
                      </td>
                    </tr>
                    <tr className="no-border">
                      <td className="text-end no-border">Paid:</td>
                      <td className="text-end no-border">{paidAmount || 0}</td>
                    </tr>
                    {due > 0 && (
                      <tr className="no-border">
                        <td className="text-end no-border">Commission:</td>
                        <td className="text-end no-border">{due || 0}</td>
                      </tr>
                    )}
                  </>
                ) : (
                  <>
                    <tr
                      className="text-center d-flex align-items-center justify-content-center border border-dark border-top-0 fw-bold"
                      style={{ height: "70px", fontSize: "22px" }}
                    >
                      Paid
                    </tr>
                  </>
                )}
              </tbody>
            )}
          </table>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <p className="m-0 text-dark">User: {createdBy}</p>
          <p className="p-0 m-0 fst-italic text-dark">
            Licensed to: {settingsData?.website_name}
          </p>
          <div className="text-center text-dark fst-italic p-0 m-0">
            Powered by Clincx Medical Solutions
          </div>
        </div>
        {/* <div className="d-flex flex-row align-items-center justify-content-between">
          <p className="m-0 text-dark">
            Online Verification:{" "}
            <Link to={`${process.env.REACT_APP_UPLOAD_URL}`} target="_blank">
              https://hamadbd.com/
            </Link>{" "}
          </p>
          <p className="p-0 m-0 fst-italic text-dark">
            This is an auto generated report, does not require any signature.
          </p>
          <div className="text-center text-dark border border-start-0 border-end-0 border-bottom-0 border-dark">
            Official Seal & Signature
          </div>
        </div> */}
        {/* <div className="w-100 d-flex justify-content-end text-dark">
          {formattedDate}
        </div> */}
        <div className="invoice-footer d-flex mt-5 align-items-center justify-content-end">
          {/* <div className="d-flex align-items-center gap-1 text-dark">
            <input
              type="checkbox"
              name="checkbox-invoice"
              id="checkbox-invoice"
              defaultChecked={checked}
              onClick={() => setChecked(!checked)}
            />
            <label htmlFor="checkbox-invoice">Print Without Amount</label>
          </div> */}
          {stateData !== 1 && (
            <div className="d-flex gap-2">
              {accessPerm(32, 23) && (
                <button
                  className="btn btn-primary"
                  onClick={() => handlePrint()}
                >
                  Print
                </button>
              )}
              {role_id === 5 ? (
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate("/dashboard/medical-tests")}
                >
                  Back
                </button>
              ) : (
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate("/dashboard/patient-info")}
                >
                  Back
                </button>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default UserInvoiceCopy;
