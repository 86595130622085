import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Verification.scss";
import { UserContext } from "../../Context/UserContextAPI";

const Verification = ({ children }) => {
  const { settingsData } = useContext(UserContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  const handlePrescriptionPrint = (e) => {
    navigate("/medical-verification/result/prescription", { state });
    setTimeout(() => {
      window.print();
    }, 1200);
  };

  const collection = state?.user?.user_collections?.map(
    (collection) => collection.investigation_category?.investigation_group_id
  );
  const uniqueCollection = [...new Set(collection)];

  return (
    <>
      <section className="verification-report">
        {/* <div className="header-image">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div> */}
        <div className="">
          {(uniqueCollection?.length < 2 &&
            (state?.user?.is_pathology_done ||
              state?.user?.is_physical_done ||
              state?.user?.is_radiology_done)) ||
          (uniqueCollection?.length >= 2 &&
            uniqueCollection?.length < 3 &&
            (state?.user?.is_physical_done ? 1 : 0) +
              (state?.user?.is_pathology_done ? 1 : 0) +
              (state?.user?.is_radiology_done ? 1 : 0) >=
              2) ||
          (uniqueCollection?.length >= 3 &&
            state?.user?.is_pathology_done &&
            state?.user?.is_physical_done &&
            state?.user?.is_radiology_done) ? (
            <>
              {/* <div className="row">
                {pathname.includes("/report") && (
                  <div className="col-12">
                    <h4
                      className="text-center fw-bold uppercase border border-dark fs-3 py-1"
                      style={{
                        color: `${
                          state?.user?.status === 1
                            ? "black"
                            : state?.user?.status === 2
                            ? "#5cb85c"
                            : state?.user?.status === 3
                            ? "red"
                            : state?.user?.status === 4 && "#0000FF"
                        }`,
                      }}
                    >
                      {state?.user?.status === 1 &&
                        `PROCESSING CERTIFICATE FOR ${state?.country?.name.toUpperCase()}`}
                      {state?.user?.status === 2 &&
                        `FIT CERTIFICATE FOR ${state?.country?.name.toUpperCase()}`}
                      {state?.user?.status === 3 &&
                        `UNFIT CERTIFICATE FOR ${state?.country?.name.toUpperCase()}`}
                      {state?.user?.status === 4 &&
                        `HELD UP CERTIFICATE FOR ${state?.country?.name.toUpperCase()}`}
                    </h4>
                  </div>
                )}
              </div> */}
              <div className="print-btn-group d-flex align-items-center justify-content-center gap-2 mb-3">
                {/* {state?.user?.status === 4 ? (
                  <button
                    className="btn btn-primary"
                    onClick={handlePrescriptionPrint}
                  >
                    Prescription
                  </button>
                ) : null} */}
              </div>
            </>
          ) : (
            <div className="row">
              <div
                className="col-12 d-flex align-items-center border border-dark"
                style={{ height: "10vh" }}
              >
                <h4
                  className="text-center fw-bold text-uppercase w-100 fs-5"
                  style={{
                    color: "red",
                    fontSize: "22px",
                  }}
                >
                  Your Report is Under Processing...
                </h4>
              </div>
            </div>
          )}
          {children}
        </div>
      </section>
    </>
  );
};

export default Verification;
