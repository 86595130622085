import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";

const Signin = () => {
  const { methodSignin } = useContext(UserContext);
  const navigate = useNavigate();

  const handlerOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const mobile = form.mobile.value;
    const password = form.password.value;

    // console.log(phoneNumber, password);
    if (mobile && password) {
      const signinData = {
        mobile: mobile,
        password: password,
      };
      methodSignin(signinData)
        .then((res) => {
          if (res.status === 200) {
            const userData = res.data;
            window.localStorage.setItem("jwtToken", userData.token);
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const myStyle = {
    container: {
      position: "relative",
      height: "100vh",
      width: "100vw", // Ensure the container covers the full viewport width
    },
    backgroundImage: {
      position: "fixed", // Fix the background image in place
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/website-background.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed", // Ensure background stays fixed
      opacity: 0.8,
      zIndex: -1,
    },
  };

  return (
    <>
      {/* Signin Page Container */}
      <div className="wrapper" style={myStyle.backgroundImage}>
        {/* <!--start content--> */}
        <main
          className="h-100 d-flex align-items-center justify-content-center mx-auto"
          style={myStyle.container}
        >
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card index-card shadow rounded-0 overflow-hidden">
                <div className="row">
                  <div className="card-body p-4 p-sm-5">
                    <h5 className="card-title fs-6 text-center text-dark">Sign In</h5>
                    <form
                      className="form-body"
                      onSubmit={(e) => handlerOnSubmit(e)}
                    >
                      <div className="login-separater text-center mb-4">
                        <span>SIGN IN WITH PHONE NUMBER</span>
                        <hr />
                      </div>
                      <div className="row gap-2 mx-auto w-100 d-flex justify-content-center">
                        <div className="col-12 w-50">
                          <label
                            htmlFor="inputEmailAddress"
                            className="form-label text-dark"
                          >
                            Phone Number
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                              <i className="fa-solid fa-phone"></i>
                            </div>
                            <input
                              type="text"
                              name="mobile"
                              className="form-control radius-30 ps-5"
                              id="inputEmailAddress"
                              placeholder="Phone Number"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 w-50">
                          <label
                            htmlFor="inputChoosePassword"
                            className="form-label text-dark"
                          >
                            Password
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                              <i className="bi bi-lock-fill"></i>
                            </div>
                            <input
                              type="password"
                              name="password"
                              className="form-control radius-30 ps-5"
                              id="inputChoosePassword"
                              placeholder="Enter Password"
                              required
                            />
                          </div>
                          <div className="text-start mt-2">
                            <Link
                              to="/forget-password"
                              style={{
                                color: "#14977e",
                                border: "none",
                              }}
                            >
                              Forgot Password ?
                            </Link>
                          </div>
                        </div>

                        <div className="col-12 text-center mt-1">
                          <div className="d-flex justify-content-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg radius-30 w-50"
                              style={{
                                backgroundColor: "#14977e",
                                border: "none",
                                color: "white",
                                width: "17rem",
                              }}
                            >
                              Sign In
                            </button>
                          </div>
                          <Link
                            className="text-underline-none text-white"
                            to="/"
                          >
                            <button className="btn btn-secondary btn-lg radius-30 text-white mt-3 w-50">
                              Back to Home
                            </button>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Signin;
