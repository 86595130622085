import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
  const location = useLocation();
  const { state } = location;
  const { mobile } = state;
  console.log(mobile);
  const navigate = useNavigate();

  const handlerOnSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const newPassword = form.newPassword.value;
    const confirmPassword = form.confirmPassword.value;

    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        const userInfo = {
          mobile: mobile,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        };
        updatePassword(userInfo);
      } else {
        toast.error("Password Not Matched!!");
      }
    }
  };

  const updatePassword = async (userInfo) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/user/password`,
        userInfo,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      console.log(res);
      // const data = res.data;

      if (res.status === 200) {
        toast.success("Password Reset Successfully!!");
        navigate("/signin");
      }
    } catch (error) {
      console.error(error);
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const myStyle = {
    container: {
      position: "relative",
      height: "100vh",
      width: "100vw", // Ensure the container covers the full viewport width
    },
    backgroundImage: {
      position: "fixed", // Fix the background image in place
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/website-background.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed", // Ensure background stays fixed
      opacity: 0.8,
      zIndex: -1,
    },
  };

  return (
    <>
      {/* ResetPassword Container */}
      <div className="wrapper" style={myStyle.backgroundImage}>
        {/* <!--start content--> */}
        <main
          className="h-100 d-flex align-items-center justify-content-center mx-auto"
          style={myStyle.container}
        >
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card index-card shadow rounded-0 overflow-hidden">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title text-center">
                        Genrate New Password
                      </h5>
                      <p className="card-text text-center mb-5">
                        We received your reset password request. Please enter
                        your new password!
                      </p>
                      <form
                        className="form-body"
                        onSubmit={(e) => handlerOnSubmit(e)}
                      >
                        <div className="row gap-2 mx-auto w-100 d-flex justify-content-center">
                          <div className="col-12 w-50">
                            <label
                              htmlFor="inputNewPassword"
                              className="form-label"
                            >
                              New Password
                            </label>
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="password"
                                name="newPassword"
                                className="form-control radius-30 ps-5"
                                id="inputNewPassword"
                                placeholder="Enter New Password"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 w-50">
                            <label
                              htmlFor="inputConfirmPassword"
                              className="form-label"
                            >
                              Confirm Password
                            </label>
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="password"
                                name="confirmPassword"
                                className="form-control radius-30 ps-5"
                                id="inputConfirmPassword"
                                placeholder="Confirm Password"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 w-50">
                            <div className="d-grid gap-3">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg radius-30"
                                style={{
                                  backgroundColor: "#14977e",
                                  border: "none",
                                  color: "white",
                                }}
                              >
                                Change Password
                              </button>
                              <Link
                                to="/signin"
                                className="btn btn-secondary btn-lg radius-30"
                              >
                                Back to Login
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ResetPasswordPage;
