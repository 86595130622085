import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../../Context/UserContextAPI";
import Select from "react-select";

const notify = () => toast.error("Field can not be empty!");

const SettingsPage = () => {
  const { accessPerm } = useContext(UserContext);
  const daysOfWeekOptions = [
    { id: "0", value: "Sunday", label: "Sunday" },
    { id: "1", value: "Monday", label: "Monday" },
    { id: "2", value: "Tuesday", label: "Tuesday" },
    { id: "3", value: "Wednesday", label: "Wednesday" },
    { id: "4", value: "Thursday", label: "Thursday" },
    { id: "5", value: "Friday", label: "Friday" },
    { id: "6", value: "Saturday", label: "Saturday" },
  ];
  // data fetched from api will be stored in this state
  const [data, setData] = useState({});

  const [form, setForm] = useState({
    website_name: data?.website_name || "",
    website_url: data?.website_url || "",
    tag_line: data?.tag_line || "",
    short_name: data?.short_name || "",
    address: data?.address || "",
    mobile: data?.mobile || "",
    copyright: data?.copyright || "",
    vat_type: data?.vat_type || "",
    logo_image: data?.logo_image || "",
    header_image: data?.header_image || "",
    footer_image: data?.footer_image || "",
    background_image: data?.background_image || "",
    fav_image: data?.fav_image || "",
    days_of_week: data?.days_of_week || "",
    introducer_label: data?.introducer_label || "",
    point: data?.point || "",
  });

  const [vatId, setVatId] = useState(0);

  const [logoImg, setLogoImg] = useState(null);
  const [favIcon, setFavIcon] = useState(null);
  const [headerImg, setHeaderImg] = useState(null);
  const [footerIcon, setFooterIcon] = useState(null);
  const [backgroundImg, setBackgroundImg] = useState(null);

  const [previewLogo, setPreviewLogo] = useState(null);
  const [previewFavicon, setPreviewFavicon] = useState(null);
  const [previewHeader, setPreviewHeader] = useState(null);
  const [previewFooter, setPreviewFooter] = useState(null);
  const [previewBackground, setPreviewBackground] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    setSelectedDays(selectedIds);
    const daysOfWeekString = selectedIds.join(",");
    setForm({ ...data, days_of_week: daysOfWeekString });
  };

  const selectVATHandler = (e) => {
    setVatId(e.target.value);
    // console.log(e.target.value);
    setForm((prev) => {
      return {
        ...prev,
        vat_type: e.target.value,
      };
    });
  };

  const handleChange = ({ currentTarget: input }) => {
    if (input.value.trim() === "") {
      input.value = "";
    }
    const daysOfWeekValue = selectedDays ? selectedDays.join(",") : "";
    setForm({
      ...form, // Use the current form state
      days_of_week: daysOfWeekValue,
      [input.name]: input.value,
    });
  };

  const methodUploadUsersPhotoFile = async () => {
    let uploadUrl = `${process.env.REACT_APP_API_BASE_URL}/settings/image-upload`;
    const formData = new FormData();

    if (logoImg) {
      formData.append("logo_image", logoImg, logoImg.name); // Include filename
    }
    if (favIcon) {
      formData.append("fav_icon", favIcon, favIcon.name); // Include filename
    }
    if (headerImg) {
      formData.append("header_image", headerImg, headerImg.name); // Include filename
    }
    if (footerIcon) {
      formData.append("footer_image", footerIcon, footerIcon.name); // Include filename
    }
    if (backgroundImg) {
      formData.append("background_image", backgroundImg, backgroundImg.name); // Include filename
    }

    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set correct content type
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
    } catch (error) {
      console.error("Error uploading images:", error);
    }

    // Return null if no images were uploaded or there was an error
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      form.website_name !== "" &&
      form.website_url !== "" &&
      form.tag_line !== "" &&
      form.address !== "" &&
      form.mobile !== "" &&
      form.copyright !== "" &&
      form.vat_type !== ""
    ) {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/settings`;
        const res = await axios.put(url, form, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        });
        setData(res?.data);
        console.log(res?.data);
        if (res?.status === 200) {
          toast.success("Successfully updated!");
          setTimeout(() => {
            window.location.reload();
          }, 800);
        }
      } catch (error) {
        console.log(error);
      }

      methodUploadUsersPhotoFile();
    } else {
      notify();
    }
  };

  useEffect(() => {
    getData();
    // setUpData({...upData, [data.key] : data.value})
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${process.env.REACT_APP_UPLOAD_URL}/${data?.fav_image}`;
  }, [data?.fav_image]); // Add data?.fav_image as dependency

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/settings`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response.data.data[0];
        setData(allData);
        setForm(allData);
        setVatId(allData.vat_type);

        // Convert the comma-separated string to an array of integers
        const selectedDaysArray = allData.days_of_week.split(",");
        setSelectedDays(selectedDaysArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const previewLogoImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewLogo(e.target.files[0]);
    }
  };

  const previewFaviconImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewFavicon(e.target.files[0]);
    }
  };

  const previewHeaderImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewHeader(e.target.files[0]);
    }
  };

  const previewFooterImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewFooter(e.target.files[0]);
    }
  };

  const previewBackgroundImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewBackground(e.target.files[0]);
    }
  };

  const result = daysOfWeekOptions?.filter((option) =>
    selectedDays.includes(option.id)
  );

  console.log(form);

  return (
    <>
      <div className="row text-dark">
        <div className="col-xl-12 mx-auto">
          <div className="card">
            <div className="card-body">
              <form className="needs-validation">
                <div className="border p-4 rounded">
                  <div className="card-title d-flex align-items-center">
                    <h5 className="mb-0">System Settings</h5>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <label className="col-sm-3 col-form-label">
                      Website Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={form?.website_name}
                        className="form-control"
                        id="inputEnterYourName"
                        name="website_name"
                        placeholder={data?.website_name}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-3 col-form-label">
                      Website URL
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={form?.website_url}
                        className="form-control"
                        id="inputEnterYourName"
                        name="website_url"
                        placeholder={data?.website_url}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Tag Line
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={form?.tag_line}
                        name="tag_line"
                        className="form-control"
                        id="inputPhoneNo2"
                        placeholder={data?.tag_line}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Short Name (Optional)
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={form?.short_name}
                        name="short_name"
                        className="form-control"
                        id="inputPhoneNo2"
                        placeholder={data?.short_name}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputAddress4"
                      className="col-sm-3 col-form-label"
                    >
                      Address
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        onChange={handleChange}
                        value={form?.address}
                        name="address"
                        id="inputAddress4"
                        rows={3}
                        placeholder={data?.address}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputAddress4"
                      className="col-sm-3 col-form-label"
                    >
                      Contact Numbers
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        onChange={handleChange}
                        value={form?.mobile}
                        name="mobile"
                        className="form-control"
                        id="inputAddress4"
                        rows={2}
                        placeholder={data?.mobile}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputHeader"
                      className="col-sm-3 col-form-label"
                    >
                      Header
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="file"
                        name="header_image"
                        onChange={(e) => {
                          previewHeaderImage(e);
                          setHeaderImg(e.target.files[0]);
                          setForm((prev) => {
                            return {
                              ...prev,
                              header_image: e.target.files[0].name,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputHeader"
                      className="col-sm-3 col-form-label"
                    >
                      Header Preview
                    </label>
                    <div className="col-sm-9">
                      {previewHeader ? (
                        <img
                          className="mt-3"
                          src={URL.createObjectURL(previewHeader)}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      ) : (
                        <img
                          className="mt-3"
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.header_image}`}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Footer
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="file"
                        name="footer_image"
                        onChange={(e) => {
                          previewFooterImage(e);
                          setFooterIcon(e.target.files[0]);
                          setForm((prev) => {
                            return {
                              ...prev,
                              footer_image: e.target.files[0].name,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Footer Preview
                    </label>
                    <div className="col-sm-9">
                      {previewFooter ? (
                        <img
                          className="mt-3"
                          src={URL.createObjectURL(previewFooter)}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      ) : (
                        <img
                          className="mt-3"
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.footer_image}`}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Report Background Image
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="file"
                        name="background_image"
                        onChange={(e) => {
                          previewBackgroundImage(e);
                          setBackgroundImg(e.target.files[0]);
                          setForm((prev) => {
                            return {
                              ...prev,
                              background_image: e.target.files[0].name,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Background Preview
                    </label>
                    <div className="col-sm-9">
                      {previewBackground ? (
                        <img
                          className="mt-3"
                          src={URL.createObjectURL(previewBackground)}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      ) : (
                        <img
                          className="mt-3"
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.background_image}`}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Logo
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="file"
                        name="logo_image"
                        onChange={(e) => {
                          previewLogoImage(e);
                          setLogoImg(e.target.files[0]);
                          setForm((prev) => {
                            return {
                              ...prev,
                              logo_image: e.target.files[0].name,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Logo Preview
                    </label>
                    <div className="col-sm-9">
                      {previewLogo ? (
                        <img
                          className="mt-3"
                          src={URL.createObjectURL(previewLogo)}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      ) : (
                        <img
                          className="mt-3"
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.logo_image}`}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Favicon
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="file"
                        name="fav_image"
                        onChange={(e) => {
                          previewFaviconImage(e);
                          setFavIcon(e.target.files[0]);
                          setForm((prev) => {
                            return {
                              ...prev,
                              fav_image: e.target.files[0].name,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPhoneNo2"
                      className="col-sm-3 col-form-label"
                    >
                      Favicon Preview
                    </label>
                    <div className="col-sm-9">
                      {previewFavicon ? (
                        <img
                          className="mt-3"
                          src={URL.createObjectURL(previewFavicon)}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      ) : (
                        <img
                          className="mt-3"
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.fav_image}`}
                          alt="preview"
                          style={{ height: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="inputNationality"
                      className="col-sm-3 col-form-label d-flex justify-content-start"
                    >
                      VAT Type
                    </label>
                    <div className="col-sm-9">
                      <select
                        value={vatId}
                        onChange={selectVATHandler}
                        className="single-select form-select"
                      >
                        <option value={1}>Included</option>
                        <option value={2}>Excluded</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="inputDaysOfWeek"
                      className="col-sm-3 col-form-label d-flex justify-content-start"
                    >
                      Days of Week
                    </label>
                    <div className="col-sm-9">
                      <Select
                        name="days_of_week"
                        isMulti
                        value={result || ""}
                        onChange={handleSelectChange}
                        options={daysOfWeekOptions}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-3 col-form-label">1 Point =</label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        onChange={handleChange}
                        value={form?.point}
                        className="form-control"
                        id="inputEnterYourName"
                        name="point"
                        placeholder={data?.point}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-3 col-form-label">
                      Introducer Label
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={form?.introducer_label}
                        name="introducer_label"
                        id="inputEnterYourName"
                        placeholder={data?.introducer_label}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-3 col-form-label">Copyright</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={form?.copyright}
                        name="copyright"
                        id="inputEnterYourName"
                        placeholder={data?.copyright}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-3 col-form-label" />
                    <div className="col-sm-9">
                      {accessPerm(1, 2) && (
                        <button
                          type="submit"
                          className="btn btn-primary px-5"
                          onClick={handleSubmit}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
