import { Fragment, useContext, useEffect, useMemo, useState } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import CountrySummaryModal from "../../components/Modal/RegistrationSummaryModal/CountrySummaryModal";
import AgencySummaryModal from "../../components/Modal/RegistrationSummaryModal/AgencySummaryModal";
import PackageSummaryModal from "../../components/Modal/RegistrationSummaryModal/PackageSummaryModal";
import { UserContext } from "../../Context/UserContextAPI";
import Select from "react-select";
import { format } from "date-fns";
import VirtualizedMenuList from "../../components/VirtualMenuList/VirtualMenuList";
import Loading from "../../utils/Loader/Loading";

const UnfitPatients = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    countryUsers,
    companyUsers,
    delegateUsers,
    introducerUsers,
    packages,
    accessPerm,
  } = useContext(UserContext);

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      const representativeMobile =
        user?.user_users_maps[0]?.representative_user_map?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const countryOptions = [
    { value: "", label: "Select Country" },
    ...(countryUsers?.map((country) => ({
      value: parseInt(country?.id),
      label: `${country?.shortname} - ${country?.name}`,
    })) || []),
  ];

  const companyOptions = [
    { value: "", label: "Select Company" },
    ...(companyUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const delegateOptions = [
    { value: "", label: "Select Delegate" },
    ...(delegateUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  const [regInputField, setRegInputField] = useState("");
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });
  const [totalStatus, setTotalStatus] = useState(0);

  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [introducerWiseUsers, setIntroducerWiseUsers] = useState([]);
  const [packageWiseUsers, setPackageWiseUsers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
  });

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };
    let notDoneCount = 0;

    data.forEach((userInfo) => {
      const collection = userInfo?.user?.user_collections?.map(
        (collection) =>
          collection.investigation_category?.investigation_group_id
      );

      const uniqueCollection = [...new Set(collection)];
      const done =
        (uniqueCollection?.length < 2 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) <
            2) ||
        (uniqueCollection?.length >= 2 &&
          uniqueCollection?.length < 3 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) >=
            2) ||
        (uniqueCollection?.length >= 3 &&
          userInfo?.user?.is_pathology_done &&
          userInfo?.user?.is_physical_done &&
          userInfo?.user?.is_radiology_done);

      // Counting entries where the task is not done
      if (!done || userInfo?.user?.status === 1) {
        notDoneCount++;
      }

      switch (userInfo?.user?.status) {
        case 1:
          counts.status1 = counts.status1 + 1;
          break;
        case done && 2:
          counts.status2 = counts.status2 + 1;
          break;
        case done && 3:
          counts.status3 = counts.status3 + 1;
          break;
        case done && 4:
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });

    setStatusCounts(counts);
    setTotalStatus(userDetails?.length);
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const packageId = form.package.value;
    const introducer = form.introducer.value;
    const country = form.country.value;
    const company = form.company.value;
    const delegate = form.delegate.value;
    const regNo = form.invoice_reg_mobile.value;

    const filterData = {
      fromDate,
      toDate,
      packageId,
      introducer,
      country,
      company,
      delegate,
      regNo: regNo.trim(),
    };

    methodFilterPatientsDetails(filterData);
  };

  const methodFilterPatientsDetails = async (filterData) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search/unfit`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data;

      if (data) {
        calculateStatus(data?.userDetails);
        setUserDetails(data?.userDetails);
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setTotalStatus(data?.userDetails?.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        packageId: "",
        introducer: "",
        country: "",
        company: "",
        delegate: "",
        regNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
        country: null,
        company: null,
        delegate: null,
      });

      setRegInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const handlePrint = async (info) => {
    window.open(`/print-unfit-certificate/${info?.user_id}`, "_blank");
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("createdAt", {
        header: () => (
          <span className="d-flex justify-content-center">Date</span>
        ),
        cell: (info) => info.renderValue().split("T")[0],
      }),
      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Registration no</span>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Patient name</span>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("user.passport_no", {
        header: () => (
          <span className="d-flex justify-content-center">Passport No</span>
        ),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("user.role.name", {
        header: () => (
          <span className="d-flex justify-content-center">Type</span>
        ),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("country.name", {
        header: () => (
          <span className="d-flex justify-content-center">Country</span>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center">Package</span>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("user", {
        header: () => (
          <span className="d-flex justify-content-center">Actions</span>
        ),
        cell: (info) => {
          return (
            <div className="text-start">
              {accessPerm(50, 23) && (
                <button
                  className="btn btn-primary text-white btn-block btn-sm"
                  style={{ width: "80px" }}
                  onClick={() => handlePrint(info?.row?.original)}
                >
                  Print
                </button>
              )}
            </div>
          );
        },
      }),
    ],
    [accessPerm]
  );

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/unfit`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data?.data;
      if (data) {
        calculateStatus(data?.userDetails);
        setUserDetails(data?.userDetails);
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-2 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase fw-bold">
                Unfit Patient Reports
              </h6>
            </div>

            <hr />

            <div className="card shadow-none mb-3">
              <div className="rounded">
                <div className="">
                  <form
                    onSubmit={(e) => handlerOnSearch(e)}
                    className="col-sm-12 fw-bold"
                  >
                    <div className="d-flex flex-wrap">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="p-0 m-0">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="p-0 m-0">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      </div>

                      <div className="p-1">
                        <label className="col-form-label">Package</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("package", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 300,
                                maxWidth: 320,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            options={options}
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) =>
                              handleSelect("introducer", selectedOptions)
                            }
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Country</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: 200,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            name="country"
                            options={countryOptions}
                            value={selectedValues.country}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("country", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Company</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="company"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 200,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            options={companyOptions}
                            value={selectedValues.company}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("company", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Delegate</label>
                        <div className="fw-light">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="delegate"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                                width: 200,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            options={delegateOptions}
                            value={selectedValues.delegate}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("delegate", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label tdt">
                          Registration No.
                        </label>
                        <div className="">
                          <input
                            style={{ width: "180px", height: "37px" }}
                            type="text"
                            onChange={(e) => setRegInputField(e.target.value)}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 p-1 d-flex align-items-end justify-content-start gap-2">
                      <button
                        type="submit"
                        className="btn text-white border border-3 fw-bold"
                        style={{ backgroundColor: "#34762b" }}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning border border-3 fw-bold"
                        onClick={(e) => reset(e)}
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="patient-info-table-search">
              <div className="fit-unfit-status">
                <span className="fw-bolder">Record Summary &gt; </span>
                <span className="text-danger fw-bolder">{`UNFIT: ${statusCounts?.status3}`}</span>
              </div>
              <div className="reg-summary">
                <span className="me-1">Summary Of:</span>{" "}
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#country-summary-modal"
                >
                  Country
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#agency-summary-modal"
                >
                  Agency
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#package-summary-modal"
                >
                  Package
                </span>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <table className="table align-middle table-hover table-bordered mb-0 rounded">
                <thead className="bg-primary">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} className="text-light p-1">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="p-1">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <div>
        <CountrySummaryModal countryWiseUsers={countryWiseUsers} />
        <AgencySummaryModal introducerWiseUsers={introducerWiseUsers} />
        <PackageSummaryModal packageWiseUsers={packageWiseUsers} />
      </div>
    </>
  );
};

export default UnfitPatients;
