import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./Homepage.scss";
import { UserContext } from "../../Context/UserContextAPI";
import { Link } from "react-router-dom";
import ApexCharts from "apexcharts";

const Homepage = () => {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  // const smsBalanceAPI = `https://api.greenweb.com.bd/g_api.php?token=${process.env.REACT_APP_SMS_API_TOKEN}&balance`;
  const chartRef = useRef();
  const pieRef = useRef();
  const [chartUserDetails, setChartUserDetails] = useState([]);
  const [pieUserDetails, setPieUserDetails] = useState([]);
  const [data, setData] = useState({});
  const [smsBalance, setSMSBalance] = useState();
  const [vatId, setVatId] = useState(0);
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });
  const [totalStatus, setTotalStatus] = useState(0);
  const { accessPerm } = useContext(UserContext);

  const getData = () => {
    axios
      .get(settingURL)
      .then((response) => {
        const allData = response.data.data[0];
        setData(allData);
        setVatId(allData.vat_type);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getSMSBalance = () => {
  //   axios
  //     .get(smsBalanceAPI)
  //     .then((response) => {
  //       setSMSBalance(response?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const removeHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const getChartUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/chart/all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      console.log(response?.data);
      setChartUserDetails(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPieUserDetails = async () => {
    console.log("called");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/pie/all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      console.log(response?.data);
      setPieUserDetails(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const showChart = useCallback(() => {
    const totalCount = chartUserDetails?.totalCount || 0;
    const fitCount = chartUserDetails?.fitCount || 0;
    const unfitCount = chartUserDetails?.unfitCount || 0;
    const heldupCount = chartUserDetails?.heldupCount || 0;
    const processingCount = chartUserDetails?.processingCount || 0;

    console.log("Chart Data:", {
      fitCount,
      unfitCount,
      heldupCount,
      processingCount,
    });

    const options = {
      chart: {
        type: "bar",
      },
      series: [
        {
          name: "Patient",
          data: [
            totalCount,
            fitCount,
            unfitCount,
            heldupCount,
            processingCount,
          ],
        },
      ],
      xaxis: {
        categories: ["TOTAL", "FIT", "UNFIT", "HELDUP", "PROCESSING"],
      },
      yaxis: {
        min: 0,
        max: 200,
        tickAmount: 5,
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            position: "top",
          },
          columnWidth: "80%",
        },
      },
      colors: ["#fff200", "#008000", "#FF0000", "#0000FF", "#000000"], // Colors corresponding to TOTAL, FIT, UNFIT, HELDUP, PROCESSING
      dataLabels: {
        enabled: true,
        formatter: function (value) {
          return value;
        },
        offsetY: -40,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
    };

    if (chartRef.current) {
      // Destroy existing chart instance if it exists
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }
      // Initialize new chart instance
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();
      chartRef.current.chart = chart; // Store chart instance in ref
    }
  }, [chartUserDetails]);

  const showPie = useCallback(() => {
    const introducersCount = pieUserDetails?.introducersCount || 0;
    const representativesCount = pieUserDetails?.representativesCount || 0;
    const countriesCount = pieUserDetails?.countryCount || 0;

    const pieOptions = {
      series: [introducersCount, representativesCount, countriesCount],
      chart: {
        width: "100%",
        type: "pie",
      },
      labels: ["Introducers", "Representatives", "Countries"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        position: "right",
        offsetY: 0,
        height: 230,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        dropShadow: {
          enabled: false,
        },
      },
      title: {
        text: "",
        align: "center",
        margin: 0,
        offsetY: 20,
        style: {
          fontSize: "22px",
        },
      },
    };

    if (pieRef.current) {
      // Destroy existing chart instance if it exists
      if (pieRef.current.pie) {
        pieRef.current.pie.destroy();
      }
      // Initialize new chart instance
      const pie = new ApexCharts(pieRef.current, pieOptions);
      pie.render();
      pieRef.current.pie = pie; // Store chart instance in ref
    }
  }, [pieUserDetails]);

  useEffect(() => {
    getData();
    // getSMSBalance();
    getChartUserDetails();
    getPieUserDetails();
  }, []);

  useEffect(() => {
    showChart();
  }, [chartUserDetails, showChart]);

  useEffect(() => {
    showPie();
  }, [pieUserDetails, showPie]);

  return (
    <div className="p-2">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">DASHBOARD</h6>
            </div>
            <main>
              {/* <div className="container-fluid" style={{ maxHeight: "180px" }}>
            <div className="d-flex align-items-center justify-content-center py-5">
              <div className="row g-0">
                <div className="p-1 text-center">
                  <h2 className="mt-2 fs-4" style={{ color: "#128772" }}>
                    {data?.website_name}
                  </h2>
                  <p className="m-0 text-dark">Address: {data?.address}</p>
                  <p className="contact text-dark">Mobile: {data?.mobile}</p>
                </div>
              </div>
            </div>
          </div> */}
              <div className="p-3 d-flex flex-wrap gap-2">
                <div className="d-flex justify-content-start">
                  {accessPerm(75, 4) && (
                    <div
                      className="card d-flex flex-column border"
                      style={{ width: "180px", height: "160px" }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ backgroundColor: "#cce3c1", height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark fs-6">
                              <div className="fw-bold">
                                Tk. {removeHtmlTags(smsBalance || 0)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                SMS Balance
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/patient-info/create"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/registration.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Registration
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/specimen-collection"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/collection.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Collection
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/medical-reports"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/medical-report.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Medical Results
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/medical-registration"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/report-registration.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Registration Report
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/result"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/medical-result.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Result Report
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/fit-patients"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/fit.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Fit Patient
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/unfit-patients"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/unfit.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Unfit Patient
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex justify-content-start">
                  <Link
                    to="/dashboard/held-up-patients"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="card d-flex flex-column border"
                      style={{
                        width: "180px",
                        height: "160px",
                        cursor: "pointer",
                        border: "1px solid transparent",
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow =
                          "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      <div
                        className="row flex-grow-1"
                        style={{ height: "90px" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="/img/heldup.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ height: "50px", backgroundColor: "#000000" }}
                      >
                        <div className="col d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center text-dark">
                              <div className="fw-bold text-white">
                                Heldup Patient
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="border p-3 rounded">
          <h6 className="card-title p-2 text-uppercase">
            Today's Patients Update
          </h6>
          <div className="card-body">
            <div className="card-box">
              <div className="d-flex w-100 justify-content-between">
                <div className="col-sm-6 border p-2" ref={chartRef}></div>
                <div
                  className="col-sm-5 d-flex align-items-center justify-content-center border"
                  ref={pieRef}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
