import { useEffect } from "react";

export default function Toolbar({
  changeSelectedTool,
  handleClick,
  removeDrawing,
  selectedTool,
  texts,
  setTexts,
  circles,
  setCircles,
  lines,
  setLines,
  undo,
  setUndo,
  history,
  setHistory,
  redo,
  setRedo,
  color,
  setColor,
  brightness,
  setBrightness,
  setSelectedTool,
  deformableShapes,
  setDeformableShapes,
}) {
  function handleUndo() {
    const lastUndo = undo[undo.length - 1];

    if (lastUndo === "text") {
      setUndo((previous) => previous.slice(0, -1));
      setHistory((previous) => [...previous, texts[texts.length - 1]]);
      setTexts((previous) => previous.slice(0, -1));
      setRedo((previous) => [...previous, "text"]);
    } else if (lastUndo === "circle") {
      setUndo((previous) => previous.slice(0, -1));
      setHistory((previous) => [...previous, circles[circles.length - 1]]);
      setCircles((previous) => previous.slice(0, -1));
      setRedo((previous) => [...previous, "circle"]);
    } else if (lastUndo === "pen") {
      setUndo((previous) => previous.slice(0, -1));
      setHistory((previous) => [...previous, lines[lines.length - 1]]);
      setLines((previous) => previous.slice(0, -1));
      setRedo((previous) => [...previous, "pen"]);
    } else if (lastUndo === "rect") {
      setUndo((previous) => previous.slice(0, -1));
      setHistory((previous) => [...previous, deformableShapes[deformableShapes.length - 1]]);
      setDeformableShapes((previous) => previous.slice(0, -1));
      setRedo((previous) => [...previous, "rect"]);
    }
  }

  function handleRedo() {
    const lastRedo = redo[redo.length - 1];

    if (lastRedo === "text") {
      const historyText = history[history.length - 1];
      setRedo((previous) => previous.slice(0, -1));
      setTexts((previous) => [...previous, historyText]);
      setHistory((previous) => previous.slice(0, -1));
      setUndo((previous) => [...previous, "text"]);
    } else if (lastRedo === "circle") {
      const historyCircle = history[history.length - 1];
      setRedo((previous) => previous.slice(0, -1));
      setCircles((previous) => [...previous, historyCircle]);
      setHistory((previous) => previous.slice(0, -1));
      setUndo((previous) => [...previous, "circle"]);
    } else if (lastRedo === "pen") {
      const historyPen = history[history.length - 1];
      setRedo((previous) => previous.slice(0, -1));
      setLines((previous) => [...previous, historyPen]);
      setHistory((previous) => previous.slice(0, -1));
      setUndo((previous) => [...previous, "pen"]);
    } else if (lastRedo === "rect") {
      const historyRect = history[history.length - 1];
      setRedo((previous) => previous.slice(0, -1));
      setDeformableShapes((previous) => [...previous, historyRect]);
      setHistory((previous) => previous.slice(0, -1));
      setUndo((previous) => [...previous, "rect"]);
    }
  }

  useEffect(() => {
    const imageLayer = document.querySelector(".konvajs-content canvas");
    imageLayer.style.filter = `brightness(${brightness})`;
  }, [brightness]);

  useEffect(() => {
    setSelectedTool("rect");
  }, []);

  return (
    <>
      <div className={`toolbar d-flex flex-column`} style={{ width: "50px" }}>
        <button
          type="button"
          className={`btn ${
            selectedTool === "circle"
              ? "btn-primary"
              : "btn-outline-secondary text-white"
          }`}
          title="Circle"
          onClick={() => changeSelectedTool("circle")}
          style={{ backgroundColor: "#FA1E40" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className={`bi bi-circle`}
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          </svg>
        </button>
        <button
          type="button"
          className={`btn ${
            selectedTool === "rect"
              ? "btn-primary"
              : "btn-outline-secondary text-white"
          }`}
          title="Rectangle"
          onClick={() => changeSelectedTool("rect")}
          style={{ backgroundColor: "#FA1E40" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className="bi bi-bounding-box-circles"
            viewBox="0 0 16 16"
          >
            <path d="M2 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2M0 2a2 2 0 0 1 3.937-.5h8.126A2 2 0 1 1 14.5 3.937v8.126a2 2 0 1 1-2.437 2.437H3.937A2 2 0 1 1 1.5 12.063V3.937A2 2 0 0 1 0 2m2.5 1.937v8.126c.703.18 1.256.734 1.437 1.437h8.126a2.004 2.004 0 0 1 1.437-1.437V3.937A2.004 2.004 0 0 1 12.063 2.5H3.937A2.004 2.004 0 0 1 2.5 3.937zM14 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2M2 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2m12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
          </svg>
        </button>

        <button
          type="button"
          title="Reset"
          className={`btn text-white`}
          onClick={removeDrawing}
          style={{ backgroundColor: "#5537FA" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className="bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>
        <button
          type="button"
          className={`btn ${
            selectedTool === "pen"
              ? "btn-primary"
              : "btn-outline-secondary text-white"
          }`}
          title="Pencil"
          onClick={() => changeSelectedTool("pen")}
          style={{ backgroundColor: "#D537FA" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className={`bi bi-pencil`}
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
          </svg>
        </button>
        <button
          type="button"
          className={`btn ${
            selectedTool === "text"
              ? "btn-primary"
              : "btn-outline-secondary text-white"
          }`}
          title="Text"
          onClick={() => changeSelectedTool("text")}
          style={{ backgroundColor: "#FA37C9" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className={`bi bi-type`}
            viewBox="0 0 16 16"
          >
            <path d="m2.244 13.081.943-2.803H6.66l.944 2.803H8.86L5.54 3.75H4.322L1 13.081h1.244zm2.7-7.923L6.34 9.314H3.51l1.4-4.156h.034zm9.146 7.027h.035v.896h1.128V8.125c0-1.51-1.114-2.345-2.646-2.345-1.736 0-2.59.916-2.666 2.174h1.108c.068-.718.595-1.19 1.517-1.19.971 0 1.518.52 1.518 1.464v.731H12.19c-1.647.007-2.522.8-2.522 2.058 0 1.319.957 2.18 2.345 2.18 1.06 0 1.716-.43 2.078-1.011zm-1.763.035c-.752 0-1.456-.397-1.456-1.244 0-.65.424-1.115 1.408-1.115h1.805v.834c0 .896-.752 1.525-1.757 1.525z" />
          </svg>
        </button>
        <button className="color-picker btn btn-outline-secondary p-0">
          <input
            type="color"
            name=""
            id=""
            value={color}
            className="border-0 w-100 h-100"
            onChange={(event) =>
              setColor((previous) => {
                return (previous = event.target.value);
              })
            }
          />
        </button>
        <button
          type="button"
          className={`btn ${
            selectedTool === "undo"
              ? "btn-primary"
              : "btn-outline-secondary text-white"
          }`}
          title="Undo"
          onClick={handleUndo}
          style={{ backgroundColor: "#69564B" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className={`bi bi-arrow-counterclockwise`}
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
            />
            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
          </svg>
        </button>
        <button
          type="button"
          className={`btn ${
            selectedTool === "redo"
              ? "btn-primary"
              : "btn-outline-secondary text-white"
          }`}
          style={{ backgroundColor: "#1D0CFF" }}
          title="Redo"
          onClick={handleRedo}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className={`bi bi-arrow-clockwise`}
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
            />
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
          </svg>
        </button>
        <div className="range-slider d-flex align-items-center justify-content-center h-50 mt-3">
          <input
            type="range"
            name=""
            id=""
            min={0}
            max={3}
            step={0.1}
            value={brightness}
            onChange={(event) =>
              setBrightness((previous) => {
                return (previous = event.target.value);
              })
            }
            style={{ width: "200px", transform: "rotate(90deg)" }}
          />
        </div>
      </div>
    </>
  );
}
