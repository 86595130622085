import axios from "axios";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./MedicalReport.scss";
import JsBarcode from "jsbarcode";
import { UserContext } from "../../../../Context/UserContextAPI";
import QRCode from "qrcode.react";
import { format } from "date-fns";

const PrintMedicalReport = () => {
  const staticURL = `${process.env.REACT_APP_API_BASE_URL}/static-contents`;
  const { accessPerm, settingsData } = useContext(UserContext);

  const pdfRef = useRef();
  const { userId } = useParams();
  const [status, setStatus] = useState(0);
  const [physicalNewRiskFactor, setPhysicalNewRiskFactor] = useState("");
  const [pathologyData, setPathologyData] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState();
  const [xrayImage, setXrayImage] = useState();
  const urlWithBase64ID = `https://${process.env.REACT_APP_API_BASE_URL}/medical-verification/result/report/print/${userId}`;

  let pathologyStatus = "";
  let physicalStatus = "";
  let pathologyRiskFactor = "";
  let physicalRiskFactor = "";

  // const pathologyStatusCheckHandler = (reports) => {
  //   reports?.forEach((report) => {
  //     // check hemoglobin status
  //     if (report.investigation_id === 15) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 16) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       } else if (report.result < 11.8) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       }
  //     }

  //     // check R.B.S status
  //     if (report.investigation_id === 16) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 7.5) {
  //         pathologyStatus += "R.B.S.: " + report?.result + ", ";
  //       } else if (report.result > 7.5 && report.result <= 9) {
  //         pathologyRiskFactor += "30% Risk for Diabetes, ";
  //       } else if (report.result > 9 && report.result <= 12) {
  //         pathologyRiskFactor += "20% Risk for Diabetes, ";
  //       } else if (report.result > 12 && report.result <= 15) {
  //         pathologyRiskFactor += "40% Risk for Diabetes, ";
  //       } else if (report.result > 15 && report.result <= 18) {
  //         pathologyRiskFactor += "50% Risk for Diabetes, ";
  //       } else if (report.result > 18 && report.result <= 25) {
  //         pathologyRiskFactor += "75% Risk for Diabetes, ";
  //       } else if (report?.result > 25) {
  //         pathologyRiskFactor += "90~100% Risk for Diabetes, ";
  //       }
  //     }

  //     // check S. Creatinine status
  //     if (report.investigation_id === 17) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 1.3) {
  //         pathologyStatus += "S. Creatinine: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 2) {
  //         pathologyRiskFactor += "35% Risk for S. Creatinine, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "60% Risk for S. Creatinine, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for S. Creatinine, ";
  //       }
  //     }

  //     // check S.Bilirubin status
  //     if (report.investigation_id === 20) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 0.99) {
  //         pathologyStatus += "S.Bilirubin: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 1.6) {
  //         pathologyRiskFactor += "35% Risk for Jaundice, ";
  //       } else if (report.result > 1.6 && report.result <= 2) {
  //         pathologyRiskFactor += "50% Risk for Jaundice, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "75% Risk for Jaundice, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for Jaundice, ";
  //       }
  //     }

  //     // check Malarial Parasite status
  //     if (report.investigation_id === 25) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Found") {
  //         pathologyStatus += "Malarial Parasite: Found, ";
  //       }
  //     }

  //     // check SGPT status
  //     if (report.investigation_id === 23) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 41) {
  //         pathologyStatus += "SGPT: " + report?.result + ", ";
  //       } else if (report.result > 60 && report.result <= 80) {
  //         pathologyRiskFactor += "20% Risk for SGPT, ";
  //       } else if (report.result > 80 && report.result <= 100) {
  //         pathologyRiskFactor += "25% Risk for SGPT, ";
  //       } else if (report.result > 100 && report.result <= 140) {
  //         pathologyRiskFactor += "40% Risk for SGPT, ";
  //       } else if (report.result > 140 && report.result <= 200) {
  //         pathologyRiskFactor += "75% Risk for SGPT, ";
  //       } else if (report.result > 200) {
  //         pathologyRiskFactor += "90~100% Risk for SGPT, ";
  //       }
  //     }

  //     // check SGOT status
  //     if (report.investigation_id === 24) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 38) {
  //         pathologyStatus += "SGOT: " + report?.result + ", ";
  //       }
  //     }

  //     // check C.U. Sugar status
  //     if (report.investigation_id === 21) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > "NIL") {
  //         pathologyStatus += "C.U. Sugar: Nill, ";
  //       }
  //     }

  //     // check HBsAg status
  //     if (report.investigation_id === 26) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HBsAg: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyRiskFactor += "100% Risk for Blood(HBsAg), ";
  //       }
  //     }

  //     // check VDRL status
  //     if (report.investigation_id === 27) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //         pathologyRiskFactor += "75% Risk for Blood(VDRL), ";
  //       } else if (report.result === "W. Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //       }
  //     }

  //     // check TPHA status
  //     if (report.investigation_id === 28) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(TPHA), ";
  //       }
  //     }

  //     // check Anti-HCV status
  //     if (report.investigation_id === 29) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(Anti-HCV), ";
  //       }
  //     }

  //     // check HIV 1 - 2 status
  //     if (report.investigation_id === 30) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(HIV), ";
  //       }
  //     }

  //     // check pregnancy status
  //     if (report.investigation_id === 22) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (
  //         report.result === "W. Positive" ||
  //         report.result === "Positive"
  //       ) {
  //         pathologyStatus += "Pregnancy: " + report?.result + ", ";
  //       }
  //     }
  //   });
  // };

  const physicalStatusCheckHandler = (reports) => {
    reports.forEach((report) => {
      // check left eye status
      if (report.investigation_id === 7) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "LEFT EYE: Squint";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "LEFT EYE: Blind";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "LEFT EYE: Dimness of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "LEFT EYE: Color of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        }
      }

      // check right eye status
      if (report.investigation_id === 8) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "RIGHT EYE: Squint";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "RIGHT EYE: Blind";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "RIGHT EYE: Dimness of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "RIGHT EYE: Color of Vision";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        }
      }

      // check left ear status
      if (report.investigation_id === 9) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "LEFT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "LEFT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        }
      }

      // check right ear status
      if (report.investigation_id === 10) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "RIGHT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "RIGHT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        }
      }

      // check hernia status
      if (report.investigation_id === 11) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hernia: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check hydrocele status
      if (report.investigation_id === 12) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hydrocele: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check BP status
      if (report.investigation_id === 3) {
        const bpAValue = parseInt(report.result?.split("/")[0]);
        const bpBValue = parseInt(report.result?.split("/")[0]);

        if (
          (bpAValue > 130 || bpAValue < 100) &&
          (bpBValue > 85 || bpBValue < 60)
        ) {
          physicalStatus += "Blood Pressure: " + report.result + ", ";
        }
      }

      // check pulse status
      if (report.investigation_id === 4) {
        const pulseValue = parseInt(report.result);

        if (pulseValue > 100 || pulseValue < 60) {
          physicalStatus += "Pulse: " + report.result + ", ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          } else {
            // riskFactor = riskFactor + "Pulse: 100%, ";
          }
        }
      }
    });
  };

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  function divideArray(array) {
    const length = array.length;
    const chunkSize = Math.ceil(length / 4);

    const result = [[], [], [], []];

    for (let i = 0; i < length; i++) {
      const chunkIndex = Math.floor(i / chunkSize);
      result[chunkIndex].push(array[i]);
    }
    return result;
  }

  const [userResultsData, setUserResultsData] = useState({
    user_id: userId,
    final_comments: "",
    advice: "",
    risk_factor: "",
    is_auto: 0,
    status: 0,
  });

  const [pathologyResults, setPathologyResults] = useState([]);
  const [radiologyResults, setRadiologyResults] = useState([]);

  const getRadiologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${2}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        const dividedIntoFour = divideArray(data);
        setRadiologyResults(dividedIntoFour);
        setRadiologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPathologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { comments } = response?.data?.data;
      setPhysicalNewRiskFactor(comments);
    } catch (error) {
      console.error(error);
    }
  };

  function getUserResults() {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-user-results/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        setUserResultsData((prev) => {
          return {
            ...prev,
            final_comments: data?.final_comments,
            advice: data?.advice,
            risk_factor: data?.risk_factor,
          };
        });
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    const getUserStatus = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/user-test-status/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      if (res?.data.some((item) => item.status === 3)) {
        setStatus(3);
      } else if (
        res?.data?.some((item) => item.status === 4 && item.status !== 3)
      ) {
        setStatus(4);
      } else {
        setStatus(2);
      }
    };
    getUserStatus();
    getRadiologyData();
    getPathologyData();
    getUserResults();
    fetchStaticContentData();
  }, []);

  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [physicalTests, setPhysicalTests] = useState([]);
  const [pathologyTests, setPathologyTests] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [riskFactor, setRiskFactor] = useState("");
  const [pathologyCommentRisk, setPathologyCommentRisk] = useState();
  const [physicalCommentRisk, setPhysicalCommentRisk] = useState();
  const [staticData, setStaticData] = useState([]);

  const registrationNo = user?.user?.registration_no;

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: true,
  });

  const formattedBirthDate = user?.user?.date_of_birth
    ? (() => {
        const date = new Date(user?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }
    const days = Math.floor(hours || 0 / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const receivingDate = user?.user_payment?.createdAt;
  const preparationDuration =
    user?.user?.role_id !== 5
      ? user?.package?.preparation_duration ?? 0
      : user?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUserDetail(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/pathology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPathologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setRadiologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  async function getRiskFactor() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-risk-factor/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const risk = response?.data?.data || [];
      setRiskFactor(risk[0]?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  const getPathologyComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-pathology-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;
      setPathologyCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response?.data?.data);
      const commentsData = response?.data?.data;
      const physicalCommentRisk = commentsData.filter(
        (comment) => comment.investigation_group_id === 101
      );
      setPhysicalCommentRisk(physicalCommentRisk);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStaticContentData = async () => {
    await axios
      .get(staticURL, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        setStaticData(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const removeHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyData, user?.gender?.id]);

  const physicalNewRiskFactorWithoutTags = removeHtmlTags(
    physicalNewRiskFactor
  );
  const pathologyCommentRiskWithoutTags = removeHtmlTags(pathologyCommentRisk);
  const riskFactorWithoutTags = removeHtmlTags(riskFactor);

  useEffect(() => {
    userId && getPathologyComment();
    userId && getPhysicalComment();
    userId && getPathologyTests();
    userId && getRadiologyTests();
    userId && getUserDetail();
    userId && getRiskFactor();
    userId && getPhysicalTests();
    userId && getComment();
  }, [userId]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        console.log(item?.user_file);
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  // Get user, Introducer, and Representative---------------------
  const [representative, setRepresentative] = useState();
  useEffect(() => {
    userDetail && setUser(userDetail.userDetail);
    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            }
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userDetail]);

  const userDetailAdviceIds =
    userDetail?.userDetail?.user?.user_pescriptions[0]?.advice_ids;

  const advice = staticData
    .filter((advice) => userDetailAdviceIds?.includes(advice?.id))
    .map((advice) => advice?.name);

  const radiologyFindings = radiologyTests.find(
    (item) => item?.investigation_test?.name === "Findings"
  )?.result;

  // pathologyStatusCheckHandler(pathologyTests);
  physicalStatusCheckHandler(physicalTests);
  return (
    <>
      <div className="row align-items-center mt-2">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <section className="medical-report-print px-3 mx-2" ref={pdfRef}>
        <div className="row mb-2 text-dark text-center border border-2 border-dark px-2">
          <h6 className="mb-0 fs-5">
            Medical Report for {user?.user?.f_name || "..."}
            {`[${user?.user?.registration_no || "..."}]`}
          </h6>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-sm-5 h-100">
              <div
                className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center"
                style={{ height: "90px" }}
              >
                <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                  DATE: {formatDeliveryTime(newDate, "dd-MM-yyyy hh:mm a")}
                </p>
                <img
                  src={patientIdDataUrl}
                  alt=""
                  className="w-100 img-fluid"
                  style={{
                    height: "60px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                />
              </div>
            </div>
            <>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {user?.user?.image ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.image}`}
                      className="img-fluid"
                      alt="patient"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <img
                      src="./dummy.jpg"
                      alt="patient"
                      className="img-fluid"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {user?.user?.finger_print ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.finger_print}`}
                      className="img-fluid"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <div
                      className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      No Fingerprint Found
                    </div>
                  )}
                </div>
              </div>
            </>

            {/* <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-start-0 border-dark"
                style={{
                  height: "90px",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <table
                  className="table mt-sm-0 mb-0 border border-top-0 border-bottom-0 border-end-0 border-dark"
                  id="xrayImage"
                  style={{
                    height: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: 0,
                          height: "100%",
                          width: "100%",
                          border: "0",
                          borderTop: "0",
                        }}
                      >
                        {xrayImage ? (
                          <img
                            src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                            alt="X-Ray film"
                            className="img-fluid"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          user?.user?.role_id !== 5 && (
                            <div
                              className="text-center d-flex align-items-center justify-content-center fw-bold"
                              style={{
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              No X-Ray film found
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                X-RAY IMAGE
              </p>
            </div> */}
            <div className="col-sm-3 h-100">
              <div
                className="d-flex justify-content-center align-items-center w-100 border border-dark"
                style={{ height: "90px" }}
              >
                <Link to={urlWithBase64ID}>
                  <QRCode value={urlWithBase64ID} size={85} />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="col-sm-12">
              <div className="row text-dark">
                <table
                  className="responsive-width1 table-bordered"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    {/* Row 1 - Name and Client ID */}
                    <tr>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Name
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {user?.user?.f_name || "---"}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Client ID
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {user?.user?.registration_no || "---"}
                      </td>
                    </tr>

                    {/* Row 2 - Gender and Passport No */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Gender
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.gender?.name || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Passport No
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.passport_no || "---"}
                      </td>
                    </tr>

                    {/* Row 3 - Date of Birth and Travelling To */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.role_id !== 5 ? `Date of Birth` : `Age`}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.role_id !== 5
                          ? formattedBirthDate || "---"
                          : user?.user?.age || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Travelling To
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.country?.name || "---"}
                      </td>
                    </tr>

                    {/* Row 4 - Father Name and Contact Number */}
                    {user?.user?.role_id !== 5 && (
                      <tr>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Father Name
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {user?.father_name || "---"}
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Contact Number
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {user?.user?.mobile || "---"}
                        </td>
                      </tr>
                    )}

                    {/* Row 5 - Nationality and Agency */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Nationality
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.national_country?.nationality || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.role_id !== 5
                          ? `${settingsData?.introducer_label}`
                          : `Reference`}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        colSpan={3}
                      >
                        {user?.user?.role_id !== 5
                          ? user?.introducer_user?.f_name || "---"
                          : user?.user?.reference || "---"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="below-table w-100 p-1 mb-1 text-dark"
                  style={{ position: "relative", bottom: "0" }}
                >
                  History of past ilness: 1) Allergy: ABSENT 2) Psychiatric and
                  neurological disorders (Epilepsy, depression): ABSENT 3)
                  Others: NAD
                </p>
                <p
                  className="below-table w-100 p-1 m-0 border border-dark text-dark"
                  style={{ position: "relative", bottom: "5px" }}
                >
                  I hereby permit the {settingsData?.website_name} and the
                  undersigned physician to furnish such information the company
                  many need pertaining to my health status and pertinent and
                  medical findings and do hereby release them from any and all
                  legal responsibility by doing so. I also certify that my
                  medical history contained above is true and any false
                  statement will disqualify me from my employment, benefits and
                  claim.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-flex flex-column flex-sm-row">
            <div className="col-sm-6">
              <table className="table border border-dark mb-0 fw-bold">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center"
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Medical Examination
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      TYPE OF EXAMINATION
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      RESULTS
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedData)?.map(
                    ([categoryId, categoryData]) => (
                      <React.Fragment key={categoryId}>
                        <tr>
                          <td
                            colSpan="3"
                            className="text-nowrap text-uppercase"
                          >
                            {categoryData?.name}
                          </td>
                        </tr>
                        {categoryData?.items?.map((data, index) => {
                          // Log the data object for debugging
                          return (
                            <tr key={index}>
                              <td className="border text-start py-1 ps-5 border-dark text-dark">
                                {data?.investigation?.name || "---"}
                              </td>
                              <td className="border text-start py-1 border-dark text-dark">
                                {data?.result || "---"}{" "}
                                {data?.result &&
                                  data?.result !== "N/A" &&
                                  data?.investigation?.investigation_tests[0]
                                    ?.uom?.name}
                              </td>
                              <td className="border text-start py-1 border-dark text-dark">
                                {data?.investigation?.investigation_tests[0]
                                  ?.report_normal_value || ""}
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    )
                  )}
                  {radiologyData && (
                    <tr>
                      <td className="text-nowrap text-uppercase">
                        X-RAY INVESTIGATION
                      </td>
                    </tr>
                  )}
                  {radiologyData
                    ? radiologyData?.map((item, i) => (
                        <tr key={i}>
                          <td className="border text-start py-1 ps-5 border-dark">
                            {item?.investigation_test?.name === "Findings"
                              ? "Comment"
                              : `${item?.investigation_test?.name}`}
                          </td>
                          <td
                            className={`border text-start py-1 border-dark  ${
                              item?.test_id === 35 &&
                              item?.investigation_test?.name === "Findings"
                                ? item?.result === "Normal CXR"
                                  ? "text-success text-uppercase"
                                  : "text-primary"
                                : ""
                            }`}
                            colSpan={2}
                          >
                            {item?.result}
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>

            <div className="d-flex flex-column justify-content-start col-sm-6 ps-sm-3 ps-0">
              <table className="table border border-dark mb-0 fw-bold">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center"
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Laboratory Investigation
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      TYPE OF EXAMINATION
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      RESULTS
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedPathologyData)?.map(
                    ([categoryId, categoryData]) => (
                      <React.Fragment key={categoryId}>
                        <tr>
                          <td
                            colSpan="3"
                            className="text-nowrap text-uppercase"
                          >
                            {categoryData?.name}
                          </td>
                        </tr>
                        {categoryData?.items?.map((data, index) => (
                          <tr key={index}>
                            <td className="border text-start py-1 ps-5 border-dark  text-dark">
                              {data?.investigation_test?.investigation?.name ||
                                "---"}
                            </td>
                            <td className="border text-start py-1 border-dark  text-dark">
                              {data?.result || "---"}{" "}
                              {data?.result &&
                                data?.result !== "N/A" &&
                                data?.investigation_test?.uom?.name}
                            </td>
                            <td className="border text-start py-1 border-dark  text-dark">
                              {data?.investigation_test?.report_normal_value ||
                                ""}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
              <div className="w-100 mt-2 mx-auto">
                <div className="col-sm-12 d-flex text-dark border border-dark p-1">
                  Problematic Test: <div className="ps-1"></div>
                  <div
                    dangerouslySetInnerHTML={{ __html: physicalCommentRisk }}
                  />
                  <div className="ps-1"></div>
                  {pathologyCommentRisk ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${pathologyCommentRisk}`,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <div className="ps-1"></div>
                  {radiologyData?.find(
                    (report) =>
                      report?.investigation_test?.id === 35 &&
                      report?.result !== "Normal CXR" &&
                      report?.result !== ""
                  ) && (
                    <>
                      <span
                        style={{
                          color:
                            radiologyData?.find(
                              (report) => report?.investigation_test?.id === 35
                            )?.result !== "Normal CXR"
                              ? "red"
                              : "black",
                        }}
                      >
                        X-Ray
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="container">
                <div className="row w-100">
                  <div className="text-start">
                    <p className="text-uppercase m-0 fst-italic text-dark p-0 m-0">
                      Mentioned above is the medical report for{" "}
                      {user?.user?.f_name}, who is
                      <span
                        className="fw-bold text-uppercase fs-6 fst-normal p-0 m-0"
                        style={{
                          color: `${
                            user?.user?.status === 1
                              ? "black"
                              : user?.user?.status === 2
                              ? "#5cb85c"
                              : user?.user?.status === 3
                              ? "red"
                              : user?.user?.status === 4 && "#0000FF"
                          }`,
                        }}
                      >
                        {user?.user?.status === 1 && ` PROCESSING`}
                        {user?.user?.status === 2 && ` FIT`}
                        {user?.user?.status === 3 && ` UNFIT`}
                        {user?.user?.status === 4 && ` HELD UP`}
                      </span>{" "}
                      for the above mentioned job according to the medical
                      criteria.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="text-start text-dark p-0 mt-1">
              Printed By: {user?.user?.created_by}
            </div>
            <div className="text-dark">
              <div
                className="border-top mt-0 border-dark"
                style={{ width: "125px" }}
              ></div>
              CHECKED BY
            </div>
          </div>
        </div>
        <div className="row mt-3 inputs">
          <div className="col-4 px-1 text-dark">
            <h6>Comments</h6>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "10px",
                width: "100%",
                height: "100%",
              }}
            >
              {physicalStatus &&
                `Physical: ${
                  physicalStatus !== "Normal" ? physicalStatus : "N/A"
                }`}
              {physicalStatus && <br />}
              {pathologyStatus &&
                `Pathology: ${pathologyStatus ? pathologyStatus : "N/A"}`}
              {radiologyFindings && <br />}
              {radiologyFindings &&
                `X-Ray: ${radiologyFindings ? radiologyFindings : "N/A"}`}
            </div>
          </div>
          <div className="col-4 px-1 text-dark">
            <h6>Advice</h6>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "10px",
                width: "100%",
                height: "100%",
              }}
            >
              {advice}
            </div>
          </div>
          <div className="col-4 ps-1 text-dark">
            <h6>Risk Factor</h6>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "10px",
                width: "100%",
                height: "100%",
              }}
              name="risk_factor"
              id="risk_factor"
              rows="5"
              className="w-100 border-none ps-1"
            >
              {physicalNewRiskFactor
                ? `Physical: ${physicalNewRiskFactorWithoutTags}% Risk,\n`
                : ""}

              {pathologyCommentRisk
                ? `Pathology: ${pathologyCommentRiskWithoutTags}\n`
                : ""}
              {riskFactor ? `X-Ray: ${riskFactorWithoutTags}% Risk\n` : ""}
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end gap-2 mt-4 fit-button p-2">
        {accessPerm(68, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default PrintMedicalReport;
