import React, { useContext } from "react";
import axios from "axios";
import { useState } from "react";
import DataTable from "react-data-table-component";
import "./TrailBalance.scss";
import { format } from "date-fns";
import { UserContext } from "../../../Context/UserContextAPI";

const TrailBalance = () => {
  const { accessPerm } = useContext(UserContext);
  const [trailInfo, setTrailInfo] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const [transactionFrom, setTransactionFrom] = useState(null);
  const [transactionTo, setTransactionTo] = useState(null);
  const [initialDateFetched, setInitialDateFetched] = useState(false);

  const columns = [
    {
      name: "SL NO",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row?.accountHead?.code,
      sortable: true,
    },
    {
      name: "Head Name",
      selector: (row) => row?.accountHead?.name,
      sortable: true,
    },
    {
      name: "Debit",
      sortable: true,
      cell: (record) => {
        return <div className="w-100 text-end">{record?.debit}</div>;
      },
    },
    {
      name: "Credit",
      sortable: true,
      cell: (record) => {
        return <div className="w-100 text-end">{record?.credit}</div>;
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: false,
    show_pagination: false,
    button: {
      excel: false,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [];

  const customStyles = {
    headRow: {
      style: {
        fontSize: "15px", // Increase the font size of header titles
        border: "1px solid #e0e0e0", // Add a border to the cells
        fontWeight: "bold",
        backgroundColor: "#095d7e",
        color: "white",
      },
    },
    headCells: {
      style: {
        border: "1px solid #e0e0e0", // Add a border to every cell of the head row
        overflowWrap: "break-word", // Wrap and break text to the next line
      },
    },
    cells: {
      style: {
        fontSize: "14px", // Adjust the font size of cell content as needed
        border: "1px solid #e0e0e0", // Add a border to the cells
        padding: "4px",
        whiteSpace: "pre-wrap", // Set white space to pre-wrap for all cells
        fontWeight: "bold",
      },
    },
  };

  function formatDateToDisplay(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;

    setTransactionFrom(fromDate);
    setTransactionTo(toDate);

    const filterData = {
      fromDate,
      toDate,
    };

    methodFilterPatientsTrailList(filterData);
  };

  const methodFilterPatientsTrailList = async (filterData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/voucher/search/trail`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      // console.log("found", response?.data);
      const sortedDates = response?.data.map(
        (item) => new Date(item?.createdAt)
      );
      sortedDates.sort((a, b) => a - b);
      setInitialDateFetched(true);
      setTrailInfo(response?.data);

      // Calculate total debit for selected date range
      const debitSum = response?.data.reduce(
        (sum, item) => sum + parseFloat(item.debit || 0),
        0
      );
      const creditSum = response?.data.reduce(
        (sum, item) => sum + parseFloat(item.credit || 0),
        0
      );
      setTotalDebit(debitSum);
      setTotalCredit(creditSum);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="trail-balance">
      <div className="card">
        <div className="card-body">
          <div className="border p-2 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Trail Balance</h6>
            </div>

            <hr />

            <div className="shadow-none">
              <div className="border p-2 rounded">
                <div className="">
                  <form onSubmit={(e) => handlerOnSearch(e)}>
                    <div className="d-flex gap-4 pb-2">
                      <div className="w-25">
                        <label className="col-form-label fw-bold">
                          From Date
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            className="form-control"
                            placeholder="Date Picker..."
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                          />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="col-form-label fw-bold">
                          To Date
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="to_date"
                            className="form-control"
                            placeholder="Date Picker..."
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-end">
                        <button
                          type="submit"
                          className="btn btn-success border border-3 pt-1"
                          style={{
                            fontSize: "15px", // Increase the font size of header titles
                            backgroundColor: "#0d6efd",
                            color: "white",
                            border: "none",
                          }}
                        >
                          Generate Trail Balance
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="print-datatable-scroll-container">
              <div className="datatable-scroll-container">
                <DataTable
                  columns={columns}
                  data={trailInfo}
                  highlightOnHover
                  striped
                  dense
                  subHeader
                  subHeaderAlign="right"
                  subHeaderComponent={
                    <div className="w-100 d-flex justify-content-start">
                      <div className="fw-bold fs-6">
                        {initialDateFetched === true ? (
                          <>
                            Date:{" "}
                            {formatDateToDisplay(transactionFrom) ===
                            "Invalid Date"
                              ? ""
                              : formatDateToDisplay(transactionFrom)}{" "}
                            {(transactionFrom !== "" &&
                              transactionTo !== "") === true
                              ? "to"
                              : ""}{" "}
                            {formatDateToDisplay(transactionTo) ===
                            "Invalid Date"
                              ? ""
                              : formatDateToDisplay(transactionTo)}{" "}
                          </>
                        ) : (
                          "Date : "
                        )}
                      </div>
                    </div>
                  }
                  noDataComponent="Select Dates to Check Records"
                  subHeaderWrap
                  fixedHeader
                  fixedHeaderScrollHeight
                  {...config}
                  extraButtons={extraButtons}
                  customStyles={customStyles}
                />
              </div>
              {initialDateFetched ? (
                <div
                  className="sc-jsEeA-d leGWtK rdt_TableRow"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto auto",
                  }}
                >
                  <div className="sc-hLQTFJ sc-eDLKEg sc-jTQDJr Wjvsc bFyntL hJGRSq rdt_TableCell"></div>
                  <div className="sc-hLQTFJ sc-eDLKEg sc-jTQDJr Wjvsc bFyntL hJGRSq rdt_TableCell"></div>
                  <div className="sc-hLQTFJ sc-eDLKEg sc-jTQDJr Wjvsc bFyntL  hJGRSq rdt_TableCell amount-table">
                    Total
                  </div>
                  <div className="sc-hLQTFJ sc-eDLKEg sc-jTQDJr Wjvsc bFyntL hJGRSq rdt_TableCell amount-table">
                    {totalDebit}
                  </div>
                  <div className="sc-hLQTFJ sc-eDLKEg sc-jTQDJr Wjvsc bFyntL hJGRSq rdt_TableCell amount-table">
                    {totalCredit}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2 unfit-button p-2">
          {accessPerm(45, 23) && (
            <button className="btn btn-primary" onClick={() => window.print()}>
              Print
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrailBalance;
