import axios from "axios";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode.react";
import "../../Investigation/Report/Certificates/HeldUp.scss";
import { PDFExport } from "@progress/kendo-react-pdf";
import { format } from "date-fns";
import { UserContext } from "../../../Context/UserContextAPI";
import XrayImage from "../../XrayImage/XrayImage";

const PrintVerificationReport = () => {
  const { settingsData } = useContext(UserContext);
  const [xrayImage, setXrayImage] = useState("");
  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [pathologyData, setPathologyData] = useState([]);
  const [data, setData] = useState({});
  const [downloadTimestamp, setDownloadTimestamp] = useState(null);
  const websiteUrlWithBase64ID = `https://edmcbd.com`;
  const pdfRef = useRef(null);

  const { id } = useParams();
  const urlWithBase64ID = `${process.env.REACT_APP_UPLOAD_URL}/medical-verification/result/report/print/${id}`;

  const formattedBirthDate = data?.user?.date_of_birth
    ? (() => {
        const date = new Date(data?.user?.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const registrationNo = data?.user?.registration_no;

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: true,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }

    const days = Math.floor(hours / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const receivingDate = data?.user_payment?.createdAt;
  const preparationDuration = data?.package?.preparation_duration;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const getPhysicalData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${id}/${3}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPhysicalData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getRadiologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${id}/${2}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setRadiologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getPathologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${id}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPathologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical-verification/${id}`)
      .then((response) => {
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryId =
        item?.investigation_test?.investigation?.investigation_category?.id;
      if (!categoryId) return acc;
      if (!acc[categoryId]) {
        acc[categoryId] = {
          name: item?.investigation_test?.investigation?.investigation_category
            .name,
          items: [],
        };
      }
      acc[categoryId].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyData, data?.gender?.id]);

  const pdfGenerate = () => {
    if (pdfRef.current) {
      pdfRef.current.save({
        fileName: `${data?.user?.registration_no}.pdf`,
      });
    }
  };

  const qrCodeImage = new Image();
  qrCodeImage.src = websiteUrlWithBase64ID;

  useEffect(() => {
    getPhysicalData();
    getRadiologyData();
    getPathologyData();
    getData();
  }, [id]);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  useEffect(() => {
    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, "0")}-${String(
      now.getMonth() + 1
    ).padStart(2, "0")}-${now.getFullYear()}`;
    setDownloadTimestamp(formattedDate); // Store the current date in dd-mm-yyyy format

    if (
      data?.user?.registration_no &&
      patientIdDataUrl &&
      data?.introducer_user?.f_name &&
      downloadTimestamp
    ) {
      setTimeout(() => {
        pdfGenerate();
      }, 1600);
    }
  }, [data]);

  return (
    <PDFExport ref={pdfRef} className="h-100">
      <section
        className="held-up-certificate container mx-auto w-100"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.background_image})`,
          backgroundSize: "contain", // Adjust as needed
          backgroundPosition: "center", // Adjust as needed
          backgroundRepeat: "no-repeat", // Adjust as needed
        }}
      >
        <div className="header-image">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
        <div className="mt-2 p-3">
          <div className="row">
            <h4 className="fw-bold fs-4 text-center" style={{ color: "#000" }}>
              MEDICAL TEST REPORT
            </h4>
          </div>
          <div className="row">
            <div className="col-sm-5 h-100">
              <div
                className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center"
                style={{ height: "90px" }}
              >
                <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                  DATE: {formatDeliveryTime(newDate, "dd-MM-yyyy hh:mm a")}
                </p>
                <img
                  src={patientIdDataUrl}
                  alt=""
                  className="w-100 img-fluid"
                  style={{
                    height: "60px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                />
                {/* <p
                  className="p-0 mb-2 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                  
                >
                  REG DATE:{" "}
                  {formatDate(data?.user?.createdAt, "dd-MM-yyyy hh:mm a")}
                </p> */}
              </div>
            </div>
            <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-dark"
                style={{ height: "90px" }}
              >
                {data?.user?.image ? (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${data?.user?.image}`}
                    className="img-fluid"
                    alt="patient"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <img
                    src="./dummy.jpg"
                    alt="patient"
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-dark"
                style={{ height: "90px" }}
              >
                {data?.user?.finger_print ? (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${data?.user?.finger_print}`}
                    className="img-fluid"
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <div
                    className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    No Fingerprint Found
                  </div>
                )}
              </div>
            </div>
            {/* <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-bottom-0 border-start-0 border-dark"
                style={{ height: "90px", width: "100%", overflow: "hidden" }}
              >
                <table
                  className="table mt-sm-0 mb-0 border border-top-0 border-end-0 border-dark"
                  id="xrayImage"
                  style={{
                    height: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: 0, height: "100%", width: "100%" }}>
                        {!!xrayImage ? (
                          <img
                            src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                            alt="X-Ray film"
                            className="img-fluid"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          <div
                            className="text-center d-flex align-items-center justify-content-center fw-bold"
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            No X-Ray film found
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                X-RAY IMAGE
              </p>
            </div> */}
            <div className="col-sm-3 h-100">
              <div
                className="d-flex justify-content-center align-items-center w-100 border border-dark"
                style={{ height: "90px" }}
              >
                <Link to={urlWithBase64ID}>
                  <QRCode value={urlWithBase64ID} size={85} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-1 px-3">
          <div className="col-sm-12">
            <div className="row text-dark">
              <div className="w-50">
                <table className="">
                  <tbody>
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Name
                      </td>
                      <td
                        className="text-uppercase border border-dark border-start-0 border-end-0 ps-1 align-middle"
                        colSpan={3}
                      >
                        {data?.user?.f_name || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Gender
                      </td>
                      <td className="text-uppercase border border-dark border-start-0 border-end-0 ps-1 align-middle">
                        {data?.gender?.name || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Date of birth
                      </td>
                      <td className="text-uppercase border border-dark border-start-0 border-end-0 ps-1 align-middle">
                        {formattedBirthDate || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Father Name{" "}
                      </td>
                      <td className="text-uppercase border border-dark border-start-0 border-end-0 ps-1 align-middle">
                        {data?.father_name || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Nationality{" "}
                      </td>
                      <td className="text-uppercase border border-dark border-start-0 border-end-0 ps-1 align-middle">
                        {data?.national_country?.nationality || "---"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-50">
                <table className="table-bordered border border-dark">
                  <tbody>
                    <tr>
                      <td className="text-uppercase ps-1 align-middle">
                        Client Id{" "}
                      </td>
                      <td className="text-uppercase ps-1 align-middle">
                        {data?.user?.registration_no || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase ps-1 align-middle">
                        Passport no{" "}
                      </td>
                      <td className="text-uppercase ps-1 align-middle">
                        {data?.user?.passport_no || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase ps-1 align-middle">
                        Travelling To
                      </td>
                      <td className="text-uppercase ps-1 align-middle">
                        {data?.country?.name || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase ps-1 align-middle">
                        Contact Number
                      </td>
                      <td className="text-uppercase ps-1 align-middle">
                        {data?.user?.mobile || "---"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase ps-1 align-middle">
                        {settingsData?.introducer_label}
                      </td>
                      <td
                        className="text-uppercase ps-1 align-middle"
                        colSpan={3}
                      >
                        {data?.introducer_user?.f_name || "---"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-3">
          <div className="col-sm-12 d-flex flex-row">
            <div className="col-sm-6">
              <table className="table mt-1 border border-dark mb-0 fw-bold">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center"
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Medical Examination
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap"
                    >
                      TYPE OF EXAMINATION
                    </th>
                    <th className="border text-center border py-1 border-dark text-nowrap">
                      RESULTS
                    </th>
                    <th className="border text-center border py-1 border-dark text-nowrap">
                      REF. VALUE
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedData)?.map(
                    ([categoryId, categoryData]) => (
                      <React.Fragment key={categoryId}>
                        <tr>
                          <td
                            colSpan="3"
                            className="text-nowrap text-uppercase"
                          >
                            {categoryData?.name}
                          </td>
                        </tr>
                        {categoryData?.items?.map((data, index) => {
                          // Log the data object for debugging
                          return (
                            <tr key={index}>
                              <td className="border text-start py-1 ps-5 border-dark text-dark">
                                {data?.investigation?.name || "---"}
                              </td>
                              <td className="border text-start py-1 border-dark text-dark">
                                {data?.result || "---"}{" "}
                                {data?.result &&
                                  data?.result !== "N/A" &&
                                  data?.investigation?.investigation_tests[0]
                                    ?.uom?.name}
                              </td>
                              <td className="border text-start py-1 border-dark text-dark">
                                {data?.investigation?.investigation_tests[0]
                                  ?.report_normal_value || ""}
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    )
                  )}
                  {radiologyData && (
                    <tr>
                      <td className="text-nowrap text-uppercase">
                        X-RAY INVESTIGATION
                      </td>
                    </tr>
                  )}
                  {radiologyData
                    ? radiologyData?.map((item, i) => (
                        <tr key={i}>
                          <td className="border text-start py-1 ps-5 border-dark">
                            {item?.investigation_test?.name === "Findings"
                              ? "Comment"
                              : `${item?.investigation_test?.name}`}
                          </td>
                          <td
                            className={`border text-start py-1 border-dark ${
                              item?.test_id === 35 &&
                              item?.investigation_test?.name === "Findings"
                                ? item?.result === "Normal CXR"
                                  ? "text-success text-uppercase"
                                  : "text-primary"
                                : ""
                            }`}
                            colSpan={2}
                          >
                            {item?.result}
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>

            <div className="d-flex flex-column justify-content-between col-sm-6 ps-3">
              <table className="table mt-1 border border-dark mb-0 fw-bold">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center "
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Laboratory Investigation
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap"
                    >
                      TYPE OF EXAMINATION
                    </th>
                    <th className="border text-center border py-1 border-dark text-nowrap">
                      RESULTS
                    </th>
                    <th className="border text-center border py-1 border-dark text-nowrap">
                      REF. VALUE
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedPathologyData)?.map(
                    ([categoryId, categoryData]) => (
                      <React.Fragment key={categoryId}>
                        <tr>
                          <td
                            colSpan="3"
                            className="text-nowrap text-uppercase"
                          >
                            {categoryData?.name}
                          </td>
                        </tr>
                        {categoryData?.items?.map((data, index) => (
                          <tr key={index}>
                            <td className="border text-start py-1 ps-5 border-dark  text-dark">
                              {data?.investigation_test?.investigation?.name ||
                                "---"}
                            </td>
                            <td className="border text-start py-1 border-dark  text-dark">
                              {data?.result || "---"}{" "}
                              {data?.result &&
                                data?.result !== "N/A" &&
                                data?.investigation_test?.uom?.name}
                            </td>
                            <td className="border text-start py-1 border-dark  text-dark">
                              {data?.investigation_test?.report_normal_value ||
                                ""}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
              <div className="container mt-2">
                <div className="row w-100">
                  <div className="text-start">
                    <p className="text-uppercase m-0 text-dark fst-italic">
                      Mentioned above is the medical report for{" "}
                      {data?.user?.f_name}, who is
                      <span
                        className="fw-bold text-uppercase fs-5 fst-normal"
                        style={{
                          color: `${
                            data?.user?.status === 1
                              ? "black"
                              : data?.user?.status === 2
                              ? "#5cb85c"
                              : data?.user?.status === 3
                              ? "red"
                              : data?.user?.status === 4 && "#0000FF"
                          }`,
                        }}
                      >
                        {data?.user?.status === 1 && ` PROCESSING`}
                        {data?.user?.status === 2 && ` FIT`}
                        {data?.user?.status === 3 && ` UNFIT`}
                        {data?.user?.status === 4 && ` HELD UP`}
                      </span>{" "}
                      for the above mentioned job according to the medical
                      criteria.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="page-break"></div> */}
        <div className="footer-image">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
      </section>
      <XrayImage />
    </PDFExport>
  );
};

export default PrintVerificationReport;
