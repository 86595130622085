import axios from "axios";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./MedicalPhysical.scss";
import JsBarcode from "jsbarcode";
import { UserContext } from "../../../../Context/UserContextAPI";
import "../Certificates/HeldUp.scss";
import "../Certificates/Unfit.scss";
import QRCode from "qrcode.react";
import { format } from "date-fns";

const PrintMedicalPhysical = () => {
  const pdfRef = useRef();

  const { userId } = useParams();
  const { currentUser, accessPerm, settingsData } = useContext(UserContext);

  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [xrayImage, setXrayImage] = useState("");
  const [investigations, setInvestigations] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [physicalRiskFactor, setPhysicalRiskFactor] = useState("");
  const [physicalReportData, setPhysicalReportData] = useState([]);
  const [physicalComment, setPhysicalComment] = useState({
    user_id: userId,
    comments: "",
    status: 1,
    investigation_group_id: 3,
  });

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const urlWithBase64ID = `https://${process.env.REACT_APP_API_BASE_URL}/medical-verification/result/report/print/${userDetail?.user?.id}`;
  const registrationNo = userDetail?.userDetail?.user?.registration_no;
  const formattedBirthDate = user?.user?.date_of_birth
    ? (() => {
        const date = new Date(user?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: true,
  });
  const patientIdDataUrl = patientIdBarcode.toDataURL();

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }
    const days = Math.floor(hours || 0 / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const receivingDate = user?.user_payment?.createdAt;
  const preparationDuration =
    user?.user?.role_id !== 5
      ? user?.package?.preparation_duration ?? 0
      : user?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const getActiveInvestigationTests = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/reports/get-data/3`,
        {
          invIds: user?.package?.investigation_id.split(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setInvestigations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { comments } = response.data.data;

      setPhysicalRiskFactor(comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUserDetail(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  useEffect(() => {
    if (physicalData.length <= 0) {
      investigations.forEach((investigation) => {
        setPhysicalReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: investigation?.id,
              test_id: investigation?.investigation_tests[0]?.id,
              user_file: "",
              result: "",
            },
          ];

          return [...previousReport];
        });
      });
    }
  }, [investigations, physicalData, userId]);

  useEffect(() => {
    setPhysicalReportData([]);

    if (physicalData.length > 0) {
      physicalData.forEach((physicalTest) => {
        setPhysicalReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: physicalTest?.investigation_id,
              test_id: physicalTest?.test_id,
              user_file: "",
              result: physicalTest?.result?.replace(/<[^>]*>/g, ""),
            },
          ];

          return [...previousReport];
        });
      });
    }
  }, [userId, physicalData]);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  // Get user, Introducer, and Representative---------------------
  const [representative, setRepresentative] = useState();
  useEffect(() => {
    userDetail && setUser(userDetail.userDetail);
    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            }
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userDetail]);

  useEffect(() => {
    getUserDetail();
    getPhysicalTests();
    getRadiologyTests();
    getComment();
  }, [userId]);

  useEffect(() => {
    setPhysicalComment((previousComment) => {
      return { ...previousComment, user_id: userId, comments: "" };
    });
  }, [userId]);

  useEffect(() => {
    user && getActiveInvestigationTests();
  }, [user]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        console.log(item?.user_file);
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  return (
    <>
      <div className="row align-items-center mt-4">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <section
        className="held-up-certificate px-3 mx-2"
        ref={pdfRef}
        style={{ fontSize: "12px" }}
      >
        <div className="">
          <div className="row">
            <h4
              className="fw-bold fs-5 text-center mt-2"
              style={{ color: "#000" }}
            >
              MEDICAL PHYSICAL REPORT
            </h4>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-sm-5 h-100">
              <div
                className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center"
                style={{ height: "90px" }}
              >
                <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                  DATE: {formatDeliveryTime(newDate, "dd-MM-yyyy hh:mm a")}
                </p>
                <img
                  src={patientIdDataUrl}
                  alt=""
                  className="w-100 img-fluid"
                  style={{
                    height: "60px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                />
              </div>
            </div>
            <>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {user?.user?.image ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.image}`}
                      className="img-fluid"
                      alt="patient"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <img
                      src="./dummy.jpg"
                      alt="patient"
                      className="img-fluid"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {user?.user?.finger_print ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.user?.finger_print}`}
                      className="img-fluid"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <div
                      className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      No Fingerprint Found
                    </div>
                  )}
                </div>
              </div>
            </>

            {/* <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-start-0 border-dark"
                style={{ height: "90px", width: "100%", overflow: "hidden" }}
              >
                <table
                  className="table mt-sm-0 mb-0 border border-top-0 border-bottom-0 border-end-0 border-dark"
                  id="xrayImage"
                  style={{
                    height: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: 0,
                          height: "100%",
                          width: "100%",
                          border: "0",
                          borderTop: "0",
                        }}
                      >
                        {xrayImage ? (
                          <img
                            src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                            alt="X-Ray film"
                            className="img-fluid"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          user?.user?.role_id !== 5 && (
                            <div
                              className="text-center d-flex align-items-center justify-content-center fw-bold"
                              style={{
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              No X-Ray film found
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                X-RAY IMAGE
              </p>
            </div> */}
            <div className="col-sm-3 h-100">
              <div
                className="d-flex justify-content-center align-items-center w-100 border border-dark"
                style={{ height: "90px" }}
              >
                <Link to={urlWithBase64ID}>
                  <QRCode value={urlWithBase64ID} size={85} />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="col-sm-12">
              <div className="row">
                <table
                  className="responsive-width1 table-bordered"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    {/* Row 1 - Name and Client ID */}
                    <tr>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Name
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {user?.user?.f_name || "---"}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Client ID
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {user?.user?.registration_no || "---"}
                      </td>
                    </tr>

                    {/* Row 2 - Gender and Passport No */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Gender
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.gender?.name || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Passport No
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.passport_no || "---"}
                      </td>
                    </tr>

                    {/* Row 3 - Date of Birth and Travelling To */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.role_id !== 5 ? `Date of Birth` : `Age`}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.role_id !== 5
                          ? formattedBirthDate || "---"
                          : user?.user?.age || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Travelling To
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.country?.name || "---"}
                      </td>
                    </tr>

                    {/* Row 4 - Father Name and Contact Number */}
                    {user?.user?.role_id !== 5 && (
                      <tr>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Father Name
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {user?.father_name || "---"}
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Contact Number
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {user?.user?.mobile || "---"}
                        </td>
                      </tr>
                    )}

                    {/* Row 5 - Nationality and Agency */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Nationality
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.national_country?.nationality || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {user?.user?.role_id !== 5
                          ? `${settingsData?.introducer_label}`
                          : `Reference`}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        colSpan={3}
                      >
                        {user?.user?.role_id !== 5
                          ? user?.introducer_user?.f_name || "---"
                          : user?.user?.reference || "---"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* <p
                  className="below-table w-100 p-1 mb-1 text-dark"
                  style={{ position: "relative", bottom: "12px" }}
                >
                  History of past ilness: 1) Allergy: ABSENT 2) Psychiatric and
                  neurological disorders (Epilepsy, depression): ABSENT 3)
                  Others: NAD
                </p>
                <p
                  className="below-table w-100 p-1 m-0 border border-dark text-dark"
                  style={{ position: "relative", bottom: "12px" }}
                >
                  I hereby permit the {settingsData?.website_name} and the
                  undersigned physician to furnish such information the company
                  many need pertaining to my health status and pertinent and
                  medical findings and do hereby release them from any and all
                  legal responsibility by doing so. I also certify that my
                  medical history contained above is true and any false
                  statement will disqualify me from my employment, benefits and
                  claim.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-3"
          style={{
            color: "black",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {physicalData && physicalData?.length > 0 ? (
            <table className="table border border-dark mb-0 fw-bold">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-center"
                    style={{ color: "black" }}
                    colSpan={3}
                  >
                    Medical Examination
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={1}
                    className="border text-center py-1 border border-dark text-nowrap"
                  >
                    TYPE OF EXAMINATION
                  </th>
                  <th className="border text-center border py-1 border-dark text-nowrap">
                    RESULTS
                  </th>
                  <th className="border text-center border py-1 border-dark text-nowrap">
                    REF. VALUE
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {Object.entries(groupedData)?.map(
                  ([categoryId, categoryData]) => (
                    <React.Fragment key={categoryId}>
                      <tr>
                        <td colSpan="3" className="text-nowrap text-uppercase">
                          {categoryData?.name}
                        </td>
                      </tr>
                      {categoryData?.items?.map((data, index) => {
                        // Log the data object for debugging
                        return (
                          <tr key={index}>
                            <td className="border text-start py-1 ps-5 border-dark text-dark">
                              {data?.investigation?.name || "---"}
                            </td>
                            <td className="border text-start py-1 border-dark text-dark">
                              {data?.result || "---"}{" "}
                              {data?.result &&
                                data?.result !== "N/A" &&
                                data?.investigation?.investigation_tests[0]?.uom
                                  ?.name}
                            </td>
                            <td className="border text-start py-1 border-dark text-dark">
                              {data?.investigation?.investigation_tests[0]
                                ?.report_normal_value || ""}
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <div className="text-center border border-dark w-50 mx-auto">
              No Data Available
            </div>
          )}
        </div>
        <div className="mt-2">
          <div className="d-flex w-100 mt-0 justify-content-between">
            <div className="col-sm-6 text-dark">
              <div
                className="border-top mt-5 border-dark"
                style={{ width: "200px" }}
              ></div>
              CHECKED BY
            </div>
            <div className=" mt-4 text-dark">
              <div className="text-start text-dark p-0 mt-1">
                Printed By: {user?.user?.created_by}
              </div>
              <p className="p-0 m-0">
                {settingsData?.copyright} {settingsData?.website_name}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end gap-2 mt-2 unfit-button p-2">
        {accessPerm(68, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default PrintMedicalPhysical;
