import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./Prescription.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import JsBarcode from "jsbarcode";
import { UserContext } from "../../Context/UserContextAPI";
import { add, format, isValid } from "date-fns";

function PrintPrescription() {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;
  const staticURL = `${process.env.REACT_APP_API_BASE_URL}/static-contents`;
  const investigationURL = `${process.env.REACT_APP_API_BASE_URL}/investigation`;
  const prescriptionURL = `${process.env.REACT_APP_API_BASE_URL}/prescription`;
  const medicineURL = `${process.env.REACT_APP_API_BASE_URL}/medicine`;

  const [addressData, setAddressData] = useState({});
  const [staticData, setStaticData] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [drugGroups, setDrugGroups] = useState([]);
  const [investigationData, setInvestigationData] = useState([]);
  const [medicines, setMedicines] = useState([1]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [userDetail, setUserDetail] = useState();
  const [user, setUser] = useState();
  const [physicalTests, setPhysicalTests] = useState([]);
  const [pathologyTests, setPathologyTests] = useState([]);
  const [pathologyReportData, setPathologyReportData] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [defaultDate, setDefaultDate] = useState();
  const registrationNo = userDetail?.user?.registration_no;

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: false,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();
  const urlWithBase64ID = `https://${process.env.REACT_APP_API_BASE_URL}/medical-verification/result/report/print/${userDetail?.user?.id}`;

  const formattedBirthDate = user?.date_of_birth
    ? (() => {
        const date = new Date(user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {}
  }

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }
    const days = Math.floor(hours || 0 / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };
  const receivingDate = userDetail?.user_payment?.createdAt;
  const preparationDuration =
    user?.role_id !== 5
      ? userDetail?.package?.preparation_duration ?? 0
      : userDetail?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);
  const [radiologyData, setRadiologyData] = useState([]);
  const [xrayImage, setXrayImage] = useState("");

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { accessPerm } = useContext(UserContext);
  const { userId } = useParams();

  const [medicineForm, setMedicineForm] = useState([
    {
      user_id: userId,
      prescription_id: "",
      drug_id: "",
      rules_id: "",
      duration_id: "",
    },
  ]);

  const [prescriptionForm, setPrescriptionForm] = useState({
    user_id: userId,
    diagnosis_ids: [],
    complaint_ids: [],
    investigation_ids: [],
    advice_ids: [],
    next_visit_date: "",
  });

  let pathologyStatus = "";
  let physicalStatus = "";
  let pathologyRiskFactor = "";
  let physicalRiskFactor = "";

  // const pathologyStatusCheckHandler = (reports) => {
  //   reports?.forEach((report) => {
  //     // check hemoglobin status
  //     if (report.investigation_id === 15) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 16) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       } else if (report.result < 11.8) {
  //         pathologyStatus += "Hemoglobin: " + report?.result + ", ";
  //       }
  //     }

  //     // check R.B.S status
  //     if (report.investigation_id === 16) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 7.5) {
  //         pathologyStatus += "R.B.S.: " + report?.result + ", ";
  //       } else if (report.result > 7.5 && report.result <= 9) {
  //         pathologyRiskFactor += "30% Risk for Diabetes, ";
  //       } else if (report.result > 9 && report.result <= 12) {
  //         pathologyRiskFactor += "20% Risk for Diabetes, ";
  //       } else if (report.result > 12 && report.result <= 15) {
  //         pathologyRiskFactor += "40% Risk for Diabetes, ";
  //       } else if (report.result > 15 && report.result <= 18) {
  //         pathologyRiskFactor += "50% Risk for Diabetes, ";
  //       } else if (report.result > 18 && report.result <= 25) {
  //         pathologyRiskFactor += "75% Risk for Diabetes, ";
  //       } else if (report?.result > 25) {
  //         pathologyRiskFactor += "90~100% Risk for Diabetes, ";
  //       }
  //     }

  //     // check S. Creatinine status
  //     if (report.investigation_id === 17) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 1.3) {
  //         pathologyStatus += "S. Creatinine: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 2) {
  //         pathologyRiskFactor += "35% Risk for S. Creatinine, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "60% Risk for S. Creatinine, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for S. Creatinine, ";
  //       }
  //     }

  //     // check S.Bilirubin status
  //     if (report.investigation_id === 20) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 0.99) {
  //         pathologyStatus += "S.Bilirubin: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 1.6) {
  //         pathologyRiskFactor += "35% Risk for Jaundice, ";
  //       } else if (report.result > 1.6 && report.result <= 2) {
  //         pathologyRiskFactor += "50% Risk for Jaundice, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         pathologyRiskFactor += "75% Risk for Jaundice, ";
  //       } else if (report.result > 3) {
  //         pathologyRiskFactor += "90~100% Risk for Jaundice, ";
  //       }
  //     }

  //     // check Malarial Parasite status
  //     if (report.investigation_id === 25) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Found") {
  //         pathologyStatus += "Malarial Parasite: Found, ";
  //       }
  //     }

  //     // check SGPT status
  //     if (report.investigation_id === 23) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 41) {
  //         pathologyStatus += "SGPT: " + report?.result + ", ";
  //       } else if (report.result > 60 && report.result <= 80) {
  //         pathologyRiskFactor += "20% Risk for SGPT, ";
  //       } else if (report.result > 80 && report.result <= 100) {
  //         pathologyRiskFactor += "25% Risk for SGPT, ";
  //       } else if (report.result > 100 && report.result <= 140) {
  //         pathologyRiskFactor += "40% Risk for SGPT, ";
  //       } else if (report.result > 140 && report.result <= 200) {
  //         pathologyRiskFactor += "75% Risk for SGPT, ";
  //       } else if (report.result > 200) {
  //         pathologyRiskFactor += "90~100% Risk for SGPT, ";
  //       }
  //     }

  //     // check SGOT status
  //     if (report.investigation_id === 24) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 38) {
  //         pathologyStatus += "SGOT: " + report?.result + ", ";
  //       }
  //     }

  //     // check C.U. Sugar status
  //     if (report.investigation_id === 21) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > "NIL") {
  //         pathologyStatus += "C.U. Sugar: Nill, ";
  //       }
  //     }

  //     // check HBsAg status
  //     if (report.investigation_id === 26) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HBsAg: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyRiskFactor += "100% Risk for Blood(HBsAg), ";
  //       }
  //     }

  //     // check VDRL status
  //     if (report.investigation_id === 27) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //         pathologyRiskFactor += "75% Risk for Blood(VDRL), ";
  //       } else if (report.result === "W. Reactive") {
  //         pathologyStatus += "VDRL: " + report?.result + ", ";
  //       }
  //     }

  //     // check TPHA status
  //     if (report.investigation_id === 28) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "TPHA: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(TPHA), ";
  //       }
  //     }

  //     // check Anti-HCV status
  //     if (report.investigation_id === 29) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "Anti-HCV: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(Anti-HCV), ";
  //       }
  //     }

  //     // check HIV 1 - 2 status
  //     if (report.investigation_id === 30) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         pathologyStatus += "HIV 1 - 2: " + report?.result + ", ";
  //         pathologyRiskFactor += "100% Risk for Blood(HIV), ";
  //       }
  //     }

  //     // check pregnancy status
  //     if (report.investigation_id === 22) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (
  //         report.result === "W. Positive" ||
  //         report.result === "Positive"
  //       ) {
  //         pathologyStatus += "Pregnancy: " + report?.result + ", ";
  //       }
  //     }
  //   });
  // };

  const physicalStatusCheckHandler = (reports) => {
    reports.forEach((report) => {
      // check left eye status
      if (report.investigation_id === 7) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "LEFT EYE: Squint, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "LEFT EYE: Blind, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "LEFT EYE: Dimness of Vision, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "LEFT EYE: Color of Vision, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for LEFT EYE, ";
        }
      }

      // check right eye status
      if (report.investigation_id === 8) {
        if (report.result === "6/6") {
        } else if (report.result === "Squint") {
          physicalStatus += "RIGHT EYE: Squint, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Blind") {
          physicalStatus += "RIGHT EYE: Blind, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Dimness of Vision") {
          physicalStatus += "RIGHT EYE: Dimness of Vision, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        } else if (report.result === "Color of Vision") {
          physicalStatus += "RIGHT EYE: Color of Vision, ";
          physicalRiskFactor = physicalRiskFactor + "100% Risk for RIGHT EYE, ";
        }
      }

      // check left ear status
      if (report.investigation_id === 9) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "LEFT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "LEFT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for LEFT EAR, ";
        }
      }

      // check right ear status
      if (report.investigation_id === 10) {
        if (report?.result === "Normal") {
        } else if (
          report.result === "" ||
          report.result === "N/A" ||
          report.result === "---"
        ) {
        } else if (report?.result === "Short of Hearing") {
          physicalStatus += "RIGHT EAR: Short of Hearing, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        } else if (report?.result === "Deaf") {
          physicalStatus += "RIGHT EAR: Deaf, ";
          physicalRiskFactor =
            physicalRiskFactor + "30~100% Risk for RIGHT EAR, ";
        }
      }

      // check hernia status
      if (report.investigation_id === 11) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hernia: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check hydrocele status
      if (report.investigation_id === 12) {
        if (report.result === "Absent") {
        } else if (report?.result === "Present") {
          physicalStatus += "Hydrocele: Present, ";
          physicalRiskFactor =
            physicalRiskFactor + "Operation case for Hernia, ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          }
        }
      }

      // check BP status
      if (report.investigation_id === 3) {
        const bpAValue = parseInt(report.result?.split("/")[0]);
        const bpBValue = parseInt(report.result?.split("/")[1]);

        if (
          bpAValue > 130 ||
          bpAValue < 100 ||
          bpBValue > 85 ||
          bpBValue < 60
        ) {
          physicalStatus += "Blood Pressure: " + report.result + ", ";
        }
      }

      // check pulse status
      if (report.investigation_id === 4) {
        const pulseValue = parseInt(report.result);

        if (pulseValue > 100 || pulseValue < 60) {
          physicalStatus += "Pulse: " + report.result + ", ";
        } else {
          if (
            report.result === "" ||
            report.result === null ||
            report.result === undefined
          ) {
          } else {
            // riskFactor = riskFactor + "Pulse: 100%, ";
          }
        }
      }
    });
  };

  const getUserDetail = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user-details/${userId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setUserDetail(response.data.data?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStaticContentData = async () => {
    await axios
      .get(staticURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStaticData(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchAddressData = async () => {
    await axios
      .get(settingURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response.data.data[0];
        setAddressData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPatientInfo = async () => {
    try {
      const prescriptionResponse = await axios.get(
        `${prescriptionURL}/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const prescriptionData = prescriptionResponse.data.data;

      const medicineResponse = await axios.get(`${medicineURL}/${userId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const medicineData = medicineResponse.data.data;

      const diagnosisIdsArray = prescriptionData?.diagnosis_id
        ? prescriptionData.diagnosis_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedDiagnosis = diagnosisIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));
      const complaintIdsArray = prescriptionData?.complaint_id
        ? prescriptionData.complaint_id.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedComplaint = complaintIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const investigationIdsArray = prescriptionData?.investigation_ids
        ? prescriptionData.investigation_ids
            .split(",")
            .map((id) => parseInt(id, 10))
        : [];

      const selectedInvestigation = investigationIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      const adviceIdsArray = prescriptionData?.advice_ids
        ? prescriptionData.advice_ids.split(",").map((id) => parseInt(id, 10))
        : [];

      const selectedAdvice = adviceIdsArray.map((id) => ({
        value: id,
        label: staticData.find((data) => data.id === id)?.name || "",
        key: `${id}`,
      }));

      setPrescriptionForm((prevForm) => ({
        ...prevForm,
        diagnosis_ids: selectedDiagnosis.map((item) => item.value),
        complaint_ids: selectedComplaint.map((item) => item.value),
        investigation_ids: selectedInvestigation.map((item) => item.value),
        advice_ids: selectedAdvice.map((item) => item.value),
        next_visit_date: prescriptionData?.next_visit_date || "",
      }));
      setSelectedDiagnosis(selectedDiagnosis);

      setPrescriptionData(prescriptionData);
      setMedicineForm(medicineData);
    } catch (error) {
      console.error(error);
    }
  };

  const options = drugs.map((drug) => ({
    value: drug?.id,
    label: drug?.name,
  }));

  const fetchDrugsAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drugs/all-drugs`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data.data;
    setDrugs(data);
  };

  const fetchDrugsGroupAPI = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/drug-groups/all-groups`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data.data;
    setDrugGroups(data);
  };

  const fetchInvestigationData = async () => {
    await axios
      .get(investigationURL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => setInvestigationData(response.data.data))
      .catch((err) => console.log(err));
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPhysicalTests(response?.data?.data);
      physicalStatusCheckHandler(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Radiology Tests Data:", response.data);
      setRadiologyTests(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyTests = async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${2}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response?.data);
        setPathologyTests(response?.data);
        // pathologyStatusCheckHandler(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getPathologyTestData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/pathology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPathologyReportData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetail();
    getPhysicalTests();
    getRadiologyTests();
    getPathologyTestData();
    getPathologyTests();
  }, [userId]);

  useEffect(() => {
    setMedicines(medicines);
    fetchDrugsAPI();
    fetchDrugsGroupAPI();
    fetchAddressData();
    fetchStaticContentData();
    fetchInvestigationData();
    fetchPatientInfo();
    // handlePrint();
  }, []);

  useEffect(() => {
    userDetail && setUser(userDetail?.user);
  }, [userDetail]);

  useEffect(() => {
    if (
      prescriptionForm?.next_visit_date &&
      isValid(new Date(prescriptionForm.next_visit_date))
    ) {
      setDefaultDate(prescriptionForm.next_visit_date);
    } else {
      const today = new Date();
      const sevenDaysLater = add(today, { days: 7 });
      const formattedDate = format(sevenDaysLater, "yyyy-MM-dd");
      setDefaultDate(formattedDate);
    }
  }, [prescriptionForm?.next_visit_date]);

  const radiologyFindings = radiologyTests.find(
    (item) => item?.investigation_test?.name === "Findings"
  )?.result;

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  // pathologyStatusCheckHandler(pathologyReportData);
  physicalStatusCheckHandler(pathologyTests);
  return (
    <>
      <div className="prescription-info d-flex mx-auto justify-content-center">
        <div className="header-image">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${addressData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
        <div
          className="d-flex flex-column mx-auto"
          style={{
            // width: "1500px",
            width: "100%",
            overflowX: "scroll",
            fontWeight: "bolder",
            overflow: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="mt-2 p-3 title-top">
            <div className="row">
              <h4
                className="fw-bold fs-4 text-center"
                style={{ color: "#000" }}
              >
                Prescription
              </h4>
            </div>
            <div className="row justify-content-center h-100">
              <>
                <div className="col-sm-2 h-100">
                  <div
                    className="pres-photo border border-end-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {user?.image ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.image}`}
                        className="img-fluid"
                        alt="patient"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <img
                        src="./dummy.jpg"
                        alt="patient"
                        className="img-fluid"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-end-0 border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    PHOTO
                  </p>
                </div>
                <div className="col-sm-8 h-100">
                  <div className="row">
                    <table
                      className="responsive-width1 table-bordered"
                      style={{
                        width: "100%",
                        height: "114px",
                        borderCollapse: "collapse",
                        textAlign: "left",
                      }}
                    >
                      <tbody>
                        {/* Row 1 - Name and Client ID */}
                        <tr>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Name
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {user?.f_name || "---"}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            Client ID
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            style={{ width: "25%" }}
                          >
                            {user?.registration_no || "---"}
                          </td>
                        </tr>

                        {/* Row 2 - Gender and Passport No */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Gender
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {userDetail?.gender?.name || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Passport No
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.passport_no || "---"}
                          </td>
                        </tr>

                        {/* Row 3 - Date of Birth and Travelling To */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.role_id !== 5 ? `Date of Birth` : `Age`}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.role_id !== 5
                              ? formattedBirthDate || "---"
                              : user?.age || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Travelling To
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {userDetail?.country?.name || "---"}
                          </td>
                        </tr>

                        {/* Row 4 - Father Name and Contact Number */}
                        {user?.role_id !== 5 && (
                          <tr>
                            <td className="text-uppercase border border-dark ps-1 align-middle">
                              Father Name
                            </td>
                            <td className="text-uppercase border border-dark ps-1 align-middle">
                              {userDetail?.father_name || "---"}
                            </td>
                            <td className="text-uppercase border border-dark ps-1 align-middle">
                              Contact Number
                            </td>
                            <td className="text-uppercase border border-dark ps-1 align-middle">
                              {user?.mobile || "---"}
                            </td>
                          </tr>
                        )}

                        {/* Row 5 - Nationality and Agency */}
                        <tr>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            Nationality
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {userDetail?.national_country?.nationality || "---"}
                          </td>
                          <td className="text-uppercase border border-dark ps-1 align-middle">
                            {user?.role_id !== 5
                              ? `${addressData?.introducer_label}`
                              : `Reference`}
                          </td>
                          <td
                            className="text-uppercase border border-dark ps-1 align-middle"
                            colSpan={3}
                          >
                            {user?.role_id !== 5
                              ? userDetail?.introducer_user?.f_name || "---"
                              : user?.reference || "---"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-2 h-100">
                  <div
                    className="pres-photo border border-start-0 border-dark"
                    style={{ width: "100%", height: "114px" }}
                  >
                    {user?.finger_print ? (
                      <img
                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.finger_print}`}
                        className="img-fluid"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div
                        className="p-0 border border-start-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      >
                        No Fingerprint Found
                      </div>
                    )}
                  </div>
                  <p
                    className="pres-title p-0 m-0 w-100 border border-top-0 border-start-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                    style={{ height: "29px" }}
                  >
                    THUMB PRINT
                  </p>
                </div>
              </>
            </div>
          </div>
          {/* <div className="row justify-content-center">
            {user?.role_id !== 5 && (
              <div className="col-sm-2 p-1 h-100">
                <div className="invoice-img d-flex gap-1 flex-column border border-dark w-100">
                  {user?.image ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.image}`}
                      style={{ objectFit: "fill" }}
                      alt=""
                    />
                  ) : (
                    <img
                      src="./dummy.jpg"
                      alt="patient"
                      className="img-fluid"
                    />
                  )}
                </div>
                <div className=" mt-1">
                  <img
                    src={patientIdDataUrl}
                    alt=""
                    style={{ height: "80px", width: "100%" }}
                  />
                </div>
              </div>
            )}
            <div className="col-sm-8 p-1">
              <table
                className="w-100 table-bordered border-dark mb-0 text-dark"
                style={{ height: "100%" }}
              >
                <tbody>
                  <tr>
                    <td className="text-uppercase">
                      <span className="ps-1">Id no: </span>
                      <span className="ps-1 fw-bold">
                        {user?.registration_no || "---"}
                      </span>
                    </td>
                    <td className="text-uppercase">
                      <span className="ps-1">Reg no: </span>
                      <span className="ps-1">
                        {user?.registration_no || "---"}
                      </span>
                    </td>
                    {user?.role_id !== 5 ? (
                      <td colSpan={2} className="text-uppercase">
                        <span className="text-uppercase ps-1">
                          Passport no:{" "}
                        </span>
                        <span className="text-uppercase ps-1">
                          {user?.passport_no || "---"}
                        </span>
                      </td>
                    ) : (
                      <td colSpan={2} className="text-uppercase">
                        <span className="text-uppercase ps-1"></span>
                        <span className="text-uppercase ps-1"></span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    {user?.role_id !== 5 && (
                      <>
                        <td className="text-uppercase ps-1">Country</td>
                        <td className="text-uppercase ps-1">
                          {userDetail?.country?.name || "---"}
                        </td>
                      </>
                    )}
                    <td className="text-uppercase ps-1">Medical date</td>
                    <td
                      className="text-uppercase ps-1"
                      colSpan={user?.role_id === 5 ? 3 : undefined}
                    >
                      {formattedMedicalDate || "---"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-uppercase ps-1">Name</td>
                    <td className="text-uppercase ps-1" colSpan={3}>
                      {user?.f_name || "---"}
                    </td>
                  </tr>
                  {user?.role_id !== 5 && (
                    <tr>
                      <td className="text-uppercase ps-1">Father name</td>
                      <td className="text-uppercase ps-1" colSpan={3}>
                        {userDetail?.father_name || "---"}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-uppercase ps-1">
                      {user?.role_id !== 5 ? `Date of birth` : `Age`}
                    </td>
                    <td className="text-uppercase ps-1">
                      {user?.role_id !== 5
                        ? formattedBirthDate || "---"
                        : user?.age || "---"}
                    </td>
                    <td className="text-uppercase ps-1">Gender</td>
                    <td className="text-uppercase ps-1">
                      {userDetail?.gender?.name || "---"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-uppercase ps-1">
                      {user?.role_id !== 5 ? `Agency` : `Reference`}
                    </td>
                    <td className="text-uppercase ps-1" colSpan={3}>
                      {user?.role_id !== 5
                        ? userDetail?.introducer_user?.f_name || "---"
                        : user?.reference || "---"}
                    </td>
                  </tr>
                  {userDetail?.company_user && (
                    <tr>
                      <td className="text-uppercase ps-1">Company</td>
                      <td className="text-uppercase ps-1" colSpan={3}>
                        {userDetail?.company_user?.f_name || "---"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {user?.role_id !== 5 && (
              <div className="col-sm-2 p-1 h-100">
                <div className="finger-img  d-flex flex-column h-100 border border-dark">
                  {user?.finger_print ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${user?.finger_print}`}
                      alt=""
                      className="finger-img"
                    />
                  ) : (
                    <div className="p-2 border text-center text-dark finger-img d-flex align-items-center justify-content-center">
                      No Fingerprint Found
                    </div>
                  )}
                  <p className="p-0 m-0 border border-1 border-dark text-center fw-bold w-100 d-flex align-items-center justify-content-center text-dark">
                    Finger Print
                  </p>
                </div>
              </div>
            )}
          </div> */}

          <div className="row mt-0 d-flex align-items-start justify-content-between p-3 text-dark">
            <div className="col-5 prescription-only-print text-dark">
              <div>
                <label className="form-label fw-bold mb-0 text-dark border-dark border-bottom w-100 d-block">
                  Diagnosis
                </label>
                <div className="p-1">
                  {physicalStatus && (
                    <div>
                      Physical: {physicalStatus}
                      {physicalStatus && <br />}
                    </div>
                  )}
                  <div>
                    {pathologyStatus && (
                      <span>
                        Pathology: {pathologyStatus}
                        {radiologyFindings && <br />}
                      </span>
                    )}
                  </div>
                  {radiologyFindings && (
                    <div>Radiology: {radiologyFindings}</div>
                  )}
                </div>
              </div>
              <div>
                <label className="form-label fw-bold mb-0 text-dark border-dark border-bottom w-100 d-block">
                  Complaints
                </label>
                <ul className="pt-1 d-flex flex-wrap gap-3">
                  {prescriptionForm.complaint_ids.map((id) => (
                    <li
                      key={id}
                      className="list-item"
                      style={{ paddingRight: "8px" }}
                    >
                      {staticData.find((data) => data?.id === id)?.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <label className="form-label fw-bold mb-0 text-dark border-dark border-bottom w-100 d-block">
                  Investigations
                </label>
                <ul className="pt-1 d-flex flex-wrap gap-3">
                  {prescriptionForm.investigation_ids.map((id) => (
                    <li
                      key={id}
                      className="list-item"
                      style={{ paddingRight: "8px" }}
                    >
                      {investigationData.find((data) => data?.id === id)?.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <label className="form-label fw-bold mb-0 text-dark border-dark border-bottom w-100 d-block">
                  Advices
                </label>
                <ul className="pt-1 d-flex flex-wrap gap-3">
                  {prescriptionForm.advice_ids.map((id) => (
                    <li
                      key={id}
                      className="list-item"
                      style={{ paddingRight: "8px" }}
                    >
                      {staticData.find((data) => data?.id === id)?.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <Form.Label className="form-label fw-bold mb-0 text-dark border-dark border-bottom w-100 d-block">
                  Next Visits
                </Form.Label>
                <ul className="pt-1">
                  <li>
                    <div className="next-visit-date">
                      Next Visit Date:{" "}
                      {defaultDate &&
                        format(new Date(defaultDate), "dd-MM-yyyy")}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 d-flex flex-column justify-content-end medicine-only-print">
              <div className="text-dark fw-bold fs-6">
                R <sub>x</sub>
              </div>

              <ol className="mt-1 w-100">
                {medicineForm.map((medicine, index) => {
                  const selectedOption = options.find(
                    (option) => option.value === medicine.drug_id
                  );

                  return (
                    <li key={index} style={{ pageBreakInside: "avoid" }}>
                      {/* Medicine Name */}
                      <strong className="text-uppercase">
                        {selectedOption?.label}
                      </strong>
                      <br />
                      {/* Rules and Duration */}
                      <span className="text-uppercase static-content-data fw-bold">
                        {
                          staticData.find(
                            (data) => data?.id === medicine.rules_id
                          )?.name
                        }
                        {" ------------------- "}
                        {
                          staticData.find(
                            (data) => data?.id === medicine.duration_id
                          )?.name
                        }
                      </span>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
          <div className="prescription-signature mt-2 text-dark">
            <hr className="signature-line p-0 m-0" />
            <b className="signature-officer">Medical Officer</b>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-4 fit-button">
          {accessPerm(68, 23) && (
            <button className="btn btn-primary" onClick={() => window.print()}>
              Print
            </button>
          )}

          <button
            className="btn btn-outline-secondary"
            onClick={() => window.close()}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
}

export default PrintPrescription;
