import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import axios from "axios";

const ProfilePreviewPage = () => {
  const settingURL = `${process.env.REACT_APP_API_BASE_URL}/settings`;

  const { currentUser } = useContext(UserContext);
  const [data, setData] = useState({});

  const getData = () => {
    axios
      .get(settingURL)
      .then((response) => {
        const allData = response.data.data[0];
        setData(allData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let userRecords = currentUser;

  const location = useLocation();

  if (location?.state?.data) {
    userRecords = location?.state?.data;
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase text-dark">User's Profile</h6>
              <div className="col">
                {/* Create User trigger modal Button */}
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="card shadow-sm border-0 overflow-hidden">
                  <div className="card-body p-2">
                    <div className="profile-avatar text-center">
                      {userRecords?.user?.image === null ? (
                        <img
                          src="/userimg.png"
                          className="rounded-circle shadow"
                          width={120}
                          height={120}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_UPLOAD_URL}/users/${
                            userRecords?.image
                              ? userRecords?.image
                              : userRecords?.user?.image
                          }`}
                          className="rounded-circle shadow"
                          width={120}
                          height={120}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="text-center mt-4 text-dark">
                      <h6 className="mb-1">
                        Reg No.{" "}
                        {userRecords?.registration_no
                          ? userRecords?.registration_no
                          : userRecords?.user?.registration_no}
                      </h6>
                      <h4 className="mb-1">
                        {userRecords?.f_name
                          ? userRecords?.f_name
                          : userRecords?.user?.f_name}
                        {userRecords?.l_name
                          ? userRecords?.l_name
                          : userRecords?.user?.l_name}
                      </h4>
                      <h6 className="mb-1">
                        Email:{" "}
                        {userRecords?.email
                          ? userRecords?.email
                          : userRecords?.user?.email}
                      </h6>
                      <h6 className="mb-3">
                        Mobile:{" "}
                        {userRecords?.mobile
                          ? userRecords?.mobile
                          : userRecords?.user?.mobile}
                      </h6>
                      <h6 className="mb-3">
                        Date Of Birth:{" "}
                        {userRecords?.date_of_birth
                          ? userRecords?.date_of_birth
                          : userRecords?.user?.date_of_birth}
                      </h6>
                      <p className="mb-0 text-secondary">
                        {userRecords?.address_1
                          ? userRecords?.address_1
                          : userRecords?.user?.address_1}
                      </p>
                      <p className="mb-0 text-secondary">
                        {userRecords?.address_2
                          ? userRecords?.address_2
                          : userRecords?.user?.address_2}
                      </p>
                      <div className="mt-4" />
                      <h6 className="mb-1">
                        {userRecords?.role?.name
                          ? userRecords?.role?.name
                          : userRecords?.user?.role?.name}
                      </h6>
                      <p className="mb-0 text-secondary text-dark">
                        {data?.short_name}
                      </p>
                    </div>
                    <ul className="list-group list-group-flush mt-4">
                      <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
                        Contact Person
                        <span className="badge bg-light text-dark">
                          {userRecords?.contact_person
                            ? userRecords?.contact_person
                            : userRecords?.user?.contact_person}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
                        NID
                        <span className="badge bg-light text-dark">
                          {userRecords?.nid
                            ? userRecords?.nid
                            : userRecords?.user?.nid}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
                        Status
                        <span className="badge bg-primary rounded-pill">
                          {userRecords?.status
                            ? userRecords?.status
                            : userRecords?.user?.status}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                        Credit Limit
                        <span className="badge bg-primary rounded-pill">
                          BDT{" "}
                          {`${
                            userRecords?.credit_limit === undefined ||
                            userRecords?.user?.credit_limit === undefined
                              ? 0
                              : userRecords?.credit_limit ||
                                userRecords?.user?.credit_limit
                          }`}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                        Commission Rate
                        <span className="badge bg-primary rounded-pill">
                          {userRecords?.commission_rate
                            ? userRecords?.commission_rate
                            : userRecords?.user?.commission_rate}
                          %
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                        Wallet
                        <span className="badge bg-primary rounded-pill">
                          {userRecords?.wallet
                            ? userRecords?.wallet
                            : userRecords?.user?.wallet}
                        </span>
                      </li>
                    </ul>
                    <hr />
                    <div className="text-start">
                      <h5 className="text-dark">Remarks</h5>
                      <p className="mb-0">
                        {userRecords?.remarks
                          ? userRecords?.remarks
                          : userRecords?.user?.remarks}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePreviewPage;
