import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { UserContext } from "../../../Context/UserContextAPI";

const Department = () => {
  const departmentURL = `${process.env.REACT_APP_API_BASE_URL}/department`;
  const { accessPerm } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [form, setForm] = useState({
    name: "",
    info: "",
    status: "1",
  });

  const createData = (e) => {
    e.preventDefault();
    axios
      .post(departmentURL, form, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        fetchData();
        toast.success("Department Created successfully!!");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const updateData = (e, id) => {
    e.preventDefault();
    const updatedForm = {
      ...form,
      status: isChecked ? "1" : "0",
    };
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/department/${id}`,
        updatedForm,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        fetchData();
        toast.success("Department Updated successfully!!");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handlerEdit = (data) => {
    setForm({
      name: data?.name,
      info: data?.info,
      status: data?.status ? "1" : "0",
    });
    setIsChecked(data?.status === 1);
  };

  const deleteData = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/department/${id}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        fetchData();
        toast.success("Department Deleted successfully!!");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const onchange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheck = () => {
    setIsChecked(!isChecked);
    setForm((prevForm) => ({
      ...prevForm,
      status: !isChecked ? "1" : "0",
    }));
  };

  const fetchData = () => {
    axios
      .get(`${departmentURL}/all`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((response) => setData(response?.data?.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [form]);

  const columns = [
    {
      key: "name",
      text: "Name",
      align: "center",
      sortable: true,
    },
    {
      key: "info",
      text: "Info",
      className: "info",
      align: "center",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <>{data?.status === 1 ? "active" : "inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <>
            <div className="col">
              {/* Button trigger modal */}
              {accessPerm(40, 2) && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target={`#editDepartment-${data.id}`}
                  style={{ marginRight: "5px" }}
                  onClick={() => handlerEdit(data)}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}

              {/* Modal */}
              <div
                className="modal fade"
                id={`editDepartment-${data.id}`}
                tabIndex={-1}
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Department</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={(e) => updateData(e, data?.id)}>
                      <div className="modal-body pb-0">
                        <div className="mb-3 row">
                          <label
                            htmlFor="inputName"
                            className="col-sm-3 col-form-label d-flex justify-content-start"
                          >
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              name="name"
                              defaultValue={data?.name}
                              onChange={(e) => onchange(e)}
                              type="text"
                              className="form-control"
                              id="inputName"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label
                            htmlFor="inputInfo"
                            className="col-sm-3 col-form-label d-flex justify-content-start"
                          >
                            Info
                          </label>
                          <div className="col-sm-12">
                            <textarea
                              name="info"
                              defaultValue={data?.info}
                              onChange={(e) => onchange(e)}
                              type="textarea"
                              className="form-control"
                              id="inputInfo"
                              rows={2}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-sm-12">
                            <div className="form-check d-flex justify-content-end align-items-center">
                              <input
                                onChange={() => handleCheck(isChecked)}
                                className="form-check-input mt-0 me-2"
                                type="checkbox"
                                defaultChecked={
                                  data?.status === 1 ? true : false
                                }
                                id="flexCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Update changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {/* Button trigger modal */}
              {accessPerm(40, 3) && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target={`#deleteDepartment-${data.id}`}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              )}

              {/* Activity */}
              <div
                className="modal fade"
                id={`deleteDepartment-${data.id}`}
                tabIndex={-1}
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Delete Department</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3 row ">
                        <div className="col-sm-10">
                          <p>
                            Are you sure you want to delete this department?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteData(data.id)}
                        data-bs-dismiss="modal"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target={`#deleteDepartment-${data.id}`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      excel: false,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [];

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Department List</h6>
              <div className="col">
                {/* Button trigger modal */}
                {accessPerm(40, 1) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleVerticallycenteredActivity"
                    onClick={handleCheck}
                  >
                    <i className="fa-solid fa-plus"></i> Add New
                  </button>
                )}

                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleVerticallycenteredActivity"
                  tabIndex={-1}
                  style={{ display: "none" }}
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <form
                      className="modal-content"
                      onSubmit={(e) => createData(e)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Add New Department</h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body pb-0">
                          <div className="mb-3 row ">
                            <label
                              htmlFor="inputName"
                              className="col-sm-3 col-form-label d-flex justify-content-start"
                            >
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-12">
                              <input
                                onChange={onchange}
                                name="name"
                                type="text"
                                className="form-control"
                                id="inputName"
                                required
                              />
                            </div>
                          </div>
                          <div className="mb-3 row ">
                            <label
                              htmlFor="inputInfo"
                              className="col-sm-3 col-form-label d-flex justify-content-start"
                            >
                              Info
                            </label>
                            <div className="col-sm-12">
                              <textarea
                                onChange={onchange}
                                name="info"
                                type="textarea"
                                className="form-control"
                                id="inputInfo"
                                rows={2}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm-12">
                              <div className="form-check d-flex justify-content-end align-items-center">
                                <input
                                  onChange={() => handleCheck()}
                                  className="form-check-input mt-0 me-2"
                                  type="checkbox"
                                  checked={isChecked}
                                  id="flexCheckChecked"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Save changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={data}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Department;
