import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../Investigation/Report/MedicalXray/MedicalXray.scss";
import JsBarcode from "jsbarcode";
import { UserContext } from "../../Context/UserContextAPI";
import "../Investigation/Report/Certificates/HeldUp.scss";
import "../Investigation/Report/Certificates/Unfit.scss";
import QRCode from "qrcode.react";

const XrayImage = () => {
  const pdfRef = useRef();
  const { accessPerm, settingsData } = useContext(UserContext);
  const navigate = useNavigate();
  const { userId } = useParams();
  const { state } = useLocation();
  console.log(state);
  const patientData = state?.record;
  const [xrayImage, setXrayImage] = useState();
  const [xrayLoading, setXrayLoading] = useState(true);

  const [riskFactor, setRiskFactor] = useState("");
  const [loading, setLoading] = useState(false);

  const [userDetail, setUserDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [radiologyTests, setRadiologyTests] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [radiologyReportData, setRadiologyReportData] = useState([]);
  const [fit, setFit] = useState(0);
  const [unfit, setUnfit] = useState(0);
  const [heldUp, setHeldUp] = useState(0);
  const [imagePath, setImagePath] = useState("");

  const [radiologyComment, setRadiologyComment] = useState({
    user_id: userId,
    comments: "",
    status: 1,
    investigation_group_id: 1,
  });
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState({
    user_id: patientData?.user_id,
    comments: "",
    status: 1,
    investigation_group_id: 100,
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const urlWithBase64ID = `https://${process.env.REACT_APP_API_BASE_URL}/medical-verification/result/report/print/${userDetail?.user?.id}`;
  const now = new Date();
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(now);

  const registrationNo = state?.user?.registration_no;
  const formattedBirthDate = state?.user?.date_of_birth
    ? (() => {
        const date = new Date(state?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";
  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: true,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }
    const days = Math.floor(hours || 0 / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const receivingDate = user?.user_payment?.createdAt;
  const preparationDuration =
    user?.user?.role_id !== 5
      ? user?.package?.preparation_duration ?? 0
      : user?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  async function getComments() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const comments = response?.data?.data || [];

      setRadiologyComment((previousComment) => {
        return {
          ...previousComment,
          user_id: userId,
          comments: comments[0]?.comments,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getUserDetail = async () => {
    try {
      setLoading(true);
      setXrayLoading(true);
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUserDetail(response?.data?.data);
      userDetail && setUser(userDetail?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveInvestigationTests = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/reports/get-data/2`,
        {
          invIds: user?.package?.investigation_id.split(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setInvestigations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (radiologyTests.length <= 0) {
      investigations?.investigation_tests?.forEach((investigation) => {
        setRadiologyReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: investigations.id,
              test_id: investigation.id,
              user_file: "",
              result: "",
            },
          ];
          return [...previousReport];
        });
      });
    }
  }, [investigations, userId]);

  useEffect(() => {
    setLoading(true);
    setRadiologyReportData([]);
    if (radiologyTests.length > 0) {
      radiologyTests.forEach((radiologyTest) => {
        setRadiologyReportData((previousReport) => {
          previousReport = [
            ...previousReport,
            {
              user_id: userId,
              investigation_id: radiologyTest.investigation_id || "",
              test_id: radiologyTest.test_id || "",
              user_file: radiologyTest?.user_file,
              result: radiologyTest.result?.replace(/<[^>]*>/g, "") || "",
            },
          ];
          return [...previousReport];
        });

        if (radiologyTest.investigation_id && radiologyTest.test_id === 1) {
          setXrayImage(
            (previousImage) => (previousImage = radiologyTest.user_file)
          );
          setXrayLoading(false);
        }
      });
    }
    setLoading(false);
  }, [userId, radiologyTests, imagePath]);

  // useEffect(() => {
  //   if (riskFactor >= 1 && riskFactor <= 70) {
  //     setRadiologyComment((previous) => ({ ...previous, status: 4 }));
  //   } else if (riskFactor === "") {
  //     setRadiologyComment((previous) => ({ ...previous, status: 2 }));
  //   } else {
  //     setRadiologyComment((previous) => ({ ...previous, status: 3 }));
  //   }
  // }, [radiologyReportData]);

  const [representative, setRepresentative] = useState();
  useEffect(() => {
    setLoading(true); // newly added
    userDetail && setUser(userDetail?.userDetail);
    if (riskFactor === undefined) {
      setRiskFactor("");
    }

    const introducer_id = userDetail?.userDetail?.introducer_user?.id;

    if (introducer_id) {
      axios(
        `${process.env.REACT_APP_API_BASE_URL}/users-map/introducer/${introducer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => {
          const representative_id = res?.data?.user_id;

          axios(
            `${process.env.REACT_APP_API_BASE_URL}/users/user/${representative_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
              },
            }
          )
            .then((res) => {
              setRepresentative(res?.data?.data?.userQuery);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  }, [userDetail, userDetail?.nextPage, userDetail?.previousPage, riskFactor]);

  useEffect(() => {
    if (userId) {
      setLoading(true);

      getUserDetail()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [userId]);

  useEffect(() => {
    userId && getComments();
  }, [userId]);

  useEffect(() => {
    setRadiologyComment((previousComment) => {
      return {
        ...previousComment,
        user_id: userId,
        comments: radiologyComment,
      };
    });
  }, [userId]);

  useEffect(() => {
    setRadiologyRiskFactor((previousComment) => {
      return {
        ...previousComment,
        user_id: userId,
        comments: riskFactor,
      };
    });
  }, [userId]);

  useEffect(() => {
    user && getActiveInvestigationTests();
  }, [user]);

  useEffect(() => {
    userId && getRadiologyTests();
  }, [userId]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  return (
    <>
      <section
        className="held-up-certificate px-3"
        ref={pdfRef}
        style={{
          minHeight: "100vh",
          width: "100%",
          position: "relative",
        }}
      >
        <div className="row align-items-center">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
            alt="Report Header"
            className="mx-auto"
            style={{ width: "100%" }}
          />
        </div>
        <div className="w-100">
          <div className="row">
            <h4 className="fw-bold fs-5 text-center" style={{ color: "#000" }}>
              MEDICAL X-RAY REPORT
            </h4>
          </div>
        </div>
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-sm-5 h-100">
              <div
                className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center"
                style={{ height: "90px" }}
              >
                <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                  DATE: {formatDeliveryTime(newDate, "dd-MM-yyyy hh:mm a")}
                </p>
                <img
                  src={patientIdDataUrl}
                  alt=""
                  className="w-100 img-fluid"
                  style={{
                    height: "60px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                />
              </div>
            </div>
            <>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {state?.user?.image ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.user?.image}`}
                      className="img-fluid"
                      alt="patient"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <img
                      src="./dummy.jpg"
                      alt="patient"
                      className="img-fluid"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {state?.user?.finger_print ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.user?.finger_print}`}
                      className="img-fluid"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <div
                      className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      No Fingerprint Found
                    </div>
                  )}
                </div>
              </div>
            </>
            <div className="col-sm-3 h-100">
              <div
                className="d-flex justify-content-center align-items-center w-100 border border-dark"
                style={{ height: "90px" }}
              >
                <Link to={urlWithBase64ID}>
                  <QRCode value={urlWithBase64ID} size={85} />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="col-sm-12">
              <div className="row text-dark">
                <table
                  className="responsive-width1 table-bordered"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    {/* Row 1 - Name and Client ID */}
                    <tr>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Name
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {state?.user?.f_name || "---"}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Client ID
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {state?.user?.registration_no || "---"}
                      </td>
                    </tr>

                    {/* Row 2 - Gender and Passport No */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Gender
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.gender?.name || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Passport No
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.passport_no || "---"}
                      </td>
                    </tr>

                    {/* Row 3 - Date of Birth and Travelling To */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.role_id !== 5 ? `Date of Birth` : `Age`}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.role_id !== 5
                          ? formattedBirthDate || "---"
                          : state?.user?.age || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Travelling To
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.country?.name || "---"}
                      </td>
                    </tr>

                    {/* Row 4 - Father Name and Contact Number */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Father Name
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.father_name || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Contact Number
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.mobile || "---"}
                      </td>
                    </tr>

                    {/* Row 5 - Nationality and Agency */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Nationality
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.national_country?.nationality || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.role_id !== 5
                          ? `${settingsData?.introducer_label}`
                          : `Reference`}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        colSpan={3}
                      >
                        {state?.user?.role_id !== 5
                          ? state?.introducer_user?.f_name || "---"
                          : state?.user?.reference || "---"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center w-100 mt-3">
          <div className="col-sm-12">
            <div className="d-flex justify-content-center">
              {!xrayImage ? (
                <div
                  className="d-flex align-items-center justify-content-center fs-6 border border-5 rounded-1 text-dark"
                  style={{ width: "100%" }}
                >
                  No X-Ray Image Found
                </div>
              ) : (
                <div>
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                    style={{ width: "100" }}
                    alt=""
                    className="medical-xray-print-image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 pb-4 checked-by p-4">
          <div className="d-flex w-100 mt-0 justify-content-between">
            <div className="text-dark">
              <div
                className="border-top mt-5 border-dark"
                style={{ width: "200px" }}
              ></div>
              CHECKED BY
            </div>
            <div className=" mt-4 text-dark">
              <div className="text-start text-dark p-0 mt-1">
                Printed By: {state?.user?.created_by}
              </div>
              <p className="p-0 m-0">
                {settingsData?.copyright} {settingsData?.website_name}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end gap-2 mt-2 unfit-button p-2">
        {accessPerm(68, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={(e) => {
            navigate("/medical-verification");
          }}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default XrayImage;
