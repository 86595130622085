import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AgencySummaryModal from "../../components/Modal/RegistrationSummaryModal/AgencySummaryModal";
import CountrySummaryModal from "../../components/Modal/RegistrationSummaryModal/CountrySummaryModal";
import PackageSummaryModal from "../../components/Modal/RegistrationSummaryModal/PackageSummaryModal";
import RepresentativeSummeryModal from "../../components/Modal/RegistrationSummaryModal/RepresentativeSummaryModal";
import "../../Pages/ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import { UserContext } from "../../Context/UserContextAPI";
import Select from "react-select";
import VirtualizedMenuList from "../../components/VirtualMenuList/VirtualMenuList";
import Loading from "../../utils/Loader/Loading";

const ResultPage = () => {
  const { accessPerm, settingsData } = useContext(UserContext);

  const {
    countryUsers,
    companyUsers,
    delegateUsers,
    introducerUsers,
    packages,
  } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState([]);
  const [regInputField, setRegInputField] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });
  const [totalStatus, setTotalStatus] = useState(0);
  const [otherStatus, setOtherStatus] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
  });

  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [introducerWiseUsers, setIntroducerWiseUsers] = useState([]);
  const [packageWiseUsers, setPackageWiseUsers] = useState([]);
  const [representativeWiseUsers, setRepresentativeWiseUsers] = useState([]);

  const formattedBirthDate = selectedRowData?.user?.date_of_birth
    ? (() => {
        const date = new Date(selectedRowData?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      const representativeMobile =
        user?.user_users_maps[0]?.representative_user_map?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const countryOptions = [
    { value: "", label: "Select Country" },
    ...(countryUsers?.map((country) => ({
      value: parseInt(country?.id),
      label: `${country?.shortname} - ${country?.name}`,
    })) || []),
  ];

  const companyOptions = [
    { value: "", label: "Select Company" },
    ...(companyUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const delegateOptions = [
    { value: "", label: "Select Delegate" },
    ...(delegateUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };
    let notDoneCount = 0;

    data.forEach((userInfo) => {
      const collection = userInfo?.user?.user_collections?.map(
        (collection) =>
          collection.investigation_category?.investigation_group_id
      );

      const uniqueCollection = [...new Set(collection)];
      const done =
        (uniqueCollection?.length < 2 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) <
            2) ||
        (uniqueCollection?.length >= 2 &&
          uniqueCollection?.length < 3 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) >=
            2) ||
        (uniqueCollection?.length >= 3 &&
          userInfo?.user?.is_pathology_done &&
          userInfo?.user?.is_physical_done &&
          userInfo?.user?.is_radiology_done);

      // Counting entries where the task is not done
      if (!done || userInfo?.user?.status === 1) {
        notDoneCount++;
      }

      switch (userInfo?.user?.status) {
        case 1:
          counts.status1 = counts.status1 + 1;
          break;
        case done && 2:
          counts.status2 = counts.status2 + 1;
          break;
        case done && 3:
          counts.status3 = counts.status3 + 1;
          break;
        case done && 4:
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });

    setStatusCounts(counts);
    setTotalStatus(userDetails?.length);
    setOtherStatus(notDoneCount);
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const status = form.status.value;
    const packageId = form.package.value;
    const introducer = form.introducer.value;
    const country = form.country.value;
    const company = form.company.value;
    const delegate = form.delegate.value;
    const regNo = form.invoice_reg_mobile.value;

    const filterData = {
      fromDate,
      toDate,
      status,
      packageId,
      introducer,
      country,
      company,
      delegate,
      regNo: regNo.trim(),
    };

    methodFilterPatientsDetails(filterData, "form");
  };

  const methodFilterPatientsDetails = async (filterData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data;
      if (data) {
        calculateStatus(data?.userDetails);
        // setUserDetails(data?.userDetails);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setTotalStatus(data?.userDetails?.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setModalOpen(true);
    console.log(rowData);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedRowData(null);
    setModalOpen(false);
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (row, index) => {
          const reversedSerialNumber = row?.row?.index + 1;

          return (
            <div className="text-center d-flex justify-content-start fw-bold">
              <span className="text-center">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: () => (
          <span className="d-flex justify-content-center">Date</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          const dateValue = new Date(info.renderValue(reversedIndex));
          const formattedDate = format(dateValue, "dd-MM-yyyy");

          return (
            <div className="d-flex justify-content-start">{formattedDate}</div>
          );
        },
      }),

      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Patient Name</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.passport_no", {
        header: () => (
          <span className="d-flex justify-content-center">Passport No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;

          info.getValue();
          const value1 = info.renderValue(reversedIndex);
          const representative_Mob =
            info?.row?.original?.introducer_user?.user_users_maps[0]?.representative_user_map?.mobile?.slice(
              -2
            );
          return (
            <div>
              {representative_Mob ? (
                <span className="text-center d-flex justify-content-start">
                  {value1} - ({representative_Mob})
                </span>
              ) : (
                <span className="text-center d-flex justify-content-start">
                  {value1}
                </span>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("country.shortname", {
        header: () => (
          <span className="d-flex justify-content-center">Country</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center">Package</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user", {
        header: () => (
          <span className="d-flex justify-content-center">Status</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          const {
            is_radiology_done,
            is_pathology_done,
            is_physical_done,
            status,
          } = info.renderValue(reversedIndex);

          const collection = info?.row?.original?.user?.user_collections?.map(
            (collection) =>
              collection.investigation_category?.investigation_group_id
          );

          const uniqueCollection = [...new Set(collection)];

          const isDone =
            (uniqueCollection?.length < 2 &&
              (is_pathology_done || is_physical_done || is_radiology_done)) ||
            (uniqueCollection?.length >= 2 &&
              uniqueCollection?.length < 3 &&
              (is_pathology_done ? 1 : 0) +
                (is_physical_done ? 1 : 0) +
                (is_radiology_done ? 1 : 0) >=
                2) ||
            (uniqueCollection?.length >= 3 &&
              is_pathology_done &&
              is_physical_done &&
              is_radiology_done);

          let content;

          if (isDone && status === 2) {
            content = "FIT";
          } else if (isDone && status === 3) {
            content = "UNFIT";
          } else if (isDone && status === 4) {
            content = "HELD-UP";
          } else {
            content = "PROCESSING";
          }

          return <div className="d-flex justify-content-start">{content}</div>;
        },
      }),

      columnHelper.accessor("user.role.name", {
        header: () => (
          <span className="d-flex justify-content-center">Type</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),
    ],
    [accessPerm]
  );

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        status: "",
        packageId: "",
        introducer: "",
        country: "",
        company: "",
        delegate: "",
        regNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
        country: null,
        company: null,
        delegate: null,
      });

      setRegInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        calculateStatus(data?.userDetails);
        setTotalStatus(data?.userDetails?.length);
        // setUserDetails(data?.userDetails);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 text-uppercase fw-bold"
                  style={{ color: "red" }}
                >
                  Results
                </h6>
                <div className="d-flex align-items-center justify-content-start gap-3">
                  <form
                    onSubmit={(e) => handlerOnSearch(e)}
                    className="col-sm-12"
                  >
                    <div className="row w-100">
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">Status</label>
                        <div className="">
                          <select
                            className="form-select medical-form-select"
                            name="status"
                            aria-label="Default select example"
                          >
                            <option value={""}>Select Status</option>
                            <option value={1}>PROCESSING</option>
                            <option value={2}>FIT</option>
                            <option value={3}>UNFIT</option>
                            <option value={4}>HELD-UP</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">Package</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="package"
                            className="fw-light"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("package", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 p-1">
                        <label className="col-form-label">Introducer</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            options={options}
                            className="fw-light"
                            placeholder="Select Introducer"
                            onChange={(selectedOptions) =>
                              handleSelect("introducer", selectedOptions)
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            isSearchable={true}
                            value={selectedValues.introducer}
                            name="introducer"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">Country</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="country"
                            className="fw-light"
                            options={countryOptions}
                            value={selectedValues.country}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("country", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">Company</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="company"
                            className="fw-light"
                            options={companyOptions}
                            value={selectedValues.company}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("company", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label">Delegate</label>
                        <div className="">
                          <Select
                            components={{ MenuList: VirtualizedMenuList }}
                            name="delegate"
                            className="fw-light"
                            options={delegateOptions}
                            value={selectedValues.delegate}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("delegate", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 p-1">
                        <label className="col-form-label tdt">
                          Registration No.
                        </label>
                        <div className="">
                          <input
                            type="text"
                            onChange={(e) => setRegInputField(e.target.value)}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select py-2"
                            style={{ width: "180px", height: "37px" }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 p-1 d-flex align-items-end justify-content-between gap-2">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <button
                            type="submit"
                            className="btn btn-primary text-white border border-3 fw-bold"
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning border border-3"
                            onClick={(e) => reset(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="patient-info-table-search">
              <div className="fit-unfit-status">
                <span className="fw-bolder">Record Summary &gt; </span>
                <span className="text-success fw-bolder">{`FIT: ${statusCounts?.status2}`}</span>
                <span> | </span>
                <span className="text-danger fw-bolder">{`UNFIT: ${statusCounts?.status3}`}</span>
                <span> | </span>
                <span className="fw-bolder" style={{ color: "blue" }}>
                  {`HELD-UP: ${statusCounts?.status4}`}
                </span>
                <span> | </span>
                <span className="fw-bolder">{`Others: ${otherStatus}`}</span>
                <span> | </span>
                <span className="fw-bolder">{`Total: ${totalStatus}`}</span>
              </div>
              <div className="reg-summary d-flex justify-content-end">
                <span className="me-1">Summary Of:</span>{" "}
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#country-summary-modal"
                >
                  Country
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#agency-summary-modal"
                >
                  Agency
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#repres-summary-modal"
                >
                  Representative
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#package-summary-modal"
                >
                  Package
                </span>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <table className="table align-middle table-hover table-bordered mb-0 rounded">
                <thead className="bg-primary">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, i) => (
                        <th
                          key={i}
                          id={`th-${i}`}
                          className="text-light p-1"
                          style={{ backgroundColor: "#095d7e" }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, i) => {
                    const {
                      original: {
                        user: {
                          status,
                          is_radiology_done,
                          is_pathology_done,
                          is_physical_done,
                        },
                      },
                    } = row;
                    const collection =
                      row?.original?.user?.user_collections?.map(
                        (collection) =>
                          collection.investigation_category
                            ?.investigation_group_id
                      );

                    const uniqueCollection = [...new Set(collection)];

                    const done =
                      (uniqueCollection?.length < 2 &&
                        (is_pathology_done ||
                          is_physical_done ||
                          is_radiology_done)) ||
                      (uniqueCollection?.length >= 2 &&
                        uniqueCollection?.length < 3 &&
                        (is_pathology_done ? 1 : 0) +
                          (is_physical_done ? 1 : 0) +
                          (is_radiology_done ? 1 : 0) >=
                          2) ||
                      (uniqueCollection?.length >= 3 &&
                        is_pathology_done &&
                        is_physical_done &&
                        is_radiology_done);

                    return (
                      <tr
                        key={i}
                        className={`${done && status === 4 && "text-primary"} ${
                          done && status === 2 && "text-success"
                        } ${done && status === 3 && "text-danger"} ${
                          status === 1 && "text-dark"
                        } 
           `}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(row?.original)} // Open the modal when the row is clicked
                      >
                        {row.getVisibleCells().map((cell, i) => (
                          <td key={i} className="p-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            <div
              className={`modal ${isModalOpen ? "show fade" : ""}`}
              style={{ display: isModalOpen ? "block" : "none" }}
              tabIndex="-1"
            >
              <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Medical Report [{formatDate(selectedRowData?.createdAt)}]
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={closeModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    {selectedRowData ? (
                      <div className="w-100 d-flex flex-column">
                        <div className="row justify-content-center h-100">
                          <>
                            <div className="col-sm-2 h-100">
                              <div
                                className="pres-photo border border-end-0 border-dark"
                                style={{ width: "100%", height: "114px" }}
                              >
                                {selectedRowData?.user?.image ? (
                                  <img
                                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${selectedRowData?.user?.image}`}
                                    className="img-fluid"
                                    alt="patient"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="./dummy.jpg"
                                    alt="patient"
                                    className="img-fluid"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                              </div>
                              <p
                                className="pres-title p-0 m-0 w-100 border border-end-0 border-top-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                                style={{ height: "29px" }}
                              >
                                PHOTO
                              </p>
                            </div>
                            <div className="col-sm-8 h-100">
                              <div className="row">
                                <table
                                  className="responsive-width1 table-bordered"
                                  style={{
                                    width: "100%",
                                    height: "143px",
                                    borderCollapse: "collapse",
                                    textAlign: "left",
                                  }}
                                >
                                  <tbody>
                                    {/* Row 1 - Name and Client ID */}
                                    <tr>
                                      <td
                                        className="text-uppercase border border-dark ps-1 align-middle"
                                        style={{ width: "25%" }}
                                      >
                                        Name
                                      </td>
                                      <td
                                        className="text-uppercase border border-dark ps-1 align-middle"
                                        style={{ width: "25%" }}
                                      >
                                        {selectedRowData?.user?.f_name || "---"}
                                      </td>
                                      <td
                                        className="text-uppercase border border-dark ps-1 align-middle"
                                        style={{ width: "25%" }}
                                      >
                                        Client ID
                                      </td>
                                      <td
                                        className="text-uppercase border border-dark ps-1 align-middle"
                                        style={{ width: "25%" }}
                                      >
                                        {selectedRowData?.user
                                          ?.registration_no || "---"}
                                      </td>
                                    </tr>

                                    {/* Row 2 - Gender and Passport No */}
                                    <tr>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        Gender
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        {selectedRowData?.gender?.name || "---"}
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        Passport No
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        {selectedRowData?.user?.passport_no ||
                                          "---"}
                                      </td>
                                    </tr>

                                    {/* Row 3 - Date of Birth and Travelling To */}
                                    <tr>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        {selectedRowData?.user?.role_id !== 5
                                          ? `Date of Birth`
                                          : `Age`}
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        {selectedRowData?.user?.role_id !== 5
                                          ? formattedBirthDate || "---"
                                          : selectedRowData?.user?.age || "---"}
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        Travelling To
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        {selectedRowData?.country?.name ||
                                          "---"}
                                      </td>
                                    </tr>

                                    {/* Row 4 - Father Name and Contact Number */}
                                    {selectedRowData?.user?.role_id !== 5 && (
                                      <tr>
                                        <td className="text-uppercase border border-dark ps-1 align-middle">
                                          Father Name
                                        </td>
                                        <td className="text-uppercase border border-dark ps-1 align-middle">
                                          {selectedRowData?.father_name ||
                                            "---"}
                                        </td>
                                        <td className="text-uppercase border border-dark ps-1 align-middle">
                                          Contact Number
                                        </td>
                                        <td className="text-uppercase border border-dark ps-1 align-middle">
                                          {selectedRowData?.user?.mobile ||
                                            "---"}
                                        </td>
                                      </tr>
                                    )}

                                    {/* Row 5 - Nationality and Agency */}
                                    <tr>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        Nationality
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        {selectedRowData?.national_country
                                          ?.nationality || "---"}
                                      </td>
                                      <td className="text-uppercase border border-dark ps-1 align-middle">
                                        {selectedRowData?.user?.role_id !== 5
                                          ? `${settingsData?.introducer_label}`
                                          : `Reference`}
                                      </td>
                                      <td
                                        className="text-uppercase border border-dark ps-1 align-middle"
                                        colSpan={3}
                                      >
                                        {selectedRowData?.user?.role_id !== 5
                                          ? selectedRowData?.introducer_user
                                              ?.f_name || "---"
                                          : selectedRowData?.user?.reference ||
                                            "---"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-sm-2 h-100">
                              <div
                                className="pres-photo border border-start-0 border-dark"
                                style={{ width: "100%", height: "114px" }}
                              >
                                {selectedRowData?.user?.finger_print ? (
                                  <img
                                    src={`${process.env.REACT_APP_UPLOAD_URL}/users/${selectedRowData?.user?.finger_print}`}
                                    className="img-fluid"
                                    alt=""
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                ) : (
                                  <div
                                    className="p-0 border border-start-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  >
                                    No Fingerprint Found
                                  </div>
                                )}
                              </div>
                              <p
                                className="pres-title p-0 m-0 w-100 border border-top-0 border-start-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                                style={{ height: "29px" }}
                              >
                                THUMB PRINT
                              </p>
                            </div>
                          </>
                        </div>
                        <div className="row" style={{ marginTop: "2rem" }}>
                          <div className="col-sm-3">
                            {accessPerm(68, 14) &&
                              (selectedRowData?.user?.is_pathology_done ===
                              1 ? (
                                <Link
                                  className="btn btn-info btn-block btn-md fw-bold"
                                  style={{ width: "200px" }}
                                  to={{
                                    pathname: `/medical-pathology-print/${selectedRowData?.user_id}`,
                                    state: selectedRowData,
                                  }}
                                  target="_blank"
                                >
                                  Pathology Report
                                </Link>
                              ) : (
                                <Link
                                  className="btn btn-info btn-block btn-md fw-bold"
                                  style={{ width: "200px" }}
                                  disabled
                                  to={{
                                    pathname: `/medical-pathology-print/${selectedRowData?.user_id}`,
                                    state: selectedRowData,
                                  }}
                                  target="_blank"
                                >
                                  Pathology Report
                                </Link>
                              ))}
                          </div>
                          <div className="col-sm-3">
                            {accessPerm(68, 15) &&
                              (selectedRowData?.user?.is_physical_done === 1 ? (
                                <button
                                  className="btn btn-info btn-md"
                                  style={{ width: "200px" }}
                                  onClick={() =>
                                    window.open(
                                      `/medical-physical-print/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Physical Report
                                </button>
                              ) : (
                                <button
                                  className="btn btn-info btn-md"
                                  style={{ width: "200px" }}
                                  disabled
                                  onClick={() =>
                                    window.open(
                                      `/medical-physical-print/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Physical Report
                                </button>
                              ))}
                          </div>
                          <div className="col-sm-3">
                            {accessPerm(68, 13) &&
                              (selectedRowData?.user?.is_radiology_done ===
                              1 ? (
                                <button
                                  className="btn btn-info btn-block btn-md"
                                  style={{ width: "200px" }}
                                  onClick={() =>
                                    window.open(
                                      `/medical-radiology-print/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Radiology Report
                                </button>
                              ) : (
                                <button
                                  className="btn btn-info btn-block btn-md"
                                  style={{ width: "200px" }}
                                  disabled
                                  onClick={() =>
                                    window.open(
                                      `/medical-radiology-print/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Radiology Report
                                </button>
                              ))}
                          </div>
                          <div className="col-sm-3">
                            {accessPerm(68, 5) && (
                              <button
                                className="btn btn-info btn-block btn-md"
                                style={{ width: "200px" }}
                                onClick={() =>
                                  window.open(
                                    `/medical-report-print/${selectedRowData?.user_id}`,
                                    "_blank"
                                  )
                                }
                              >
                                Medical Report
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "1rem" }}>
                          <div className="col-sm-3">
                            {accessPerm(68, 10) &&
                              (selectedRowData?.user?.is_radiology_done &&
                              selectedRowData?.user?.is_pathology_done &&
                              selectedRowData?.user?.is_physical_done &&
                              selectedRowData?.user?.status === 2 ? (
                                <Link
                                  className="btn btn-info btn-block btn-md fw-bold"
                                  style={{ width: "200px" }}
                                  to={{
                                    pathname: `/medical-health-card-print/${selectedRowData?.user_id}`,
                                    state: selectedRowData,
                                  }}
                                  target="_blank"
                                >
                                  Health Card
                                </Link>
                              ) : (
                                <Link
                                  className="btn btn-info btn-block btn-md fw-bold"
                                  style={{ width: "200px" }}
                                  disabled
                                  to={{
                                    pathname: `/medical-health-card-print/${selectedRowData?.user_id}`,
                                    state: selectedRowData,
                                  }}
                                  target="_blank"
                                >
                                  Health Card
                                </Link>
                              ))}
                          </div>
                          <div className="col-sm-3">
                            {accessPerm(68, 12) &&
                              (selectedRowData?.user?.is_radiology_done &&
                              selectedRowData?.user?.is_pathology_done &&
                              selectedRowData?.user?.is_physical_done &&
                              selectedRowData?.user?.status === 4 ? (
                                <Link
                                  to={{
                                    pathname: `/medical-prescription-print/${selectedRowData?.user_id}`,
                                    state: selectedRowData,
                                  }}
                                  target="_blank"
                                >
                                  <button
                                    className="btn btn-info btn-block btn-md fw-bold"
                                    style={{ width: "200px" }}
                                  >
                                    Prescription
                                  </button>
                                </Link>
                              ) : (
                                <button
                                  className="btn btn-info btn-block btn-md fw-bold"
                                  disabled
                                  style={{ width: "200px" }}
                                >
                                  <Link
                                    to={{
                                      pathname: `/medical-prescription-print/${selectedRowData?.user_id}`,
                                      state: selectedRowData,
                                    }}
                                    target="_blank"
                                  >
                                    Prescription
                                  </Link>
                                </button>
                              ))}
                          </div>
                          <div className="col-sm-3">
                            {accessPerm(68, 16) && (
                              <Link
                                className="btn btn-info btn-block btn-md fw-bold"
                                style={{ width: "200px" }}
                                to={{
                                  pathname: `/print-medical-examination/${selectedRowData?.user_id}`,
                                  state: selectedRowData,
                                }}
                                target="_blank"
                              >
                                Medical Examination
                              </Link>
                            )}
                          </div>
                          <div className="col-sm-3">
                            <button
                              className="btn btn-info btn-block btn-md"
                              style={{ width: "200px" }}
                              onClick={() =>
                                window.open(
                                  `/header-report-print/${selectedRowData?.user_id}`,
                                  "_blank"
                                )
                              }
                            >
                              Print Report with Header
                            </button>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "1rem" }}>
                          <div className="col-sm-3">
                            {accessPerm(68, 16) &&
                              (selectedRowData?.user?.is_radiology_done &&
                              selectedRowData?.user?.is_pathology_done &&
                              selectedRowData?.user?.is_physical_done &&
                              selectedRowData?.user?.status === 2 ? (
                                <button
                                  className="btn btn-success text-dark btn-block btn-md"
                                  style={{ width: "200px" }}
                                  onClick={() =>
                                    window.open(
                                      `/print-fit-certificate/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Fit
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success text-dark btn-block btn-md"
                                  style={{ width: "200px" }}
                                  disabled
                                  onClick={() =>
                                    window.open(
                                      `/print-fit-certificate/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Fit
                                </button>
                              ))}
                          </div>
                          <div className="col-sm-3">
                            {accessPerm(68, 16) &&
                              (selectedRowData?.user?.is_radiology_done &&
                              selectedRowData?.user?.is_pathology_done &&
                              selectedRowData?.user?.is_physical_done &&
                              selectedRowData?.user?.status === 3 ? (
                                <button
                                  className="btn btn-danger text-dark btn-block btn-md"
                                  style={{ width: "200px" }}
                                  onClick={() =>
                                    window.open(
                                      `/print-unfit-certificate/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Unfit
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger text-dark btn-block btn-md"
                                  style={{ width: "200px" }}
                                  disabled
                                  onClick={() =>
                                    window.open(
                                      `/print-unfit-certificate/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Unfit
                                </button>
                              ))}
                          </div>
                          <div className="col-sm-3">
                            {accessPerm(68, 16) &&
                              (selectedRowData?.user?.is_radiology_done &&
                              selectedRowData?.user?.is_pathology_done &&
                              selectedRowData?.user?.is_physical_done &&
                              selectedRowData?.user?.status === 4 ? (
                                <button
                                  className="btn btn-primary text-dark btn-block btn-md"
                                  style={{ width: "200px" }}
                                  onClick={() =>
                                    window.open(
                                      `/print-held-up-certificate/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Heldup
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary text-dark btn-block btn-md"
                                  style={{ width: "200px" }}
                                  disabled
                                  onClick={() =>
                                    window.open(
                                      `/print-held-up-certificate/${selectedRowData?.user_id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Heldup
                                </button>
                              ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h4>No Record Available</h4>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-warning btn-block btn-md w-25"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isModalOpen && <div className="modal-backdrop fade show"></div>}
          </div>
        </div>
      </div>

      <div>
        <CountrySummaryModal countryWiseUsers={countryWiseUsers} />
        <AgencySummaryModal introducerWiseUsers={introducerWiseUsers} />
        <PackageSummaryModal packageWiseUsers={packageWiseUsers} />
        <RepresentativeSummeryModal
          representativeWiseUsers={representativeWiseUsers}
        />
      </div>
    </>
  );
};

export default React.memo(ResultPage);
