import { useEffect, useRef, useState, useCallback } from "react";
import {
  Circle,
  Image,
  Label,
  Layer,
  Line,
  Stage,
  Tag,
  Text,
} from "react-konva";
import { Ellipse } from "react-konva";

import useImage from "use-image";
import Toolbar from "./Toolbar";

export default function XrayEditor({
  xrayFilm,
  riskFactor,
  setRiskFactor,
  stageRef,
}) {
  const [selectedTool, setSelectedTool] = useState("");
  const [xrayEditorSize, setXrayEditorSize] = useState({ x: 0, y: 0 });
  const [startDrawingPoint, setStartDrawingPoint] = useState({ x: 0, y: 0 });
  const [endDrawingPoint, setEndDrawingPoint] = useState({ x: 0, y: 0 });
  const [undo, setUndo] = useState([]);
  const [history, setHistory] = useState([]);
  const [redo, setRedo] = useState([]);
  const [color, setColor] = useState("#FF0000");
  const [brightness, setBrightness] = useState(1);

  const [textArea, setTextArea] = useState({
    visible: false,
    value: "",
    style: {
      position: "absolute",
      top: "",
      left: 0,
      width: "",
      height: "",
      fontSize: "",
      border: "none",
      padding: "0px",
      margin: "0px",
      overflow: "hidden",
      background: "none",
      outline: "none",
      resize: "none",
      lineHeight: "",
      fontFamily: "",
      transformOrigin: "left top",
      textAlign: "",
      color: "",
      rotation: "",
    },
  });

  // shapes
  const [lines, setLines] = useState([]);
  const [circles, setCircles] = useState([]);
  const [deformableShapes, setDeformableShapes] = useState([]);

  const [texts, setTexts] = useState([]);

  const textRef = useRef();
  const imageRef = useRef();
  const drawingRef = useRef();
  const isDrawing = useRef(false);
  const textAreaRef = useRef();

  // const [image] = useImage(`${process.env.REACT_APP_UPLOAD_URL}/${xrayFilm}`);
  const [image] = useImage(
    `${process.env.REACT_APP_UPLOAD_URL}/${xrayFilm}`,
    "anonymous",
    "origin"
  );

  function changeSelectedTool(tool) {
    setSelectedTool((previous) => {
      return (previous = tool);
    });
  }

  function handleMouseDown(event) {
    const position = event.target.getStage().getPointerPosition();

    switch (selectedTool) {
      case "pen":
        isDrawing.current = true;
        setStartDrawingPoint({ x: position.x, y: position.y });
        setLines([
          ...lines,
          { selectedTool, points: [position.x, position.y], color },
        ]);
        setUndo((previous) => [...previous, "pen"]);
        break;
      case "circle":
        // Check if clicked inside an existing circle
        const existingCircle = circles.find(
          (circle) =>
            Math.sqrt(
              Math.pow(position.x - circle.x, 2) +
                Math.pow(position.y - circle.y, 2)
            ) <= circle.radius
        );

        if (existingCircle) {
          setSelectedTool("");
        } else {
          isDrawing.current = true;
          setStartDrawingPoint({ x: position.x, y: position.y });
          setCircles([
            ...circles,
            {
              id: circles.length + 1,
              x: position.x,
              y: position.y,
              radius: 0,
              strokeWidth: 6,
              stroke: color,
            },
          ]);
          setUndo((previous) => [...previous, "circle"]);
        }
        break;

      case "rect":
        const existingRect = deformableShapes.find(
          (shape) =>
            position.x >= shape.x &&
            position.x <= shape.x + shape.width &&
            position.y >= shape.y &&
            position.y <= shape.y + shape.height
        );

        if (existingRect) {
          // Clicked inside an existing rectangle, make it draggable or resizable
          setSelectedTool(""); // Or set it to "rect" if you want to continue interacting with the existing rectangle
        } else {
          // Clicked outside any rectangle, create a new one
          isDrawing.current = true;
          setStartDrawingPoint({ x: position.x, y: position.y });
          setDeformableShapes([
            ...deformableShapes,
            {
              id: deformableShapes.length + 1,
              x: position.x,
              y: position.y,
              width: 0,
              height: 0,
              strokeWidth: 6,
              stroke: color,
            },
          ]);
          setUndo((previous) => [...previous, "rect"]);
        }
        break;
      case "text":
        setTexts([
          ...texts,
          {
            x: position.x,
            y: position.y,
            text: "Checked",
            fill: color,
            fontSize: 30,
          },
        ]);
        setUndo((previous) => [...previous, "text"]);
        break;
      default:
        break;
    }
  }

  function handleMouseMove(event) {
    if (!isDrawing.current) return;

    const position = event.target.getStage().getPointerPosition();

    setEndDrawingPoint((previous) => {
      return { ...previous, x: position.x, y: position.y };
    });

    switch (selectedTool) {
      case "pen":
        let lastLine = lines[lines.length - 1];
        lastLine.points = lastLine.points.concat([position.x, position.y]);
        lines.splice(lines.length - 1, 1, lastLine);
        break;
      case "circle":
        const newRadius = Math.sqrt(
          Math.pow(position.x - startDrawingPoint.x, 2) +
            Math.pow(position.y - startDrawingPoint.y, 2)
        );
        setCircles((prevCircles) => {
          const updatedCircles = [...prevCircles];
          updatedCircles[updatedCircles.length - 1].radius = newRadius;
          return updatedCircles;
        });
        break;
      case "rect":
        const newWidth = position.x - startDrawingPoint.x;
        const newHeight = position.y - startDrawingPoint.y;

        setDeformableShapes((prevShapes) => {
          const updatedShapes = [...prevShapes];
          const lastIndex = updatedShapes.length - 1;
          updatedShapes[lastIndex] = {
            ...prevShapes[lastIndex],
            x: newWidth < 0 ? position.x : startDrawingPoint.x,
            y: newHeight < 0 ? position.y : startDrawingPoint.y,
            width: Math.abs(newWidth),
            height: Math.abs(newHeight),
          };
          return updatedShapes;
        });
        break;

      default:
        return;
    }
  }

  function handleMouseUp() {
    isDrawing.current = false;
  }

  function handleDoubleClick(clickEvent) {
    const textPosition = clickEvent.target.absolutePosition();
    const stageContainer = clickEvent.target.getStage().container();

    clickEvent.target.hide();

    setTextArea((previous) => {
      return {
        ...previous,
        visible: true,
        style: {
          ...textArea.style,
          top: stageContainer.offsetTop + textPosition.y + "px",
          left: stageContainer.offsetLeft + textPosition.x + "px",
          width:
            clickEvent.target.width() - clickEvent.target.padding() * 2 + "px",
          height:
            clickEvent.target.height() -
            clickEvent.target.padding() * 2 +
            5 +
            "px",
          fontSize: clickEvent.target.fontSize() + "px",
          lineHeight: clickEvent.target.lineHeight(),
          fontFamily: clickEvent.target.fontFamily(),
          textAlign: clickEvent.target.align(),
          color: clickEvent.target.fill(),
          rotation: clickEvent.target.rotation(),
        },
      };
    });
  }

  function handleCornerDragMove(index, event) {
    const position = event.target.getStage().getPointerPosition();
    const shape = deformableShapes[index];

    if (shape) {
      const newWidth = Math.abs(position.x - shape.x) * 2; // Multiply by 2 to get the full width
      const newHeight = Math.abs(position.y - shape.y) * 2; // Multiply by 2 to get the full height

      // Update the shape with the new width and height
      setDeformableShapes((prevShapes) => {
        const updatedShapes = [...prevShapes];
        updatedShapes[index] = {
          ...prevShapes[index],
          width: newWidth,
          height: newHeight,
        };
        return updatedShapes;
      });
    }
  }

  const handleDragMoveEllipse = useCallback(
    (index, event) => {
      const position = event.target.getStage().getPointerPosition();
      const shape = deformableShapes[index];

      if (shape) {
        // Calculate the new width and height based on the dragged distance
        const newWidth = Math.max(Math.abs(position.x - shape.x) * 2, 0);
        const newHeight = Math.max(Math.abs(position.y - shape.y) * 2, 0);

        // Update the shape with the new width and height
        setDeformableShapes((prevShapes) => {
          const updatedShapes = [...prevShapes];
          updatedShapes[index] = {
            ...prevShapes[index],
            width: newWidth,
            height: newHeight,
          };
          return updatedShapes;
        });
      }
    },
    [deformableShapes]
  );

  function handleClick(action) {
    switch (action) {
      case "undo":
        if (history.length > 0) {
          const previousState = history[history.length - 1];

          // Update undo and redo states
          setUndo((prevUndo) => [...prevUndo, previousState]);
          setRedo([]);

          // Apply the changes based on the previous state
          setHistory((prevHistory) => prevHistory.slice(0, -1));
        }
        break;
      case "redo":
        if (redo.length > 0) {
          const nextState = redo[redo.length - 1];

          // Update undo and redo states
          setUndo((prevUndo) => [...prevUndo, nextState]);
          setRedo((prevRedo) => prevRedo.slice(0, -1));

          // Apply the changes based on the next state
          setHistory((prevHistory) => [...prevHistory, nextState]);
        }
        break;
      default:
        break;
    }
  }

  function removeDrawing() {
    setLines([]);
    setCircles([]);
    setTexts([]);
    setDeformableShapes([]);
    setRiskFactor("");
    setBrightness(1);
  }

  useEffect(() => {
    const xrayEditorWrapper = document.querySelector(".xray-editor-wrapper");

    setXrayEditorSize((previous) => {
      return {
        ...previous,
        x: xrayEditorWrapper.clientWidth,
        y: xrayEditorWrapper.clientHeight,
      };
    });
  }, []);

  function handleOutsideClick(e) {
    if (textArea.visible && e.target !== textAreaRef.current) {
      textRef.current.text(textArea.value);
      textRef.current.show();

      setTextArea((previous) => {
        return { ...previous, visible: false };
      });
    } else {
      return;
    }
  }

  return (
    <div className="xray-editor-wrapper d-flex">
      <Toolbar
        changeSelectedTool={changeSelectedTool}
        handleClick={handleClick}
        removeDrawing={removeDrawing}
        selectedTool={selectedTool}
        texts={texts}
        setTexts={setTexts}
        circles={circles}
        setCircles={setCircles}
        lines={lines}
        setLines={setLines}
        undo={undo}
        setUndo={setUndo}
        history={history}
        setHistory={setHistory}
        redo={redo}
        setRedo={setRedo}
        color={color}
        setColor={setColor}
        brightness={brightness}
        setBrightness={setBrightness}
        setSelectedTool={setSelectedTool}
        deformableShapes={deformableShapes}
        setDeformableShapes={setDeformableShapes}
      />
      <Stage
        ref={stageRef}
        width={700}
        height={800}
        onClick={handleOutsideClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ width: "100%" }}
      >
        <Layer>
          <Image
            ref={imageRef}
            image={image}
            width={700}
            height={800}
            onClick={(event) => {
              event.target.cache();
              event.target.brightness();
            }}
          />
        </Layer>
        <Layer ref={drawingRef}>
          {lines.map((line, index) => (
            <Line
              key={index}
              points={line?.points}
              stroke={line?.color}
              strokeWidth={5}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation={
                line?.tool === "eraser" ? "destination-out" : "source-over"
              }
            />
          ))}
          {circles.map((circle, index) => (
            <Circle
              key={index}
              x={circle?.x}
              y={circle?.y}
              radius={circle?.radius}
              stroke={circle?.stroke}
              strokeWidth={circle?.strokeWidth}
              draggable
              onDragMove={(event) => handleCornerDragMove(index, event)}
              onTransform={(event) => {
                const newScaleX = event.currentTarget.scaleX();
                const newScaleY = event.currentTarget.scaleY();

                setCircles((prevCircles) => {
                  const updatedCircles = [...prevCircles];
                  updatedCircles[index] = {
                    ...prevCircles[index],
                    radius:
                      updatedCircles[index].radius *
                      Math.max(newScaleX, newScaleY),
                  };
                  return updatedCircles;
                });

                // Reset scaling to maintain consistent circle appearance
                event.currentTarget.scaleX(1);
                event.currentTarget.scaleY(1);
              }}
              onDblClick={(e) => {
                // Handle double-click for text editing
                handleDoubleClick(e);
              }}
            />
          ))}
          {riskFactor && (
            <Label x={330} y={680}>
              <Tag fill={"black"} />
              <Text
                text={`${riskFactor}% Risk`}
                fill="red"
                padding={5}
                fontSize={25}
                fontStyle="bold"
                fontWeight="bold"
                fontFamily="Roboto"
              />
            </Label>
          )}
          {texts.map((text, index) => (
            <Text
              ref={textRef}
              key={index}
              x={text?.x}
              y={text?.y}
              text={text?.text}
              fontSize={text?.fontSize}
              draggable
              onClick={handleDoubleClick}
              onMouseOver={() => {
                if (selectedTool === "text") {
                  setSelectedTool((previous) => {
                    return (previous = "");
                  });
                }
              }}
              fill={text?.fill}
            />
          ))}
          {deformableShapes.map((shape, index) => (
            <Ellipse
              key={index}
              x={shape.x + shape.width / 2} // Center X-coordinate
              y={shape.y + shape.height / 2} // Center Y-coordinate
              radiusX={shape.width / 2} // Half of the width as the radius
              radiusY={shape.height / 2} // Half of the height as the radius
              stroke={shape.stroke}
              strokeWidth={shape.strokeWidth}
              draggable
              onDragMove={(event) => handleDragMoveEllipse(index, event)}
              // Additional properties for other functionalities (e.g., onDblClick, etc.)
            />
          ))}
        </Layer>
      </Stage>
      {textArea.visible && (
        <textarea
          value={textArea?.value}
          style={textArea?.style}
          ref={textAreaRef}
          autoFocus
          onKeyDown={(e) => {
            // hide on enter
            // but don't hide on shift + enter
            if (e?.keyCode === 13 && !e?.shiftKey) {
              textRef.current.text(e.target.value);
              textRef.current.show();
              setTextArea((previous) => ({ ...previous, visible: false }));
            }
            // on esc do not set value back to node
            if (e?.keyCode === 27) {
              // removeTextarea();
            }
          }}
          onChange={(event) => {
            setTextArea((previous) => ({
              ...previous,
              value: event.target.value,
            }));
          }}
        />
      )}
    </div>
  );
}
