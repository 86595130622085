import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import { UserContext } from "../../../Context/UserContextAPI";

const invCatURL = `${process.env.REACT_APP_API_BASE_URL}/investigation-category`;

const InvestigationCategory = () => {
  const { accessPerm } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [isCollectionChecked, setIsCollectionChecked] = useState(true);
  const [isAutoCollectionChecked, setIsAutoCollectionChecked] = useState(true);
  const [form, setForm] = useState({
    name: "",
    investigation_group_id: "",
    info: "",
    show_in_collection: "1",
    enable_auto_collection: "0",
    status: "1",
  });
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState(0);

  const columns = [
    {
      key: "name",
      text: "Name",
      align: "center",
      sortable: true,
    },
    {
      key: "group",
      text: "Group",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <>{data?.investigation_group?.name}</>;
      },
    },

    {
      key: "info",
      text: "Info",
      align: "center",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <>{data?.status === 1 ? "active" : "inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <>
            <div className="col">
              {/* Button trigger modal */}
              {accessPerm(22, 2) && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target={`#editInvCat-${data.id}`}
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    editHandler(data.id);
                  }}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}

              {/* Modal */}
              <div
                className="modal fade"
                id={`editInvCat-${data.id}`}
                tabIndex={-1}
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Edit Investigation Category
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3 row ">
                        <label
                          htmlFor="inputName"
                          className="col-sm-3 col-form-label d-flex justify-content-start"
                        >
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            onChange={onchange}
                            name="name"
                            value={form?.name}
                            type="text"
                            className="form-control"
                            id="inputName"
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-3 row ">
                        <label
                          htmlFor="inputNationality"
                          className="col-sm-3 col-form-label d-flex justify-content-start"
                        >
                          Group
                        </label>
                        <div className="col-sm-9">
                          <select
                            value={groupId}
                            onChange={selectGroupHandler}
                            className="single-select form-select"
                          >
                            <option value={0}>Select a group</option>
                            {groupList.map((group) => (
                              <option key={group?.id} value={group?.id}>
                                {group?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label
                          htmlFor="inputInfo"
                          className="col-sm-3 col-form-label d-flex justify-content-start"
                        >
                          Info
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            name="info"
                            defaultValue={data?.info}
                            onChange={(e) => onchange(e)}
                            type="textarea"
                            className="form-control"
                            id="inputInfo"
                            rows={2}
                          />
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-sm-12">
                          <div className="form-check d-flex justify-content-end align-items-center">
                            <input
                              onChange={() => handleCollectionCheck()}
                              className="form-check-input mt-0 me-2"
                              type="checkbox"
                              checked={isCollectionChecked}
                              id="flexCheckCollChecked"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckCollChecked"
                            >
                              Show in Collection
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-sm-12">
                          <div className="form-check d-flex justify-content-end align-items-center">
                            <input
                              onChange={() => handleAutoCollectionCheck()}
                              className="form-check-input mt-0 me-2"
                              type="checkbox"
                              checked={isAutoCollectionChecked}
                              id="flexAutoCheckCollChecked"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexAutoCheckCollChecked"
                            >
                              Enable Auto Collection
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-sm-12">
                          <div className="form-check d-flex justify-content-end align-items-center">
                            <input
                              onChange={() => handleCheck()}
                              className="form-check-input mt-0 me-2"
                              type="checkbox"
                              checked={isChecked}
                              id="flexCheckChecked"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Active
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => updateData(e, data.id)}
                      >
                        Update changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {/* Button trigger modal */}
              {accessPerm(23, 3) && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target={`#deleteInvCat-${data.id}`}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              )}
              {/* Activity */}
              <div
                className="modal fade"
                id={`deleteInvCat-${data.id}`}
                tabIndex={-1}
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Delete Investigation Category
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3 row ">
                        <div className="col-sm-10">
                          <p className="text-nowrap">
                            Are you sure you want to delete this Investigation
                            Category?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteData(data.id)}
                        data-bs-dismiss="modal"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target={`#deleteInvCat-${data.id}`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      excel: false,
      print: true,
      extra: true,
    },
  };

  const createData = async () => {
    if (!form.name) toast.error("Name can not be empty!");

    try {
      await axios.post(invCatURL, form, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      await fetchData();
      toast.success("Investigation category created successfully");
    } catch (error) {
      console.error(error);
    }
  };

  const editHandler = (id) => {
    // then form state update using setForm
    let obj = {};
    for (let category of data) {
      if (category.id == id) {
        obj = {
          name: category.name,
          investigation_group_id: category.investigation_group.id,
          info: category.info,
          show_in_collection: category?.show_in_collection ? "1" : "0",
          enable_auto_collection: category?.enable_auto_collection ? "1" : "0",
          status: category?.status ? "1" : "0",
        };
        setForm(obj);
        setGroupId(category?.investigation_group_id);
        setIsCollectionChecked(category?.show_in_collection === 1);
        setIsAutoCollectionChecked(category?.enable_auto_collection === 1);
        setIsChecked(category?.status === 1);
      }
    }
  };

  const selectGroupHandler = (e) => {
    setGroupId(e.target.value);
    setForm((prev) => {
      return {
        ...prev,
        investigation_group_id: e.target.value,
      };
    });
  };

  const updateData = async (e, id) => {
    e.preventDefault();
    if (!form.name) toast.error("Name can not be empty!");
    const updatedForm = {
      ...form,
      show_in_collection: isCollectionChecked ? "1" : "0",
      enable_auto_collection: isAutoCollectionChecked ? "1" : "0",
      status: isChecked ? "1" : "0",
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/investigation-category/${id}`,
        updatedForm,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      await fetchData();
      toast.success("Investigation category updated successfully");
    } catch (error) {}

    // window.location.reload(false);
  };

  const deleteData = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/investigation-category/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      }
    );

    toast.success("Investigation category deleted successfully");

    await fetchData();
  };

  const onchange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheck = () => {
    setIsChecked(!isChecked);
    setForm((prevForm) => ({
      ...prevForm,
      status: !isChecked ? "1" : "0",
    }));
  };

  const handleCollectionCheck = () => {
    setIsCollectionChecked(!isCollectionChecked);
    setForm((prevForm) => ({
      ...prevForm,
      show_in_collection: !isCollectionChecked ? "1" : "0",
    }));
  };

  const handleAutoCollectionCheck = () => {
    setIsAutoCollectionChecked(!isAutoCollectionChecked);
    setForm((prevForm) => ({
      ...prevForm,
      enable_auto_collection: !isAutoCollectionChecked ? "1" : "0",
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${invCatURL}/all`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      });
      const updatedData = response?.data?.data?.map((item) => {
        return {
          ...item,
        };
      });

      setData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGroupList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/investigation-category/group/data`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setGroupList(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchGroupList();
  }, [form]);
  console.log(isAutoCollectionChecked);
  const extraButtons = [];
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">
                Investigation Category List
              </h6>
              <div className="col">
                {/* Button trigger modal */}
                {accessPerm(22, 1) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleVerticallycenteredActivity"
                  >
                    <i className="fa-solid fa-plus"></i> Add New
                  </button>
                )}
                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleVerticallycenteredActivity"
                  tabIndex={-1}
                  style={{ display: "none" }}
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Add New Investigation Category
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body pb-0">
                        <div className="mb-3 row ">
                          <label
                            htmlFor="inputName"
                            className="col-sm-3 col-form-label d-flex justify-content-start"
                          >
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              onChange={onchange}
                              name="name"
                              type="text"
                              className="form-control"
                              id="inputName"
                              required
                            />
                          </div>
                        </div>

                        <div className="mb-3 row ">
                          <label
                            htmlFor="inputGroup"
                            className="col-sm-3 col-form-label d-flex justify-content-start"
                          >
                            Group
                          </label>
                          <div className="col-sm-9">
                            <select
                              defaultValue={groupId}
                              onChange={selectGroupHandler}
                              className="single-select form-select"
                            >
                              <option value={0}>Select a group</option>
                              {groupList.map((group) => (
                                <option key={group?.id} value={group.id}>
                                  {group.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label
                            htmlFor="inputInfo"
                            className="col-sm-3 col-form-label d-flex justify-content-start"
                          >
                            Info
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="info"
                              defaultValue={data?.info}
                              onChange={(e) => onchange(e)}
                              type="textarea"
                              className="form-control"
                              id="inputInfo"
                              rows={2}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row ">
                          <div className="col-sm-12">
                            <div className="form-check d-flex justify-content-end align-items-center">
                              <input
                                onChange={() => handleCollectionCheck()}
                                className="form-check-input mt-0 me-2"
                                type="checkbox"
                                checked={isCollectionChecked}
                                id="flexCheckCollChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckCollChecked"
                              >
                                Show in Collection
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <div className="col-sm-12">
                            <div className="form-check d-flex justify-content-end align-items-center">
                              <input
                                onChange={() => handleAutoCollectionCheck()}
                                className="form-check-input mt-0 me-2"
                                type="checkbox"
                                checked={isAutoCollectionChecked}
                                id="flexAutoCheckCollChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexAutoCheckCollChecked"
                              >
                                Enable Auto Collection
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <div className="col-sm-12">
                            <div className="form-check d-flex justify-content-end align-items-center">
                              <input
                                onChange={() => handleCheck()}
                                className="form-check-input mt-0 me-2"
                                type="checkbox"
                                checked={isChecked}
                                id="flexCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={createData}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={data}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestigationCategory;
