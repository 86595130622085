import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../ProcessAndProcedure/MedicalReports/MedicalReports.scss";
import { UserContext } from "../../Context/UserContextAPI";
import "./PrintRegistration.scss";
import jsPDF from "jspdf";
import "../Investigation/Report/Certificates/HeldUp.scss";
import "../Investigation/Report/Certificates/Unfit.scss";
import { format } from "date-fns";
import { enUS } from "date-fns/locale"; // Import the locale you want to use

const PrintAccountRegistration = () => {
  const pdfRef = useRef();
  const { state } = useLocation();
  const { currentUser, settingsData } = useContext(UserContext);

  const [userDetails, setUserDetails] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });
  const [totalStatus, setTotalStatus] = useState(0);

  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [introducerWiseUsers, setIntroducerWiseUsers] = useState([]);
  const [packageWiseUsers, setPackageWiseUsers] = useState([]);
  const [representativeWiseUsers, setRepresentativeWiseUsers] = useState([]);

  const now = new Date();
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(now);

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };

    data.forEach((userInfo) => {
      switch (userInfo?.user?.status) {
        case 1:
          counts.status1 = counts.status1 + 1;
          break;
        case 2:
          counts.status2 = counts.status2 + 1;
          break;
        case 3:
          counts.status3 = counts.status3 + 1;
          break;
        case 4:
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });

    setStatusCounts(counts);

    const total =
      counts.status1 + counts.status2 + counts.status3 + counts.status4;
    setTotalStatus(total);
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (row) => {
          return (
            <div className="text-center d-flex justify-content-center fw-bold">
              <span className="text-center">{row?.row?.index + 1}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: () => (
          <span className="d-flex justify-content-center">Date</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.createdAt ? (
                <>{`${info.row.original.user.createdAt.split("T")[0]}`}</>
              ) : (
                "---"
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.registration_no ? (
                <>{`${info.row.original.user.registration_no}`} </>
              ) : (
                "---"
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Name</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.f_name ? (
                <>{`${info.row.original.user.f_name}`}</>
              ) : (
                "---"
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.passport_no", {
        header: () => (
          <span
            className="d-flex justify-content-center
        "
          >
            Passport No
          </span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.passport_no ? (
                <>{`${info.row.original.user?.passport_no}`}</>
              ) : (
                "---"
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span
            className="d-flex justify-content-center
        "
          >
            Agency
          </span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.introducer_user?.f_name ? (
                <>{`${info.row.original.introducer_user?.f_name}`}</>
              ) : (
                "---"
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("country.shortname", {
        header: () => (
          <span className="d-flex justify-content-center">Country</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.country?.name ? (
                <>{`${info.row.original.country.name}`}</>
              ) : (
                "---"
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.status", {
        header: () => (
          <span className="d-flex justify-content-center">Total Bill</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-end">
              <span className="text-left">
                {info?.row?.original?.user_payment?.amount}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("user.status", {
        header: () => (
          <span className="d-flex justify-content-center">Paid Bill</span>
        ),
        cell: (info, index) => {
          return (
            <div className="d-flex justify-content-end">
              <span className="text-left">
                {info?.row?.original?.user_payment?.paid_amount}
              </span>
            </div>
          );
        },
      }),
    ],
    []
  );

  const totalPaidBill = state?.checkedUsers?.reduce(
    (sum, users) => sum + (users?.user_payment?.paid_amount || 0),
    0
  );
  const totalBill = state?.checkedUsers?.reduce(
    (sum, users) => sum + (users?.user_payment?.amount || 0),
    0
  );

  const dueBill = totalBill - totalPaidBill;

  const table = useReactTable({
    data: state?.checkedUsers,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  const getUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/all`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        calculateStatus(data?.userDetails);
        // setUserDetails(data?.userDetails);
        if (data?.userDetails) {
          setUserDetails(data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const pdfGenerate = () => {
    const input = pdfRef.current;
    const doc = new jsPDF("p", "mm");

    const addHeader = () => {
      let headerDate;
      let headerText;
      headerText = state?.searchedData?.introducer?.label
        ? state?.searchedData?.introducer?.label
        : "All Registration";

      const dateFormat = "dd-MMM-yyyy";
      let fromDate;
      let toDate;
      const fromDateValue = state?.searchedData?.from_date;
      const toDateValue = state?.searchedData?.to_date;

      fromDate = format(new Date(fromDateValue || new Date()), dateFormat, {
        locale: enUS,
      });

      toDate = format(new Date(toDateValue || new Date()), dateFormat, {
        locale: enUS,
      });

      headerDate = `From ${fromDate} To ${toDate}`;

      const fontSize = 9;

      const containerWidth = doc.internal.pageSize.width;

      doc.setFontSize(fontSize);

      // Calculate text width for headerText
      const textWidth = doc.getTextWidth(headerText);

      // Center headerText within the container
      const headerTextXCoordinate = (containerWidth - textWidth) / 2;
      doc.text(headerText, headerTextXCoordinate, 10);

      const headerFromDate = headerDate; // Assign headerFromDate a value

      const headerFromDateWidth = doc.getTextWidth(headerFromDate);

      // Center headerFromDate within the container
      const headerFromDateXCoordinate =
        (containerWidth - headerFromDateWidth) / 2;

      // Calculate the yCoordinate for centering headerFromDate below headerText
      const headerTextHeight = doc.getTextDimensions(headerText, {
        fontSize,
      }).h;
      const headerFromDateHeight = doc.getTextDimensions(headerFromDate, {
        fontSize,
      }).h;
      const totalHeaderFromDateHeight = headerTextHeight + headerFromDateHeight;
      const yCoordinateFromDate = 10 + totalHeaderFromDateHeight / 2; // Adjust 2 for spacing

      doc.text(headerFromDate, headerFromDateXCoordinate, yCoordinateFromDate);
    };

    const addFooter = () => {
      const xCoordinate = doc.internal.pageSize.width / 2;

      doc.setFontSize(9);

      const userText = `Created at ${formattedDate} by ${currentUser?.f_name}`;
      const userTextWidth = doc.getTextWidth(userText);
      doc.text(
        userText,
        xCoordinate - userTextWidth / 2,
        doc.internal.pageSize.height - 10
      );

      const copyrightText = `Copyright © ${new Date().getFullYear()} ${
        settingsData?.website_name
      }`;
      const copyrightTextWidth = doc.getTextWidth(copyrightText);
      doc.text(
        copyrightText,
        xCoordinate - copyrightTextWidth / 2,
        doc.internal.pageSize.height - 5
      );
    };

    // Adjust the font size and add borders to thead
    const tableOptions = {
      theme: "grid",
      margin: {
        left: 10,
        right: 10,
      },
      styles: {
        fontSize: 8,
        halign: "center",
        valign: "middle",
        cellPadding: 0.5,
        cellWidth: "auto",
      },
      headStyles: {
        fillColor: [200, 200, 200],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      bodyStyles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
    };

    // Add the header on the first page
    addHeader();

    // Add content and footer to each page
    let pageNumber = 1;
    doc.autoTable({
      beforePageContent: function () {
        if (pageNumber > 1) {
          addHeader(); // Add header on every page except the first one
        }
      },
      afterPageContent: function () {
        addFooter(); // Add footer on every page
        pageNumber++;
      },
      html: input,
      startY: 20,
      ...tableOptions,
    });

    // Save the PDF
    doc.save("Download.pdf");
  };

  useEffect(() => {
    setTimeout(() => {
      pdfGenerate();
    }, 1200);
  }, []);

  useEffect(() => {
    setUserDetails(state?.checkedUsers);
    getUserDetails();
  }, []);

  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="print-reg-header">
              <div className="card shadow-none mb-3">
                <div className="border p-3 rounded">
                  {state?.searchedData?.introducer ? (
                    <h6 className="mb-0 text-uppercase text-center fw-bold">
                      {state?.searchedData?.introducer?.label}{" "}
                    </h6>
                  ) : (
                    <h6 className="mb-0 text-uppercase text-center fw-bold text-dark">
                      All Registration
                    </h6>
                  )}
                  <div className="mb-0 text-center fw-light">
                    From{" "}
                    {state?.searchedData?.from_date
                      ? format(
                          new Date(state?.searchedData?.from_date),
                          "dd-MMM-yyyy",
                          { locale: enUS } // or use es for Spanish, adjust as needed
                        )
                      : format(new Date(), "dd-MMM-yyyy", {
                          locale: enUS,
                        })}{" "}
                    To{" "}
                    {state?.searchedData?.to_date
                      ? format(
                          new Date(state?.searchedData?.to_date),
                          "dd-MMM-yyyy",
                          { locale: enUS } // or use es for Spanish, adjust as needed
                        )
                      : format(new Date(), "dd-MMM-yyyy", { locale: enUS })}
                  </div>
                </div>
              </div>
            </div>

            <table
              className="table align-middle table-hover table-bordered mb-0 mt-3 rounded"
              ref={pdfRef}
            >
              <thead className="bg-primary">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, i) => (
                      <th
                        key={i}
                        id={`th-${i}`}
                        className="text-light p-1"
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, i) => {
                  const {
                    original: {
                      user: {
                        is_radiology_done,
                        is_pathology_done,
                        is_physical_done,
                      },
                    },
                  } = row;

                  return (
                    <tr key={i}>
                      {row.getVisibleCells().map((cell, i) => (
                        <td key={i} className="p-1">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
              <tr className="w-100 border">
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border fw-bold ps-1">Total Bill</td>
                <td
                  className="border ps-1"
                  colSpan={2}
                  style={{ textAlign: "right" }}
                >
                  {totalBill}
                </td>
              </tr>
              <tr className="w-100 border">
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border fw-bold ps-1">Paid Bill</td>
                <td
                  className="border ps-1"
                  colSpan={2}
                  style={{ textAlign: "right" }}
                >
                  {totalPaidBill}
                </td>
              </tr>
              <tr className="w-100 border">
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border-0"></td>
                <td className="border fw-bold ps-1">Commission Bill</td>
                <td
                  className="border ps-1"
                  colSpan={2}
                  style={{ textAlign: "right" }}
                >
                  {dueBill}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="print-reg-footer">
          <div className="d-flex flex-column align-items-center justify-content-center pb-3 text-dark">
            <div>
              Created at {formattedDate} by {currentUser?.f_name}
            </div>
            <div>
              Copyright &copy; {new Date().getFullYear()}{" "}
              {settingsData?.website_name}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintAccountRegistration;
