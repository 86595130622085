import axios from "axios";
import jwt_decode from "jwt-decode";
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-hot-toast";

// export UserContext for access--------
export const UserContext = createContext();

const UserContextAPI = ({ children }) => {
  const notificationURL = `${process.env.REACT_APP_API_BASE_URL}/unchecked-notifications`;
  const [currentUser, setCurrentUser] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingNotification, setLoadingNotification] = useState(true);
  const [introducerUsers, setIntroducerUsers] = useState([]);
  const [countryUsers, setCountryUsers] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [delegateUsers, setDelegateUsers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [settingsData, setSettingsData] = useState([]);
  const [representativeUsers, setRepresentativeUsers] = useState([]);
  const [fetchRepresentativeUsers, setFetchRepresentativeUsers] = useState([]);
  const [introducers, setIntroducers] = useState([]);
  const [fetchUsers, setFetchUsers] = useState([]);
  const [xrayStatus, setXrayStatus] = useState(true);
  const [searchedData, setSearchedData] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [lastFetchedNotificationId, setLastFetchedNotificationId] = useState();
  const [totalNotifications, setTotalNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [closed, setClosed] = useState(false);
  let token = window.localStorage.getItem("jwtToken");
  const userId = currentUser?.id;
  const observer = useRef();

  const methodSignin = async (signinData) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/signin`,
        signinData,
        {
          headers: {
            "Content-Type": "application/json",
            // authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      if (res) {
        setLoading(false);
        setCurrentUser(res?.data?.data?.userQuery);
        setPermissions(res?.data?.data?.permissions);
      }
      return res;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const accessPerm = (moduleId, activityId) => {
    let moduleActivitys = permissions?.find(
      (moduleActivity) =>
        moduleActivity.module_id === moduleId &&
        moduleActivity.activity_id === activityId
    );
    if (moduleActivitys) {
      return true;
    } else {
      return false;
    }
  };

  const modulePerm = (moduleId) => {
    let modules = permissions?.find((module) => module.module_id === moduleId);
    if (modules) {
      return true;
    } else {
      return false;
    }
  };

  const fetchAPI = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/user/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      if (res.status === 200) {
        setLoading(false);
        setCurrentUser(data?.userQuery);
        setPermissions(data?.permissions);
      } else {
        toast.error("user not found");
      }
    } catch (error) {
      // console.error(error);
      // toast.error(error?.response?.data?.message);
    }
  };

  const smsAPI = async (mobileNumber, message) => {
    try {
      const res = await axios.post(
        `https://api.greenweb.com.bd/api.php?token=${process.env.REACT_APP_SMS_API_TOKEN}&to=${mobileNumber}&message=${message}`
      );
      if (res.status === 200) {
        return res;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchIntroducerUserAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users/introducer`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setIntroducerUsers(data);
    } catch (error) {
      // console.log(error);
    }
  };
  const fetchCountryUserAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users/country`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setCountryUsers(data);
    } catch (error) {
      // console.log(error);
    }
  };
  const fetchCompanyUserAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users/company`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setCompanyUsers(data);
    } catch (error) {
      // console.log(error);
    }
  };
  const fetchDelegateUserAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users/delegate`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setDelegateUsers(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchPackagesAPI = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/packages`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
        },
      })
      .then((res) => {
        setPackages(res?.data?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/settings`)
      .then((response) => {
        const allData = response.data.data[0];
        setSettingsData(allData);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const fetchRepresentativeUserAPI = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users/representative`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = response?.data?.data;
      setRepresentativeUsers(data);
      setFetchRepresentativeUsers(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchUserAPI = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = response?.data?.data?.data;
      setFetchUsers(data);
    } catch (error) {
      // console.log(error);
    }
  };

  // const lastNotificationRef = useCallback(
  //   (node) => {
  //     if (loadingNotification) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loading, hasMore]
  // );

  // const getNotifications = (page) => {
  //   setLoadingNotification(true);
  //   const roleId = currentUser?.role_id;
  //   if (roleId) {
  //     axios
  //       .post(notificationURL, {
  //         userId,
  //         roleId,
  //         page,
  //         limit: 10,
  //         lastFetchedNotificationId,
  //       })
  //       .then((response) => {
  //         const newNotifications = response?.data?.data || [];
  //         setTotalNotifications(response?.data?.pagination?.total);
  //         setLastFetchedNotificationId(
  //           response?.data?.lastFetchedNotificationId
  //         ); // Update the ID

  //         setNotifications((prevNotifications) =>
  //           page === 1
  //             ? newNotifications
  //             : [...prevNotifications, ...newNotifications]
  //         );
  //         setLoadingNotification(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching notifications:", error);
  //         setLoadingNotification(false);
  //       });
  //   }
  // };

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.fav_image}`;
  }, [settingsData?.fav_image]); // Add data?.fav_image as dependency

  // useEffect(() => {
  //   if (currentUser?.role_id) {
  //     getNotifications(1); // Fetch the first page of notifications on mount
  //   }
  // }, [currentUser?.role_id]);

  // useEffect(() => {
  //   if (page > 1) {
  //     getNotifications(page);
  //   }
  // }, [page]);

  useEffect(() => {
    fetchPackagesAPI();
    getData();
    fetchUserAPI();
    fetchRepresentativeUserAPI();
    fetchDelegateUserAPI();
    fetchIntroducerUserAPI();
    fetchCountryUserAPI();
    fetchCompanyUserAPI();
    // getNotifications(1);
    if (token) {
      let decoded = jwt_decode(token);
      const { id } = decoded;
      fetchAPI(id);
    }
    // // else {
    //   window.location.href = "/";
    // // }
  }, [token]);

  // useEffect(() => {
  //   getNotifications(1);
  // }, []);

  const methods = {
    currentUser,
    setCurrentUser,
    accessPerm,
    modulePerm,
    smsAPI,
    loading,
    setLoading,
    methodSignin,
    introducerUsers,
    companyUsers,
    countryUsers,
    delegateUsers,
    packages,
    settingsData,
    representativeUsers,
    fetchRepresentativeUsers,
    introducers,
    fetchUsers,
    xrayStatus,
    setXrayStatus,
    searchedData,
    setSearchedData,
    closed,
    setClosed,
    token,
    notifications,
    setNotifications,
    totalNotifications,
    setTotalNotifications,
    lastFetchedNotificationId,
    setLastFetchedNotificationId,
    // lastNotificationRef,
    hasMore,
    setHasMore,
    // getNotifications,
    loadingNotification,
    setLoadingNotification,
  };
  return (
    <>
      <UserContext.Provider value={methods}>{children}</UserContext.Provider>
    </>
  );
};

export default React.memo(UserContextAPI);
