import React, { useEffect } from "react";
import MedicalFormCopy from "../Investigation/Report/MedicalForm/MedicalFormCopy";
import { useLocation } from "react-router-dom";
import UserInvoiceCopy from "../Investigation/Invoice/UserInvoiceCopy";
import StaticXray from "../StaticXray/StaticXray";

const PrintInvoiceFormXray = () => {
  const patientData = 1;

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 2000);
  }, []);

  return (
    <div>
      <UserInvoiceCopy stateData={patientData} />

      <div style={{ pageBreakAfter: "always" }}></div>

      <MedicalFormCopy stateData={patientData} />

      <div style={{ pageBreakAfter: "always" }}></div>

      <StaticXray stateData={patientData} />
    </div>
  );
};

export default PrintInvoiceFormXray;
