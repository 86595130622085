import axios from "axios";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import JsBarcode from "jsbarcode";
import "./MedicalExamination.scss";
import QRCode from "qrcode.react";
import { format } from "date-fns";

const PrintMedicalExamination = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const pdfRef = useRef();
  const { accessPerm, settingsData } = useContext(UserContext);

  const [physicalData, setPhysicalData] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [state, setState] = useState([]);
  const [xrayImage, setXrayImage] = useState("");
  const [pathologyData, setPathologyData] = useState([]);
  const [radiologyComment, setRadiologyComment] = useState();
  const [radiologyRiskFactor, setRadiologyRiskFactor] = useState();
  const [riskFactor, setRiskFactor] = useState("");
  const [pathologyCommentRisk, setPathologyCommentRisk] = useState();
  const [physicalCommentRisk, setPhysicalCommentRisk] = useState();
  const [physicalRiskFactor, setPhysicalRiskFactor] = useState("");

  const { userId } = useParams();
  const urlWithBase64ID = `https://${process.env.REACT_APP_API_BASE_URL}/medical-verification/result/report/print/${userId}`;

  const registrationNo = state?.user?.registration_no;

  const formattedBirthDate = state?.user?.date_of_birth
    ? (() => {
        const date = new Date(state?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  const formatDeliveryTime = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const addDaysToDate = (dateString, hours) => {
    // Check if dateString is undefined or not a valid date
    if (!dateString || isNaN(new Date(dateString))) {
      return "";
    }
    const days = Math.floor(hours || 0 / 24);

    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const receivingDate = state?.user_payment?.createdAt;
  const preparationDuration =
    state?.user?.role_id !== 5
      ? state?.package?.preparation_duration ?? 0
      : state?.max_preparation_duration ?? 0;
  const newDate = addDaysToDate(receivingDate, preparationDuration);

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/user-details/test/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setState(response?.data?.data?.userDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(registrationNo), {
    height: 20,
    margin: 0,
    displayValue: true,
  });

  const patientIdDataUrl = patientIdBarcode.toDataURL();

  const getRadiologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${2}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setRadiologyData(response?.data);
        console.log(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPathologyData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPathologyData(response?.data);
      })
      .catch((err) => console.log(err));
  };

  const getPathologyComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-pathology-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;
      setPathologyCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  const getPhysicalComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const commentsData = response?.data?.data;

      setPhysicalCommentRisk(commentsData?.comments);
    } catch (error) {
      console.error(error);
    }
  };

  async function getComments() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const comments = response?.data?.data || [];

      setRadiologyComment(comments[0]?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  const getComment = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/get-physical-comments/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { comments } = response?.data?.data;
      setPhysicalRiskFactor(comments);
    } catch (error) {
      console.error(error);
    }
  };

  async function getRiskFactor() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-risk-factor/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const risk = response?.data?.data || [];

      setRadiologyRiskFactor(risk[0]?.comments);
      setRiskFactor(risk[0]?.comments);
    } catch (error) {
      console.log(error);
    }
  }

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyData, state?.gender?.id]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        console.log(item?.user_file);
        if (item.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item.user_file);
        }
      });
  }, [radiologyData]);

  useEffect(() => {
    getUserDetail();
    getRadiologyData();
    getPhysicalTests();
    getPathologyData();
    getComments();
    getRiskFactor();
    getPhysicalComment();
    getPathologyComment();
  }, []);

  useEffect(() => {
    userId && getComments();
    userId && getComment();
    userId && getRiskFactor();
  }, [userId]);

  return (
    <>
      <div className="header-image">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${settingsData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <section className="medical-examination mx-3" ref={pdfRef}>
        <div className="title-top">
          <h6 className="text-center fs-6 py-1 fw-bold text-uppercase border border-dark mt-0 text-dark">
            {state?.user?.status &&
              `          Medical Examination Report for patient in
            ${state?.country?.name.toUpperCase()}`}
          </h6>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-sm-5 h-100">
              <div
                className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center"
                style={{ height: "90px" }}
              >
                <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                  DATE: {formatDeliveryTime(newDate, "dd-MM-yyyy hh:mm a")}
                </p>
                <img
                  src={patientIdDataUrl}
                  alt=""
                  className="w-100 img-fluid"
                  style={{
                    height: "60px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                />
                {/* <p
                  className="p-0 mb-2 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark"
                  
                >
                  REG DATE:{" "}
                  {formatDate(state?.user?.createdAt, "dd-MM-yyyy hh:mm a")}
                </p> */}
              </div>
            </div>
            <>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {state?.user?.image ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.user?.image}`}
                      className="img-fluid"
                      alt="patient"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <img
                      src="./dummy.jpg"
                      alt="patient"
                      className="img-fluid"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-2 h-100">
                <div
                  className="report-avatar border border-end-0 border-dark"
                  style={{ height: "90px" }}
                >
                  {state?.user?.finger_print ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/users/${state?.user?.finger_print}`}
                      className="img-fluid"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <div
                      className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      No Fingerprint Found
                    </div>
                  )}
                </div>
              </div>
            </>

            {/* <div className="col-sm-2 h-100">
              <div
                className="report-avatar border border-end-0 border-start-0 border-dark"
                style={{ height: "90px", width: "100%", overflow: "hidden" }}
              >
                <table
                  className="table mt-sm-0 mb-0 border border-top-0 border-bottom-0 border-end-0 border-dark"
                  id="xrayImage"
                  style={{
                    height: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: 0,
                          height: "100%",
                          width: "100%",
                          border: "0",
                          borderTop: "0",
                        }}
                      >
                        {xrayImage ? (
                          <img
                            src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                            alt="X-Ray film"
                            className="img-fluid"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          state?.user?.role_id !== 5 && (
                            <div
                              className="text-center d-flex align-items-center justify-content-center fw-bold"
                              style={{
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              No X-Ray film found
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                X-RAY IMAGE
              </p>
            </div> */}
            <div className="col-sm-3 h-100">
              <div
                className="d-flex justify-content-center align-items-center w-100 border border-dark"
                style={{ height: "90px" }}
              >
                <Link to={urlWithBase64ID}>
                  <QRCode value={urlWithBase64ID} size={85} />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="col-sm-12">
              <div className="row text-dark">
                <table
                  className="responsive-width1 table-bordered"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    {/* Row 1 - Name and Client ID */}
                    <tr>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Name
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {state?.user?.f_name || "---"}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        Client ID
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        style={{ width: "25%" }}
                      >
                        {state?.user?.registration_no || "---"}
                      </td>
                    </tr>

                    {/* Row 2 - Gender and Passport No */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Gender
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.gender?.name || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Passport No
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.passport_no || "---"}
                      </td>
                    </tr>

                    {/* Row 3 - Date of Birth and Travelling To */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.role_id !== 5 ? `Date of Birth` : `Age`}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.role_id !== 5
                          ? formattedBirthDate || "---"
                          : state?.user?.age || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Travelling To
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.country?.name || "---"}
                      </td>
                    </tr>

                    {/* Row 4 - Father Name and Contact Number */}
                    {state?.user?.role_id !== 5 && (
                      <tr>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Father Name
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {state?.father_name || "---"}
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          Contact Number
                        </td>
                        <td className="text-uppercase border border-dark ps-1 align-middle">
                          {state?.user?.mobile || "---"}
                        </td>
                      </tr>
                    )}

                    {/* Row 5 - Nationality and Agency */}
                    <tr>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        Nationality
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.national_country?.nationality || "---"}
                      </td>
                      <td className="text-uppercase border border-dark ps-1 align-middle">
                        {state?.user?.role_id !== 5
                          ? `${settingsData?.introducer_label}`
                          : `Reference`}
                      </td>
                      <td
                        className="text-uppercase border border-dark ps-1 align-middle"
                        colSpan={3}
                      >
                        {state?.user?.role_id !== 5
                          ? state?.introducer_user?.f_name || "---"
                          : state?.user?.reference || "---"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="below-table w-100 p-1 mb-1 text-dark"
                  style={{ position: "relative", bottom: "0" }}
                >
                  History of past ilness: 1) Allergy: ABSENT 2) Psychiatric and
                  neurological disorders (Epilepsy, depression): ABSENT 3)
                  Others: NAD
                </p>
                <p
                  className="below-table w-100 p-1 m-0 border border-dark text-dark"
                  style={{ position: "relative", bottom: "5px" }}
                >
                  I hereby permit the {settingsData?.website_name} and the
                  undersigned physician to furnish such information the company
                  many need pertaining to my health status and pertinent and
                  medical findings and do hereby release them from any and all
                  legal responsibility by doing so. I also certify that my
                  medical history contained above is true and any false
                  statement will disqualify me from my employment, benefits and
                  claim.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-flex flex-column flex-sm-row">
            <div className="col-sm-6">
              <table className="table border border-dark mb-0 fw-bold">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center"
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Medical Examination
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      TYPE OF EXAMINATION
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      RESULTS
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedData)?.map(
                    ([categoryId, categoryData]) => (
                      <React.Fragment key={categoryId}>
                        <tr>
                          <td
                            colSpan="3"
                            className="text-nowrap text-uppercase"
                          >
                            {categoryData?.name}
                          </td>
                        </tr>
                        {categoryData?.items?.map((data, index) => {
                          // Log the data object for debugging
                          return (
                            <tr key={index}>
                              <td className="border text-start py-1 ps-5 border-dark text-dark">
                                {data?.investigation?.name || "---"}
                              </td>
                              <td className="border text-start py-1 border-dark text-dark">
                                {data?.result || "---"}{" "}
                                {data?.result &&
                                  data?.result !== "N/A" &&
                                  data?.investigation?.investigation_tests[0]
                                    ?.uom?.name}
                              </td>
                              <td className="border text-start py-1 border-dark text-dark">
                                {data?.investigation?.investigation_tests[0]
                                  ?.report_normal_value || ""}
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    )
                  )}
                  {radiologyData && (
                    <tr>
                      <td className="text-nowrap text-uppercase">
                        X-RAY INVESTIGATION
                      </td>
                    </tr>
                  )}
                  {radiologyData
                    ? radiologyData?.map((item, i) => (
                        <tr key={i}>
                          <td className="border text-start py-1 ps-5 border-dark">
                            {item?.investigation_test?.name === "Findings"
                              ? "Comment"
                              : `${item?.investigation_test?.name}`}
                          </td>
                          <td
                            className={`border text-start py-1 border-dark  ${
                              item?.test_id === 35 &&
                              item?.investigation_test?.name === "Findings"
                                ? item?.result === "Normal CXR"
                                  ? "text-success text-uppercase"
                                  : "text-primary"
                                : ""
                            }`}
                            colSpan={2}
                          >
                            {item?.result}
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>

            <div className="d-flex flex-column justify-content-start col-sm-6 ps-sm-3 ps-0">
              <table className="table border border-dark mb-0 fw-bold">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase text-center"
                      style={{ color: "black" }}
                      colSpan={3}
                    >
                      Laboratory Investigation
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={1}
                      className="border text-center py-1 border border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      TYPE OF EXAMINATION
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      RESULTS
                    </th>
                    <th
                      className="border text-center border py-1 border-dark text-nowrap"
                      style={{ color: "black" }}
                    >
                      REF. VALUE
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Object.entries(groupedPathologyData)?.map(
                    ([categoryId, categoryData]) => (
                      <React.Fragment key={categoryId}>
                        <tr>
                          <td
                            colSpan="3"
                            className="text-nowrap text-uppercase"
                          >
                            {categoryData?.name}
                          </td>
                        </tr>
                        {categoryData?.items?.map((data, index) => (
                          <tr key={index}>
                            <td className="border text-start py-1 ps-5 border-dark  text-dark">
                              {data?.investigation_test?.investigation?.name ||
                                "---"}
                            </td>
                            <td className="border text-start py-1 border-dark  text-dark">
                              {data?.result || "---"}{" "}
                              {data?.result &&
                                data?.result !== "N/A" &&
                                data?.investigation_test?.uom?.name}
                            </td>
                            <td className="border text-start py-1 border-dark  text-dark">
                              {data?.investigation_test?.report_normal_value ||
                                ""}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
              <div className="w-100 mt-2 mx-auto">
                <div className="col-sm-12 d-flex text-dark border border-dark p-1">
                  Problematic Test: <div className="ps-1"></div>
                  <div
                    dangerouslySetInnerHTML={{ __html: physicalCommentRisk }}
                  />
                  <div className="ps-1"></div>
                  {pathologyCommentRisk ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${pathologyCommentRisk}`,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <div className="ps-1"></div>
                  {radiologyData?.find(
                    (report) =>
                      report?.investigation_test?.id === 35 &&
                      report?.result !== "Normal CXR" &&
                      report?.result !== ""
                  ) && (
                    <>
                      <span
                        style={{
                          color:
                            radiologyData?.find(
                              (report) => report?.investigation_test?.id === 35
                            )?.result !== "Normal CXR"
                              ? "red"
                              : "black",
                        }}
                      >
                        X-Ray
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="container">
                <div className="row w-100">
                  <div className="text-start">
                    <p className="text-uppercase m-0 fst-italic text-dark p-0 m-0">
                      Mentioned above is the medical report for{" "}
                      {state?.user?.f_name}, who is
                      <span
                        className="fw-bold text-uppercase fs-6 fst-normal p-0 m-0"
                        style={{
                          color: `${
                            state?.user?.status === 1
                              ? "black"
                              : state?.user?.status === 2
                              ? "#5cb85c"
                              : state?.user?.status === 3
                              ? "red"
                              : state?.user?.status === 4 && "#0000FF"
                          }`,
                        }}
                      >
                        {state?.user?.status === 1 && ` PROCESSING`}
                        {state?.user?.status === 2 && ` FIT`}
                        {state?.user?.status === 3 && ` UNFIT`}
                        {state?.user?.status === 4 && ` HELD UP`}
                      </span>{" "}
                      for the above mentioned job according to the medical
                      criteria.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="text-start text-dark p-0 mt-1">
              Printed By: {state?.user?.created_by}
            </div>
            <div className="text-dark">
              <div
                className="border-top mt-0 border-dark"
                style={{ width: "125px" }}
              ></div>
              CHECKED BY
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end gap-2 mt-2 unfit-button p-2">
        {accessPerm(68, 23) && (
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>
        )}

        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default PrintMedicalExamination;
