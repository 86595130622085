import axios from "axios";
import JsBarcode from "jsbarcode";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MedicalForm.scss";
import { UserContext } from "../../../../Context/UserContextAPI";
import { format } from "date-fns";

const MedicalFormCopy = ({ stateData, stateDataInv }) => {
  const { accessPerm } = useContext(UserContext);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { state } = useLocation();
  const patientData = state?.record;
  const invCsv = patientData?.package?.investigation_id;
  const invIds = invCsv?.split(",");

  const [systemData, setSystemData] = useState({});
  const userId = state?.record?.user?.id;

  const [physicalInvTest1, setPhysicalInvTests1] = useState([]);
  const [radiologyInvTest1, setRadiologyInvTests1] = useState([]);
  const [pathologyInvTest1, setPathologyInvTests1] = useState([]);
  const [pathologyResults, setPathologyResults] = useState([]);
  const [pathologyResultData, setPathologyResultData] = useState([]);
  const [physicalResults, setPhysicalResults] = useState([]);
  const [radiologyData, setRadiologyData] = useState([]);
  const [xrayImage, setXrayImage] = useState("");
  const [pathologyData, setPathologyData] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);

  const [phyComment, setPhyComment] = useState("");

  const [finalComment, setFinalComment] = useState("");
  const urlWithBase64ID = `https://${process.env.REACT_APP_API_BASE_URL}/medical-verification/result/report/print/${patientData?.user?.id}`;

  let status = "";
  let riskFactor = "";

  const formattedBirthDate = patientData?.user?.date_of_birth
    ? (() => {
        const date = new Date(patientData?.user.date_of_birth);
        const day = date.getDate();
        const month = date.toLocaleString("en-GB", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "---";

  const birthYear = state?.record?.user?.date_of_birth
    ? new Date(state?.record?.user?.date_of_birth).getFullYear()
    : null;

  const currentYear = new Date().getFullYear();
  const age = birthYear ? `${currentYear - birthYear} Y` : "---";

  function formatDate(inputDate) {
    try {
      const date = new Date(inputDate);

      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  }

  // const pathologyStatusCheckHandler = (reports) => {
  //   reports?.forEach((report) => {
  //     // check hemoglobin status
  //     if (report.investigation_id === 15) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 16 || report.result < 11.8) {
  //         status += "Hemoglobin: " + report?.result + ", ";
  //       }
  //     }

  //     // check R.B.S status
  //     if (report.investigation_id === 16) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 7.5) {
  //         status += "R.B.S.: " + report?.result + ", ";
  //       } else if (report.result > 7.5 && report.result <= 9) {
  //         riskFactor += "30% Risk for Diabetes, ";
  //       } else if (report.result > 9 && report.result <= 12) {
  //         riskFactor += "20% Risk for Diabetes, ";
  //       } else if (report.result > 12 && report.result <= 15) {
  //         riskFactor += "40% Risk for Diabetes, ";
  //       } else if (report.result > 15 && report.result <= 18) {
  //         riskFactor += "50% Risk for Diabetes, ";
  //       } else if (report.result > 18 && report.result <= 25) {
  //         riskFactor += "75% Risk for Diabetes, ";
  //       } else if (report?.result > 25) {
  //         riskFactor += "90~100% Risk for Diabetes, ";
  //       }
  //     }

  //     // check S. Creatinine status
  //     if (report.investigation_id === 17) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 1.3) {
  //         status += "S. Creatinine: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 2) {
  //         riskFactor += "35% Risk for S. Creatinine, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         riskFactor += "60% Risk for S. Creatinine, ";
  //       } else if (report.result > 3) {
  //         riskFactor += "90~100% Risk for S. Creatinine, ";
  //       }
  //     }

  //     // check urea status
  //     // if (report.investigation_id === 18) {
  //     //   if (report.result === "---" || report.result === "") {
  //     //     // Do nothing for "---" result
  //     //   } else if (report.result >= 5 && report.result <= 24) {
  //     //   } else if (report.result !== "Negative") {
  //     //     status += "Urea: " + report?.result + ", ";
  //     //   }
  //     // }

  //     // check S.Bilirubin status
  //     if (report.investigation_id === 20) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 0.99) {
  //         status += "S.Bilirubin: " + report?.result + ", ";
  //       } else if (report.result > 1.3 && report.result <= 1.6) {
  //         riskFactor += "35% Risk for Jaundice, ";
  //       } else if (report.result > 1.6 && report.result <= 2) {
  //         riskFactor += "50% Risk for Jaundice, ";
  //       } else if (report.result > 2 && report.result <= 3) {
  //         riskFactor += "75% Risk for Jaundice, ";
  //       } else if (report.result > 3) {
  //         riskFactor += "90~100% Risk for Jaundice, ";
  //       }
  //     }

  //     // check Malarial Parasite status
  //     if (report.investigation_id === 25) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Found") {
  //         status += "Malarial Parasite: Found, ";
  //       }
  //     }

  //     // check SGPT status
  //     if (report.investigation_id === 23) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 41) {
  //         status += "SGPT: " + report?.result + ", ";
  //       } else if (report.result > 60 && report.result <= 80) {
  //         riskFactor += "20% Risk for SGPT, ";
  //       } else if (report.result > 80 && report.result <= 100) {
  //         riskFactor += "25% Risk for SGPT, ";
  //       } else if (report.result > 100 && report.result <= 140) {
  //         riskFactor += "40% Risk for SGPT, ";
  //       } else if (report.result > 140 && report.result <= 200) {
  //         riskFactor += "75% Risk for SGPT, ";
  //       } else if (report.result > 200) {
  //         riskFactor += "90~100% Risk for SGPT, ";
  //       }
  //     }

  //     // check SGOT status
  //     if (report.investigation_id === 24) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > 38) {
  //         status += "SGOT: " + report?.result + ", ";
  //       }
  //     }

  //     // check C.U. Sugar status
  //     if (report.investigation_id === 21) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result > "NIL") {
  //         status += "C.U. Sugar: Nill, ";
  //       }
  //     }

  //     // check HBsAg status
  //     if (report.investigation_id === 26) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         status += "HBsAg: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         riskFactor += "100% Risk for Blood(HBsAg), ";
  //       }
  //     }

  //     // check VDRL status
  //     if (report.investigation_id === 27) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "Reactive") {
  //         status += "VDRL: " + report?.result + ", ";
  //         riskFactor += "75% Risk for Blood(VDRL), ";
  //       } else if (report.result === "W. Reactive") {
  //         status += "VDRL: " + report?.result + ", ";
  //       }
  //     }

  //     // check TPHA status
  //     if (report.investigation_id === 28) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         status += "TPHA: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         status += "TPHA: " + report?.result + ", ";
  //         riskFactor += "100% Risk for Blood(TPHA), ";
  //       }
  //     }

  //     // check Anti-HCV status
  //     if (report.investigation_id === 29) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         status += "Anti-HCV: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         status += "Anti-HCV: " + report?.result + ", ";
  //         riskFactor += "100% Risk for Blood(Anti-HCV), ";
  //       }
  //     }

  //     // check HIV 1 - 2 status
  //     if (report.investigation_id === 30) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (report.result === "W. Positive") {
  //         status += "HIV 1 - 2: " + report?.result + ", ";
  //       } else if (report.result === "Positive") {
  //         status += "HIV 1 - 2: " + report?.result + ", ";
  //         riskFactor += "100% Risk for Blood(HIV), ";
  //       }
  //     }

  //     // check pregnancy status
  //     if (report.investigation_id === 22) {
  //       if (
  //         report.result === "" ||
  //         report.result === "N/A" ||
  //         report.result === "---"
  //       ) {
  //         // Do nothing for "---" result
  //       } else if (
  //         report.result === "W. Positive" ||
  //         report.result === "Positive"
  //       ) {
  //         status += "Pregnancy: " + report?.result + ", ";
  //       }
  //     }
  //   });
  // };

  function divideArray(array) {
    if (!array || !Array.isArray(array)) {
      return [[], []]; // Handle the case where the input is invalid
    }

    const length = array?.length;
    const chunkSize = Math.ceil(length / 2);

    const result = [[], []];

    for (let i = 0; i < length; i++) {
      const chunkIndex = Math.floor(i / chunkSize);
      result[chunkIndex].push(array[i]);
    }
    return result;
  }

  function addDaysToDate(date, days) {
    const isValidDate = (d) => d instanceof Date && !isNaN(d);

    if (!isValidDate(date)) {
      console.error("Invalid date value:", date);
      return null; // Or handle invalid date as needed
    }

    let result = new Date(date);
    result.setDate(result.getDate() + days);

    return result.toISOString();
  }

  function getPathologyData() {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        setPathologyData(response?.data);
        setPathologyResultData(response?.data);
        // pathologyStatusCheckHandler(response?.data);
        const dividedIntoTwo = divideArray(data);
        setPathologyResults(dividedIntoTwo);
      })
      .catch((err) => console.log(err));
  }

  const getRadiologyTests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/radiology/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      // if(response.data.data)
      setRadiologyData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  function getPhysicalData() {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${3}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        // const dividedIntoTwo = divideArray(data);
        setPhysicalResults(data);
      })
      .catch((err) => console.log(err));
  }

  const getPhysicalTests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/reports/physical/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setPhysicalData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  function getFinalComment() {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-user-results/${userId}}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      )
      .then((response) => {
        // setPhysicalData(response?.data)
        const data = response?.data;
        const finComment = data?.final_comments;
        setFinalComment(finComment);
      })
      .catch((err) => console.log(err));
  }

  function getPhyComment() {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-comments/${userId}}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // setPhysicalData(response?.data)
        const data = response?.data;
        const phyC = data?.comments;
        setPhyComment(phyC);
      })
      .catch((err) => console.log(err));
  }

  function getSystemData() {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/settings`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const allData = response?.data?.data[0];
        setSystemData(allData);
        // formData.append('name', data.name)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPhysicalFieds() {
    try {
      const dataToSend = {
        invIds: invIds,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-data/3`, // 3 is for physical(Investigation Group Id)
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = response?.data?.data;
      data?.forEach((obj) => {
        obj.info = obj?.investigation_tests[0]?.info?.replace(/<[^>]*>/g, "");
      });

      setPhysicalInvTests1(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getRadiologyFieds() {
    try {
      const dataToSend = {
        invIds: invIds,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-data/2`, // 3 is for physical(Investigation Group Id)
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = response?.data?.data?.investigation_tests;
      // data?.forEach((obj) => {
      //   obj.info = obj?.investigation_tests[0]?.info?.replace(/<[^>]*>/g, "");
      // });

      setRadiologyInvTests1(data);
    } catch (error) {
      console.log(error);
    }
  }

  const getPathologyResultData = async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/reports/get-healthcard-results/${userId}/${1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {})
      .catch((err) => console.log(err));
  };

  async function getPathologyFieds() {
    try {
      const dataToSend = {
        invIds: invIds,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/get-data/1`, // 1 is for pathology(Investigation Group Id)
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = response?.data?.data;

      data?.forEach((obj) => {
        obj.info = obj?.investigation_tests[0]?.info?.replace(/<[^>]*>/g, "");
      });
      setPathologyInvTests1(data);
    } catch (error) {
      console.log(error);
    }
  }

  const generateBarcode = (text) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {
      format: "CODE128",
      width: 1,
      height: 20,
      displayValue: false,
    });
    return canvas.toDataURL();
  };

  const groupedData = useMemo(() => {
    return physicalData.reduce((acc, item) => {
      const categoryName = item?.investigation?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalData]);

  const groupedPhysicalInvTest1 = useMemo(() => {
    return physicalInvTest1.reduce((acc, item) => {
      const categoryName = item?.investigation_category?.name;
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [physicalInvTest1]);

  const groupedPathologyInvTest1 = useMemo(() => {
    return pathologyInvTest1.reduce((acc, item) => {
      const categoryName = item?.investigation_category?.name;
      // console.log("cat name", categoryName);
      // console.log("item", item);
      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }
      acc[categoryName].items.push(item);
      return acc;
    }, {});
  }, [pathologyInvTest1]);

  const groupedPathologyData = useMemo(() => {
    return pathologyData.reduce((acc, item) => {
      const categoryName =
        item?.investigation_test?.investigation?.investigation_category?.name;

      if (!categoryName) return acc;
      if (!acc[categoryName]) {
        acc[categoryName] = {
          name: categoryName,
          items: [],
        };
      }

      acc[categoryName].items.push(item);

      return acc;
    }, {});
  }, [pathologyData, patientData?.gender?.id]);

  useEffect(() => {
    getPathologyData();
  }, []);

  useEffect(() => {
    getPhysicalData();
  }, []);

  useEffect(() => {
    getPathologyResultData();
  }, []);

  useEffect(() => {
    getPhysicalFieds();
    getPathologyFieds();
    getRadiologyFieds();
    getPhysicalTests();
    getSystemData();
    getPhyComment();
    getFinalComment();
    getRadiologyTests();
  }, [state]);

  // pathologyStatusCheckHandler(pathologyResultData);

  useEffect(() => {
    setXrayImage(radiologyData?.find((item) => item?.user_file !== ""));
  }, [radiologyData]);

  useEffect(() => {
    radiologyData &&
      radiologyData.forEach((item) => {
        if (item?.investigation_id === 31 && item.test_id === 1) {
          setXrayImage(item?.user_file);
        }
      });
  }, [radiologyData]);

  const findings = radiologyData?.find(
    (x) => x?.investigation_test?.name === "Findings"
  )?.result;
  const patientIdBarcode = document.createElement("canvas");
  JsBarcode(patientIdBarcode, String(patientData?.user?.registration_no), {
    format: "CODE128", // Use CODE128 format,
    width: 1,
    height: 20,
  });
  const patientIdDataUrl = patientIdBarcode.toDataURL();
  const patientRegNameDataUrl = generateBarcode(
    String(`${state?.record?.user?.f_name} - ${age}`)
  );
  console.log(radiologyData.length);
  return (
    <div className="medical-form mx-3">
      <div className="row align-items-center">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${systemData?.header_image}`}
          alt="Report Header"
          className="mx-auto"
          style={{ width: "100%" }}
        />
      </div>
      <div className="text-center text-uppercase text-dark w-100 fs-6">
        Medical Form
      </div>
      <div className="">
        <div className="row">
          <div className="col-sm-4 h-100">
            <div
              className="w-100 border border-dark border-end-0 d-flex flex-column justify-content-center p-1"
              style={{ height: "90px" }}
            >
              <p className="p-0 m-0 w-100 text-center fw-bold d-flex align-items-center justify-content-center text-dark">
                DATE:{" "}
                {formatDate(
                  state?.record?.user?.createdAt,
                  "dd-MM-yyyy hh:mm a"
                )}
              </p>
              <img
                src={patientIdDataUrl}
                alt=""
                className="patient-barcode2"
                style={{
                  height: "70px",
                }}
              />
            </div>
          </div>
          <div className="col-sm-2 h-100">
            <div
              className="report-avatar border border-end-0 border-dark"
              style={{ height: "90px" }}
            >
              {patientData?.user?.image ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.image}`}
                  className="img-fluid"
                  alt="patient"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              ) : (
                <img
                  src="./dummy.jpg"
                  alt="patient"
                  className="img-fluid"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-sm-2 h-100">
            <div
              className="report-avatar border border-end-0 border-dark"
              style={{ height: "90px" }}
            >
              {patientData?.user?.finger_print ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/users/${patientData?.user?.finger_print}`}
                  className="img-fluid"
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              ) : (
                <div
                  className="p-0 border border-end-0 text-center report-avatar text-dark d-flex align-items-center justify-content-center"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  No Fingerprint Found
                </div>
              )}
            </div>
          </div>
          {/* <div className="col-sm-2 h-100">
            <div
              className="report-avatar border border-end-0 border-start-0 border-bottom-0 border-dark"
              style={{ height: "90px", width: "100%", overflow: "hidden" }}
            >
              <table
                className="table mt-sm-0 mb-0 border border-end-0 border-top-0 border-bottom-0 border-dark"
                id="xrayImage"
                style={{
                  height: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: 0, height: "100%", width: "100%" }}>
                      {!!xrayImage ? (
                        <img
                          src={`${process.env.REACT_APP_UPLOAD_URL}/${xrayImage}`}
                          alt="X-Ray film"
                          className="img-fluid"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                          }}
                        />
                      ) : (
                        <div
                          className="text-center d-flex align-items-center justify-content-center fw-bold"
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          No X-Ray film found
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="p-0 m-0 w-100 border border-top-0 border-end-0 border-dark text-center fw-bold d-flex align-items-center justify-content-center text-dark">
              X-RAY IMAGE
            </p>
          </div> */}
          <div className="col-sm-4 h-100">
            <div
              className="w-100 border border-dark d-flex flex-column justify-content-between"
              style={{ height: "90px" }}
            >
              <img
                src={patientRegNameDataUrl}
                alt=""
                className="patient-barcode2"
                style={{
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 text-dark">
        <div className="row">
          <table
            className="responsive-width1 table-bordered"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <tbody>
              {/* Row 1 - Name and Client ID */}
              <tr>
                <td
                  className="text-uppercase border border-dark ps-1 align-middle"
                  style={{ width: "25%" }}
                >
                  Name
                </td>
                <td
                  className="text-uppercase border border-dark ps-1 align-middle"
                  style={{ width: "25%" }}
                >
                  {state?.record?.user?.f_name || "---"}
                </td>
                <td
                  className="text-uppercase border border-dark ps-1 align-middle"
                  style={{ width: "25%" }}
                >
                  Client ID
                </td>
                <td
                  className="text-uppercase border border-dark ps-1 align-middle"
                  style={{ width: "25%" }}
                >
                  {state?.record?.user?.registration_no || "---"}
                </td>
              </tr>

              {/* Row 2 - Gender and Passport No */}
              <tr>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  Gender
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  {state?.record?.gender?.name || "---"}
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  Passport No
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  {state?.record?.user?.passport_no || "---"}
                </td>
              </tr>

              {/* Row 3 - Date of Birth and Travelling To */}
              <tr>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  {state?.record?.user?.role_id !== 5 ? `Date of Birth` : `Age`}
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  {state?.record?.user?.role_id !== 5
                    ? formattedBirthDate || "---"
                    : state?.record?.user?.age || "---"}
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  Travelling To
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  {state?.record?.country?.name || "---"}
                </td>
              </tr>

              {/* Row 4 - Father Name and Contact Number */}
              {state?.record?.user?.role_id !== 5 && (
                <tr>
                  <td className="text-uppercase border border-dark ps-1 align-middle">
                    Father Name
                  </td>
                  <td className="text-uppercase border border-dark ps-1 align-middle">
                    {state?.record?.father_name || "---"}
                  </td>
                  <td className="text-uppercase border border-dark ps-1 align-middle">
                    Contact Number
                  </td>
                  <td className="text-uppercase border border-dark ps-1 align-middle">
                    {state?.record?.user?.mobile || "---"}
                  </td>
                </tr>
              )}

              {/* Row 5 - Nationality and Agency */}
              <tr>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  Nationality
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  {state?.record?.national_country?.nationality || "---"}
                </td>
                <td className="text-uppercase border border-dark ps-1 align-middle">
                  {state?.record?.user?.role_id !== 5
                    ? `${systemData?.introducer_label}`
                    : `Reference`}
                </td>
                <td
                  className="text-uppercase border border-dark ps-1 align-middle"
                  colSpan={3}
                >
                  {state?.record?.user?.role_id !== 5
                    ? state?.record?.introducer_user?.f_name || "---"
                    : state?.record?.user?.reference || "---"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-12 d-flex flex-column flex-sm-row">
          <div className="col-sm-6">
            <table className="table border border-dark mb-0 fw-bold">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-center"
                    style={{ color: "black" }}
                    colSpan={3}
                  >
                    Medical Examination
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={1}
                    className="border text-center py-1 border border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    TYPE OF EXAMINATION
                  </th>
                  <th
                    className="border text-center border py-1 border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    RESULTS
                  </th>
                  <th
                    className="border text-center border py-1 border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    REF. VALUE
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {groupedData && Object.keys(groupedData)?.length > 0
                  ? Object.entries(groupedData)?.map(
                      ([categoryId, categoryData]) => (
                        <React.Fragment key={categoryId}>
                          <tr>
                            <td
                              colSpan="3"
                              className="text-nowrap text-uppercase"
                            >
                              {categoryData?.name}
                            </td>
                          </tr>
                          {categoryData?.items?.map((data, index) => {
                            // Log the data object for debugging
                            return (
                              <tr key={index}>
                                <td className="border text-start py-1 ps-5 border-dark text-dark">
                                  {data?.investigation?.name || "---"}
                                </td>
                                <td className="border text-start py-1 border-dark text-dark">
                                  {data?.result || "---"}{" "}
                                  {data?.result &&
                                    data?.result !== "N/A" &&
                                    data?.investigation?.investigation_tests[0]
                                      ?.uom?.name}
                                </td>
                                <td className="border text-start py-1 border-dark text-dark">
                                  {data?.investigation?.investigation_tests[0]
                                    ?.report_normal_value || ""}
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      )
                    )
                  : Object.entries(groupedPhysicalInvTest1)?.map(
                      ([categoryId, categoryData]) => {
                        return (
                          <React.Fragment key={categoryId}>
                            <tr>
                              <td
                                colSpan="3"
                                className="text-nowrap text-uppercase"
                              >
                                {categoryData?.name}
                              </td>
                            </tr>
                            {categoryData?.items?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td className="border text-start py-1 ps-5 border-dark ">
                                    {data?.investigation_tests[0]?.name ||
                                      "---"}
                                  </td>
                                  <td className="border text-start py-1 border-dark "></td>
                                  <td className="border text-start py-1 border-dark "></td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      }
                    )}

                {radiologyData && (
                  <tr>
                    <td className="text-nowrap text-uppercase">
                      X-RAY INVESTIGATION
                    </td>
                  </tr>
                )}
                {radiologyData?.length > 0
                  ? radiologyData?.map((item, i) => (
                      <tr key={i}>
                        <td className="border text-start py-1 ps-5 border-dark">
                          {item?.investigation_test?.name === "Findings"
                            ? "Comment"
                            : `${item?.investigation_test?.name}`}
                        </td>
                        <td
                          className={`border text-start py-1 border-dark  ${
                            item?.test_id === 35 &&
                            item?.investigation_test?.name === "Findings"
                              ? item?.result === "Normal CXR"
                                ? "text-success text-uppercase"
                                : "text-primary"
                              : ""
                          }`}
                          colSpan={2}
                        >
                          {item?.result}
                        </td>
                      </tr>
                    ))
                  : radiologyInvTest1?.map((item, i) => (
                      <tr key={i}>
                        <td className="border text-start py-1 ps-5 border-dark">
                          {item?.name === "Findings"
                            ? "Comment"
                            : `${item?.name || ""}`}
                        </td>
                        <td className="border text-start py-1 border-dark "></td>
                        <td className="border text-start py-1 border-dark "></td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex flex-column justify-content-between col-sm-6 ps-sm-3 ps-0">
            <table className="table border border-dark mb-0 fw-bold">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-center"
                    style={{ color: "black" }}
                    colSpan={3}
                  >
                    Laboratory Investigation
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={1}
                    className="border text-center py-1 border border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    TYPE OF EXAMINATION
                  </th>
                  <th
                    className="border text-center border py-1 border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    RESULTS
                  </th>
                  <th
                    className="border text-center border py-1 border-dark text-nowrap"
                    style={{ color: "black" }}
                  >
                    REF. VALUE
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {groupedPathologyData &&
                Object.keys(groupedPathologyData)?.length > 0
                  ? Object.entries(groupedPathologyData)?.map(
                      ([categoryId, categoryData]) => (
                        <React.Fragment key={categoryId}>
                          <tr>
                            <td
                              colSpan="3"
                              className="text-nowrap text-uppercase"
                            >
                              {categoryData?.name}
                            </td>
                          </tr>
                          {categoryData?.items?.map((data, index) => (
                            <tr key={index}>
                              <td className="border text-start py-1 ps-5 border-dark  text-dark">
                                {data?.investigation_test?.investigation
                                  ?.name || "---"}
                              </td>
                              <td className="border text-start py-1 border-dark  text-dark">
                                {data?.result || "---"}{" "}
                                {data?.result &&
                                  data?.result !== "N/A" &&
                                  data?.investigation_test?.uom?.name}
                              </td>
                              <td className="border text-start py-1 border-dark  text-dark">
                                {data?.investigation_test
                                  ?.report_normal_value || ""}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      )
                    )
                  : Object.entries(groupedPathologyInvTest1)?.map(
                      ([categoryId, categoryData]) => {
                        // console.log("this", categoryId);
                        return (
                          <React.Fragment key={categoryId}>
                            <tr>
                              <td
                                colSpan="3"
                                className="text-nowrap text-uppercase"
                              >
                                {categoryData?.name}
                              </td>
                            </tr>
                            {categoryData?.items?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td className="border text-start py-1 ps-5 border-dark ">
                                    {data?.investigation_tests[0]?.name ||
                                      "---"}
                                  </td>
                                  <td className="border text-start py-1 border-dark "></td>
                                  <td className="border text-start py-1 border-dark "></td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      }
                    )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-1 mx-auto">
        <div className="d-flex col-9 border border-dark p-1">
          <p className="fw-bold mb-0 text-dark">COMMENTS:</p>
          <p className="fw-bold mb-0 ps-2">
            {finalComment ? `Final Comment: ${finalComment}` : ""}
          </p>
          <p className="fw-bold mb-0">
            {phyComment ? `Physical: ${phyComment}` : ""}
          </p>
          <p className="fw-bold mb-0 text-dark">
            {status ? `Pathology: ${status}` : ""}
          </p>
          <p className="fw-bold mb-0 text-dark">
            {findings ? `X-Ray: ${findings}` : ""}
          </p>
        </div>
        <div className="col-3 border-dark border-start-0 border">
          <p className="fw-bold mt-4 mb-0 text-center text-dark">Authorised</p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end mt-3">
        <div className="text-dark medical-form-sign-1">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Radiologist
        </div>
        <div className="text-dark medical-form-sign-2">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Pathologist
        </div>
        <div className="text-dark medical-form-sign-3">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Technologist
        </div>
        <div className="text-dark medical-form-sign-4">
          <div
            className="border-top mt-0 border-dark"
            style={{ width: "125px" }}
          ></div>
          Physician
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end printed-by">
        <div className="text-start text-dark p-0 mt-1">
          Printed By: {state?.record?.user?.created_by}
        </div>
      </div>
      {stateData ? (
        stateData !== 1 ||
        (stateDataInv === 2 && (
          <div className="print-button d-flex gap-2 mt-3 justify-content-end">
            <button
              className="btn btn-outline-secondary"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
            {accessPerm(32, 23) && (
              <button
                className="btn btn-primary"
                onClick={() => window.print()}
              >
                Print
              </button>
            )}
          </div>
        ))
      ) : (
        <div className="print-button d-flex gap-2 mt-3 justify-content-end">
          <button
            className="btn btn-outline-secondary"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
          {accessPerm(32, 23) && (
            <button className="btn btn-primary" onClick={() => window.print()}>
              Print
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MedicalFormCopy;
