import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { UserContext } from "../../../Context/UserContextAPI";
import Camera from "../../../components/Camera/Camera";
import VirtualizedMenuList from "../../../components/VirtualMenuList/VirtualMenuList";
import { format, addDays } from "date-fns";

const CreatePatientInformation = () => {
  const {
    smsAPI,
    currentUser,
    introducerUsers,
    packages,
    delegateUsers,
    countryUsers,
  } = useContext(UserContext);
  const [genders, setGenders] = useState([]);
  const [reportDelivery, setReportDelivery] = useState(() => {
    const futureDate = addDays(new Date(), 5);
    return format(futureDate, "yyyy-MM-dd"); // Format required for date input
  });
  const [packagePrice, setPackagePrice] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [meritalStatus, setMeritalStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [states, setStates] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [packageName, setPackageName] = useState("");
  // const [selectedIntroducer, setSelectedIntroducer] = useState(false);

  const [companyUsers, setCompanyUsers] = useState([]);
  const [delegetsUsers, setDelegetsUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [representativeUsers, setRepresentativeUsers] = useState([]);
  const [mappedIntroReps, setMappedIntroReps] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [imgSrc, setImgSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewUserPhoto, setPreviewUserPhoto] = useState(null);
  const [representativeId, setRepresentativeId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [refresh, setRefresh] = useState([]);
  const navigate = useNavigate();
  // const handlePackagePriceChange = (e) => {
  //   const newPrice = Number(e.target.value); // Convert string input to number
  //   setPackagePrice(newPrice);
  //   setPaidAmount(newPrice); // Automatically set paidAmount to the same value
  // };

  const getMappedIntroReps = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users-map`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setMappedIntroReps(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlerOnSubmitForm = async (e) => {
    e.preventDefault();
    // if (selectedIntroducer === true) {
    //   toast.error(
    //     "Please assign representative to introducer first to register!"
    //   );
    //   return;
    // }

    const form = e.target;
    const f_name = form.f_name.value;
    const l_name = "";
    const mobile = form.mobile.value;
    const report_delivery_date = form.report_delivery_date.value;
    const image = form.image.files[0];
    const finger_print = form.finger_print.files[0];
    const gender_id = form.gender_id.value;
    const passport_no = form.passport_no.value;
    const nid = "";
    const email = "";
    const date_of_birth = form.date_of_birth.value;
    const marital_status_id = form.marital_status_id.value;
    const father_name = form.father_name.value;
    const mother_name = form.mother_name.value;
    const country_id = form.country_id.value;
    const state_id = "";
    const city_id = "";
    const nationality_id = "18";
    const profession_id = form.profession_id.value;
    const company_id = form.company_id.value;
    const delegates_id = form.delegates_id.value;
    const introducer_id = form.introducer_id.value;
    const representative_id = representativeId;
    const package_id = form.package_id.value ? form.package_id.value : 0;
    const address_1 = form.address_1.value;
    const remarks = form.remarks.value;
    const package_price = form.package_price.value;
    const package_name = packageName;
    const paid_amount = form.paid_amount.value || 0;
    const payment_method_id = form.payment_method_id.value;
    const role_id = 4;

    const formData = new FormData();
    if (image) {
      formData.append("image", image);
    }

    if (finger_print) {
      formData.append("finger_print", finger_print);
    }

    if (passport_no) {
      formData.append("passport_no", passport_no);
    }

    const patientData = {
      f_name,
      l_name,
      mobile,
      report_delivery_date,
      gender_id,
      passport_no,
      nid,
      email,
      date_of_birth,
      marital_status_id,
      father_name,
      mother_name,
      country_id,
      state_id,
      city_id,
      nationality_id,
      profession_id,
      company_id,
      delegates_id,
      introducer_id,
      representative_id,
      package_id,
      address_1,
      remarks,
      package_name,
      package_price,
      paid_amount,
      payment_method_id,
      created_by: currentUser?.f_name,
      role_id,
    };

    if (imgSrc && finger_print && !image) {
      // console.log(uploadUserImage(imgSrc));
      const cameraImage = await uploadUserImage(imgSrc);
      if (cameraImage) {
        patientData.image = cameraImage;

        const imagePath = await methodUploadUsersPhotoFile(formData);
        if (imagePath?.finger_print) {
          patientData.finger_print = imagePath?.finger_print;
          methodCreateUserDetails(patientData, form);
        }
      }
    } else if (imgSrc && !finger_print && !image) {
      const cameraImage = await uploadUserImage(imgSrc);
      if (cameraImage) {
        patientData.image = cameraImage;
        methodCreateUserDetails(patientData, form);
      }
    } else if (finger_print && !imgSrc && !image) {
      const imagePath = await methodUploadUsersPhotoFile(formData);
      if (imagePath?.finger_print) {
        patientData.finger_print = imagePath?.finger_print;
        methodCreateUserDetails(patientData, form);
      }
    } else if (image && finger_print && !imgSrc) {
      const imagePath = await methodUploadUsersPhotoFile(formData);
      if (imagePath?.image && imagePath?.finger_print) {
        patientData.image = imagePath?.image;
        patientData.finger_print = imagePath?.finger_print;
        methodCreateUserDetails(patientData, form);
      } else {
        if (imagePath?.image) {
          patientData.image = imagePath?.image;
          methodCreateUserDetails(patientData, form);
        }
        if (imagePath?.finger_print) {
          patientData.finger_print = imagePath?.finger_print;
          methodCreateUserDetails(patientData, form);
        }
      }
    } else if (image && !finger_print && !imgSrc) {
      const imagePath = await methodUploadUsersPhotoFile(formData);
      if (imagePath?.image) {
        patientData.image = imagePath?.image;
        methodCreateUserDetails(patientData, form);
      }
    } else {
      methodCreateUserDetails(patientData, form);
    }
  };

  const methodUploadUsersPhotoFile = async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/upload-image`,
        formData
      );
      if (response) {
        const imagePath = response?.data;
        return imagePath;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadUserImage = async (imgSrc) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/upload-base64-photo`,
        {
          imgSrc: imgSrc,
        }
      );
      const imagePath = res.data;
      return imagePath;
    } catch (error) {
      console.log(error);
    }
  };

  const methodCreateUserDetails = async (patientData, form) => {
    setIsSubmitting(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/create`,
        patientData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const userId = res?.data?.data?.user?.id;
      const mobileNumber = res?.data?.data?.user?.mobile;
      const customerName = `${res?.data?.data?.user?.f_name}`;
      const regNo = res?.data?.data?.user?.registration_no;
      const message = `Dear ${customerName}, Your Registration has been Completed Successfully! Your registration number is: ${regNo}. Thank You!`;
      const paidAmount = res?.data?.data?.paymentResult?.paid_amount;
      const paymentMessage = `Dear ${customerName}, We have received your payment BDT ${paidAmount}. Thank you for your payment!`;
      const introducerUser = introducerUsers?.find(
        (introducer) => introducer?.id === parseInt(patientData?.introducer_id)
      );
      const introducerName = `${introducerUser?.f_name}`;
      const introducerMobile = introducerUser?.mobile;
      if (
        res.status === 201 ||
        res?.data?.status === "OK" ||
        res.status.statusText === "Created"
      ) {
        if (patientData?.introducer_id && introducerUser?.SMSChecked === 0) {
          const verificationURL = `https://${process.env.REACT_APP_API_BASE_URL}:5005/medical-verification/result/report/print/${userId}`;
          const represForIntro = mappedIntroReps?.find(
            (mapped) =>
              mapped.map_user_id === parseInt(patientData?.introducer_id)
          );
          const representativeName =
            represForIntro?.representative_user_map?.f_name;
          const introducerMessage = `Dear ${introducerName}, Representative (${representativeName}), Patient (${customerName}) Registration has been Completed Successfully! Registration number: ${regNo}. Please click the link ${verificationURL} to show your medical result.  Thank You!`;

          smsAPI(introducerMobile, introducerMessage)
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              // console.log(err);
            });
        }

        smsAPI(mobileNumber, message)
          .then((res) => {
            if (paidAmount) {
              smsAPI(mobileNumber, paymentMessage)
                .then((res) => {
                  // console.log(res);
                })
                .catch((err) => {
                  // console.log(err);
                });
            }
            toast.success("User Details Created successfully!!");
            setRefresh(!refresh);
            form.reset();
            setIsSubmitting(false);
            setPackagePrice(0);
            navigate("/dashboard/patient-info");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error(res?.data?.message);
        setRefresh(!refresh);
        setIsSubmitting(false);
      }
    } catch (error) {}
  };

  const fetchStatesAPI = async (e) => {
    try {
      const country_id = e.value;
      // console.log(country_id);

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/countrys/${country_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      const data = res?.data?.data;
      setStates(data);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMeritalStatusAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/marital-status`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      // console.log(res);
      setMeritalStatus(res?.data?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchCountriesAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/country`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setCountries(res?.data?.data);

      const filterNationalities = res?.data?.data?.filter(
        (filterNationality) => {
          return filterNationality?.nationality !== null;
        }
      );
      setNationalities(filterNationalities);
    } catch (error) {}
  };

  const fetchProfessionsAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/professions`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      // console.log(res);
      setProfessions(res?.data?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchGendersAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/gender`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setGenders(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsersAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/all-users`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      setUsers(res?.data?.data?.data);

      const filterCompanyUsers = res?.data?.data?.data?.filter(
        (filterCompanyUser) => {
          return filterCompanyUser?.role_id === 6;
        }
      );
      setCompanyUsers(filterCompanyUsers);

      const filterDelegetesUsers = res?.data?.data?.data?.filter(
        (filterDelegetsUser) => {
          return filterDelegetsUser?.role_id === 7;
        }
      );
      setDelegetsUsers(filterDelegetesUsers);

      const filterRepresentativeUsers = res?.data?.data?.data?.filter(
        (filterRepresentativeUser) => {
          return filterRepresentativeUser?.role_id === 9;
        }
      );
      setRepresentativeUsers(filterRepresentativeUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPaymentMethodsAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/account-head`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const filterPaymentMethods = res?.data?.data?.filter(
        (filterPaymentMethod) => {
          return (
            filterPaymentMethod?.head_type_id === 3 ||
            filterPaymentMethod?.head_type_id === 4
          );
        }
      );

      setPaymentMethods(filterPaymentMethods);
    } catch (error) {
      console.log(error);
    }
  };

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      // const representativeMobile =
      //   user?.user_users_maps[0]?.representative_user_map?.mobile;

      // const mobileLabel = representativeMobile
      //   ? `(${representativeMobile.slice(-2)})`
      //   : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        // label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
        label: `${user?.registration_no} - ${user?.f_name}`,
      };
    }),
  ];

  const countryOptions = [
    { value: "", label: "Select Country" },
    ...(countryUsers?.map((country) => ({
      value: parseInt(country?.id),
      label: `${country?.shortname} - ${country?.name}`,
    })) || []),
  ];

  const companyOptions = [
    { value: "", label: "Select Company" },
    ...(companyUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const delegateOptions = [
    { value: "", label: "Select Delegate" },
    ...(delegateUsers?.map((user) => ({
      value: parseInt(user?.id),
      label: `${user?.f_name}`,
    })) || []),
  ];

  const nationalityOptions = countries?.map((country) => ({
    value: parseInt(country?.id),
    label: country?.nationality,
  }));
  const defaultNationality = [{ value: 18, label: "Bangladeshi" }];

  // Initialize the state for selected values
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelect = (selectedOptions) => {
    // setSelectedIntroducer(
    //   selectedOptions?.label?.toLowerCase().includes("not assigned")
    // );
    setSelectedValues(selectedOptions);
    const introducerId = selectedOptions.value;
    const mappedIntroRep = mappedIntroReps.find(
      (mapped) => mapped.map_user_id === introducerId
    );
    const representativeId =
      mappedIntroRep?.representative_user_map?.id || null;
    setRepresentativeId(representativeId);
  };

  const handlePackagePriceChange = (e) => {
    const newPrice = Number(e.target.value);
    setPackagePrice(newPrice);
  };

  const handlePaidAmountChange = (e) => {
    const newPaidAmount = Number(e.target.value);
    setPaidAmount(newPaidAmount);
  };

  useEffect(() => {
    fetchMeritalStatusAPI();
    fetchCountriesAPI();
    fetchProfessionsAPI();
    fetchGendersAPI();
    fetchUsersAPI();
    fetchPaymentMethodsAPI();
    getMappedIntroReps();
  }, []);

  useEffect(() => {
    setPaidAmount(packagePrice);
  }, [packagePrice]);

  const previewImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreview(e.target.files[0]);
    }
  };
  const previewUserPhotoFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewUserPhoto(e.target.files[0]);
    }
  };
  return (
    <>
      <>
        <div className="row">
          <div className="col-xl-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  onSubmit={(e) => handlerOnSubmitForm(e)}
                >
                  <div className="border p-4 rounded">
                    <div className="card-title d-flex align-items-center">
                      <h5 className="mb-0">Add New Patient Information</h5>
                    </div>
                    <hr />
                    <div className="row ps-3 mb-5 ">
                      <div className="d-flex gap-3 ">
                        <div className="mb-3 d-flex align-items-center justify-content-center ">
                          <div className="d-flex flex-column">
                            <label className=" col-form-label">
                              User Photo
                            </label>

                            <div
                              onClick={() => {
                                setImgSrc(null);
                              }}
                            >
                              <label
                                id="user-photo-input-label"
                                htmlFor="user-photo-input"
                                className="btn btn-light border border-dark py-0"
                                style={{ fontSize: "0.8rem" }}
                              >
                                Select
                              </label>
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              name="image"
                              id="user-photo-input"
                              className="form-control"
                              hidden={true}
                              onChange={(e) => previewUserPhotoFile(e)}
                            />
                          </div>
                          <div
                            className="ms-4"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => {
                              setPreviewUserPhoto(null);
                            }}
                          >
                            <img
                              src={
                                imgSrc
                                  ? imgSrc
                                  : previewUserPhoto
                                  ? URL.createObjectURL(previewUserPhoto)
                                  : "/userimg.png"
                              }
                              // name='image'
                              alt="user_photo"
                              className="img-fluid rounded-3 "
                              style={{ width: "8rem", height: "9rem" }}
                            />
                          </div>
                        </div>

                        <div className="mb-3 d-flex align-items-center justify-content-center ">
                          <div className="d-flex align-items-center justify-content-center gap-4">
                            <label className=" col-form-label">
                              Finger Print
                            </label>
                            <label htmlFor="file-input">
                              <input
                                type="file"
                                accept="image/*"
                                name="finger_print"
                                id="file-input"
                                className="form-control"
                                onChange={(e) => previewImage(e)}
                                style={{ display: "none" }}
                              />
                              {preview ? (
                                <img
                                  src={URL.createObjectURL(preview)}
                                  alt=""
                                  className="img-fluid rounded-3"
                                  style={{ width: "8rem", height: "9rem" }}
                                />
                              ) : (
                                <img
                                  src="/no-fingerprint.jpg"
                                  alt=""
                                  className="img-fluid rounded-3"
                                  style={{ width: "8rem", height: "9rem" }}
                                />
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-sm-6 px-3">
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Introducer <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <Select
                              components={{ MenuList: VirtualizedMenuList }}
                              options={options}
                              placeholder="Select Introducer"
                              onChange={handleSelect}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  color: "black",
                                  // border: !selectedIntroducer
                                  //   ? state.border
                                  //   : "1px solid red",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "black",
                                }),
                              }}
                              isSearchable={true}
                              value={selectedValues}
                              name="introducer_id"
                              required
                            />
                            {/* {selectedIntroducer && (
                              <p className="text-danger p-0 m-0 text-start">
                                Assign Representative to this Introducer first!
                              </p>
                            )} */}
                          </div>
                        </div>
                        {/* <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Reg. No <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="registration_no"
                              className="form-control"
                              required
                              defaultValue={maxRegistration}
                            />
                          </div>
                        </div> */}
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Full Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="f_name"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Father's Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="father_name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Mother's Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="mother_name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Passport No
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="passport_no"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Date of Birth
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="date_of_birth"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Gender <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-select"
                              name="gender_id"
                              aria-label="Default select example"
                              required
                            >
                              <option value={""}>Choose One...</option>
                              {genders?.map((gender) => (
                                <option
                                  key={gender?.id}
                                  value={parseInt(gender?.id)}
                                >
                                  {gender?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Marital Status
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-select"
                              name="marital_status_id"
                              aria-label="Default select example"
                            >
                              <option value={""}>Choose One...</option>
                              {meritalStatus?.map((meritSts) => (
                                <option
                                  key={meritSts?.id}
                                  value={parseInt(meritSts?.id)}
                                >
                                  {meritSts?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Nationality
                          </label>
                          <div className="col-sm-9">
                            <Select
                              name="nationality_id"
                              options={nationalityOptions}
                              defaultValue={defaultNationality[0]}
                              onChange={(e) => fetchStatesAPI(e)}
                              isSearchable={true}
                              isDisabled
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Profession
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="profession_id"
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value={""}>Choose One...</option>
                              {professions?.map((profession) => (
                                <option
                                  key={profession?.id}
                                  value={parseInt(profession?.id)}
                                >
                                  {profession?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Country <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <Select
                              name="country_id"
                              options={countryOptions}
                              onChange={(e) => fetchStatesAPI(e)}
                              value={countryOptions?.value}
                              isSearchable={true}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 px-3">
                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="remarks"
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Address
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              name="address_1"
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                            ></textarea>
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Contact Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="tel"
                              name="mobile"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Report Delivery Date
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="date"
                              name="report_delivery_date"
                              className="form-control"
                              defaultValue={reportDelivery}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Company
                          </label>
                          <div className="col-sm-9">
                            <Select
                              components={{ MenuList: VirtualizedMenuList }}
                              name="company_id"
                              className="fw-light"
                              options={companyOptions}
                              value={selectedValues.company}
                              isSearchable={true}
                              onChange={(selectedOptions) =>
                                handleSelect("company", selectedOptions)
                              }
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Delegate
                          </label>
                          <div className="col-sm-9">
                            <Select
                              components={{ MenuList: VirtualizedMenuList }}
                              name="delegates_id"
                              className="fw-light"
                              options={delegateOptions}
                              value={selectedValues.delegate}
                              isSearchable={true}
                              onChange={(selectedOptions) =>
                                handleSelect("delegate", selectedOptions)
                              }
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Package <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="package_id"
                              className="form-select"
                              aria-label="Default select example"
                              required
                              onChange={(e) => {
                                const singlePackage = packages.find(
                                  (p) => p.id === parseInt(e.target.value)
                                );
                                setPackageName(singlePackage?.name);
                                setPackagePrice(singlePackage?.price);
                                //show regular always
                                // if (singlePackage) {
                                //   singlePackage?.discounted_price > 0
                                //     ? setPackagePrice(
                                //         singlePackage?.discounted_price
                                //       )
                                //     : setPackagePrice(singlePackage?.price);
                                // }
                              }}
                            >
                              <option value={""}>Select...</option>
                              {packages?.map((singlePackage) => (
                                <option
                                  key={singlePackage?.id}
                                  value={parseInt(singlePackage?.id)}
                                >
                                  {singlePackage?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Total Amount
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="package_price"
                              value={packagePrice}
                              onChange={handlePackagePriceChange}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Paid Amount
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="paid_amount"
                              value={paidAmount} // This reflects the current paidAmount state
                              onChange={handlePaidAmountChange} // Allows user to manually change the paidAmount
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label d-flex justify-content-start">
                            Payment Method{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-select"
                              name="payment_method_id"
                              aria-label="Default select example"
                              required
                            >
                              <option value="">Choose One</option>
                              {paymentMethods?.map((payment) => (
                                <option
                                  key={payment.id}
                                  value={parseInt(payment?.id)}
                                  selected={payment.head_type_id === 3}
                                >
                                  {payment?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end gap-2">
                      <div className="">
                        <button
                          type="submit"
                          className="btn btn-outline-secondary px-2 mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/dashboard/patient-info");
                          }}
                        >
                          Go Back{" "}
                          <span>
                            <i className="bi bi-arrow-left" />
                          </span>
                        </button>
                      </div>
                      <div className="">
                        <button
                          type="submit"
                          className="btn btn-primary px-5"
                          // onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* Modal Body */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Camera
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pt-0">
              {/* <AllWebCameras /> */}
              <Camera imgSrc={imgSrc} setImgSrc={setImgSrc} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePatientInformation;
