import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import "./DashboardLayout.scss";
import NavbarModule from "../Navbar/NavbarModule";

const DashboardLayout = () => {
  const { settingsData, accessPerm, closed, setClosed } =
    useContext(UserContext);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const [data, setData] = useState({});
  const [roles, setRoles] = useState([]);

  const fetchAllUserRoles = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/roles`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );
      if (res) {
        setRoles(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllUserRoles();
    setData(settingsData);
  }, [settingsData]);

  return (
    <div>
      <header>
        <NavbarModule />
      </header>
      <div>
        <main className="page-content">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
